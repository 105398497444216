import { ExpandMore, KeyboardArrowRight, Edit, Upload, ReadMore, Link} from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';
import { Checkbox, IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTable } from "../../reducers/slices/tables/table.slice";
import useNavigate from "./useNavigate";
import iconsImage from '../../../resources/icons.png'; 
import MappedText from "../../scenes/study-page/create-study/analysis-page/components/clinical-info/mapText";
import { fileDownload } from "../utils/fileDownload";
import {deleteRequest} from "./requests/deleteRequest";
import DeleteIcon from "@material-ui/icons/Delete";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const test = (e) => {
  e.stopPropagation();
};
const useOptionFilter = (columns, type, states, setExpandedRows, data) => {
  const { reportType, urlDownload } = useSelector(state => state.generaltable);
  const dataRef = React.useRef(states?.mainData);
  const dispatch = useDispatch();
  const token = useSelector(state => state.authorization.token);
  let isToggle = states.customFeatures.some(
    (item) => item.hasOwnProperty("toggle") && item["toggle"] === true
  );

  let expanding = states.customFeatures.some(
    (item) => item.hasOwnProperty("expanding") && item["expanding"] === true
  );

  const actionsObject = states.customFeatures.find((item) => "actions" in item);
  let isEdit = actionsObject?.actions?.edit;
  let isDelete = actionsObject?.actions?.delete;
  let isUpload = actionsObject?.actions?.upload;
  let isLink = actionsObject?.actions?.link;
  let isDownload= actionsObject?.actions?.download;
  let isDownloadDoc = actionsObject?.actions?.downloadDoc;

  let showActions = false; 

  if (actionsObject && actionsObject.actions) {

    showActions = Object.values(actionsObject.actions).some(
      (value) => value === true
    );
  }

  dataRef.current = states?.mainData;

  return (hooks) => {
    const navigate = useNavigate();
    hooks.visibleColumns.push((columns) => {

      let newColumns = [...columns];

      if (expanding) {
        isToggle = false;

        newColumns = [
          {
            id: "expansion",
            disableFilters: true,
            Header: () => null, 
            Cell: ({ row }) => (
              <div>
                <span
                  {...row.getToggleRowExpandedProps()}
                  onClick={(e) => {
                    e.stopPropagation();

                    {
                      row.isExpanded
                        ? dispatch(
                            setTable({
                              option: "isLabel",
                              value: { status: false, rowId: row.id },
                            })
                          )
                        : dispatch(
                            setTable({
                              option: "isLabel",
                              value: { status: true, rowId: row.id },
                            })
                          );
                    }

                    const newData = dataRef.current[row.original.originalIndex]; 
                    setExpandedRows((prevState) => ({
                      ...prevState,
                      [row.id]: {
                        expanded: true,
                        data: [newData], 
                      },
                    }));
                    states.setRowIdSelected(row.id);
                    states.setIdExpanded({
                      id: row.id,
                      expanded: true,
                    });
                    row.toggleRowExpanded();
                  }}
                >

              { row.isExpanded ? 
              <IconButton aria-label="expandMore" color="black" >
                  <ExpandMore />
                </IconButton>
                
              :
              <IconButton aria-label="keyboardArrowRight" color="black" >
              <KeyboardArrowRight />
            </IconButton>
            }
                </span>
              </div>
            ),
          },
          ...newColumns,
        ];
      }

      if (showActions) {
        newColumns = [
          ...newColumns,
          {
            id: "actions",
            disableFilters: true,
            Header: () => isLink ? 'Evidence' : <MappedText text='Actions'/>,

            Cell: ({ row }) => (
              <Stack direction="row">
            
                {isEdit && (
                  <Tooltip title={<MappedText text="Access Request"/>}>
                    <div
                      onClick={() =>
                    navigate(`dir_overview/${row.original[states.idRelation]}`)
                  }
                  >
                   <IconButton aria-label="expandMore" color="black" >
                  <ReadMore />
                </IconButton></div>
                  </Tooltip>
                )}
                  {isDelete && (
                      <Tooltip title={<MappedText text="Delete Request"/>}>
                          <div
                              onClick={() => {

                                  const confirmDelete = window.confirm("Are you sure you want to delete this request? This action cannot be undone.");

                                  if (confirmDelete) {
                                      const idRelation = row.original[states.idRelation];
                                      // Function to handle delete request
                                      deleteRequest(idRelation)
                                          .then((response) => {
                                              if (response.ok){ console.log(response); return response.json();}
                                              else {console.log(response); throw new Error(response.statusText);}
                                          })
                                          .then((data) => {
                                              alert("Success", data)
                                              console.log('Success:', data);
                                              window.location.reload(); // Reload the page
                                          })
                                          .catch((error) => {
                                              alert("ERROR", error)
                                              console.error('ERROR:', error);
                                          });
                                  }

                              }}
                          >
                              <IconButton aria-label="delete" >
                                  <DeleteIcon />
                              </IconButton>
                          </div>
                      </Tooltip>
                  )}
                {isDownload && (
                  <Tooltip title={<MappedText text="Download"/>}>
                  <div>
                    <IconButton aria-label="expandMore" color="black" >
                    <DownloadIcon />
                  </IconButton></div>
                  </Tooltip>
                )}
                
                 {isUpload && (
                  <Tooltip title={<MappedText text="Upload"/>}>
                    <div
                      onClick={() => {
                                                dispatch(
                          setTable({
                            option: "isOpenUpload",
                            value: true,
                          })
                        );
                      }}
                    >
                     <IconButton aria-label="expandMore" color="black" >
                  <Upload />
                </IconButton></div>
               
                  </Tooltip>
                )}
                 {isLink && (
                  
              <Tooltip title={<MappedText text="Link"/>}>
              <IconButton onClick={() => window.open(window.origin, "_blank")} color="black">
                <img src={iconsImage} alt="Link Icon" style={{ width: '100%', height: '24px' }} />
              </IconButton>
               
              </Tooltip>
            )}
           {isDownloadDoc && (
          <Tooltip title={<MappedText text={"Download Report"}/>}>
          <IconButton
            aria-label="download"
            onClick={() => {
              let url = "";
              if (states.reportType === "clinical") {
                url = `${states.urlDownload}/download_clinical_report/${row.original.clinical_report_id}`;
              } else if (states.reportType === "patient") {
                url = `${states.urlDownload}/download_patient_report/${row.original.patient_report_id}`;
              }
              fileDownload(url, token);
            }}
            color="black"
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
          )}
              </Stack>
            ),
          },
        ];
      }


      if (isToggle) {
        newColumns = [
          {
            id: "selection",
            disableFilters: true,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div style={{ borderRight:"0px!important" }}>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {isToggle && (
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                )}
              </div>
            ),
          },
          ...newColumns,
        ];
      }
      return newColumns;
    });
  };
};

export default useOptionFilter;
