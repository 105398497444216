import {
  createTitle,
  createKeyValueParagraph,
  createParagraphWithSpacing,
  createBoldUnderlinedParagraph,
  createHeadTitle,
  createSpacer
} from "../../write-sections/write_utils/documentHelpers";



function AnnexesGermline(allDataApis, annexesFields) {
  return [
    createHeadTitle("ANNEXES"),
    createSpacer(50, 50),
    createParagraphWithSpacing("The following annexes complete the report, which should be reviewed as a whole."),
    createSpacer(50, 50),
    createTitle("ANNEX I: CLINICAL RECORD"),
    createTitle("ANNEX II: METHODOLOGY"),
    createTitle("ANNEX III: QUALITY CONTROL METRICS"),
    createTitle("ANNEX IV: VARIANT METRICS AND GENOTYPE-PHENOTYPE ASSOCIATIONS"),
    createTitle("ANNEX V: PHARMACOGENOMICS"),
    createBoldUnderlinedParagraph("ANNEX I: CLINICAL RECORD"),
    createParagraphWithSpacing("Clinical symptoms and physical findings", true),
    createParagraphWithSpacing(allDataApis?.participants?.[0]?.features
      ?.filter(feature => feature.observed === true) 
      .map(feature => feature.name) 
      .join(", ") || "NA" ),
    createParagraphWithSpacing("Family history", true),
    createParagraphWithSpacing(annexesFields?.family_history || "NA"),//Pending
    createBoldUnderlinedParagraph("ANNEX II: METHODOLOGY"),
    createKeyValueParagraph("Test method", `${allDataApis?.experiments?.[0].library_strategy} using the ${allDataApis?.experiments?.[0].kit }` || "NA"),
    createKeyValueParagraph("Type of variants assessed", annexesFields?.type_of_variants_assessed.toString() || "NA" ),
    createKeyValueParagraph("Sample received", annexesFields.sample_received || "NA"),//Pending
    createKeyValueParagraph("Sample type", allDataApis?.samples.sample_type || "NA"),
    createKeyValueParagraph("Date of sample collection", allDataApis?.samples.date_of_sample_collection || "NA"),
    createSpacer(50, 50),
    createTitle("Sample processing:"),
    createSpacer(50, 50),
    createKeyValueParagraph("Center", allDataApis?.centre || "NA"), //Pending
    createKeyValueParagraph("Sequencing platform", allDataApis?.sequencing_platform || "NA"),//Pending
    createKeyValueParagraph("Fragment (size and type)", allDataApis?.fragment || "NA"),//Pending
    createSpacer(50, 50),
    createTitle("Bioinformatics analysis:"),
    createSpacer(50, 50),
    createKeyValueParagraph("Human reference genome", allDataApis?.human_reference_genome || "NA"),//Pending
    createKeyValueParagraph("Aligner", allDataApis?.aligner || "NA"),//Pending
    createKeyValueParagraph("Mark duplicates", allDataApis?.mark_duplicates || "NA"),//Pending
    createKeyValueParagraph("Base recalibration", allDataApis?.base_recalibration || "NA"),//Pending
    createKeyValueParagraph("Smart variants caller", allDataApis?.smart_variants_caller || "NA"),//Pending
    createKeyValueParagraph("Analysis pipeline", allDataApis?.analysis_pipeline || "NA"),//Pending
  ];  
}

export default AnnexesGermline;