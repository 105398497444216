import React from 'react';
import { Typography } from '@mui/material';
import MappedText from '../../study-page/create-study/analysis-page/components/clinical-info/mapText';

const stringifyIfNeeded = (value) => {
    return value !== null && value !== undefined ? value.toString() : '';
};

const dicSex = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" }
];

const displaySexVal = (value) => {
    const found = dicSex.find(item => item.value === value);
    return found ? found.label : '';
};

const DataField = ({ title, value, helperText, NA_Text }) => {
    let capitalizedValue;


const emptySentence = {label:'No information provided'}

const isEmptyValue = (val) => {
  if (!val) return true; 
  const valStr = val.toString().trim().toLowerCase();
  return (
    !valStr ||
    valStr === 'na' ||
    valStr === 'n/a' ||
    valStr === 'undefined' ||
    valStr === 'nan' ||
    valStr === 'n/a, n/a' || 
    valStr.replace(/,/g, '').trim() === '' 
  );
};
  
    if (isEmptyValue(value)) {
      capitalizedValue = (
        <Typography
          sx={{
            fontStyle: 'italic',
            color: 'text.secondary',
            fontWeight: '400',
            fontSize: '16px',
          }}
        >
            <MappedText text={NA_Text || emptySentence.label} />

        </Typography>
        );
    } else {
    
        let stringValue = (title === 'Sex')
            ? displaySexVal(value)
            : stringifyIfNeeded(value);

        capitalizedValue = stringValue.charAt(0).toUpperCase() + stringValue.slice(1);

   
        capitalizedValue = (
            <Typography sx={{ color: '#202020', fontWeight: '400', fontSize: '16px' }}>
                <MappedText text={capitalizedValue} />
            </Typography>
        );
    }


    return (
        <>
            <Typography sx={{ color: '#626262', fontWeight: '400', fontSize: '14px' }}>
                <MappedText text={title} />
            </Typography>
            {capitalizedValue}
            {helperText && (
                <Typography sx={{ color: '#757575', fontWeight: '400', fontSize: '12px', marginTop: '4px' }}>
                    {helperText}
                </Typography>
            )}
        </>
    );
};

export default DataField;
