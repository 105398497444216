

import React from "react";
import {Alert, AlertTitle, Typography} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import MappedText from "../../../../../../../instand/reports/create-report/shared-components/mapText";
import Box from "@mui/material/Box";

const WarningAlert = () => (
    <Box p={1}>
        <Alert
            severity="warning"
            icon={<WarningIcon fontSize="inherit" color="inherit" />}
            sx={{ alignItems: 'center' }}
        >
            <AlertTitle>
                <MappedText text={"No variant selected"}/>
            </AlertTitle>

        </Alert>
        <Typography variant={"subtitle1"}><MappedText text={"Select one to use the resources"}/></Typography>
    </Box>

);

export default WarningAlert;