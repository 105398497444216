

export const GENETIC_FINDINGS_SNV_GERMLINE = [
        {
            Header: 'Gene',
            accessor: "geneName",
            filter: 'fuzzyText',
        },
        {
            Header: 'Genomic position*',
            accessor: "start_pos",
            filter: 'fuzzyText',
        },
        {
            Header: 'Transcript',
            accessor: "transcript",
            filter: 'fuzzyText',
        },
        {
            Header: 'cDNA',
            accessor: "cDNA",
            filter: 'fuzzyText',
        },
        {
            Header: 'Protein',
            accessor: "protein",
            filter: 'fuzzyText',
        },
        {
            Header: 'Clinical significance**',
            accessor: "significance",
            filter: 'fuzzyText',
        },
        {
            Header: 'Zygosity',
            accessor: "zygosity",
            filter: 'fuzzyText',
        },
];

export const GENETIC_FINDINGS_SNV_SOMATIC = [
    {
        Header: 'Gene',
        accessor: "geneName",
        filter: 'fuzzyText',
    },
    {
        Header: 'Origin',
        accessor: "origin",
        filter: 'fuzzyText',
    },
    {
        Header: 'Genomic position*',
        accessor: "start_pos",
        filter: 'fuzzyText',
    }, 
    {
        Header: 'cDNA',
        accessor: "cDNA",
        filter: 'fuzzyText',
    },
    {
        Header: 'Protein',
        accessor: "protein",
        filter: 'fuzzyText',
    },
    {
        Header: 'Clinical significance**',
        accessor: "significance",
        filter: 'fuzzyText',
    },
    {
        Header: 'Zygosity',
        accessor: "zygosity",
        filter: 'fuzzyText',
    },
    //variant allele 
    
];
