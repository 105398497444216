

import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../../../../actions";
import {connect} from "react-redux";
import Divider from "@material-ui/core/Divider";
import FilterSelector from "./components/filter-selector/FilterSelector";
import FiltersContainer from "./components/filter-accordion-panels/FiltersContainer";
import {default_query} from "../../../../../../../services/api/genomics_query_samples/query_sample";
import {getCurrentQuery, query_difference} from "../../results/components/query-applied-filters/query_difference";
import {intersection_method} from "./components/filter-sections/gene_reducer";
import QueryTopBar from "./components/query-top-bar/QueryTopBar";
import {CircularProgress, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {get_all_filter_sets, postFilterSet} from "../../../../../../../services/api/manage_study";
import {date_generator} from "../../../../../../func/helpers";
import * as GeneActions from "../../../components/side-panels/filter-panel/components/filter-sections/actions";
import {default_filters} from "../../../../../../../config-files/configFilters";
import MappedText from "../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import WarningBox from "../../../../../../study-page/create-study/WarningBox";


const useMount = (callback) => {
    useEffect(callback, [])
}

function FilterDialog(props){


    const [current_selected_filters, setCurrentSelectedFilters] = useState(false);
    const [filter_set_name, setFilterSetName] = useState(""); // not used for now;

    const [pointers, setPointers] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [reset, resetForm] = useState(false);

    const [listNames, setNames] = useState([]);
    const [message, setMessage] = useState(false);
    const [resetSignal, setResetSignal] = useState(false); // Nuevo estado para el reset

    useMount(() => {
        initializeView();
    })

/*    const getAllFilterSetNames = () => {

        get_all_filter_sets(window.config.apis_configuration.api_nextgpap_server, props.token)
            .then(r => r.json())
            .then(function(json) {
                let list_names = json.data.filter(s => s.filter_set_name !== "").map(p => p.filter_set_name);

                let defaults = default_filters.map(s => s.filter_set_name);
                let tot = defaults.concat(list_names);
                setNames(tot);


            })


    }*/

    const initializeView = () => {

        let query = props.query;

        setCurrentSelectedFilters(query);
        ///setFilterSetName("");
        setLoaded(false);
        //getAllFilterSetNames();


        let pointers = query_difference(default_query, current_selected_filters);

        Object.keys(pointers).forEach(function(item){
            if(pointers[item] === undefined){
                delete pointers[item];
            }
        })
        setPointers(pointers);

    }


    useEffect(function(){

        let diff_with_previous = query_difference(current_selected_filters, props.query);

        let diff_with_default = query_difference(default_query, props.query);

        let exclude = ["gene", "size", "chrom", "variant_type", "cadd_phred","revel","spliceai_max_ds","OCScore"];

        let keys = Object.keys(diff_with_default);
        let keys_now = Object.keys(diff_with_previous);

        let result = intersection_method(keys,exclude);

        if(result.length === 0 && keys_now.length !== 0  ){
            setPointers(diff_with_default);
            setCurrentSelectedFilters(props.query);
        }


    }, [])


    useEffect(function(){


        let filters1 = default_query;
        let query2 = getCurrentQuery(props.studies, props.studySettings);

        // TODO: what if query2 is undefined? can it happen?
        // if a query has been deleted, it's undefined.
        if(query2){
            let filters2 = (query2.filters!== undefined )
                ? JSON.parse(query2.filters)
                : query2.filter_set.filters;

            // get currentQueryInfo and add the extra info..

            let query_obj = (typeof filters2 === "object" ) ? {...filters2} : filters2

            let v_tag= query2.extra.find(s => s["variant_type"]!== undefined);
            if(typeof current_selected_filters === "object" && v_tag!== undefined){
                query_obj["variant_type"] = v_tag["variant_type"];
            }

            let pointers = query_difference(filters1, filters2);
            // avoid cadd_phred to be null:

            pointers["cadd_phred"] =  pointers["cadd_phred"] !== undefined ? pointers["cadd_phred"] : null;
            pointers["OCScore"] =  pointers["OCScore"] !== undefined ? pointers["OCScore"] : null;
            pointers["revel"] =  pointers["revel"] !== undefined ? pointers["revel"] : null;
            pointers["spliceai_max_ds"] =  pointers["spliceai_max_ds"] !== undefined ? pointers["spliceai_max_ds"] : null;


            

            setPointers(pointers);
            setCurrentSelectedFilters(query_obj);
        }



    },[props.studySettings.current_query])



    const resetFilterChoice = () => {


        setCurrentSelectedFilters(false);
        setFilterSetName("");
        setLoaded(false);
        setPointers({});
        // reset form;
        resetForm(true);
        setTimeout(() => { resetForm(false)}, 1000);
        props.gene_actions.remove_all_lists();
        setResetSignal(!resetSignal);

    };

    const setNewFilterSet = (filter_set) => {

        setCurrentSelectedFilters(filter_set.filters);
        // add genes if any

        props.gene_actions.add_to_all_genes(filter_set.filters.gene);

        setFilterSetName(filter_set.filter_set_name);
        setLoaded(true);

        // now update the queryView // that should also update the query

    };

    const renderFilterContainer = () => {

        if(reset){
            return <div >
                <Grid container
                      direction={"column"}
                      alignItems={"center"}
                      spacing={2}>
                    <Grid item lg={12}>
                        <Typography><MappedText text={"Clearing filters"}/></Typography>
                    </Grid>
                    <Grid item lg={12}>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            </div>
        }
        else{
            return <React.Fragment>
                <FiltersContainer
                    samples = {props.samples}
                    default_filters = { current_selected_filters }
                    pointers = { pointers } 
                    somatic = {props.somatic} // it indicates what is applied. Used to show the active sections;
                />
            </React.Fragment>
        }

    }


    return (
        <div className="query_view">
            <QueryTopBar
                //message={message}
                //saveFilterSet = {saveFilterSet}
                //listNames = {listNames}
                queryRunner = { props.queryRunner }
                resetQuery={resetFilterChoice}
                />
            <Divider/>
            <React.Fragment>
                <div className={"querview_content"}>
                    <Box p={0}>
                        <div className={"title_section_blue"}>
                        <MappedText text={"Use a predefined filter set"}/>
                        </div>
                    </Box>
                    <Box p={1}>
                        <FilterSelector
                            message={message}
                            filters = {props.filters}
                            setFilterSet = {setNewFilterSet}
                            reset = {resetFilterChoice}
                            resetSignal={resetSignal}
                        />
                </Box>
                </div>
            </React.Fragment>
            {renderFilterContainer()}
        </div>
    )

}



function mapStateToProps (state) {
    if(state.authorization!==undefined)

    {   let somatic=false;
        if (state?.sample_list?.somatic_samples?.[0]?.experiment_somatic !== undefined){
            somatic= state?.sample_list?.somatic_samples?.[0]?.experiment_somatic==="somatic"
    }
        if( state.form !== undefined && Object.keys(state.form).length!== 0 && state.query!== undefined)
        {
            return {
                token: state.authorization.token,
                currentQuery: state.currentQuery,
                studies: state.studyBucket.studies,
                studySettings: state.studySettings,
                query: state.query,
                somatic:somatic 
            };
        }
        else{
            return {
                token: state.authorization.token,
                currentQuery: state.currentQuery,
                query: state.query,
                studies: state.studyBucket.studies,
                studySettings: state.studySettings,
                somatic:somatic 
            }
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    gene_actions: bindActionCreators(GeneActions, dispatch),
});

export default connect(mapStateToProps,mapDispatchToProps)(FilterDialog)