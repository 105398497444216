
import React, {useState} from "react";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../../actions";
import * as StudyActions from "../../../../dashboard/reducers/study_actions";
import * as Patients_Actions  from "../../analysis-page/components/clinical-info/patient_actions";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {GPAP_Button} from "../../../../../gpap-lib/components/Button_Collection";
import * as Sample_Actions from "../reducers/sample_actions";
import AddIcon from '@material-ui/icons/Add';
import GPAP_Dialog from "../../../../../gpap-lib/components/GPAP_Dialog";

function NewStudyButton(props){

    let [redirect, setRedirect] = useState(false);
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        let {studyActions, patients_actions, samples_actions } = props;
        let settings = {analysisType: "", current_analysis: null};  // to force rendering of entry page;

        // --- actions settings
        studyActions.setStudySettings(settings);
        patients_actions.reset_patients();
        samples_actions.resetSamples();
        // ---
        setRedirect(true)
        setOpen(false);
    };
    
    const handleOpen = () => {
        setOpen(true);
      };
      
      const handleClose = () => {
        setOpen(false);
      };


    if(!redirect){
        const showButton = props.studySettings.current_analysis !== null;
       
            // if there are no analysis in our bucket, it means that you are about to create a study.
           return (
            <>
                {showButton && (
                    <GPAP_Button startIcon={<AddIcon/>} onClick={handleOpen}> New Study</GPAP_Button>
                )}
                <GPAP_Dialog
                    open={open}
                    handleClose={handleClose}
                    title= {"You are going to create a new study"}
                    text= {"Your current work will be lost if you have not saved it yet. Please, save the study if necessary."}
                    onExitText={"Cancel"}
                    onConfirmText={"Okay"}
                    onExitAction={handleClose}
                    onConfirmAction={handleClick}
                    type="warning"

                />

            </>
           );
    }
    else{
         return <Redirect to="home" />;
    }


}



//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.sample_list!== undefined)
        {
            return {
                samples: state.sample_list.samples,
                studySettings: state.studySettings,
                query: state.query
            }
        }
        else{
            return {samples: [],  "inheritance": state.inheritance, token:state.authorization.token}
        }
    }
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    studyActions: bindActionCreators(StudyActions, dispatch),
    samples_actions: bindActionCreators(Sample_Actions, dispatch),
    patients_actions: bindActionCreators(Patients_Actions, dispatch),
});




export default connect(mapStateToProps,mapDispatchToProps)(NewStudyButton);