import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as StudyActions from "../../../reducers/study_actions";
import * as Actions from "../../../../../actions";
import * as QueryActions from "../../../../../actions";
import {
    experiments_view_new,
    getParticipantBySample,
    getSAVariants,
    getVariants
} from "../../../../../services/api/get";

import {query_sample} from "../../../../../services/api/genomics_query_samples/query_sample";
import Grid from "@material-ui/core/Grid";
import Results from "../components/results/Results";
import GPAP_Drawer from "../../gpap-drawer/GPAP_Drawer";
import QueryGenerator from "../components/query-generator/QueryGenerator";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import {getCurrentAnalysis, getStudy} from "../components/results/components/variant-table/getAnnotations";
import * as GeneActions from "../components/side-panels/filter-panel/components/filter-sections/actions";
import map_sa_output from "../map_sa_output";
import {delete_query} from "../../../../../services/api/manage_study";
import {default_filters} from "../../../../../config-files/configFilters";
import {check_variant_response, empty_json} from "../check_variant_response";
import {get_all_variants} from "../../../../../services/api/variants_api";
import formatSamples from "../../../../study-page/create-study/analysis-page/formatSamples";
import {getCurrentQuery} from "../components/results/components/query-applied-filters/query_difference";
import {createDefaultSample, DEFAULT_GPAP_RESPONSE} from "../config_genomics";
import * as SelectVariantActions from "../components/results/components/variant-table/actions";
import {assignSettings} from "../../../../study-page/create-study/search-across-all/utils";
import * as SidebarActions from "../../../../../components/SideNavigation/sidebar_actions";
import * as PositionActions from "../components/side-panels/filter-panel/components/filter-sections/position-section/position_actions";
import {getSamplesFromBucket, } from "./helpers";
import {getExperimentsViewBody, getParticipantBySampleBody} from "./Post_Objects";
import {STATIC_VARIABLES} from "../../../../../config-files/const_variables";
import NoView from "./NoView";
import {date_generator} from "../../../../func/helpers";
import {Alert} from "../../../../../gpap-lib/components/Alerts";
import {getTotalFromJson} from "../components/results/getTotalFromJson";
import ManualFiltering from "../../../../instand/genetic_findings/manual-filtering/ManualFiltering";
import { somatic_query_default } from "../components/results/components/variant-table/gpap-table/somatic_table/somatic_query_default";
import { useSelector } from 'react-redux';


function Genomics(props){

    // works well

    const [loading, setLoading] = useState(false);
    const [loadingParticipants, setLoadingParticipants] = useState(false);
    const [variants, setVariants] = useState({...DEFAULT_GPAP_RESPONSE});
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackText, setSnackText] = useState( '' );
    const [snackColor, setSnackColor] = useState( '' );
    const [flagged, setFlagged] = useState( false );
    const [openDrawer, setOpenDrawer] = useState(false);
    const [summary, setSummary] = useState(false);
    const [sample_agg, setSampleAgg] = useState(false);
    const [studyLoaded, setStudyLoaded] = useState(false);
    const [sampleList, setSampleList] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [filters, setFilterList] = useState(default_filters);
    const [initialize, setInitialize] = useState(false);
    const [cohort, setCohort] = useState(false)

    const {allDataApis } = useSelector((state) => state.generaltable);
    const selectedDir = allDataApis?.dir?.find(d => d.dir_id === allDataApis?.dirs?.dir_id);


    const validTypes = ["family_trio", "somatic", "instand"];

    /* This function runs the query. If a cohort is provided, runs a query against a cohort. It also add the analysis info to the store,
    the first analysis of an array */

    const runQuery = (samples_cohort) => {
        // prepare query from STORE;

        let { actions, studies, studyActions, queryActions } = props;
        // run query;

        let current_study = studies[studies.length-1];
        let first_analysis= current_study.analyses[0];

        if(first_analysis!== undefined){
            let analysis_type = first_analysis.analysis_type;

            // get the first query to run
            const FIRST_QUERY = first_analysis.queries[0];

            if(FIRST_QUERY!== undefined  ){

                // setup info for family trio analysis

                if (validTypes.includes(analysis_type)) {
                    studyActions.setStudySettings({
                        current_analysis: first_analysis.localId,
                        analysisType: analysis_type,
                        current_query: FIRST_QUERY.localId
                    });
                }

                let first_query_to_run = (FIRST_QUERY.filter_set!== undefined)
                    ? FIRST_QUERY.filter_set.filters
                    : JSON.parse(FIRST_QUERY.filters);  // there are two cases. One is from loading the data from DB, the other at runtime.


                // this is the case in which I am loading the query from the DB. In this case I have the filter_set.
                if(FIRST_QUERY.filter_set){
                    queryActions.updateQuery(FIRST_QUERY.filter_set.filters);
                }

                // assign cohort samples to first_query
                if(analysis_type === "cohort" ){
                    // if loading, I need to get the cohort first;
                    if(samples_cohort!== undefined){
                        first_query_to_run.samples_germline = formatSamples(samples_cohort,0);
                    }
                }



                if (validTypes.includes(analysis_type)) {
                    actions.set_current_query(FIRST_QUERY);
                    loadGeneLists(FIRST_QUERY.extra);
                    // set gene operation;
                    let operator_applied = FIRST_QUERY.extra.find(s => s.hasOwnProperty("gene_list_operator"));
                    let geneOperation = (operator_applied!== undefined) ? operator_applied.gene_list_operator : "union";
                    props.geneListActions.setGeneOperation(geneOperation);
                    queryRunner(first_query_to_run, false, analysis_type, first_analysis.localId);
                    setStudyLoaded(true);
                }
                else{
                    actions.set_current_query(FIRST_QUERY);
                    loadGeneLists(FIRST_QUERY.extra);
                    // set gene operation;
                    let operator_applied = FIRST_QUERY.extra.find(s => s.hasOwnProperty("gene_list_operator"));
                    let geneOperation = (operator_applied!== undefined) ? operator_applied.gene_list_operator : "union";
                    props.geneListActions.setGeneOperation(geneOperation);
                    ///
                    props.geneListActions.add_to_all_genes(first_query_to_run.gene);
                    queryRunner(first_query_to_run, false, analysis_type, props.studySettings.current_analysis, props.studySettings.current_query);
                    setStudyLoaded(true);

                }
            }
        }
    };


    /* Load a predefined gene list from an array provided */
    const loadGeneLists = (lists) => {

        lists.forEach(function(s){
            if(s.hasOwnProperty("id") && s.hasOwnProperty("type")){
                props.geneListActions.add_applied_list(s);
            }
        });

    }

    /* Fetch all tagged variants from the phenostore server */
    const getAllTaggedVariants = () => {

        get_all_variants(config.apis_configuration.api_phenostore, props.token)
            .then(r => r.json())
            .then(json => setFlagged(json));

    }

    /* If the component is initialized, fetch all tagged variants with useEffect */

    useEffect(function(){

        if(!initialize){

            // get variants
            getAllTaggedVariants();
            setInitialize(true);

        }


    },[initialize]);

    /* Load a study if a parameter is passed in the URL */
    const getStudyLoad = () => {

        let bool= getIfWithParameters();
        return (bool && props.location.state !== null) ? props.location.state.loadStudy : false;

    }

    /* Check if a parameter is passed in the URL */
    // TODO: move outside the component;

    const getIfWithParameters = () => {
        return (props.location!== undefined && props.location.state!== undefined);
    }

    /* This useEffect detects a change in the sidebar and in the summary of variants. If a change occured, it loads a study and runs a query against a cohort */
    useEffect(function(){

        const open = STATIC_VARIABLES.SIDEBAR_OPTIONS.includes(props.sidebarSettings.page);
        setOpenDrawer(open);
        let bool= getIfWithParameters();
        let samples_cohort = (bool && props.location.state!== null) ? props.location.state.samples_cohort : false;
        let loadStudy = getStudyLoad();
        if(loadStudy && !studyLoaded){
            runQuery(samples_cohort);
            setOpenDrawer(false);
        }
        else if(props.studySettings.analysisType === "instand" && !studyLoaded){

            runQuery();
            setOpenDrawer(false);

        }


    }, [props.sidebarSettings, summary, props.studySettings.analysisType ]);

    /* run a search across query where gene and samples are given */
    // TODO: can be simplified and generalized.
    const runSearchAcrossQuery = (gene, samples, fromParticipantTable) => {


        //TODO: if samples are given we should get the genes for that experiment from sample_agg


        let config =window.config;

        let { query }  = props;

        delete query["variant_type"];

        let gene_to_search;

        // samples..

        // why this one? review;
        if(Array.isArray(gene) && gene.length === 0 ){
            gene_to_search = true;
        }
        else if(Array.isArray(gene) && gene.length > 0){
            query.gene = gene;
            gene_to_search = gene[0];
        }
        else{
            query.gene = [gene];
            gene_to_search = gene
        }

        // make call for variants;

        // in case we have experiments in the list but no genes selected, get the gene variant associated
        if(Array.isArray(samples) && samples.length>0 && fromParticipantTable){
            let tot_genes= [];
            let tot_positions = []
            sample_agg.filter(s => samples.includes(s.experiment)).forEach(function(d){
                let genes = d.genes.map(s=> s.gene);
                tot_genes = tot_genes.concat(genes);
                tot_positions = tot_positions.concat(d.genes.map(g => {return {chr: g.chrom, start: g.pos, end: g.end }}))
            })

            // get the positions

            gene_to_search = tot_genes;

            query.gene = tot_genes;
            //query.chrom= tot_positions;
            // add positions;



        }

        if(gene_to_search!== undefined){

            let sample_list;
            if(gene.length!== 0 ){
                sample_list= summary.find(s => s.gene === gene_to_search).sample_list;
            }

            // to get the variants from this API.
            query.size = -1;

            //samples check here..

            if(samples === "ALL_SAMPLES"){

                let query = getCurrentQuery(props.studies, props.studySettings);
                queryViewer(query, props.studySettings.analysisType);
            }
            else{
                if(samples!== undefined && samples.length!==0){

                    console.log("cohort use case - gt update")
                    // get SAMPLES_ALL settings;

                    // gt to fix // GET from Redux;
                    let samples_settings = props.samples;

                    query.samples_germline = samples.map(s => {
                        return createDefaultSample(s, samples_settings);
                    })
                }
                else{
                    if(sample_list!== undefined){
                        let samples_settings = props.samples;
                        query.samples_germline = sample_list.map(s => {
                            return createDefaultSample(s, samples_settings);});
                    }
                    }


                let api_endpoint  = config.api_endpoint;
                let { token } = props;

                setLoading(true);
                // run query;

                // force variant hits;
                query["size"] = -1;

                // TODO: move outside the methods, create a general fetch function for collecting the variants;
                getSAVariants(api_endpoint,token, query)
                    .then(response => {
                        return check_variant_response(response);
                        }
                    )
                    .then((json) => {
                        if(typeof json === "object"){
                            setVariants(json);

                            // reset all selected variants;


                            //props.selectVariantActions.selectedVariant([]);

                            props.selectVariantActions.resetSelectedTableRows();

                            let es_hits = ( typeof json.snv.hits.total === 'object' ) ? json.snv.hits.total.value : json.snv.hits.total;

                            // update participants...
                            if(es_hits!==0){
                                let samples_all = [];


                                json.snv.hits.hits.forEach(s => {
                                    if(s.fields.samples_germline!== undefined && Array.isArray(s.fields.samples_germline)){
                                        samples_all = samples_all.concat(s.fields.samples_germline.map(g => g.sample ));
                                    }
                                })

                                let distinct = [...new Set(samples_all)];
                                setSampleList(distinct);

                                // create a map with Experiment ID, Participant ID and N_variants;

                                setLoadingParticipants(true);
                                // now collect experiments
                                const data_object = getExperimentsViewBody(distinct);
                                experiments_view_new(config.apis_configuration.api_data_management, token, data_object)
                                    .then(r => r.json())
                                    .then(data => {

                                        // get the IDs and call phenostore;
                                        let id_array = data["items"].map(s => s["Participant_ID"]);
                                        let obj = getParticipantBySampleBody(id_array);

                                        getParticipantBySample(config.apis_configuration.api_phenostore, token, obj)
                                            .then(r => {
                                                if (!r.ok) {
                                                    setLoadingParticipants(false);
                                                    setParticipants({
                                                        participants: [],
                                                        map_by_exp:[],
                                                        total: null})

                                                    throw new Error(`HTTP error! Status: ${r.status}`);

                                                }
                                                return r.json();
                                            })
                                            .then(patients => {
                                                setLoadingParticipants(false);
                                                // add missing fields to participants;
                                                let participants_table = patients["rows"].map(function(par){
                                                    let sample = data["items"].find(item => item.Participant_ID === par.report_id);
                                                    if(sample!== undefined){
                                                        par["ExperimentID"] = sample["ExperimentID"];
                                                        par["LocalExperimentID"] = sample["LocalExperimentID"];
                                                        par["n_variants"] = (sample_agg.find(s => s["experiment"] === sample["ExperimentID"]).genes.length);
                                                        par["Owner"] = sample["Owner"];
                                                        par["erns"] = sample["erns"];
                                                        par["project"] = sample["project"];
                                                    }
                                                    return par;
                                                })

                                                // json objects of participants;
                                                setParticipants({
                                                    participants: participants_table,
                                                    map_by_exp: data["items"],
                                                    experiments: distinct,
                                                    aggregations: patients["aggregations"],
                                                    total: id_array.length
                                                })
                                            })
                                            .catch(error => {
                                                setLoadingParticipants(false);
                                                setParticipants({
                                                    participants: [],
                                                    map_by_exp:[],
                                                    total: null})

                                                console.error('Error fetching participants:', error);
                                                // Handle the error here, e.g., set error state or display a message to the user
                                            });


                                    } );
                            }


                            setLoading(false);
                            setSnackOpen(true);
                            props.actions.set_sa_query(query);
                            if(json.message!== undefined){
                                if(typeof json.message === "string" ){
                                    setSnackText(json.message);
                                }
                            }
                            else {
                                setSnackText("SNV Query Run Successfully!");
                            }
                        }
                        else{

                            setVariants({snv:empty_json});
                            //props.selectVariantActions.selectedVariant([]);

                            props.selectVariantActions.resetSelectedTableRows();
                            setLoading(false);
                            setSnackOpen(true);
                            if(json.message!== undefined){
                                if(typeof json.message === "string" ) {
                                    setSnackText(json.message);
                                }
                            }
                            else {
                                setSnackText("Query Not Successful");
                                setSnackColor("error");
                            }
                        }
                    })
                    .catch(function() {
                        // catch
                        setSnackOpen(true);
                        setSnackText("Request Failed");
                        setSnackColor("error");
                        setLoading(false);

                    });
            }
        }
    };

    /* Save a query. Collects a query, the json of the query pauload, analysis type id, type of variant (tagged all, tagged selected) */
    // TODO: variant type is misleading as in phenostore, variant type refers to SNV, Deletion, Inversion, etc...

    const saveQuery = (queryNew, json, analysis_type, variant_type) => {

        let { studyActions, currentAnalysis, study_id, studySettings, studyBucket  } = props;
        // check the inheritances and create the analysis;
        let date = date_generator();

        let my_analysis = getCurrentAnalysis(studySettings, studyBucket)

        const count = my_analysis.analysis.queries.length + 1;

        const localID = currentAnalysis + "_" + count;
        
        const es_hits = ( typeof json.snv.hits.total === 'object' ) ? json.snv.hits.total.value : json.snv.hits.total;

        const total = (json.snv) ? es_hits : 0;

        let extra = [...props.applied_lists];

        // update tagged variant info search;
        let v_type= (variant_type!== undefined) ? {variant_type: variant_type} : {variant_type: "GPAP"};
        let v_type_obj= extra.find(s => s["variant_type"]!==undefined);
        if(v_type_obj !== undefined){
            v_type_obj["variant_type"] = v_type["variant_type"]
        }
        else{
            extra.push(v_type);
        }


        // current filter set;

        let currentFilterSet_id = Object.keys(props.currentFilterSet).length!== 0
            ? props.currentFilterSet.id
            : -1;

        let currentFilterSet_name = Object.keys(props.currentFilterSet).length!== 0
            ? props.currentFilterSet.filter_set_name
            : "";

        // add gene operator used for genes;
        if(queryNew.gene.length>0){
            extra.push({gene_list_operator: props.geneList_operator});
        }

        // create query
        const data = {
            study_id: study_id ,
            analysis_id: currentAnalysis,
            query: {
                "localId": localID,
                "name": "query_" + count,
                "analysis_id": currentAnalysis,
                "filter_set_id": currentFilterSet_id,
                "filter_set_name": currentFilterSet_name,
                "filters": JSON.stringify(queryNew),
                "creationDate": date,
                "outputs": [{"n_variants": total, "run_date": date}],
                "extra": extra   // applied lists (e.g. predefined filters or positions etc..)
            }
        };

        studyActions.create_query(data);

        let { study_saved } = props.studySettings;

        let study_status = (study_saved) ? false : study_saved;

        studyActions.setStudySettings({
            current_query: localID,
            study_saved: study_status,
            analysisType: analysis_type});

    };


    /* Main function to run queries when changes are detected on the tabs of the dashboard.  */
    // TODO: Can be simplified and made more generic;
    const queryRunner = async (query_to_run, store, analysis_type, analysis_id, query_id) => {

        let {token, studySettings, studyBucket} = props;
        let config = window.config;
        let api_endpoint = config.api_endpoint;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        setLoading(true);
        
        //check index value if Affected return true if Unaffected return false

        query_to_run.samples_germline.map(function(sample){if (sample.index==="Affected") {sample.index=true;} else {if  (sample.index==="Unaffected") {sample.index=false;} } })

        // check endpoint to query....
        // if family or search across...

        if (analysis_type === undefined) {
            analysis_type = studySettings.analysisType;
        }

        if (analysis_type === "search_across" || analysis_type === "cohort") {


            let variant_type = query_to_run["variant_type"];
            //console.log(variant_type);
            delete query_to_run["variant_type"];


            // genes
            let geneList = props.geneList;
            let query = {...query_to_run};
            if (query.gene.length === 0) {
                query.gene = geneList;
            }

           //

            if (analysis_type !== "cohort") {
                // search across use case;

                // if this comes by clicking the query tab;
                if(analysis_id === undefined){
                    let samples_to_analyze = props.samples;
                    query.samples_germline = formatSamples(samples_to_analyze, 0);
                }
                else{
                     query.samples_germline = getSamplesFromBucket(studySettings,studyBucket, analysis_id,query_to_run.samples_germline);
                }


            }
            else if(analysis_type === "cohort"){
                if(query_to_run.samples_germline.length !== 0 && query_to_run.samples_germline.find(s => s.sample_id === "ALL_SAMPLES") === undefined){

                    //be sure query to run has settings;
                    let samples_to_analyze = props.samples;

                    // if this comes by clicking the query tab;
                    if(query_id!== undefined){
                        let study = getStudy(studySettings,studyBucket);
                        let current_analysis = study.study.analyses.find(s => s.localId === analysis_id);
                        samples_to_analyze = current_analysis.samples;
                    }

                    let my_cohort= assignSettings(query_to_run.samples_germline, samples_to_analyze);
                    query.samples_germline = my_cohort
                    setCohort([...my_cohort]);

                }
                else{
                    query.samples_germline = cohort
                }

            }

            query.size = 0;

            // samples_somatic

            query.samples_somatic = [];


            getSAVariants(api_endpoint, token, query)
                .then(response => {
                        return check_variant_response(response);
                    }
                )
                .then((json) => {

                    setLoading(false);

                    // get the summary

                    let {snv_total,cnv_total} = getTotalFromJson(json);


                    if (snv_total !== 0) {
                        const output = map_sa_output(json);
                        const {summary, sample_agg} = output;
                        setSummary(summary);
                        setSampleAgg(sample_agg);

                        let all_samples = [];
                        summary.forEach(function (item) {
                            all_samples = all_samples.concat(item.sample_list)
                        });
                        let distinct = [...new Set(all_samples)];
                        setSampleList(distinct);

                        setLoadingParticipants(true);
                        const data_object = getExperimentsViewBody(distinct);

                        experiments_view_new(config.apis_configuration.api_data_management, token, data_object)
                            .then(r => r.json())
                            .then(data => {

                                // get the IDs and call phenostore;
                                let id_array = data["items"].map(s => s["Participant_ID"]);
                                let obj = getParticipantBySampleBody(id_array);

                                getParticipantBySample(config.apis_configuration.api_phenostore, token, obj)
                                    .then(r => {
                                        if (!r.ok) {
                                            setLoadingParticipants(false);
                                            setParticipants({
                                                participants: [],
                                                map_by_exp:[],
                                                total: null})
                                            throw new Error(`HTTP error! Status: ${r.status}`);

                                        }
                                        return r.json();
                                    })
                                    .then(participants => {

                                        setLoadingParticipants(false);
                                        let participants_table = participants["rows"].map(function(par){


                                            // find in aggregations the number of variants;

                                            let sample = data["items"].find(item => item.Participant_ID === par.report_id);
                                            if(sample!== undefined){
                                                // TODO: take into account that same participants may have more than one sample!
                                                par["ExperimentID"] = sample["ExperimentID"];

                                                par["n_variants"] = (sample_agg.find(s => s["experiment"] === sample["ExperimentID"]).genes.length);
                                                par["LocalExperimentID"] = sample["LocalExperimentID"];
                                                par["Owner"] = sample["Owner"];
                                                par["erns"] = sample["erns"];
                                                par["project"] = sample["project"];

                                            }
                                            return par;
                                        })

                                        // json objects of participants;
                                        setParticipants({
                                            participants: participants_table,
                                            map_by_exp: data["items"],
                                            experiments: distinct,
                                            total: id_array.length,
                                            aggregations: participants["aggregations"]
                                        })
                                    })
                                    .catch(error => {
                                        setLoadingParticipants(false);
                                        setParticipants({
                                            participants: [],
                                            map_by_exp:[],
                                            total: null})

                                        console.error('Error fetching participants:', error);
                                        // Handle the error here, e.g., set error state or display a message to the user
                                    });

                            });

                        if (store) {
                            try{
                                saveQuery(query, json, analysis_type, variant_type);
                            }
                            catch(error){
                                setSnackOpen(true);
                                setSnackText(STATIC_VARIABLES.QUERY_CREATION_ERROR);
                                setSnackColor("error");
                                setLoading(false);
                            }

                        }

                        if (store || analysis_type !== "family_trio" || store || analysis_type !== "somatic" || analysis_type!== "instand" ) {
                            // TO REVIEW

                            /*let cnv_total = (json.hasOwnProperty("cnv")) ? json.cnv.hits.total : 0;*/

                            props.studyActions.update_query_output({
                                query_id: (query_id !== undefined) ? query_id : studySettings.current_query,
                                analysis_id: analysis_id,
                                n_variants: snv_total + cnv_total,
                                study_id: studySettings.id
                            })
                        }

                    }

                    // launch query to data management to get phenostore IDs...

                    setVariants(json);
                    //props.selectVariantActions.selectedVariant([]);

                    props.selectVariantActions.resetSelectedTableRows();
                    setSnackOpen(true);
                    if (json.message !== undefined) {
                        setSnackText(json.message);
                    } else {
                        setSnackText(STATIC_VARIABLES.SUCCESS_RUN);
                        setSnackColor("success");
                    }
                })
                .catch(function () {                        // catch
                    setSnackOpen(true);
                    setSnackText(STATIC_VARIABLES.UNAVAILABLE_SERVER);
                    setSnackColor("error");
                    setLoading(false);
                });
        } else {
            // remove not allowed keys;

            let variant_type = query_to_run["variant_type"];
            //console.log(variant_type);
            delete query_to_run["variant_type"];

            // Take somatic samples from somatic_query_default
            if (props.instandGeneticFindingsTab === "somatic"){
                query_to_run.samples_germline = []
                //query_to_run.samples_somatic = JSON.parse(JSON.stringify(somatic_query_default.samples_somatic));
                //query_to_run.samples_somatic = props.somatic_samples[0].gt_settings;
                //query_to_run.samples_somatic = formatSamples(props.somatic_samples ,0);

                let study = getStudy(props.studySettings, props.studyBucket);
                let analyses = study.study.analyses.find(a => a.localId === analysis_id);

                //Case when Switching between analysis
                if (analyses && analyses.samples_somatic && analyses.samples_somatic.length > 0){
                    query_to_run.samples_somatic = analyses.samples_somatic[0].gt_settings                    ;
                }
                
                //Case of going to the First analysis already preconfigured
                else if (analyses && !analyses.samples_somatic){
                    query_to_run.samples_somatic = JSON.parse(JSON.stringify(somatic_query_default.ref_samples_somatic));
                }
                
                //Case of New query
                else{
                    query_to_run.samples_somatic = props.somatic_samples[0].gt_settings;
                }
                 
                //Get tumor & control experiment ids from corresponding DIR
                let tumor_experiment_id = selectedDir.tumor_experiment_id ? selectedDir.tumor_experiment_id : ""
                let control_experiment_id = selectedDir.control_experiment_id ? selectedDir.control_experiment_id : ""

                query_to_run.samples_somatic[0].sample_id_tumor = tumor_experiment_id
                query_to_run.samples_somatic[0].sample_id_control = control_experiment_id

                //Case of Somatic Tumor only (without control)
                if (selectedDir.analysis === "somatic_tumor_only"){
                    delete query_to_run.samples_somatic[0].sample_id_control
                    delete query_to_run.samples_somatic[0].dp_control
                    delete query_to_run.samples_somatic[0].ad_control
                    delete query_to_run.samples_somatic[0].pass_control
                }

            }

            getVariants(api_endpoint, token, query_to_run)
                .then(response => {
                    if (response.status !== 200) {
                        return response.text();
                    }
                    return response.json();       
                    //return check_variant_response(response);
                })
               .then((json) => {
                let error = "";
                let message = json;
                    if (typeof json === 'string'){
                        //error=json;
                        setLoading(false);
                        if(message.includes("html")){
                            message = "Server Error";
                        }


                    }
                    else {
                    message = json['message'];
                    let {snv_total,cnv_total} = getTotalFromJson(json);


                    
                    if(json.status === 404 || json.status_code === 500){
                        error = json.error;
                        json= {snv: empty_json};
                        message = "Server Error";
                    }

                   /* let snv_hits = ( typeof json.snv.hits.total === 'object' )
                        ? json.snv.hits.total.value
                        : json.snv.hits.total;*/

                    setVariants(json);
                    //props.selectVariantActions.selectedVariant([]);
                    props.selectVariantActions.resetSelectedTableRows();
                    setLoading(false);
                    if (json) {
                        let queryNew = JSON.parse(JSON.stringify(query_to_run));
                        if (store) {
                            saveQuery(queryNew, json, analysis_type, variant_type);
                        }

                    /*    let cnv_total = 0
                        if(json.hasOwnProperty("cnv") )
                        {
                            cnv_total = ( typeof json.cnv.hits.total === 'object' ) ? json.cnv.hits.total.value : json.cnv.hits.total
                        }*/

                        // TO REVIEW
                        props.studyActions.update_query_output({
                            query_id: (query_id !== undefined) ? query_id : studySettings.current_query,
                            analysis_id: analysis_id,
                            n_variants: snv_total + cnv_total,
                            study_id: studySettings.id
                        });
                    }
                }
                        setSnackOpen(true);
                        if (message !== undefined) {
                            if(error!== ""){
                                setSnackText(message + ", server message is: " + error.reason);
                                setSnackColor("error");
                            }
                            else{
                                setSnackText(message);
                                setSnackColor("error");
                            }

                        } else {
                            setSnackText(STATIC_VARIABLES.SUCCESS_RUN);
                            setSnackColor("success");
                        }
                    
                
                })
                .catch(error => {
                    // catch
                    
                    console.log(error);
                    setSnackOpen(true);
                    setSnackText(STATIC_VARIABLES.UNAVAILABLE_SERVER);
                    setLoading(false);
                    setSnackColor("error");
                })

        }

        props.sidebarActions.setView({page: "home"});
    };


    /* This function allows to view a specific variant that was already performed. It is triggered with a click on a tab in the dashboard */
    const queryViewer = (query, analysisType) => {
        // prepare query from STORE;

        let { actions, studyActions } = props;

        // run query;

        studyActions.setStudySettings({current_analysis: query.analysis_id, current_query: query.localId, analysisType: analysisType});
        let query_to_view = (query.filters!== undefined) ? JSON.parse(query.filters) : query.filter_set.filters;

        queryRunner(query_to_view, false, analysisType);

        // run query;
        actions.set_current_query(query);

        setSnackOpen(true);
        setSnackText(STATIC_VARIABLES.SUCCESS_QUERY);
        setSnackColor("success");

    };

    /* Function to delete a specific query */
    const queryDelete = (query) => {
        // prepare query from STORE;

        let {  studyActions, study_id } = props;

        studyActions.delete_query(query, study_id);
        studyActions.setStudySettings({current_query: query.localId});

        if(query.query_id!== undefined){
            delete_query(config.apis_configuration.api_nextgpap_server, props.token, query)
                .then(r => r.json()).then(json => {
                if(json["message"] === "Success")
                {
                    alert(STATIC_VARIABLES.SUCCESS_DELETE);
                }
            })
        }
    };

    /* General function to fetch Variants starting from a variant ith */
    const fetchVariantData = (fromVariant) => {

        let config =window.config;
        let { token  } = props;
        let api_endpoint  = config.api_endpoint;

        let query = props.query;

        // TODO: Should change in the STORE too;
        query["from"]= fromVariant;

        setLoading(true);

        getVariants(api_endpoint,token, query)
            .then(response => response.json())
            .then((json) => {
                   setLoading(false);
                   setVariants(json);
                   //props.selectVariantActions.selectedVariant([]);
                    props.selectVariantActions.resetSelectedTableRows();
                }
            )
    };

    /* Reset the filters and the table */
    // TODO: check if neeeded;
    const resetAll = () => {

        let { actions, geneListActions, positionActions } = props;
        actions.updateQuery(query_sample);
        geneListActions.resetGenes();
        positionActions.reset_ranges();
        setVariants({...DEFAULT_GPAP_RESPONSE});

    }

    /* Reset all variant filters, and gene filters applied.  */
    const resetQuery = () => {

        let { actions, geneListActions, positionActions } = props;
        actions.updateQuery(query_sample);
        geneListActions.resetGenes();
        positionActions.reset_ranges();
        // re-render
        setSnackText("All filters were reset")
        setSnackOpen(true);
        setSnackColor("success");
    };

    /* Switch Analysis Panels in the dashboard */
    const switchAnalysis = (analysis_id, query_id) => {

        let study = getStudy(props.studySettings, props.studyBucket);

        let analyses = study.study.analyses.find(a => a.localId === analysis_id);

        if(analyses!== undefined){
            const FIRST_QUERY = analyses.queries[0];

            let query = (FIRST_QUERY.filter_set!== undefined) ? FIRST_QUERY.filter_set.filters : JSON.parse(FIRST_QUERY.filters);
            let id = FIRST_QUERY.localId;

            props.queryActions.updateQuery(query);
            queryRunner( query, false, analyses.analysis_type, analysis_id, query_id);
            props.studyActions.setStudySettings({current_query: id});
        }
    };

    /* Switch Query in the dashboard */

    const switchQuery = (query_id) => {

        let analysis = getCurrentAnalysis(props.studySettings, props.studyBucket);

        if(analysis!== undefined){
            let query_found = analysis.analysis.queries.find(a => a.localId === query_id);
            if(query_found!== undefined){
                let query =(query_found.filter_set!== undefined) ? query_found.filter_set.filters : JSON.parse(query_found.filters);
                let id = query_found.localId;
                props.queryActions.updateQuery(query);
                queryRunner( query, false, analysis.analysis_type, props.studySettings.current_analysis, query_id);
                // set up applied genes and lists...
                props.geneListActions.init_applied_lists(query_found.extra.filter(s => s.hasOwnProperty("id")));

                // set gene operation;
                let operator_applied = query_found.extra.find(s => s.hasOwnProperty("gene_list_operator"));
                let geneOperation = (operator_applied!== undefined) ? operator_applied.gene_list_operator : "union";
                props.geneListActions.setGeneOperation(geneOperation)

                props.studyActions.setStudySettings({current_query: id});

            }
        }
    };

    /* Standard call to collect variants */

    const standardRun = () => {
        setOpenDrawer(false);
        let query = props.query;
        // run query;
        queryRunner( query, true);
    };


    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false)
    };

    /* =================== RENDERING METHODS ============== */


    const renderResults_View =() => {


        return <React.Fragment>

            { (props.studySettings.analysisType === "instand")
               ? <ManualFiltering
                    size = {'extended'}
                    variants = {variants}
                    queryRunner={standardRun}
                    run_query = {queryRunner}
                    all_tagged_variants = {flagged.data}
                    runSearchAcrossQuery = {runSearchAcrossQuery}
                    loading={loading}
                    loadingParticipants={loadingParticipants}
                    type = {(summary) ? "search_across" : "family"}
                    summary = {summary}
                    sample_agg = {sample_agg}
                    sampleList = {sampleList}
                    participants = {participants}
                    fetchData={fetchVariantData}
                    switchAnalysis = {switchAnalysis}
                    switchQuery={switchQuery}
                    getAllTaggedVariants = {getAllTaggedVariants}
                    resetAll = {resetAll}
                    requestStatus = { snackText }
                    instandGeneticFindingsTab = {props.instandGeneticFindingsTab}
                    />
                : <Results size = {'extended'}
                          variants = {variants}
                          queryRunner={standardRun}
                          run_query = {queryRunner}
                          all_tagged_variants = {flagged.data}
                          runSearchAcrossQuery = {runSearchAcrossQuery}
                          loading={loading}
                          loadingParticipants={loadingParticipants}
                          type = {(summary) ? "search_across" : "family"}
                          summary = {summary}
                          sample_agg = {sample_agg}
                          sampleList = {sampleList}
                          participants = {participants}
                          fetchData={fetchVariantData}
                          switchAnalysis = {switchAnalysis}
                          switchQuery={switchQuery}
                          getAllTaggedVariants = {getAllTaggedVariants}
                          resetAll = {resetAll}
                          requestStatus = { snackText }
                />

            }

        </React.Fragment>
    };


    const renderMainView = () => {
        return (<Grid
            container
            direction="row">
            <Grid item xs={12}>
                <div> { renderResults_View() }</div>
            </Grid>
        </Grid>);
    };


    const renderDrawer = () => {
        return  <GPAP_Drawer
            resetQuery = {resetQuery}
            queryRunner = { standardRun }
            variants = {variants}
            queryViewer = { queryViewer }
            queryDelete = {queryDelete}
            cohorts={[]}
            filters = {filters}
            open={openDrawer}/>
    }


    let vertical ="top";
    let horizontal = "center";

    return  <React.Fragment>
        {renderDrawer()}
        <QueryGenerator  all_tagged_variants = {flagged.data} loadingStudy={loading} studyLoaded={studyLoaded}/>
        <div className="mainPanel">
            <Snackbar
                anchorOrigin={ {vertical, horizontal }}
                open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity={snackColor}>
                    {snackText}
                </Alert>
            </Snackbar>
            {(studyLoaded)
                ? renderMainView()
                : NoView()}
        </div>
    </React.Fragment>

}


//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.query !== undefined)
        {
            return { query: {... state.query},
                token: state.authorization.token,
                parsedToken: state.authorization.parsedToken,
                studies: state.studyBucket.studies,
                currentAnalysis: state.studySettings.current_analysis,
                currentFilterSet: state.currentFilterSet,
                study_id: state.studySettings.id,
                studySettings: state.studySettings,
                studyBucket: state.studyBucket,
                sidebarSettings: state.sidebarSettings,
                geneList: state.geneList.geneList,
                geneList_operator: state.geneList.operator,
                applied_lists: state.geneList.applied_lists,
                samples: state.sample_list.samples,
                somatic_samples: state.sample_list.somatic_samples,
                selected_cohort: state.selected_cohort
            }
        }

    }
}

// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    studyActions: bindActionCreators(StudyActions, dispatch),
    queryActions: bindActionCreators(QueryActions, dispatch),
    geneListActions: bindActionCreators(GeneActions, dispatch),
    selectVariantActions: bindActionCreators(SelectVariantActions, dispatch),
    sa_queryActions: bindActionCreators(SelectVariantActions, dispatch),
    sidebarActions: bindActionCreators(SidebarActions, dispatch),
    positionActions: bindActionCreators(PositionActions, dispatch)
});





export default connect(mapStateToProps,mapDispatchToProps)(Genomics);