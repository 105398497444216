
import React from "react";
import {AlignmentType, HeadingLevel, Paragraph, TextRun} from "docx";

import { renderToString } from "../../../create-report/shared-components/renderToString.js";
import {createSpacer} from "../../write-sections/write_utils/documentHelpers";

function WritePharmaInterpreation(sectionData, analysisType) {


    let rows = [];


    if (sectionData !== null && sectionData.length>0) {

        rows = (sectionData) ? sectionData : [];

    }

    const renderRows = (rows) => {

        const sections = rows.map(row => {

            let gene = (row.gene!== undefined) ? row.gene : "NA";
            const text = gene + ":" + row.genotype;
            const titlePar = new Paragraph({
                children: [
                    new TextRun({
                        text: text,
                        bold: true, // Make the text bold
                    })
                ],
                spacing: { after: 40, before: 40 },
            });


            const CommentPar = new Paragraph({
                children: [
                    new TextRun({
                        text: row.interpretation || renderToString("No additional information provided."), // Default text if comments are empty or undefined
                        italics: !row.interpretation, // Apply italics if comments are empty or undefined
                        color: !row.interpretation ? "888888" : "000000", // Gray color if comments are empty or undefined, otherwise black
                    }),
                ],
            });

            const spacerPar = createSpacer(50, 50);

            return [spacerPar, titlePar, CommentPar]; // Return an array of paragraphs
        });
        return sections.flat(); // Flatten the array of arrays
    }

    // snv and cnv sections
    const pharma_sections = renderRows(rows);

    if (rows.length === 0) {
        return []; 
    }


    const title = new Paragraph({
        children: [
            new TextRun({
                text: renderToString("Haplotype Interpretation"),
                bold: true,
                size: 22, 
            }),
        ]
    });

    if (analysisType.toLowerCase().includes("somatic") || analysisType === "germline"){
        return [title, ...pharma_sections, ];
    } else {
        return [title, ...pharma_sections];
    }


}

export default WritePharmaInterpreation;