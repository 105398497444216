import React from "react";
import {DATA_HEADER} from "./data_header";
import CustomTable from "../../../general-table/components/tables/CustomTable";


function Fusions_Table(props){




    return <CustomTable
        dataType={"fusions"}
        tableName={"fusions"}
        url={props.url}
        method= "POST"
        experiment={props.sampleID}
        dataSend = {{
            "fields": [],
            "filtered": [
              {
                "id": "Experiment_ID",
                "value": [
                  props.sampleID
                ]
              }
            ],
            "page": 1,
            "pageSize": 1000,
            "sorted": []
          }}  
          
        token={props.token}
        locationData="items"
        // classRow= "Fusions"
        customFeatures= {[
            { toggle: false },
            { expanding: false },
            { showHeader: true },
        ]}

        dataHeader={DATA_HEADER}
       
    />


}

export default Fusions_Table
