import React from "react";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import GPAP_Dialog from "../../../../../../../../../gpap-lib/components/GPAP_Dialog";



function SaveErrorDialog({errorDialog, setErrorDialog, trackedResponses}){

    // we should show these errors somewhere.


    let errors = trackedResponses.filter(s => !s.success);


    return <GPAP_Dialog
        open={errorDialog}
        handleClose={() => setErrorDialog(false)}
        title={"One or more queries could not be saved"}
        text={`"Something went wrong and one or more queries could not be saved correctly. 
                        You will not be able to load the study correctly.Please, contact us at" ${window.config.help_email} "if the problem persists. 
                        A total number of " 
                        ${errors.length} " errors have occurred`}
        onExitText={false}
        onConfirmText={"Close"}
        onExitAction={false}
        onConfirmAction={() => setErrorDialog(false)}
        type="error"
    />


}

export default SaveErrorDialog;