import React, { useState } from "react";
import {Typography, TextField} from "@mui/material";
import MappedText from '../../../create-report/shared-components/mapText.js';
import Grid from "@mui/material/Grid";
import DataField from "../../../create-report/shared-components/Datafield.js";
import {convertDate} from "../utils";

function GeneralInfoSection({  dirSelected, allDataApis, analysisType, setStudyPerformed }){

    const [studyPerformed, setStudyPerformedLocal]= useState([""])

    const handleStudyPerformedText = (e) => {
        setStudyPerformedLocal(e.target.value); 
        setStudyPerformed(e.target.value); 
    };

    return   <>
        {/*<Typography variant="h5" style={{ fontWeight: "700", marginBottom: '20px' }}>
            <MappedText text="General Info"/>
        </Typography>*/}

        <Grid container spacing={2} style={{ marginBottom: '30px' }}>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Issuing centre" value={dirSelected.issuingCentre || "NA"} />
              
            </Grid>
            <Grid item  xs={12} sm={6} md={6}>
                <DataField title="Requesting centre" value={allDataApis?.dir?.[0].hospital_name || "NA"} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Referrer" value={allDataApis?.dir?.[0].clinical_referrer || "NA"} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Reporting date" value={convertDate(allDataApis?.dir?.[0].creation_date) || "NA"} />
            </Grid>
        </Grid>

        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
            <MappedText text={"Patient Info"}/>
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: 4}}>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Name" value={allDataApis?.participants?.[0].first_name || "NA"} /> 
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Surname" value={allDataApis?.participants?.[0].last_name || "NA"} /> 
            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginBottom: 4}}>
           <Grid item xs={12} sm={6} md={6}>
                <DataField title="Patient ID" value={allDataApis?.participants?.[0].report_id || "NA"} /> 
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Date of Birth" value={convertDate(allDataApis?.participants?.[0].birth) || "NA"} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DataField
                    title="Sex"
                    value={ allDataApis?.participants?.[0].sex === 'M'
                                            ? 'Male'
                                            : allDataApis?.participants?.[0].sex === 'F'
                                                ? 'Female'
                                                : 'NA' }
                />
            </Grid>
        </Grid>

        <Typography variant={"h5"} style={{  fontWeight: "700", marginBottom: '20px' }}>
            <MappedText text={"Clinical Info"}/>
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: 4}}>
        {analysisType !=="germline" && (
                    <>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Sample Type" value={allDataApis?.samples?.sample_type || "NA"}/>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <DataField title="Biopsy Location" value={allDataApis?.samples?.sample_tissue_of_origin || "NA"}/>
            </Grid>
            </>
          )
            }
        </Grid>
          
        <Grid container spacing={2} sx={{ marginBottom: 4}}>
            <Grid item xs={12} sm={6} md={6}>

            <DataField title="Reason for referral" value={allDataApis?.participants?.[0]?.referral || "NA"}/>

            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginBottom: 4}}>
            <Grid item xs={12} sm={6} md={6}>
            <label><MappedText text={"Performed Study"}/></label>
                <TextField
                value={studyPerformed}
                onChange={handleStudyPerformedText}
                mt={1}
                placeholder="e.g. Molecular testing to determine management including treatment options."
                fullWidth
                />
            </Grid> 
        </Grid>
        <Grid container spacing={2} sx={{ marginBottom: 4}}>
            <Grid item xs={12} sm={6} md={6}>

            <DataField title="Test performed (Library strategy)" value={allDataApis?.experiments?.[0].library_strategy || "NA"}/>

             
            </Grid>
        </Grid>

    </>

}


export default GeneralInfoSection;