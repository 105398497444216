
import React, {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import TuneIcon from '@material-ui/icons/Tune';
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import SliderBox from "../GQ_Form/SliderBox";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";


const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

function GQ_Dialog(props) {


    const { onClose, open, experiment_id, sample } = props;

    const handleClose = () => {
        onClose()
    };


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Set Genotype Quality Settings</DialogTitle>
            <DialogContent style={{width: "500px"}}>
                <div id={experiment_id + "_sliderbox"} className={"sliderbox"}>
                    <SliderBox sample_id={experiment_id} gt_parameters={sample.gt_settings[0]}/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

/*GenotypeSettingsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};*/

export default function GenotypeSettingsDialog(props) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton
                aria-label="tune"
                onClick={handleClickOpen}
                aria-expanded={open}>
                <TuneIcon fontSize="small"/>
            </IconButton>
            <GQ_Dialog open={open} onClose={handleClose}
                       sample = {props.sample}
                       experiment_id={props.experiment_id}/>
        </div>
    );
}


