

import React from "react";
import HomeReporting from "../../../../scenes/home/instand-home/HomeInstand";
import StudyPage from "../../../../scenes/study-page/StudyPage";


function HomeSwitcher(){


    if(config.instand && !window.location.href.includes("playground")){
        return <HomeReporting/>;
    }
    else{
        return <StudyPage/>;
    }


}

export default HomeSwitcher;