import React from "react";
import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";


export default function NoDataBox({title, subtitle, redirect, linkText, onUploadClick}){

    const splitSubtitle = subtitle.split(/(upload|Upload)/);

    const renderSubtitle = () => {
        if (splitSubtitle.length > 1) {
            return (
                <>
                    {splitSubtitle[0]}
                    <span
                        onClick={onUploadClick}
                        style={{ cursor: "pointer", color: "#3E69A9", textDecorationLine: "underline"}}
                    >
                        {splitSubtitle[1]} 
                    </span>
                    {splitSubtitle[2]}
                </>
            );
        } else {
            return subtitle;
        }
    };
    return <Box
        sx={{
            backgroundColor: "#FAFAFA",
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            flexDirection: "column",
            padding: "18px",
            width: "65%"
        }}
    >
        <Typography variant="body1" sx={{ fontWeight: "bold", color: "#757575", marginBottom: "6px" }}>
            {title}
        </Typography>
        <Typography variant="body2" sx={{ color: "#757575", marginBottom: "8px" }}>
            <Link to={redirect}>{linkText}</Link> {renderSubtitle()}
        </Typography>
    </Box>

}