import React from 'react';
import { Grid, Box, } from "@mui/material";
import GeneralTable from "../../../../../create-report/shared-components/general-table/GeneralTable.js";
import { GENOTYPE_PHENOTYPE } from "../config/genotype_phenotype.js";


export default function GenotypePhenotype({data, report_id}) {
        //const token = useSelector(state => state.authorization.token);
    //     const [sectionData, setData] = useState(data)
    
    // useEffect(() => {
    //     if(report_id!== undefined && sectionData.length === data.length){
    //         setData(data)}
    // }, [report_id]);

    return (
        <Grid item xs={12}>
            <Box p={1}>
                <Box>
                <GeneralTable
                    dataType={"associations"}
                    data={ data }
                    columns={ GENOTYPE_PHENOTYPE} />
                </Box>
            </Box>
        </Grid>
    );
}
