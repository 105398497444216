import React ,{ useState, useEffect }from "react";
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from "@material-ui/core/styles";
import MappedText from "../../scenes/instand/reports/create-report/shared-components/mapText";


const useStyles = makeStyles((theme) => ({
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    iconStyle: {
        width: "62px",
        height: "62px",
        marginTop: '16px'
    },
}));

const iconConfig = {
    warning: {
        color: "#FFC107",
        element: WarningIcon,
    },
    error: {
        color: "#F44336",
        element: ErrorIcon,
    },
    success: {
        color: "#31A354",
        element: CheckCircleIcon,
    },
    alert: {
        color: "#F44336",
        element: CancelIcon,
    },
};


export default function GPAP_Dialog({ open, handleClose, title, text, onExitText, onConfirmText, onExitAction, onConfirmAction, type }){
    const classes = useStyles();

    //state para gestionar el estado del dialogo
    const [dialogOpen, setDialogOpen] = useState(open);

    //funcion para cerrar el dialogo
    const handleDialogClose = () => {
        setDialogOpen(false);
        handleClose();
    };

    //Actualizar el estado del dialogo cuando las propiedades cambian
    useEffect(() => {
        setDialogOpen(open);
    }, [open]);



    const { color, element } = iconConfig[type];

    let IconElement = element;

    return (
        <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
            <DialogContent className={classes.dialog}>
                <IconElement fontSize="large" style={{color: color}} className={classes.iconStyle}/> 
                <DialogTitle id="alert-dialog-title" style={{textAlign: "center" }}>
                    <MappedText text={title}/>
                </DialogTitle>
                <DialogContentText id="alert-dialog-description"  style={{ textAlign: 'center' }}>
                        <MappedText text={text}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            {(onExitText) ? <Button onClick={onExitAction}><MappedText text={onExitText} /></Button> : null}
            {(onConfirmText) ? <Button onClick={onConfirmAction} autoFocus>
                <MappedText text={onConfirmText}/>
            </Button>
                : null}
        </DialogActions>
        </Dialog>
    );
};