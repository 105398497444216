
export const deutsch = {
        "Get in touch": "Nehmen Sie Kontakt auf",
        "Thank you for using our product.": "Vielen Dank, dass Sie unser Produkt verwenden.",
        "Feel free to reach out if you have any questions or comments.": "Wenn Sie Fragen oder Kommentare haben, können Sie sich jederzeit an uns wenden.",
        "Data Platforms & Tools Development Team": "Entwicklungsteam für Datenplattformen und Tools",
        "Barcelona, Spain": "Barcelona, ​​Spanien",
        "Phenotypic Data": "Phänotypische Daten",
        "Experiments & Files": "Experimente und Dateien",
        "Genomic Analysis": "Genomanalyse",
        "Cohort Creation": "Kohortenerstellung",
        "Browse Phenotypic Participants": "Durchsuchen Sie phänotypische Teilnehmer",
        "Browse Experiments & Files": "Durchsuchen Sie Experimente und Dateien",
        "Settings": "Einstellungen",
        "Interpretation Requests": "Interpretationsanfragen",
        "Docs": "Dokumente",
        "Contact": "Kontakt",
        "Autosomal recessive (both homozygous and compound heterozygous)": "Autosomal-rezessiv (sowohl homozygot als auch zusammengesetzt heterozygot)",
        "homozygous": "homozygot",
        "compound heterozygosity": "zusammengesetzte Heterozygotie",
        "Autosomal dominant": "Autosomal-dominant",
        "Autosomal dominant - de novo (parents required)": "Autosomal-dominant – de novo (Eltern erforderlich)",
        "De novo": "De novo",
        "AR Consanguineous": "AR Blutsverwandtschaft",
        "X-Linked Recessive ": "X-chromosomal rezessiv",
        "X-Linked Recessive - Index Case Male": "X-chromosomal rezessiv - Indexfall männlich",
        "X-Linked Dominant ": "X-chromosomal dominant",
        "X-Linked Dominant - Index Case Male": "X-chromosomal dominant - Indexfall männlich",
        "X-Linked Dominant - Index Case Female": "X-chromosomal dominant - Indexfall weiblich",
        "Custom Inheritance ": "Benutzerdefinierte Vererbung",
        "Custom Inheritance": "Benutzerdefinierte Vererbung",
        "Somatic": "Somatisch",
        "Ensembl": "Ensemble",
        "gnomAD": "gnomAD",
        "UCSC": "UCSC",
        "NCBI": "NCBI",
        "DGVa": "DGVa",
        "GWAS Central": "GWAS-Zentrale",
        "GA4GH": "GA4GH",
        "VarSome": "VarSome",
        "dbSNP": "dbSNP",
        "OMIM": "OMIM",
        "Genetics Home Reference": "Genetik-Home-Referenz",
        "NCBI Gene Reviews": "NCBI-Genrezensionen",
        "PubCaseFinder": "PubCaseFinder",
        "PubMed": "PubMed",
        "HGMD": "HGMD",
        "Entrez": "Entrez",
        "GeneCards": "GeneCards",
        "COSMIC": "KOSMISCH",
        "ClinVar": "ClinVar",
        "ExAC": "ExAC",
        "GTEx": "GTEx",
        "ATLAS": "ATLAS",
        "WikiPathways": "WikiPathways",
        "Open PHACTS": "Öffnen Sie PHACTS",
        "DisGeNET": "DisGeNET",
        "Gene Name": "Genname",
        "Transcript ID": "Transkript-ID",
        "Effect Impact": "Wirkungswirkung",
        "Consequence": "Folge",
        "Feature Type": "Feature-Typ",
        "HGVS coding": "LKW-Codierung",
        "Human Splicing Finder": "Menschlicher Spleißfinder",
        "Amino Acid change": "Aminosäureveränderung",
        "Exon Rank": "Exon-Rang",
        "Transcript BioType": "Transkript-Biotyp",
        "CADD Pred": "CADD Pred",
        "Revel Pred": "Revel Pred",
        "SplicaAI Pred": "SplicaAI Pred",
        "SIFT PRED": "SIEBEN PRED",
        "SIFT SCORE": "SIFT-Score",
        "POLYPHEN2 Pred": "POLYPHEN 2 Vorhersage",
        "POLYPHEN Score": "POLYPHEN-Punktzahl",
        "Mutation Taster": "Mutations-Schnupper",
        "InterVar": "InterVar",
        "MT Score": "MT-Score",
        "phyloP": "PhyloP",
        "GERP++": "GERP++",
        "SiPhy": "SiPhy",
        "OCScore": "OCScore",
        "onco_classifier": "onco_classifier",
        "Study Name": "Studienname",
        "Description": "Beschreibung",
        "Created on": "Erstellt am",
        "Created by": "Erstellt von",
        "Permission": "Erlaubnis",
        "Actions": "Aktionen",
        "Gene": "Gen",
        "Chr": "Chr",
        "Start": "Start",
        "End": "Ende",
        "Length": "Länge",
        "Type": "Typ",
        "Reads Observed": "Liest Beobachtet",
        "Reads Expected": "Liest erwartet",
        "Reads Ratio": "Liest Verhältnis",
        "Int. Freq": "Int. Freq",
        "Tool": "Werkzeug",
        "Sample": "Probe",
        "OMIM PhenoType": "OMIM-Phänotyp",
        "Bayer Factor": "Bayer-Faktor",
        "DGV goldstd overlap": "DGV-Goldstd-Überlappung",
        "DGV goldstd coordinates": "DGV Goldstd-Koordinaten",
        "Gene ID": "Gen-ID",
        "Gene coding": "Genkodierung",
        "Functional class": "Funktionsklasse",
        "Aminoacid length": "Aminosäurelänge",
        "Aminoacid": "Aminosäure",
        "Impact": "Auswirkungen",
        "Change": "Ändern",
        "Nucleotide": "Nukleotid",
        "Transcript": "Transkript",
        "GT": "GT",
        "MANE": "MÄHNE",
        "Clin.Sign.": "Klinische Unterzeichnung.",
        "Criteria": "Kriterien",
        "ACMG evidences": "ACMG-Beweise",
        "ACMG classification": "ACMG-Klassifizierung",
        "Internal": "Intern",
        "GnomAD": "GnomAD",
        "CADD": "CADD",
        "Revel": "Schwelgen",
        "SpliceAI": "SpliceAI",
        "SIFT": "SIEBEN",
        "Polyphen2.": "Polyphen2.",
        "MT": "MT",
        "Intervar Evidence": "Intervar-Beweis",
        "Intervar Autom.": "Intervar Autom.",
        "# Exp.": "# Exp.",
        "Polyphen2": "Polyphen2",
        "Onco evidences": "Onco-Beweise",
        "Classification": "Einstufung",
        "Source": "Quelle",
        "Evidence Level??": "Evidenzgrad??",
        "Columns": "Spalten",
        "Create request": "Anfrage erstellen",
        "Upload report": "Bericht hochladen",
        "Create report": "Bericht erstellen",
        "Column Hiding": "Spaltenausblenden",
        "Toggle All": "Alle umschalten",
        "Complete view": "Vollständige Ansicht",
        "Split by genes": "Nach Genen gespalten",
        "Experiment ": "Experiment",
        " does not have any data available yet": "liegen noch keine Daten vor",
        "Access Request": "Zugriffsanfrage",
        "Download": "Herunterladen",
        "Upload": "Hochladen",
        "Link": "Link",
        "Download Report": "Bericht herunterladen",
        "The initial count of SNVs identified during the first execution of this query.": "Die anfängliche Anzahl der SNVs, die bei der ersten Ausführung dieser Abfrage identifiziert wurden.",
        "This query did not return any variants": "Diese Abfrage hat keine Varianten zurückgegeben",
        "No Associations": "Keine Assoziationen",
        "We are working to integrate more resources in this section": "Wir arbeiten daran, weitere Ressourcen in diesen Abschnitt zu integrieren",
        "Pathways": "Wege",
        "HmtDB - Human Mitochondrial Database": "HmtDB – Datenbank menschlicher Mitochondrien",
        "Human Pluripotent Stem Cell Registry": "Register menschlicher pluripotenter Stammzellen",
        "Pathways Reactome": "Pathways-Reaktom",
        "Reactome is a free, open-source, curated and peer-reviewed pathway database": "Reactome ist eine kostenlose, kuratierte und von Experten begutachtete Open-Source-Pathway-Datenbank",
        "URL": "URL",
        "Disease": "Krankheit",
        "Cell Line Name": "Name der Zelllinie",
        "Cell Line": "Zelllinie",
        "Provider": "Anbieter",
        "# variants grouped by chromosome": "# Varianten nach Chromosom gruppiert",
        "External Databases": "Externe Datenbanken",
        "External Links": "Externe Links",
        "No variants selected. Please select a variant to display the links": "Keine Varianten ausgewählt. Bitte wählen Sie eine Variante aus, um die Links anzuzeigen",
        "No variants selected": "Keine Varianten ausgewählt",
        "No variant selected": "Keine Variante ausgewählt",
        "Select one to use the resources": "Wählen Sie eine aus, um die Ressourcen zu nutzen",
        "New Analysis": "Neue Analyse",
        "Query": "Abfrage",
        "Current work": "Aktuelle Arbeit",
        "Participants": "Teilnehmer",
        "Save": "Speichern",
        "Not Analyzed": "Nicht analysiert",
        "Solved": "Gelöst",
        "Unsolved": "Ungelöst",
        "Ongoing Analysis": "Laufende Analyse",
        "Under segregation/ functional studies": "Unter Trennung/Funktionsstudien",
        "Heterozygous variant found in AR disorder": "Heterozygote Variante bei AR-Störung gefunden",
        "Unaffected Relative": "Unberührter Verwandter",
        "Other": "Andere",
        "select": "wählen",
        "Lists applied:": "Angewandte Listen:",
        "Gene Filtering": "Genfilterung",
        "Positions": "Positionen",
        "Variant Effect": "Varianteneffekt",
        "Genotype Features": "Genotyp-Merkmale",
        "ClinVar Classes": "ClinVar-Klassen",
        "Mane": "Mähne",
        "Onco Filter": "Onco-Filter",
        "PolyPhen-2 Pred.": "PolyPhen-2 Pred.",
        "GnomAD Filter": "GnomAD-Filter",
        "Programs Filter": "Programmfilter",
        "Variant Consequences": "Variantenfolgen",
        "Used filter-set": "Gebrauchter Filtersatz",
        "Applied Filters": "Angewandte Filter",
        "Tag variants": "Tag-Varianten",
        "There is no query selected. Or applied filters are not visible.": "Es ist keine Abfrage ausgewählt. Oder angewendete Filter sind nicht sichtbar.",
        "Filters": "Filter",
        "Variants distributions - (based on max 3000 entries)": "Variantenverteilungen – (basierend auf maximal 3000 Einträgen)",
        "Export Variants (max.1000)": "Varianten exportieren (max. 1000)",
        "Once selected, click again to unapply": "Klicken Sie nach der Auswahl erneut, um die Anwendung aufzuheben",
        "Label": "Etikett",
        "Important": "Wichtig",
        "Follow-Up": "Nachverfolgen",
        "Discarded": "Weggeworfen",
        "Candidate": "Kandidat",
        "List of Integrated Plugins": "Liste der integrierten Plugins",
        "Please, give a name to your study to save it correctly. ": "Bitte geben Sie Ihrer Studie einen Namen, um sie korrekt zu speichern.",
        "You have unsaved queries": "Sie haben nicht gespeicherte Abfragen",
        "Save current Study with Analyses and queries": "Speichern Sie die aktuelle Studie mit Analysen und Abfragen",
        "You are not the creator of the study": "Sie sind nicht der Ersteller der Studie",
        "Study title. Max 50 chars": "Studientitel. Maximal 50 Zeichen",
        "Analyses without queries will not be saved": "Analysen ohne Abfragen werden nicht gespeichert",
        "Update Study Information": "Studieninformationen aktualisieren",
        "Save Study": "Studie speichern",
        "Fill in some information to save your work.": "Geben Sie einige Informationen ein, um Ihre Arbeit zu speichern.",
        "Study description. Max 50 chars": "Studienbeschreibung. Maximal 50 Zeichen",
        "Select": "Wählen",
        "Please select study visibility": "Bitte wählen Sie Studiensichtbarkeit aus",
        "Private": "Privat",
        "Share with your groups": "Teilen Sie es mit Ihren Gruppen",
        "Public": "Öffentlich",
        "Cancel": "Stornieren",
        "Save changes": "Änderungen speichern",
        "Share the study with collaborators": "Teilen Sie die Studie mit Mitarbeitern",
        "CNV variant already Tagged": "CNV-Variante bereits markiert",
        "Tag CNV Variant": "Markieren Sie die CNV-Variante",
        "See tag in the ": "Siehe Tag im",
        "Click here if you want to see a summary of the data stored in PhenoStore for the tagged participant": "Klicken Sie hier, wenn Sie eine Zusammenfassung der im PhenoStore gespeicherten Daten für den markierten Teilnehmer sehen möchten",
        "Go to Participant": "Gehen Sie zu Teilnehmer",
        "summary": "Zusammenfassung",
        " You also deleted the variant from PhenoStore. If you did it by mistake, please tag again.": "Sie haben die Variante auch aus PhenoStore gelöscht. Wenn Sie es versehentlich getan haben, taggen Sie es bitte erneut.",
        "Delete": "Löschen",
        "Delete your tag": "Löschen Sie Ihr Tag",
        "When you TAG a variant, the tag will be visible to all users of the platform regardless of the embargo period": "Wenn Sie eine Variante markieren, ist das Tag unabhängig von der Sperrfrist für alle Benutzer der Plattform sichtbar",
        "Variant not tagged yet": "Variante noch nicht markiert",
        "Related to": "Bezogen auf",
        "Project": "Projekt",
        "Type of variant - Please review this field": "Variantentyp – Bitte überprüfen Sie dieses Feld",
        "Inheritance": "Nachlass",
        "Origin": "Herkunft",
        "Zygosity": "Zygosität",
        "Clinical Significance": "Klinische Bedeutung",
        "Interpretation": "Interpretation",
        "Is this the Causative Variant?": "Ist das die ursächliche Variante?",
        "Causative Variant (confirmed as the cause of the primary disorder)": "Verursachende Variante (bestätigt als Ursache der primären Störung)",
        "This clinical evidence has already been tagged and will be displayed in the ": "Dieser klinische Beweis wurde bereits markiert und wird im angezeigt",
        "Report": "Bericht",
        "module. Editing this tag will modify the interpretation comment.": "Modul. Durch Bearbeiten dieses Tags wird der Interpretationskommentar geändert.",
        "Tagging a clinical evidence will make it available to add in a ": "Durch das Markieren eines klinischen Nachweises wird dieser zum Hinzufügen verfügbar gemacht",
        "This haplotype has already been tagged and will be displayed in the ": "Dieser Haplotyp wurde bereits markiert und wird im angezeigt",
        " module. Editing this tag will modify the interpretation comment.": "Modul. Durch Bearbeiten dieses Tags wird der Interpretationskommentar geändert.",
        "Tagging a haplotype will make it available to add in a ": "Durch Markieren eines Haplotyps wird dieser zum Hinzufügen verfügbar gemacht",
        "Submit": "Einreichen",
        "Close": "Schließen",
        "Required": "Erforderlich",
        "Tag variants in the GPAP database": "Tag-Varianten in der GPAP-Datenbank",
        "Tag": "Etikett",
        "Pathogenic": "Pathogen",
        "Likely pathogenic": "Wahrscheinlich pathogen",
        "Uncertain significance": "Ungewisse Bedeutung",
        "Likely benign": "Wahrscheinlich harmlos",
        "Benign": "Gutartig",
        "Drug response": "Arzneimittelreaktion",
        "Association": "Verein",
        "Risk factor": "Risikofaktor",
        "Protective": "Schützend",
        "Autosomal recessive": "Autosomal-rezessiv",
        "X-linked": "X-chromosomal",
        "X-linked dominant": "X-chromosomal dominant",
        "X-linked recessive": "X-chromosomal rezessiv",
        "Y-linked inheritance": "Y-chromosomale Vererbung",
        "Mitochondrial inheritance": "Mitochondriale Vererbung",
        "Oligogenic inheritance": "Oligogene Vererbung",
        "Digenic inheritance": "Abstammung",
        "Autosomal dominant with paternal imprinting": "Autosomal-dominant mit väterlicher Prägung",
        "Autosomal dominant with maternal imprinting": "Autosomal-dominant mit mütterlicher Prägung",
        "Unknown": "Unbekannt",
        "Heterozygous": "Heterozygot",
        "Homozygous": "Homozygot",
        "Hemizygous": "Hemizygot",
        "Primary disorder": "Primäre Störung",
        "Secondary findings": "Sekundärbefunde",
        "Pharmacogenomics": "Pharmakogenomik",
        "Polygenic risk score": "Polygener Risiko-Score",
        "SNV-InDel": "SNV-InDel",
        "Duplication": "Vervielfältigung",
        "Deletion": "Streichung",
        "Inversion": "Umkehrung",
        "Short tandem repeat": "Kurze Tandemwiederholung",
        "Translocation": "Translokation",
        "germline": "Keimbahn",
        "somatic": "somatisch",
        "de novo": "de novo",
        "maternal": "mütterlicherseits",
        "paternal": "väterlicherseits",
        "inherited": "geerbt",
        "unknown": "unbekannt",
        "The filters selected for SNV-InDels do not automatically apply to CNVs and SVs except gene lists. You can further filter the CNVs directly in the table": "Die für SNV-InDels ausgewählten Filter gelten nicht automatisch für CNVs und SVs mit Ausnahme von Genlisten. Sie können die CNVs direkt in der Tabelle weiter filtern",
        "Load variants by clicking on a gene or experiment on the left, or a participant in the table above.": "Laden Sie Varianten, indem Sie links auf ein Gen oder Experiment oder auf einen Teilnehmer in der Tabelle oben klicken.",
        "No variants have passed the selected filters.": "Keine Variante hat die ausgewählten Filter bestanden.",
        "To display variants, try using less restrictive filters or expanding the gene list": "Um Varianten anzuzeigen, versuchen Sie, weniger restriktive Filter zu verwenden oder die Genliste zu erweitern",
        "Variants associated to experiments": "Mit Experimenten verbundene Varianten",
        "Variants": "Varianten",
        "The displayed number is the total sum of events in the FULL and SPLIT results.": "Die angezeigte Zahl ist die Gesamtsumme der Ereignisse in den FULL- und SPLIT-Ergebnissen.",
        "SNV and InDels": "SNV und InDels",
        "Note that the number of rows shown in the variants table can be higher than the number of genomic variants shown in the counter since one genomic variant can affect more than one gene.": "Beachten Sie, dass die Anzahl der in der Variantentabelle angezeigten Zeilen höher sein kann als die Anzahl der im Zähler angezeigten genomischen Varianten, da eine genomische Variante mehr als ein Gen betreffen kann.",
        "Gene Info": "Gen-Info",
        "Variant Information": "Varianteninformationen",
        "Chrom": "Chrom",
        "SV Type": "SV-Typ",
        "Copy Number": "Nummer kopieren",
        "CytoBand": "CytoBand",
        "Translocation_ID     ": "Translocation_ID",
        "Quality": "Qualität",
        "Clinical Association": "Klinischer Verband",
        "ACMG Classific.": "ACMG-Klassifizierung.",
        "AnnotSV Reported pathogenic": "AnnotSV Als pathogen gemeldet",
        "P_gain_phen": "P_gain_phen",
        "P_loss_phen": "P_loss_phen",
        "P_ins_phen": "P_ins_phen",
        "Population Frequency": "Bevölkerungshäufigkeit",
        "B_gain_AFmax": "B_gain_AFmax",
        "B_loss_AFmax": "B_loss_AFmax",
        "B_ins_AFmax": "B_ins_AFmax",
        "Albert AnnotSV populaton AFMax ": "Albert AnnotSV Population AFMax",
        "Internal Freq.": "Interne Freq.",
        "Exact match": "Genaue Übereinstimmung",
        "Additional Information": "Weitere Informationen",
        "Experiment ID": "Experiment-ID",
        "Translocation_ID": "Translocation_ID",
        "Internal Freq. Count": "Interne Freq. Zählen",
        "Location": "Standort",
        "Clinical Association ": "Klinischer Verband",
        "AnnotSV population AFMax": "AnnotSV-Population AFMax",
        "Internal Freq": "Interne Freq",
        "Additional information": "Weitere Informationen",
        "Previous Tags": "Vorherige Tags",
        "Status": "Status",
        "Significance": "Bedeutung",
        "Date": "Datum",
        "Comments": "Kommentare",
        "Your Tag": "Dein Tag",
        "Samples Information not available": "Probeninformationen nicht verfügbar",
        "IGV Browser": "IGV-Browser",
        "Predictors": "Prädiktoren",
        "Genotypes": "Genotypen",
        "Fam.ID": "Fam.ID",
        "Local Exp. ID": "Lokaler Exp. AUSWEIS",
        "Affected Status": "Betroffener Status",
        "Consanguinity": "Blutsverwandtschaft",
        "Sex": "Sex",
        "Solved Status": "Gelöst-Status",
        "Obs. HPOs": "Obs. HPOs",
        "Diagnosis": "Diagnose",
        "Contact Owner": "Kontaktieren Sie den Eigentümer",
        "Show the variants of the selected participant": "Zeigen Sie die Varianten des ausgewählten Teilnehmers an",
        "Participant ID": "Teilnehmer-ID",
        "Owner": "Eigentümer",
        "ERNs": "ERNs",
        "Life Status": "Lebensstatus",
        "# HPOs": "# HPOs",
        "Date of Birth": "Geburtsdatum",
        "Date of Death": "Sterbedatum",
        "indel": "indel",
        "ref": "ref",
        "alt": "alt",
        "pos": "Pos",
        "Population": "Bevölkerung",
        "freqIntGermline": "freqIntGermline",
        "gnomad_an_popmax": "gnomad_an_popmax",
        "gnomad_an": "gnomad_an",
        "freqIntGermlineNum": "freqIntGermlineNum",
        "freqIntGermlineDem": "freqIntGermlineDem",
        "gnomad_ac": "gnomad_ac",
        "gnomad_af": "gnomad_af",
        "gnomad_nhomalt": "gnomad_nhomalt",
        "gnomad_ac_popmax": "gnomad_ac_popmax",
        "gnomad_af_popmax": "gnomad_af_popmax",
        "spliceai_dp_al": "spliceai_dp_al",
        "sift_score": "sieben_score",
        "mm_poly_ac": "mm_poly_ac",
        "gnomad_faf95_popmax": "gnomad_faf95_popmax",
        "spliceai_ds_ag": "spliceai_ds_ag",
        "spliceai_dp_dg": "spliceai_dp_dg",
        "spliceai_max_ds": "spliceai_max_ds",
        "spliceai_ds_al": "spliceai_ds_al",
        "spliceai_ds_dl": "spliceai_ds_dl",
        "spliceai_dp_ag": "spliceai_dp_ag",
        "spliceai_dp_dl": "spliceai_dp_dl",
        "spliceai_ds_dg": "spliceai_ds_dg",
        "revel_score": "revel_score",
        "vep_proc_id.block_idx": "vep_proc_id.block_idx",
        "mt": "mt",
        "mm_dis_disease": "mm_dis_disease",
        "intervar_automated": "intervar_automated",
        "mm_dis_disease_status": "mm_dis_disease_status",
        "vep_proc_id.part_idx": "vep_proc_id.part_idx",
        "intervar_evidence": "intervar_evidence",
        "mm_poly_af": "mm_poly_af",
        "View Applied Filters": "Angewendete Filter anzeigen",
        "Clearing filters": "Filter löschen",
        "Use a predefined filter set": "Verwenden Sie einen vordefinierten Filtersatz",
        "Variant Type": "Variantentyp",
        "SNV Effect Predictions": "SNV-Effektvorhersagen",
        "Position Specific and Runs Of Homozygosity": "Positionsspezifisch und Homozygotieläufe",
        "Create/Adjust Filters": "Filter erstellen/anpassen",
        "Filter by variants": "Nach Varianten filtern",
        "Gene Filters": "Genfilter",
        "Filter by genes": "Nach Genen filtern",
        "Predefined lists": "Vordefinierte Listen",
        "Select a Predefined Gene List prepared from ERNs or the GPAP team": "Wählen Sie eine vordefinierte Genliste aus, die von ERNs oder dem GPAP-Team erstellt wurde",
        "Gene search and upload": "Gensuche und Hochladen",
        "Enter multiple gene symbols using HGNC nomenclature or Upload your own": "Geben Sie mehrere Gensymbole mithilfe der HGNC-Nomenklatur ein oder laden Sie Ihre eigenen hoch",
        "WARNING: This platform uses annotations from Ensembl ": "WARNUNG: Diese Plattform verwendet Anmerkungen von Ensembl",
        ". Any Gene Symbol used for filtering which does not match the symbol used in Ensembl v109 may be missed or wrongly assigned. This also applies to external web-services. We are currently working to mitigate this issue.": ". Jedes zum Filtern verwendete Gensymbol, das nicht mit dem in Ensembl v109 verwendeten Symbol übereinstimmt, wird möglicherweise übersehen oder falsch zugewiesen. Dies gilt auch für externe Webdienste. Wir arbeiten derzeit daran, dieses Problem zu beheben.",
        "Diseases": "Krankheiten",
        "Select one or more OMIM terms or a PanelApp to query the associated genes": "Wählen Sie einen oder mehrere OMIM-Begriffe oder eine PanelApp aus, um die zugehörigen Gene abzufragen",
        "Symptoms": "Symptome",
        "Collect the HPOs from the index case or search more HPOs to query the associated genes": "Sammeln Sie die HPOs aus dem Indexfall oder suchen Sie nach weiteren HPOs, um die zugehörigen Gene abzufragen",
        "Pathways are retrieved from REACTOME and associations with genes from The Comparative Toxicogenomics database": "Signalwege werden aus REACTOME und Assoziationen mit Genen aus der Datenbank „Comparative Toxicogenomics“ abgerufen",
        "Clear gene list": "Übersichtliche Genliste",
        "Operator": "Operator",
        "Union": "Union",
        "Intersection": "Überschneidung",
        "Upload comma separated list of HGNC identifiers": "Laden Sie eine durch Kommas getrennte Liste der HGNC-Kennungen hoch",
        "(The file is not stored in GPAP)": "(Die Datei wird nicht in GPAP gespeichert)",
        "Genes not validated": "Gene nicht validiert",
        "Current selected genes by applying ": "Aktuelle ausgewählte Gene durch Anwendung",
        " of gene lists": "von Genlisten",
        "No genes selected": "Keine Gene ausgewählt",
        "All gene symbols were validated using Ensembl": "Alle Gensymbole wurden mit Ensembl validiert",
        " gene symbols could not be validated using ": "Gensymbole konnten mit nicht validiert werden",
        "Validating gene symbols..": "Validierung von Gensymbolen.",
        "Gene Validation": "Genvalidierung",
        "In the next step, you can extract genes from HPOs and add them to your queries": "Im nächsten Schritt können Sie Gene aus HPOs extrahieren und zu Ihren Abfragen hinzufügen",
        "Next": "Nächste",
        "Back": "Zurück",
        "Intersect the genes extracted from the HPOs ": "Schneiden Sie die aus den HPOs extrahierten Gene ab",
        "Apply Intersection of Genes": "Wenden Sie „Intersection of Genes“ an",
        "Make union of extracted genes (default)": "Vereinigung der extrahierten Gene herstellen (Standard)",
        "Apply Union of Genes": "Wenden Sie Union of Genes an",
        "You included the genes in your query. You made an ": "Sie haben die Gene in Ihre Abfrage einbezogen. Du hast ein gemacht",
        " between the HPO genes": "zwischen den HPO-Genen",
        "Reset HPO gene list": "HPO-Genliste zurücksetzen",
        "Add Genes to Query": "Gene zur Abfrage hinzufügen",
        "No genes shared across all HPOs": "Keine Gene, die von allen HPOs gemeinsam genutzt werden",
        "No Genes": "Keine Gene",
        "Clear current HPO selection": "Aktuelle HPO-Auswahl löschen",
        "HPOs collected from analysed index case": "Aus analysierten Indexfällen gesammelte HPOs",
        "Get HPOs from Participants": "Erhalten Sie HPOs von Teilnehmern",
        "search for HPO terms": "Suche nach HPO-Begriffen",
        "You have now": "Das hast du jetzt",
        "HPO terms selected": "HPO-Begriffe ausgewählt",
        "Select Resource:": "Ressource auswählen:",
        "HPO Ontology": "HPO-Ontologie",
        "Select Method:": "Methode auswählen:",
        "of genes in this section is applied ": "der Gene in diesem Abschnitt wird angewendet",
        "Select a predefined gene list": "Wählen Sie eine vordefinierte Genliste aus",
        "search for OMIM terms": "Suche nach OMIM-Begriffen",
        "Delete OMIM associated genes": "Löschen Sie OMIM-assoziierte Gene",
        "Enter multiple OMIM ids or names of disorders": "Geben Sie mehrere OMIM-IDs oder Namen von Störungen ein",
        "Search for OMIM terms": "Suchen Sie nach OMIM-Begriffen",
        "Request Error": "Anfragefehler",
        "Error": "Fehler",
        "Loading genes...": "Gene werden geladen...",
        "Panel ": "Panel",
        "includes a total of ": "umfasst insgesamt",
        " entities. There are ": "Entitäten. Es gibt",
        " distinct genes in the ": "unterschiedliche Gene in der",
        " green list": "Grüne Liste",
        "Read more of this panel in PanelApp": "Lesen Sie mehr über dieses Panel in PanelApp",
        "Delete PanelApp genes from query": "PanelApp-Gene aus der Abfrage löschen",
        "Add Panel App Genes": "Panel-App-Gene hinzufügen",
        "Type of Panel": "Art des Panels",
        "Genes associated to": "Gene assoziiert mit",
        "No genes": "Keine Gene",
        "Search Pathways": "Suchpfade",
        "Delete Pathway associated genes": "Pathway-assoziierte Gene löschen",
        "Search for Pathways": "Nach Pfaden suchen",
        "Gene Search": "Gensuche",
        "search for a gene name": "Suche nach einem Gennamen",
        "A number between 0-1": "Eine Zahl zwischen 0 und 1",
        "INTERNAL FREQUENCY": "INTERNE FREQUENZ",
        "Clear Filters": "Filter löschen",
        "PASS + unannotated (NA)": "PASS + unkommentiert (NA)",
        "non-PASS": "Nicht bestanden",
        "Selected Positions": "Ausgewählte Positionen",
        "X": "X",
        "The X chromosome has been automatically selected to match the selected inheritance": "Das X-Chromosom wurde automatisch entsprechend der ausgewählten Vererbung ausgewählt",
        "Info": "Info",
        "Select a chromosome. Positions are considered ONLY if a chromosome is selected": "Wählen Sie ein Chromosom aus. Positionen werden NUR berücksichtigt, wenn ein Chromosom ausgewählt wird",
        "Chromosome": "Chromosom",
        "Start Position": "Startposition",
        "Chromosome required": "Chromosom erforderlich",
        "End Position": "Endposition",
        "Upload BED file": "Laden Sie die BED-Datei hoch",
        "Upload Coordinate file": "Laden Sie die Koordinatendatei hoch",
        "When applied, will return only variants that fall within a run of homozygosity of at least the length selected in the first affected sample": "Bei der Anwendung werden nur Varianten zurückgegeben, die in einen Homozygotielauf von mindestens der in der ersten betroffenen Probe ausgewählten Länge fallen",
        "Minimum run of homozygosity length": "Mindestlänge der Homozygotie",
        "Not Specified": "Nicht angegeben",
        "Annotated as disease causing": "Als krankheitsverursachend bezeichnet",
        "Disease causing": "Krankheitserregend",
        "Polymorphism": "Polymorphismus",
        "Neutral": "Neutral",
        "Damaging": "Schädlich",
        "Tolerated": "Toleriert",
        "Probably damaging": "Wahrscheinlich schädlich",
        "Possibly damaging": "Möglicherweise schädlich",
        "Oncogenic": "Onkogen",
        " Likely Oncogenic": "Wahrscheinlich onkogen",
        " VUS": "VUS",
        " Benign": "Gutartig",
        " Likely Benign": "Wahrscheinlich gutartig",
        "Likely Benign": "Wahrscheinlich gutartig",
        " Pathogenic": "Pathogen",
        " Likely Pathogenic": "Wahrscheinlich pathogen",
        "0": "0",
        "20": "20",
        "40": "40",
        "60": "60",
        "80": "80",
        "100": "100",
        "Phred-scaled CADD score, indicating level of deleteriousness e.g. 20 (predicted top 1% most deleterious)": "Phred-skalierter CADD-Score, der den Grad der Schädlichkeit angibt, z. B. 20 (voraussichtlich das oberste 1 % der schädlichsten)",
        "CADD Prediction": "CADD-Vorhersage",
        "High": "Hoch",
        "Moderate": "Mäßig",
        "Low": "Niedrig",
        "Modifier": "Modifikator",
        "transcript_ablation": "transcript_ablation",
        "splice_acceptor_variant": "splice_acceptor_variant",
        "splice_donor_variant": "splice_donor_variant",
        "stop_gained": "stop_gained",
        "frameshift_variant": "Frameshift_Variante",
        "stop_lost": "stop_lost",
        "start_lost": "start_lost",
        "transcript_amplification": "transcript_amplification",
        "feature_elongation": "feature_elongation",
        "feature_truncation": "feature_truncation",
        "inframe_insertion": "inframe_insertion",
        "inframe_deletion": "inframe_deletion",
        "missense_variant": "missense_variant",
        "protein_altering_variant": "protein_altering_variant",
        "splice_donor_5th_base_variant": "splice_donor_5th_base_variant",
        "splice_region_variant": "splice_region_variant",
        "splice_donor_region_variant": "splice_donor_region_variant",
        "splice_polypyrimidine_tract_variant": "splice_polypyrimidine_tract_variant",
        "incomplete_terminal_codon_variant": "unvollständige_terminale_codon_variante",
        "start_retained_variant": "start_retained_variant",
        "stop_retained_variant": "stop_retained_variant",
        "synonymous_variant": "synonym_variant",
        "coding_sequence_variant": "Kodierungssequenz_Variante",
        "mature_miRNA_variant": "reife_miRNA_variant",
        "5_prime_UTR_variant": "5_prime_UTR_variant",
        "3_prime_UTR_variant": "3_prime_UTR_variant",
        "non_coding_transcript_exon_variant": "non_coding_transcript_exon_variant",
        "intron_variant": "intron_variant",
        "NMD_transcript_variant": "NMD_transcript_variant",
        "non_coding_transcript_variant": "non_coding_transcript_variant",
        "coding_transcript_variant": "kodierung_transkript_variante",
        "upstream_gene_variant": "upstream_gene_variant",
        "downstream_gene_variant": "downstream_gene_variant",
        "TFBS_ablation": "TFBS_ablation",
        "TFBS_amplification": "TFBS_amplification",
        "TF_binding_site_variant": "TF_binding_site_variant",
        "regulatory_region_ablation": "regulatorische_region_ablation",
        "regulatory_region_amplification": "regulatorische_region_amplification",
        "regulatory_region_variant": "regulatorische_region_variante",
        "intergenic_variant": "intergenic_variant",
        "sequence_variant": "sequence_variant",
        "SNV": "SNV",
        "INDEL": "INDEL",
        "Mane Select": "Mähnenauswahl",
        "Selected samples": "Ausgewählte Proben",
        "Any Sample": "Jede Probe",
        "Variant of uncertain significance": "Variante mit ungewisser Bedeutung",
        "Conflicting interpretations": "Widersprüchliche Interpretationen",
        "Any": "Beliebig",
        "Coding": "Codierung",
        "RNA": "RNA",
        "Known": "Bekannt",
        "Predicted driver: Tier 1": "Voraussichtlicher Fahrer: Tier 1",
        "Predicted driver: Tier 2": "Voraussichtlicher Fahrer: Stufe 2",
        "Predicted passenger": "Voraussichtlicher Passagier",
        "Apply": "Anwenden",
        "Click here if you want to delete the Filter-Set from the database. This is an irreversible action. ": "Klicken Sie hier, wenn Sie das Filter-Set aus der Datenbank löschen möchten. Dies ist eine irreversible Aktion.",
        "GPAP Predefined Filters are curated by the GPAP team": "Vordefinierte GPAP-Filter werden vom GPAP-Team kuratiert",
        "Select a predefined filter set": "Wählen Sie einen vordefinierten Filtersatz aus",
        "Reset the applied filter-set": "Setzen Sie den angewendeten Filtersatz zurück",
        "Reset": "Zurücksetzen",
        "Filter Set deleted": "Filtersatz gelöscht",
        "No leading and trailing spaces are allowed": "Es sind keine führenden und nachfolgenden Leerzeichen zulässig",
        "Name already exists.": "Name existiert bereits.",
        "Filter Panel": "Filterfeld",
        "Run Query": "Abfrage ausführen",
        "Reset Filters": "Filter zurücksetzen",
        "Save Filter-Set": "Filtersatz speichern",
        "Filter Name": "Filtername",
        "Filter Description": "Filterbeschreibung",
        "filter_description": "filter_beschreibung",
        "Save Filter": "Filter speichern",
        "Filters saved in the database. You can find the filter-set in the section below.": "In der Datenbank gespeicherte Filter. Das Filterset finden Sie im Abschnitt unten.",
        "Filter Set Saved Correctly": "Filtersatz korrekt gespeichert",
        "Create New Analysis": "Neue Analyse erstellen",
        "Genotype Settings": "Genotyp-Einstellungen",
        "You have created ": "Du hast erstellt",
        " analyses.": "Analysen.",
        "You can now create a query that will be applied to the genotype(s). Select your filters and run the new query.": "Sie können jetzt eine Abfrage erstellen, die auf den/die Genotyp(en) angewendet wird. Wählen Sie Ihre Filter aus und führen Sie die neue Abfrage aus.",
        "Apply to current Samples": "Auf aktuelle Samples anwenden",
        "or start from new Samples:": "oder mit neuen Beispielen beginnen:",
        "custom": "Brauch",
        "Autosomal Dominant": "Autosomal-dominant",
        "Autosomal Recessive Homozygous": "Autosomal rezessiv homozygot",
        "Autosomal Recessive Compound Heteroz.": "Autosomal rezessive zusammengesetzte Heteroz.",
        "De Novo": "De Novo",
        "Deleterious": "Schädlich",
        "tolerated": "toleriert",
        "Disease_causing_automatic": "Krankheitsverursachend_automatisch",
        "Disease_causing": "Krankheitsverursachend",
        "Probably harmless": "Wahrscheinlich harmlos",
        "Known to be harmless": "Bekanntermaßen harmlos",
        "Oncegenic": "Einmalgen",
        "Likely Oncogenic": "Wahrscheinlich onkogen",
        "VUS": "VUS",
        "Likely Pathogenic": "Wahrscheinlich pathogen",
        "Variants distributions": "Variantenverteilungen",
        "GnomAD Population": "GnomAD-Bevölkerung",
        "Filter Sets": "Filtersätze",
        "Predefined Gene Lists": "Vordefinierte Genlisten",
        "Analysis Settings": "Analyseeinstellungen",
        "Analysis Parameters": "Analyseparameter",
        "Standard Pipelines": "Standard-Pipelines",
        "Preview External Resources": "Vorschau externer Ressourcen",
        "Filter Set Manager": "Filtersatz-Manager",
        "Create and Save a new Filter Set": "Erstellen und speichern Sie einen neuen Filtersatz",
        "Available Filter Sets": "Verfügbare Filtersätze",
        "GPAP default": "GPAP-Standard",
        "User created": "Benutzer erstellt",
        "No description": "Keine Beschreibung",
        "View filters": "Filter anzeigen",
        "Applied filters:": "Angewandte Filter:",
        "No filters found": "Keine Filter gefunden",
        "List of genes": "Liste der Gene",
        "Search:": "Suchen:",
        "No results": "Keine Ergebnisse",
        "This list contains": "Diese Liste enthält",
        "genes": "Gene",
        "Export in .txt": "Als .txt exportieren",
        "Save List": "Liste speichern",
        "Create a new List": "Erstellen Sie eine neue Liste",
        "List Information": "Listeninformationen",
        "List Title": "Listentitel",
        "List Source": "Quelle auflisten",
        "Notes": "Notizen",
        "Gene List": "Genliste",
        "Variant Positions": "Variantenpositionen",
        "Visibility": "Sichtweite",
        "List Visibility": "Sichtbarkeit der Liste",
        "When active, the list will be usable for analyses. If this list has the same name as an existing one, the previous one will become inactive to users.": "Wenn diese Option aktiviert ist, kann die Liste für Analysen verwendet werden. Wenn diese Liste denselben Namen wie eine bestehende hat, wird die vorherige Liste für Benutzer inaktiv.",
        "Active": "Aktiv",
        "Upload a List": "Laden Sie eine Liste hoch",
        "View Lists": "Listen anzeigen",
        "File type": "Dateityp",
        "Please select file type": "Bitte wählen Sie den Dateityp aus",
        "GPAP Gene File": "GPAP-Gendatei",
        ".list File": ".list-Datei",
        "Comma Separated List": "Durch Kommas getrennte Liste",
        "ACTIVE": "AKTIV",
        "Not Active": "Nicht aktiv",
        "Predefined Gene List Info": "Vordefinierte Informationen zur Genliste",
        "Predefined Lists in GPAP": "Vordefinierte Listen in GPAP",
        "Visible List": "Sichtbare Liste",
        "Are you sure to delete the Predefined Gene List?": "Sind Sie sicher, dass Sie die Liste der vordefinierten Gene löschen möchten?",
        "Yes, delete it": "Ja, löschen Sie es",
        "Gene list was validated": "Die Genliste wurde validiert",
        "Upload Gene list": "Genliste hochladen",
        "Some genes could not be validated. Please check them and re-upload the file:": "Einige Gene konnten nicht validiert werden. Bitte überprüfen Sie diese und laden Sie die Datei erneut hoch:",
        "Please check the text": "Bitte überprüfen Sie den Text",
        "Genes are not in the correct format, please check the text": "Die Gene haben nicht das richtige Format. Bitte überprüfen Sie den Text",
        "Fields should not be empty, please check the text": "Felder dürfen nicht leer sein, bitte überprüfen Sie den Text",
        "Gene list is empty. Please check the file.": "Die Genliste ist leer. Bitte überprüfen Sie die Datei.",
        "Cancel Gene List Upload": "Hochladen der Genliste abbrechen",
        "Upload a GPAP gene file in txt format": "Laden Sie eine GPAP-Gendatei im TXT-Format hoch",
        "Be sure that the file is in the correct format and all required fields are present": "Stellen Sie sicher, dass die Datei das richtige Format hat und alle erforderlichen Felder vorhanden sind",
        "Select file": "Datei auswählen",
        "Download our template file and fill it in with your data": "Laden Sie unsere Vorlagedatei herunter und füllen Sie diese mit Ihren Daten aus",
        "Download sample file": "Beispieldatei herunterladen",
        "Validating genes..": "Gene validieren..",
        "Upload a .list file": "Laden Sie eine .list-Datei hoch",
        "The .list file is the legacy file format used to store predefined gene lists in the GPAP. This upload is meant for CNAG users. For more information, contact Steve Laurie.": "Die .list-Datei ist das alte Dateiformat, das zum Speichern vordefinierter Genlisten im GPAP verwendet wird. Dieser Upload ist für CNAG-Benutzer gedacht. Für weitere Informationen wenden Sie sich bitte an Steve Laurie.",
        "The file must contain a JSON array of Predefined Gene List Objects.": "Die Datei muss ein JSON-Array vordefinierter Genlistenobjekte enthalten.",
        "upload .list file": "Laden Sie die .list-Datei hoch",
        "lists parsed correctly and ready to be saved.": "Listen werden korrekt analysiert und können gespeichert werden.",
        "Save Lists": "Listen speichern",
        "Preview of External resources accessible in this instance:": "Vorschau der in diesem Fall zugänglichen externen Ressourcen:",
        "SNV external resources": "Externe SNV-Ressourcen",
        "CNV external resources": "Externe CNV-Ressourcen",
        "Edit": "Bearbeiten",
        "DIR LIST": "DIR-LISTE",
        "Case ID": "Fall-ID",
        "Patient ID": "Patienten-ID",
        "Analysis Type": "Analysetyp",
        "Priority": "Priorität",
        "Deadline": "Frist",
        "Assignee(s)": "Beauftragter(n)",
        "Creation Date": "Erstellungsdatum",
        "Tumor Experiment": "Tumorexperiment",
        "Control Experiment": "Kontrollversuch",
        "Clinical referrer": "Klinischer Überweiser",
        "Clinical contact": "Klinischer Kontakt",
        "Hospital Name": "Name des Krankenhauses",
        "Tumor characteristics": "Tumoreigenschaften",
        "Tumor purity": "Tumorreinheit",
        "Average ploidy": "Durchschnittliche Ploidie",
        "Microsatellite status": "Mikrosatellitenstatus",
        "Tumor mutational burden": "Belastung durch Tumormutationen",
        "Homologous recombination deficiency (HRD) status": "Status des homologen Rekombinationsmangels (HRD).",
        "Bin-level log2 coverages and segmentation calls": "Log2-Abdeckungen und Segmentierungsaufrufe auf Bin-Ebene",
        "Source: CNVkit": "Quelle: CNVkit",
        "Copy number per chromosome": "Kopienzahl pro Chromosom",
        "Tumor Sample ID:": "Tumorproben-ID:",
        "Mutational signatures": "Mutationssignaturen",
        "Source: SigProfilerAssignment": "Quelle: SigProfilerAssignment",
        "Somatic Tumor Only": "Nur somatischer Tumor",
        "Somatic Tumor Normal": "Somatischer Tumor normal",
        "Somatic Fusion Analysis": "Somatische Fusionsanalyse",
        "Germline": "Keimbahn",
        "New Instand DIR Study": "Neue Instand DIR-Studie",
        "Request Overview": "Anfrageübersicht",
        "Reports": "Berichte",
        "Tumor Genetic Overview": "Tumorgenetischer Überblick",
        "Clinical Evidence": "Klinischer Beweis",
        "Gene Fusions": "Genfusionen",
        "Request": "Anfrage",
        "To Do": "Zu tun",
        "In Progress": "Im Gange",
        "Done": "Erledigt",
        "Medium": "Medium",
        "General Info": "Allgemeine Informationen",
        "Request ID": "ID anfordern",
        "Administrative Info": "Verwaltungsinformationen",
        "Creator": "Schöpfer",
        "Creation date": "Erstellungsdatum",
        "Hospital name": "Name des Krankenhauses",
        "Clinical referrer contact info": "Kontaktinformationen des klinischen Überweisers",
        "Filter Set": "Filtersatz",
        "Update Filters": "Filter aktualisieren",
        "Patient Info": "Patienteninformationen",
        "Clinical Info": "Klinische Informationen",
        "Clinical referral": "Klinische Überweisung",
        "Observed Symptoms": "Beobachtete Symptome",
        "Diagnostic date": "Diagnosedatum",
        "Clinical diagnosis": "Klinische Diagnose",
        "Case number pathology": "Fallzahlpathologie",
        "Treatment history and response": "Behandlungsgeschichte und Ansprechen",
        "Cancer type": "Krebstyp",
        "Tumor Sample and Experiment Info": "Informationen zu Tumorproben und Experimenten",
        "Sample Info": "Beispielinformationen",
        "Sample ID": "Proben-ID",
        "Sample site of origin": "Beispielhafter Herkunftsort",
        "Sample site of origin status": "Beispiel für den Ursprungsstandortstatus",
        "Sample fixation": "Probenfixierung",
        "Purity": "Reinheit",
        "Experiment Info": "Experimentinformationen",
        "Control Experiment ID": "Kontrollexperiment-ID",
        "Library strategy": "Bibliotheksstrategie",
        "Kit": "Bausatz",
        "Control Sample and Experiment Info": "Kontrollproben- und Experimentinformationen",
        "Relevant Observations": "Relevante Beobachtungen",
        "Sample quality observations": "Beobachtungen zur Stichprobenqualität",
        "Sequencing quality observations": "Qualitätsbeobachtungen sequenzieren",
        "Fusion": "Fusion",
        "Fusioned genes": "Fusionierte Gene",
        "Junction read count": "Anzahl der Junction-Lesevorgänge",
        "Left Gene": "Linkes Gen",
        "Left gene": "Linkes Gen",
        "Left breakpoint": "Linker Haltepunkt",
        "Right Gene": "Richtiges Gen",
        "Right gene": "Richtiges Gen",
        "Right breakpoint": "Rechter Haltepunkt",
        "SNVs and InDels - Somatic": "SNVs und InDels – Somatisch",
        "SNVs and InDels - Germline": "SNVs und InDels – Keimbahn",
        "CNVs and SVs": "CNVs und SVs",
        "Treatments": "Behandlungen",
        "Gene Evidence": "Gennachweis",
        "Genotype": "Genotyp",
        "Phenotype": "Phänotyp",
        "Haplotype1": "Haplotyp1",
        "Haplotype1 Evidence": "Haplotyp1-Beweis",
        "Haplotype2": "Haplotyp2",
        "Haplotype2 Evidence": "Haplotyp2-Beweis",
        "Variant Data": "Variantendaten",
        "Clinical Reports": "Klinische Berichte",
        "Patient Reports": "Patientenberichte",
        "Clinical reports": "Klinische Berichte",
        "Report ID": "Berichts-ID",
        "Report Name": "Berichtsname",
        "Genomic breakpoints detected": "Genomische Bruchpunkte erkannt",
        "Clinically relevant gene": "Klinisch relevantes Gen",
        "Clinical significance**": "Klinische Bedeutung**",
        "cDNA": "cDNA",
        "Protein": "Protein",
        "Associated Clinical Report ID": "Zugehörige klinische Berichts-ID",
        "Genetic Findings": "Genetische Erkenntnisse",
        "Clinical Evidence and Patient Management": "Klinische Evidenz und Patientenmanagement",
        "Disclaimer": "Haftungsausschluss",
        "Technical Limitations": "Technische Einschränkungen",
        "New Clinical Report": "Neuer klinischer Bericht",
        "Preview": "Vorschau",
        "Create": "Erstellen",
        "Annex 5: Pharmacogenomics": "Anhang 5: Pharmakogenomik",
        "These are the haplotypes that were tagged in the Pharmacogenomics tab of this Interpretation Request.Check all the haplotypes that you want to display in the final report.": "Dies sind die Haplotypen, die auf der Registerkarte „Pharmakogenomik“ dieser Interpretationsanfrage markiert wurden. Markieren Sie alle Haplotypen, die Sie im Abschlussbericht anzeigen möchten.",
        "Haplotype Interpretation": "Haplotyp-Interpretation",
        "No genes were targeted": "Es wurden keine Gene ins Visier genommen",
        "The following annexes complete the report, which should be reviewed as a whole.": "Die folgenden Anhänge vervollständigen den Bericht, der als Ganzes überprüft werden sollte.",
        "Gene Symbol": "Gensymbol",
        "Mean Coverage": "Mittlere Abdeckung",
        "Median Coverage": "Mittlere Abdeckung",
        "C10": "C10",
        "C15": "C15",
        "C20": "C20",
        "Gene MIM Number": "Gen-MIM-Nummer",
        "Phenotype MIM Number": "Phänotyp-MIM-Nummer",
        "Transcript cDNA": "Transkription der cDNA",
        "GQ": "GQ",
        "DP": "DP",
        "REF count": "REF-Anzahl",
        "ALT count": "ALT-Anzahl",
        "AAF": "AAF",
        "Duplication Rate ": "Duplizierungsrate",
        "Uniformity": "Gleichmäßigkeit",
        "Gene symbol": "Gensymbol",
        "Mean coverage": "Mittlere Abdeckung",
        "C1": "C1",
        "These are the evidences that were tagged in the": "Dies sind die Beweise, die in markiert wurden",
        " tab of this Interpretation Request.": "Registerkarte dieser Interpretationsanfrage.",
        "Check all the evidences that you want to display in the final report.": "Überprüfen Sie alle Nachweise, die Sie im Abschlussbericht anzeigen möchten.",
        "The": "Der",
        " Clinical Significance": "Klinische Bedeutung",
        "field shows the comments that were added when tagging an evidence.": "Das Feld zeigt die Kommentare an, die beim Markieren eines Beweismittels hinzugefügt wurden.",
        "The original tag comment ": "Der ursprüngliche Tag-Kommentar",
        "will not": "wird nicht",
        " be modified here. Your modifications will only be visible in the exported report.": "hier geändert werden. Ihre Änderungen werden nur im exportierten Bericht sichtbar.",
        "Conclusion": "Abschluss",
        "These are the interpretation comments that were written in each evidence tag.": "Dies sind die Interpretationskommentare, die in jedes Beweis-Tag geschrieben wurden.",
        "variant": "Variante",
        "Therapy": "Therapie",
        "Cancer Type": "Krebstyp",
        "Comment": "Kommentar",
        "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to the American College of Medical Genetics (ACMG) recommendations (Richards et al. 2015, Riggs et al. 2020), ClinGen recommendations (Welcome to ClinGen ) and the Human Variation Variant Society (HGVS)": "Die klinische Bedeutung genetischer Varianten wird anhand der derzeit verfügbaren Informationen interpretiert und unterliegt mit der Erweiterung der wissenschaftlichen Erkenntnisse Änderungen. Genomvarianten werden gemäß den Empfehlungen des American College of Medical Genetics (ACMG) (Richards et al. 2015, Riggs et al. 2020), ClinGen-Empfehlungen (Welcome to ClinGen) und der Human Variation Variant Society (HGVS) klassifiziert und gemeldet.",
        "Genetic counselling and recommendations are based on GeneReviews guidelines (Margaret et al 1993-2020).": "Genetische Beratung und Empfehlungen basieren auf den GeneReviews-Richtlinien (Margaret et al. 1993–2020).",
        "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to ClinGen, CGC and VICC recommendations (Horak et al 2022, Gene Fusion Specification —": "Die klinische Bedeutung genetischer Varianten wird anhand der derzeit verfügbaren Informationen interpretiert und unterliegt mit der Erweiterung der wissenschaftlichen Erkenntnisse Änderungen. Genomvarianten werden gemäß den Empfehlungen von ClinGen, CGC und VICC klassifiziert und gemeldet (Horak et al 2022, Gene Fusion Specification –",
        "Only FDA or EMA approved treatments are reported. The conclusion of this report is based solely on the results of the RNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician.": "Es werden nur von der FDA oder der EMA zugelassene Behandlungen gemeldet. Die Schlussfolgerung dieses Berichts basiert ausschließlich auf den Ergebnissen der RNA-Sequenzierung des Tumors und des erhaltenen Tumortyps. Die endgültige Interpretation der klinischen Konsequenz dieses Berichts sollte daher immer durch den behandelnden Arzt erfolgen.",
        "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to ClinGen, CGC and VICC recommendations (Horak et al 2022) and the Human Variation Variant Society (HGVS)": "Die klinische Bedeutung genetischer Varianten wird anhand der derzeit verfügbaren Informationen interpretiert und unterliegt mit der Erweiterung der wissenschaftlichen Erkenntnisse Änderungen. Genomvarianten werden gemäß den Empfehlungen von ClinGen, CGC und VICC (Horak et al. 2022) und der Human Variation Variant Society (HGVS) klassifiziert und gemeldet.",
        "Only FDA or EMA approved treatments are reported. The conclusion of this report is based solely on the results of the DNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician.": "Es werden nur von der FDA oder der EMA zugelassene Behandlungen gemeldet. Die Schlussfolgerung dieses Berichts basiert ausschließlich auf den Ergebnissen der DNA-Sequenzierung des Tumors und des erhaltenen Tumortyps. Die endgültige Interpretation der klinischen Konsequenz dieses Berichts sollte daher immer durch den behandelnden Arzt erfolgen.",
        "Issuing centre": "Ausgabezentrum",
        "Requesting centre": "Anforderndes Zentrum",
        "Referrer": "Referrer",
        "Reporting date": "Meldedatum",
        "Name": "Name",
        "Surname": "Nachname",
        "Sample Type": "Probentyp",
        "Biopsy Location": "Ort der Biopsie",
        "Reason for referral": "Grund der Überweisung",
        "Performed Study": "Studie durchgeführt",
        "Test performed (Library strategy)": "Test durchgeführt (Bibliotheksstrategie)",
        "*Based upon reference genome version GRCh38": "*Basierend auf der Referenzgenomversion GRCh38",
        "**Based upon ClinGen, CGC and VICC recommendations (Horak et al. 2022)": "**Basierend auf ClinGen-, CGC- und VICC-Empfehlungen (Horak et al. 2022)",
        "**Based upon ACMG and ClinGen recommendations (Richards et al. 2015 and  ": "**Basierend auf den Empfehlungen von ACMG und ClinGen (Richards et al. 2015 und",
        "These are the variants that were tagged in the": "Dies sind die Varianten, die im getaggt wurden",
        " tab of this Interpretation Request. Check all the variants that you want to display in the final report.": "Registerkarte dieser Interpretationsanfrage. Markieren Sie alle Varianten, die Sie im Abschlussbericht anzeigen möchten.",
        "Genomic breakpoints detected*": "Genomische Bruchpunkte erkannt*",
        "Included gene(s)": "Eingeschlossene Gene",
        "Genomic position*": "Genomische Position*",
        "Recommendations": "Empfehlungen",
        "No information available about the Experiment Type": "Es sind keine Informationen zum Experimenttyp verfügbar",
        "Genomic Position:": "Genomische Position:",
        "No ": "NEIN",
        " calculated": "berechnet",
        "Please fill these in manually after downloading the report": "Bitte füllen Sie diese nach dem Herunterladen des Berichts manuell aus",
        "Report name": "Berichtsname",
        "This field is required.": "Dieses Feld ist erforderlich.",
        "Associated Clinical Report": "Zugehöriger klinischer Bericht",
        "Select File": "Wählen Sie Datei aus",
        "Supported files: .doc, .docx, .pdf, .txt": "Unterstützte Dateien: .doc, .docx, .pdf, .txt",
        "Maximum file size: 10MB": "Maximale Dateigröße: 10 MB",
        "File uploaded successfully": "Datei erfolgreich hochgeladen",
        "OK": "OK",
        "Gene name": "Genname",
        "Variant": "Variante",
        "Level of evidence": "Evidenzniveau",
        "Match": "Übereinstimmen",
        "Therapy status": "Therapiestatus",
        "Drug status ": "Drogenstatus",
        "Evidence": "Beweis",
        "Evidence CGI": "Beweis-CGI",
        "Evidence CIViC ": "Beweise CIViC",
        "Participants included in the study": "In die Studie einbezogene Teilnehmer",
        "Minimum read depth at variant position": "Mindestlesetiefe an der Variantenposition",
        "Min-Max Alternate Allele Freq": "Min.-Max. alternative Allelfrequenz",
        "Minimum genotype quality (Phred-scaled) for variant position": "Mindestgenotypqualität (Phred-skaliert) für Variantenposition",
        "Type in a name": "Geben Sie einen Namen ein",
        "Configuration details": "Konfigurationsdetails",
        "Affected": "Betroffen",
        "set Genotype Settings": "Legen Sie die Genotyp-Einstellungen fest",
        "Quality Settings": "Qualitätseinstellungen",
        "No genotype configured. The sample will not be included in the analysis": "Kein Genotyp konfiguriert. Die Probe wird nicht in die Analyse einbezogen",
        "Minimum Depth": "Mindesttiefe",
        "Minimum Genotype Quality": "Mindestgenotypqualität",
        "Alternate Allele Frequency": "Alternative Allelfrequenz",
        "Please, bear in mind that the predefined inheritances take into account the reported affected status of the family members which in some cases may force unrealistic configurations. In such cases, please use the custom configuration to formulate your hypothesis\\n": "Bitte beachten Sie, dass die vordefinierten Erbschaften den gemeldeten Betroffenenstatus der Familienmitglieder berücksichtigen, was in manchen Fällen zu unrealistischen Konfigurationen führen kann. In solchen Fällen verwenden Sie bitte die benutzerdefinierte Konfiguration, um Ihre Hypothese zu formulieren\\n",
        "Select a simulated mode of inheritance configuration:": "Wählen Sie einen simulierten Modus der Vererbungskonfiguration aus:",
        "or configure a custom mode of inheritance": "oder konfigurieren Sie einen benutzerdefinierten Vererbungsmodus",
        "10": "10",
        "30": "30",
        "50": "50",
        "70": "70",
        "90": "90",
        "0.1": "0,1",
        "0.2": "0,2",
        "0.3": "0,3",
        "0.4": "0,4",
        "0.5": "0,5",
        "0.6": "0,6",
        "0.7": "0,7",
        "0.8": "0,8",
        "0.9": "0,9",
        "1": "1",
        "Only Visible to you": "Nur für Sie sichtbar",
        "Shared with your groups": "Mit Ihren Gruppen geteilt",
        "Visible to All": "Für alle sichtbar",
        "etichetta": "Etikette",
        "Amino Acid length": "Aminosäurelänge",
        "CDS Position": "CDS-Position",
        "Unaffective Relative": "Unaffektiver Verwandter",
        "Gene Coding": "Genkodierung",
        "Functional Class": "Funktionsklasse",
        "Aminoacid Length": "Aminosäurelänge",
        "Aminoacid Change": "Aminosäureveränderung",
        "Codon Change": "Codon-Änderung",
        "Transcript Biotype": "Transkript-Biotyp",
        "GnomAD AF": "GnomAD AF",
        "GnomAD AF PopMAx": "GnomAD AF PopMAx",
        "CADD Pred.": "CADD Pred.",
        "SIFT Pred.": "SIFT Pred.",
        "Polyphen2 Hvar Pred.": "Polyphen2 Hvar Pred.",
        "Mutation Taster Pred.": "Mutation Taster Pred.",
        "Indel": "Indel",
        "Reported HPOs": "Gemeldete HPOs",
        "HPOs": "HPOs",
        "Reported Diagnosis": "Gemeldete Diagnose",
        "Obs. HPSs": "Obs. HPSs",
        "Position - Start": "Position - Start",
        "Positions - End": "Positionen - Ende",
        "Polyphen2 hvar": "Polyphen2 hvar",
        "Tagged Variants": "Markierte Varianten",
        "Variant Class": "Variantenklasse",
        "ClinVar Classification": "ClinVar-Klassifizierung",
        " + labelFromStack + ": "+ labelFromStack +",
        "docs": "Dokumente",
        "Clear": "Klar",
        "Loading...": "Laden...",
        "breadcrumb": "Semmelbrösel",
        "Toggle navigation": "Navigation umschalten",
        "label": "Etikett",
        "dummyInput": "dummyInput",
        "menu": "Speisekarte",
        "container": "Container",
        "indicatorContainer": "IndikatorContainer",
        "indicatorSeparator": "IndicatorSeparator",
        "loadingIndicator": "Ladeindikator",
        "control": "Kontrolle",
        "group": "Gruppe",
        "input": "Eingang",
        "multiValue": "mehrwertig",
        "option": "Option",
        "placeholder": "Platzhalter",
        "singleValue": "Einzelwert"
    }


