

import React from 'react';
import {Checkbox, Grid, InputLabel, ListItemText, MenuItem, Typography} from '@mui/material';
import MappedText from '../../../../../create-report/shared-components/mapText.js';
import DataField from '../../../../../create-report/shared-components/Datafield.js';
import {sectionConfigGermline} from "../config/config_Germline.js";
import {renderTextField} from "../../annexes_components.js";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";


function stringifyValue(value) {
    
    return value !== null && value !== undefined ? value.toString() : 'NA';
}

function AnnexesGermlineSection({ annexesData, handleSectionUpdate, clinicalReportName, report_id, setClinicalReportName,annexesFields, updateAnnexesFields, ...additionalData  }) {

    const sectionConfig = sectionConfigGermline(clinicalReportName, setClinicalReportName, additionalData, annexesFields, updateAnnexesFields )

    
    return (<>
            {sectionConfig.map((section, index) => (
                 <Grid container spacing={1} key={index} style={{ marginBottom: '10px' }}>
               {section.title && (
                 <Grid item xs={12}>
                     <Typography variant="h6" style={{ fontWeight: '500', marginBottom: '10px' }}>
                         <MappedText text={section.title} />
                     </Typography>
                 </Grid>
             )}
             {section.subTitle && (
                 <Grid item xs={12}>
                     <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                         <MappedText text={section.subTitle} />
                     </Typography>
                 </Grid>
             )}
              {section.body && (
                 <Grid item xs={12}>
                     <Typography variant="body1" style={{ marginBottom: '10px' }}>
                         <MappedText text={section.body} />
                     </Typography>
                 </Grid>
             )}
                 {section.paragraph && (
                     <Grid item xs={12}>
                         <Typography style={{ fontSize: '12px', fontWeight: '500', marginBottom: '10px' }}>
                             <MappedText text={section.paragraph} />
                         </Typography>
                     </Grid>
                 )}
                 {section.caption && (
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                <MappedText text={section.caption} />
                            </Typography>
                        </Grid>
)}
                    {section.render && (
                        <Grid item xs={12}>
                            {section.render()}
                        </Grid>
                    )}
                    {section.fields && (
                        <Grid item xs={12} container spacing={2}>
                            {section.fields.map((field, fieldIndex) => (
                                <Grid item xs={section.columns === 1 ? 12 : 6} key={fieldIndex}>
                                    {field.component === 'TextField' ? (
                                        renderTextField(field.title, field.value, (e) => field.setValue(e.target.value))
                                    ) : field.component === 'MultiSelect' ? (
                                        <FormControl fullWidth>
                                            <InputLabel shrink>{field.title}</InputLabel> {/* Label on top */}

                                            <Select
                                                isMulti
                                                placeholder=""
                                                options={field.options.map((option) => ({ value: option, label: option }))}
                                                value={field.value.map((val) => ({ value: val, label: val }))}
                                                onChange={(selectedOptions) => field.setValue(selectedOptions.map((option) => option.value))}
                                                closeMenuOnSelect={false}
                                                styles={{
                                                    control: (base) => ({
                                                        ...base,
                                                        marginTop: '8px',  // Adds space between label and select
                                                    }),
                                                    menu: (base) => ({
                                                        ...base,
                                                        zIndex: 9999,  // Ensures the dropdown appears on top of other elements
                                                    }),
                                                    option: (base, { isFocused, isSelected }) => ({
                                                        ...base,
                                                        backgroundColor: isFocused ? '#f0f0f0' : isSelected ? '#e0e0e0' : 'white',
                                                        color: 'black',  // Ensures text is visible
                                                    }),
                                                    menuList: (base) => ({
                                                        ...base,
                                                        backgroundColor: 'white',  // Ensures the background is solid white
                                                    }),
                                                }}
                                            />
                                        </FormControl>
                                    ) : (
                                        <DataField title={field.title} value={stringifyValue(field.value)} />
                                    )}
                                </Grid>

                            ))}
                        </Grid>
                    )}
                </Grid>
            ))}
        </>
    );
}

export default AnnexesGermlineSection;