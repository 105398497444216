import React from "react";
import { Grid, Box, } from "@mui/material";
import GeneralTable from "../../../../../create-report/shared-components/general-table/GeneralTable.js";
import { COVERAGE_GENES } from "../config/coverage_genes.js";


export default function CoverageGenes({data}) {

    return (
        <Grid item xs={12}>
            <Box p={1}>
                <Box>
                <GeneralTable data={ data }
                              dataType={"coverage of the genes of interest"}
                              columns={ COVERAGE_GENES} />
                </Box>
            </Box>
        </Grid> 
    );
}
