import  React, {useState,useEffect} from "react";
import { Grid, Box, } from "@mui/material";
import GeneralTable from "../../../../../create-report/shared-components/general-table/GeneralTable.js";
import { EXOME_COVERAGE } from "../config/exome_coverage.js";


export default function ExomeCoverage({data, report_id}) {

        //const token = useSelector(state => state.authorization.token);
    //     const [sectionData, setData] = useState(data)
    
    // useEffect(() => {
    //     if(report_id!== undefined && sectionData.length === data.length){
    //         setData(data)}
    // }, [report_id]);

    return (
        <Grid item xs={12}>
            <Box>
                <GeneralTable
                    dataType={"coverage metrics"}
                    data={ data }
                    columns={ EXOME_COVERAGE} />
            </Box>
        </Grid>
    );
}
