

import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import {TableCell} from "@material-ui/core";
import TableRow  from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import {parse_date} from "../../../../../../../../../study-page/helpers";
import FlagIcon from "@material-ui/icons/Flag";
import MappedText from "../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import CaButton from "../../../../../../../../../../gpap-lib/components/shared-components/CaButton";
import {connect} from "react-redux";


function CandidatePopOver(props) {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    let disabledButton = Array.isArray(props.tagged_variants) && props.tagged_variants.length === 0;

    return (
        <div style={{display:"inline-block"}}>
        <CaButton
            disabled = {(props.tagged_variants!== undefined) ? props.tagged_variants.length === 0 : true}
            aria-describedby={id}
            onClick={handleClick}
            variant="outlined"
            text="Previous Tags"
            startIcon={<FlagIcon/>}
            customStyles={{  
                width:"155px",
                marginRight:"6px",
                color: disabledButton ?"#A6B2DE": "#4768a4" ,
                borderColor: disabledButton?  "#A6B2DE": "#4768a4",
                cursor: disabledButton ? "not-allowed" : "pointer"
            }}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2}>
                    <Grid container direction={"row"}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell><strong><MappedText text={"Experiment ID"}/></strong></TableCell>
                                        <TableCell><strong><MappedText text={"Project"}/></strong></TableCell>
                                        <TableCell><strong><MappedText text={"Type"}/></strong></TableCell>
                                        <TableCell align="right"><strong><MappedText text={"Origin"}/></strong></TableCell>
                                        <TableCell align="right"><strong><MappedText text={"Gene"}/></strong></TableCell>
                                        <TableCell align="right"><strong><MappedText text={"Status"}/></strong></TableCell>
                                        <TableCell align="right"><strong><MappedText text={"Significance"}/></strong></TableCell>
                                        <TableCell align="right"><strong><MappedText text={"Inheritance"}/></strong></TableCell>
                                        <TableCell align="right"><strong><MappedText text={"Zygosity"}/></strong></TableCell>
                                        <TableCell align="right"><strong><MappedText text={"Date"}/></strong></TableCell>
                                        <TableCell align="right"><strong><MappedText text={"Comments"}/></strong></TableCell>
                                        <TableCell align="right"><strong><MappedText text={"Your Tag"}/></strong></TableCell>
                                        {(window.config.api_endpoint.includes("navbiomed") ? <TableCell align="right"><strong>User</strong></TableCell> : null )}
                                    </TableRow>
                                <TableBody>
                                    {(props.tagged_variants!== undefined)
                                        ? props.tagged_variants.map((row) => {

                                        let data = new Date(row.timestamp);
                                        let variant_date = "";
                                        if(data.toString()!== 'Invalid Date'){
                                            variant_date = parse_date(data);
                                        }
                                        else{
                                            variant_date = row.timestamp;
                                        }


                                        let el_status = (row.status === "Causative")
                                            ? <span style={{color: "red", fontWeight: "bold"}}>{row.status}</span>
                                            : <span >{row.status}</span>

                                        return (
                                            <TableRow
                                                key={row.sample}>
                                                <TableCell component="th" scope="row">
                                                    {row.sample}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.project}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.variant_type}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.origin}
                                                </TableCell>
                                                <TableCell align="right">{row.gene}</TableCell>
                                                <TableCell align="right">{el_status}</TableCell>
                                                <TableCell align="right">{row.significance}</TableCell>
                                                <TableCell align="right">{row.inheritance}</TableCell>
                                                <TableCell align="right">{row.zygosity}</TableCell>
                                                <TableCell align="right">{variant_date}</TableCell>
                                                <TableCell align="right">{row.comments}</TableCell>
                                                <TableCell align="right">{(row.user === props.user) ? "Yes" : "No"}</TableCell>
                                                {(window.config.api_endpoint.includes("navbiomed") ? <TableCell align="right">{row.user}</TableCell> : null )}
                                            </TableRow>
                                        )
                                    })
                                        : null
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Box>
            </Popover>
        </div>
    );
}


function mapStateToProps (state) {

    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            user: state.authorization.preferredName,
        }
    }

}




export default connect(mapStateToProps, null)(CandidatePopOver);