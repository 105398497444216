
export const EXOME_COVERAGE = [
    {
        Header: 'Mean Coverage',
        accessor: "mean_coverage",
        filter: '',
    },
    {
        Header: 'Duplication Rate ',
        accessor: "duplication_rate",
        filter: '',
    },
    {
        Header: 'Uniformity',
        accessor: "uniformity",
        filter: '',
    },
    {
        Header: 'C10',
        accessor: "c10",
        filter: '',
    },
    {
        Header: 'C15',
        accessor: "c15",
        filter: '',
    }, 
];