

export const DATA_HEADER = [
  
    {
        header: "Pharmacogenomics",
            columns: [
                { nameTable: "Gene", dataSearch: "Gene", tooltip: "" },
                { filterType: {type: "none"}, nameTable: "Gene Evidence", dataSearch: "pgx_uri"},
                { nameTable: "Genotype", dataSearch: "Genotype", tooltip: "" },
                { filterType: { type: "select", option: "default" }, nameTable: "Phenotype", dataSearch: "Phenotype", tooltip: "" },
                { nameTable: "Haplotype1", dataSearch: "Haplotype1", tooltip: "" },
                { filterType: {type: "none"}, nameTable: "Haplotype1 Evidence", dataSearch: "pgx_haplo1_uri" },
                { nameTable: "Haplotype2", dataSearch: "Haplotype2", tooltip: "" },
                { filterType: {type: "none"}, nameTable: "Haplotype2 Evidence", dataSearch: "pgx_haplo2_uri" },
                { nameTable: "Variant Data", dataSearch: "VariantData", tooltip: "" },
            ],
    },
];


