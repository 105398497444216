
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {query_sample} from "../../../../../../services/api/genomics_query_samples/query_sample";
import {
    genes_parser,
    population_parser,
    position_parser,
    snv_effects_parser,
    variant_type_parser
} from "./functions/query-parsers";
import {StateMapper} from "./functions/StateMapper";
import * as Actions from "../../../../../../actions/index";
import {diff} from "deep-object-diff";
import { getCurrentAnalysis } from "../results/components/variant-table/getAnnotations";
import {convertChromosome} from "./functions/data-retrieve";
import formatSamples from "../../../../../study-page/create-study/analysis-page/formatSamples";
import {inheritances_x_linked_ids} from "../../../../../../config-files/config_inheritances";
import {X_CHROM_OBJ} from "../../../../../study-page/helpers";
import {reset_filter_set} from "../../../../../../actions/index";


/* This is a RenderLess component to parse the redux-forms, collect the values for the current query and to generate the query*/

class QueryGenerator extends Component{


    constructor(props){
        super(props);
        this.state = {
            generate:false
        };

        this.query_to_run = {...query_sample};
        this.generateQuery = this.generateQuery.bind(this);

    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        const difference = diff(prevProps.result, this.props.result);
        if(Object.keys(difference).length!== 0){
            this.generateQuery();
        }
        else if(prevProps.rohs.length !== this.props.rohs.length){
            this.generateQuery();
        }
        else if(prevProps.position_uploads !== this.props.position_uploads){
            this.generateQuery(prevProps.position_uploads);
        }

    }

    generateQuery(){
        // format samples;

        let { actions, rohs, position_uploads, studySettings, studyBucket } = this.props;
        let { samples, variant_type, snv_effects, population, position, genes} = this.props.result;

        //const study = getStudy(studySettings, studyBucket);
        const analysis = getCurrentAnalysis(studySettings,studyBucket);

        let query_to_run = {...this.query_to_run};
        // formant variants;

        // get settings according to analysis inheritance ...

        let samples_to_analyze = samples;

        // search across or cohorts samples are already formatted correctly;
        if(analysis.analysis && analysis.analysis.analysis_type !== "search_across"){
            if(analysis.analysis.analysis_type === "cohort"){

                query_to_run.samples_germline = formatSamples(samples_to_analyze, 0);
            }
            else
            {
                samples_to_analyze = analysis.analysis.samples;
                query_to_run.samples_germline = formatSamples(samples_to_analyze, 0);
            }
        }
        else{
            query_to_run.samples_germline = samples;
        }

        // compound het ?

        if(analysis.analysis !== undefined){
            query_to_run.compound_in = analysis.analysis.inheritance_mode_id === 3 ;
        }
        else {
            query_to_run.compound_in = this.props.studySettings.analysis_ids.length !== 0 && this.props.studySettings.analysis_ids[0].id === 3;
        }


        query_to_run = {...variant_type_parser(variant_type,query_to_run)};
        console.log(query_to_run);
        snv_effects_parser(snv_effects,query_to_run);
        population_parser(population, query_to_run);
        position_parser(position, query_to_run);
        genes_parser(genes, query_to_run);

        // add ROHs if found;
        if(Array.isArray(rohs)){
            let chrs = [...query_to_run.chrom];
            query_to_run.chrom= chrs.concat(rohs);
        }

        let filter_by_tagged_samples = false;
        let filter_by_tagged_all = false;
        // tagged variants filter
        if(variant_type !== undefined){

            let tagg_variant_f = variant_type.variant_types.find(d => d.key === "v_tagged");

            if(tagg_variant_f !== undefined && tagg_variant_f.values!== undefined){

                filter_by_tagged_all = (tagg_variant_f.values.hasOwnProperty("any"));
                filter_by_tagged_samples = (tagg_variant_f.values.hasOwnProperty("selected"));

                if(filter_by_tagged_all){
                    let chrs = [...query_to_run.chrom];
                    let tot_chr = [];
                    let all_genes = [];
                    if(this.props.all_tagged_variants!== undefined){
                        this.props.all_tagged_variants.forEach(function(d){
                            all_genes.push(d.gene);
                            if(d.hasOwnProperty("chromosome") && d.hasOwnProperty("start_pos"))
                            {
                                let chromosome = convertChromosome(d["chromosome"]);
                                tot_chr.push({
                                    "chrom": chromosome,
                                    "pos": d["start_pos"],
                                    "end_pos": d["start_pos"]
                                })
                            }

                        });
                    }
                    query_to_run.chrom = chrs.concat([...tot_chr]);
                    query_to_run.variant_type = "all_samples"
                    query_to_run.gene = query_to_run.gene.concat([...new Set(all_genes)]);
                }
                else if(filter_by_tagged_samples){
                    let chrs = [...query_to_run.chrom];
                    let tot_chr = [];

                    //variants to add;

                    let samples_ids = samples.map(s => s.sample_id);

                    let samples_arr = (this.props.all_tagged_variants!== undefined)
                        ? this.props.all_tagged_variants.filter(s => samples_ids.includes(s.sample))
                        : [];

                    let all_genes = [...new Set(samples_arr.map(s => s.gene))];

                    tot_chr = tot_chr.concat(samples_arr.map(variant => {return {
                        "chrom": variant.hasOwnProperty("chrom")? variant["chrom"] : variant["chromosome"],
                        "pos":   variant.hasOwnProperty("start_pos") ? variant["start_pos"] : variant["pos"] ,
                        "end_pos": variant.hasOwnProperty("start_pos") ? variant["start_pos"] : variant["pos"],
                    }}));

                    query_to_run.chrom = chrs.concat([...tot_chr]);
                    query_to_run.variant_type = "selected_samples";
                    query_to_run.gene = query_to_run.gene.concat(all_genes);

                }

            }
        }

        if(position_uploads!== undefined){
            let chrs = [...query_to_run.chrom];

            let tot_chr = [];
            tot_chr = tot_chr.concat(position_uploads.bed_ranges.map(x => JSON.parse(x)));
            tot_chr = tot_chr.concat(position_uploads.coordinate_ranges.map(x => JSON.parse(x)));

         /*   if(position_uploads.bed_ranges.length === 0 && info!== undefined && info.bed_ranges.length!== 0){
                info.bed_ranges.forEach(function(position){
                    chrs = chrs.filter(item => (item.chrom!==position.chrom && item.pos!== position.pos && item.end_pos !== position.end_pos))
                })
            }
            else if(position_uploads.coordinate_ranges.length === 0 && info!== undefined && info.coordinate_ranges.length!== 0){
                info.coordinate_ranges.forEach(function(position){
                    chrs = chrs.filter(item => (item.chrom!==position.chrom && item.pos!== position.pos && item.end_pos !== position.end_pos))
                })
            }*/

            query_to_run.chrom = chrs.concat([...tot_chr]);
        }



        // add x chromosome if needed;

        let INHERITANCE_id = (analysis) ? analysis.analysis.inheritance_mode_id : 0;
        if(inheritances_x_linked_ids.includes(INHERITANCE_id) && query_to_run.chrom !== undefined && query_to_run.chrom.find(s => s.chrom === 22) === undefined){

            // if chrom X not added yet...ADD
            if(query_to_run["chrom"].findIndex(s => s.chrom === "24") === -1){
                query_to_run["chrom"].push(X_CHROM_OBJ);
            }

        }

        // set the correct size of the query;
        if(analysis){
            query_to_run.size = (analysis.analysis!== undefined && analysis.analysis.analysis_type === "search_across") ? 0 : 3000;

            // avoid concurrent changes when a query is running
            // this is needed because the component QueryGenerator is in the Genomics component in which the update is triggered in many circumnstances;
            if(!this.props.loadingStudy){
                // check if there are differences with the filter set (if applied). If there are differences, reset the filter set
                if(Object.keys(this.props.currentFilterSet).length!==0){

                    function omit(object_query){
                        const copy_obj = JSON.parse(JSON.stringify(object_query));
                        const { cadd_phred, revel, spliceai_max_ds, OCScore, cnv_germline,samples_germline,size, ...rest } = copy_obj;

                        return rest;
                    }

                    const copy_1 = omit(query_to_run);
                    const copy_2 = omit(this.props.currentFilterSet.filters);

                    let diff_with_filter_set = diff(copy_1, copy_2);
                    if(Object.keys(diff_with_filter_set).length!==0){
                        // reset filter set;
                        actions.reset_filter_set();
                    }
                }

                 actions.updateQuery(query_to_run);
            }

        }
        else if(window.location.href.includes("settings"))
        {
                actions.updateQuery(query_to_run);
        }
        else{
            alert("You did not create a study")
        }


    }

    render(){

        return null;
    }

}


function mapFormValuesToProps (state) {
    if(state.authorization!==undefined)
    {

        let state_obj = {
            form: state.form,
            sample_list: state.sample_list,
            geneList: state.geneList
        }

        let inheritance_key = state.inheritance_key;
        let curr_analysis_id = state.studySettings.analysis_ids;

        let result = StateMapper(state_obj, inheritance_key, curr_analysis_id);

        return {
            result: result,
            rohs: state.roh_value,
            position_uploads: state.position_uploads,
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            inheritance_key: state.inheritance_key,
            currentFilterSet: state.currentFilterSet
        };

    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});



export default connect(mapFormValuesToProps,mapDispatchToProps)(QueryGenerator);