

export const PATIENT_DATA_HEADER = [
    {
        header: "Patient Reports",
        columns: [
            { nameTable: "Report ID", dataSearch: "patient_report_id", tooltip: "" },
            { nameTable: "Associated Clinical Report ID", dataSearch: "associated_clinical_report", tooltip: "" },
            { nameTable: "Report Name", dataSearch: "patient_report_name", tooltip: "" },
            { filterType: { type: "date" }, filter: "dateFilter", nameTable: "Creation Date", dataSearch: "creation_date", tooltip: "" },
            { filterType: { type: "select", option: "default" },nameTable: "Creator", dataSearch: "creator", tooltip: "" },
        ],
    },
];
