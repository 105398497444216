import React ,{useEffect, useState}from "react";
import { Grid, Box, Typography, Link } from "@mui/material";
import { GENETIC_FINDINGS_CNV_GERMLINE, GENETIC_FINDINGS_CNV_SOMATIC } from "./config/genetic_findings_cnv.js";
import { GENETIC_FINDINGS_SNV_GERMLINE, GENETIC_FINDINGS_SNV_SOMATIC } from "./config/genetic_findings_snv.js";
import GeneralTable from "../../../create-report/shared-components/general-table/GeneralTable.js";
import MappedText from "../../shared-components/mapText.js";



export default function GeneticFindingsReport({data, handleUpdate, report_id, variant_type, analysisType}) {
    const [sectionData, setData] = useState(data)
    let columns;


    if (variant_type === 'CNV') {
        columns = analysisType === 'germline' ? GENETIC_FINDINGS_CNV_GERMLINE : GENETIC_FINDINGS_CNV_SOMATIC;
    } else {
        columns = analysisType === 'germline' ? GENETIC_FINDINGS_SNV_GERMLINE : GENETIC_FINDINGS_SNV_SOMATIC;
    }

    const handleSelectedRows = (rows) => {

            let rowsData = rows.map(s => s.original);
            let obj= {rows: rowsData}
            handleUpdate(obj, variant_type);


    };

    useEffect(() => {
        setData(data);
    }, [data]);

    
    return (
        <Grid item xs={12}>
            <Box p={1}>
                <Box>
                <GeneralTable data={ sectionData } columns={columns} showCheckbox={true} handleSelectedRows={handleSelectedRows}/>
                <Typography variant='caption' display={"flex"}><MappedText text="*Based upon reference genome version GRCh38" /></Typography>
                {analysisType !== 'germline' && (
                        <Typography variant='caption' display={"flex"}>
                            <MappedText text="**Based upon ClinGen, CGC and VICC recommendations (Horak et al. 2022)" />
                        </Typography>
                    )}
                {analysisType === 'germline' && (
                    <Typography variant='caption' display={"flex"}>
                        <MappedText text="**Based upon ACMG and ClinGen recommendations (Richards et al. 2015 and  "/> <span>&nbsp;</span>
                        <Link href="https://www.clinicalgenome.org" target="_blank" rel="noopener noreferrer"> ClinGen)</Link>
                    </Typography>
                )}
                </Box>
            </Box>
        </Grid>
    );
}
