

import React, {useEffect} from "react";
import Typography from "@mui/material/Typography";
import All_Filters_Accordion
    from "../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-accordion-panels/All_Filters_Accordion";
import Box from "@mui/material/Box";
import {query_sample} from "../../../services/api/genomics_query_samples/query_sample";
import {bindActionCreators} from "redux";
import * as QueryActions from "../../../actions";
import * as GeneActions
    from "../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/actions";
import * as PositionActions
    from "../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/position-section/position_actions";
import {connect} from "react-redux";
import FilterSet_SavePanel
    from "../../dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/query-top-bar/FilterSet_SavePanel";
import {Grid, Skeleton} from "@mui/material";
import FilterSetView from "./FilterSetView";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";


function FilterSets_Manager(props){

    let {queryActions, query} = props;


    useEffect(function(){

        if("query" in query && Object.keys(query.query).length === 0){
            queryActions.updateQuery(query_sample);
        }


    },[])

    return <div>
        <Grid container direction={"row"}>
            <Grid item lg={12}>
                <Typography variant="h5" gutterBottom ><MappedText text={"Filter Set Manager"}/></Typography>
            </Grid>
            <Grid item lg={12}>
                <Box p={1}>
                    <Typography variant="h6" gutterBottom ><MappedText text={"Create and Save a new Filter Set"}/></Typography>
                </Box>
            </Grid>
            <Grid item lg={12}>
                <Box p={1}  style={{borderBottom: "1px solid #bdbdbd"}}>
                    <FilterSet_SavePanel/>
                </Box>
            </Grid>
            <Grid item lg={12}>
                <Box>
                    <All_Filters_Accordion
                        default_filters = { [] }
                        pointers = { [] }
                        somatic={props.somatic}

                    />
                </Box>
            </Grid>
            <Grid item lg={12}>
                <Box p={1}>
                   {/* <Skeleton variant="rectangular" width={600} height={600} />*/}
                    <FilterSetView/>
                </Box>
            </Grid>
        </Grid>
    </div>

}




function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            query: state.query,
        }
    }
}
const mapDispatchToProps = (dispatch) => ({
    queryActions: bindActionCreators(QueryActions, dispatch),
    geneActions: bindActionCreators(GeneActions,dispatch),
    positionActions: bindActionCreators(PositionActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(FilterSets_Manager);