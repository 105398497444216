import { withStyles } from '@mui/styles';
import { AccordionSummary } from '@mui/material';

const RightAlignedAccordionSummary = withStyles({
    expandIcon: {
        order: 1, // Keep the default position (on the right)
        transform: "none" // Remove the rotation
    }
})(AccordionSummary);

export default RightAlignedAccordionSummary;