/* This is a the Sample Section */


import React, {useEffect,useState} from 'react';
import {initialize, reduxForm,getFormValues, getFormInitialValues} from 'redux-form';
import "../../../css/query-view.css";
import { formValueSelector } from 'redux-form';

import {connect} from 'react-redux'
import {SelectArea} from "../../../../../../../../../../gpap-lib/components/shared-components/renderer";

import * as VARIANT_CONFIG from "./variantConfig";
import {dict_classes_variants, tooltips} from "./variantConfig";
import {GPAP_Button_light} from "../../../../../../../../../../gpap-lib/components/Button_Collection";
import GPAP_InfoIcon from "../../../../../../../../../../gpap-lib/components/GPAP_InfoIcon";
import MappedText from '../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';


let Variant_Section = props => {

    const { handleSubmit, pristine, reset, submitting, minimized } = props;

    //let options = [];


    const resetSection = () =>{

        let reset_obj = {
            "clinvarclasses": [],
            "variantclasses": [],
            "genotypefeatures": [],
            "v_tagged": [],
            "v_type": [],
            "Mane": [],
            "variantconsequences": []

        };
        props.dispatch(initialize(
            'variants',
            reset_obj
        ));



    };
    let default_filters_old = props.default_filters;
    const [init_obj_statefull, setinit_obj] = useState({});
    useEffect(function(){

        if((props.default_filters!== undefined))
             {
          if(props.default_filters){
              let obj = {...props.default_filters};
              let keys = Object.keys(obj);
              let init_obj = {};
              keys.forEach(function(key){
                  let options = dict_classes_variants[key];
                  switch (key) {
                      case "variant_type":
                          if(obj["variant_type"]){
                              let val = []
                              if(obj["variant_type"] === "selected_samples"){
                                  let opt = {'value': "selected", "label": "Selected samples" }
                                  val.push(opt);
                              }
                              else if(obj["variant_type"] === "all_samples"){
                                  let opt =  {'value': "any", "label": "Any Sample"};
                                  val.push(opt);
                              }
                              init_obj["v_tagged"] = val;
                          }

                      case "indel":
                          if (obj["indel"]){
                              if(Array.isArray(init_obj["v_type"]) && init_obj["v_type"].findIndex(el => el.value === "indel")  === -1){
                                  init_obj["v_type"] = init_obj["v_type"].concat( [{'value': "indel", "label": "INDEL", }])
                              }
                              else{
                                  init_obj["v_type"] = []
                                  init_obj["v_type"] = init_obj["v_type"].concat( [{'value': "indel", "label": "INDEL", }])
                              }
                          }
                          break;
                      case "svn":
                          if((obj["svn"])){
                              if(Array.isArray(init_obj["v_type"]) &&  init_obj["v_type"].findIndex(el => el.value === "snv")  === -1){
                                  init_obj["v_type"] = init_obj["v_type"].concat([{'value': "snv", "label": "SNV",  }]);
                              }
                              else{
                                  init_obj["v_type"] = []
                                  init_obj["v_type"] = init_obj["v_type"].concat( [{'value': "snv", "label": "SNV", }])
                              }

                          }
                          break;
                    case "Mane":
                          if((obj["Mane"])){
                              if(Array.isArray(init_obj["Mane"]) &&  init_obj["v_type"].findIndex(el => el.value === "Mane")  === -1){
                                  init_obj["Mane"] = init_obj["Mane"].concat([{'value': "Mane", "label": "Mane",  }]);
                              }
                              else{
                                  init_obj["Mane"] = []
                                  init_obj["Mane"] = init_obj["Mane"].concat( [{'value': "Mane", "label": "Mane", }])
                              }

                          }
                          /*if (obj["indel"]){
                               init_obj["v_type"] = init_obj["v_type"].concat([{'value': "snv", "label": "SNV",  }]);
                          }*/
                          break;
                      default:
                          let vals = obj[key];
                          let trues = Object.keys(vals).filter(k => vals[k]);
                          if(options!== undefined){
                              init_obj[key] = options.filter(opt => trues.includes(opt.value))
                          }
                  }
              });
              //check if we are tyring to rerender the same form that has been already rendered, for some reason the first time you try to modify a filter, it is fired again and it puts the filter in the form again
              if ( JSON.stringify(init_obj_statefull) !== JSON.stringify(init_obj)){
                console.log("dispatch action");
              
              setinit_obj(init_obj);
              if(Object.keys(init_obj).length>0){
                  props.dispatch(initialize(
                      'variants',
                      init_obj,
                  ));
              }}
          }
          else{ // case in which I reset .. TODO : review this

              resetSection();

          }
        }

    },[props.default_filters]);


    const renderAll = ( ) => {

        return <React.Fragment>

            <SelectArea collection_name={'v_type'}
                header='Variant Type'
                fields={VARIANT_CONFIG.variant_type} />
            <SelectArea collection_name={'v_tagged'}
                tooltip={tooltips["v_tagged"]}
                header='Tagged Variants'
                fields={VARIANT_CONFIG.tagged_variants} />
            <SelectArea collection_name={'genotypefeatures'} header='Transcript Biotype' fields={VARIANT_CONFIG.transcript} />
            {(window.config.genome_ref === "hg38") && (<SelectArea collection_name={'Mane'}
                header='Mane'
                tooltip={tooltips["mane"]}
                fields={VARIANT_CONFIG.mane_select} />)}


        </React.Fragment>


    };


    return (
        <div className={"form_section"}>
            <div className={'bar'}>
                <GPAP_Button_light size="sm" variant={"gpap_button"} disabled={ pristine || submitting } onClick={ () => resetSection()}>
                    <MappedText text={"Clear Filters"}/>
                </GPAP_Button_light>
            </div>
            <form onSubmit={handleSubmit}>
                <div className={"form_container"}>
                <SelectArea collection_name={'variantclasses'}
                            header = 'Protein Impact'
                            fields = {VARIANT_CONFIG.variant_class}
                            tooltip={tooltips["variantclasses"]}

                />
                <SelectArea collection_name={'variantconsequences'}
                            header = 'Variant Consequence'
                            fields = {VARIANT_CONFIG.variant_consequences_class}
                            tooltip={tooltips["variantconsequences"]}

                />
                <SelectArea collection_name={'clinvarclasses'}
                            tooltip={tooltips["clinvarclasses"]}
                            header = 'ClinVar Classification'
                            fields = {VARIANT_CONFIG.clinvar}/>
                    { (minimized) ? null : renderAll() }
                </div>
            </form>
        </div>
    )
};




export default reduxForm({
    form: 'variants', // a unique identifier for this form
    destroyOnUnmount: true, // this allows to keep the values even if you switch from one tab to another
    enableReinitialize : true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
    initialValues: {
        "clinvarclasses": [],
        "variantclasses": [],
        "genotypefeatures": [],
        "v_tagged": [],
        "v_type": [],
        "Mane": [],
        "variantconsequences": []

    }
})(Variant_Section)


// Connect your component and map form values to props

  



Variant_Section = connect(() => ({}))(Variant_Section);
