
export const PHARMACOGENOMICS = [
    {
        Header: 'Gene',
        accessor: 'gene',
    },
    {
        Header: 'Genotype',
        accessor: 'genotype',
    },
    {
        Header: 'Phenotype',
        accessor: 'phenotype',
    },
    {
        Header: 'Haplotype1',
        accessor: 'haplotype1',
    },
    {
        Header: 'Haplotype2',
        accessor: 'haplotype2',
    },
    {
        Header: 'Variant Data',
        accessor: 'variant_data',
    },
];

export default PHARMACOGENOMICS;
