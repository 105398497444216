





import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";



const OrangeButton = withStyles((theme) => ({
    root: {
        
        color: "white",
        backgroundColor: "#feb24c",
        '&:hover': {
            backgroundColor: "#91BFDB",
        },
        margin: "1%"
    },
}))(Button);



const GPAP_Button  = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#4575B4",
        '&:hover': {
            backgroundColor: "#91BFDB",
        },
        margin: "5px"
    },
}))(Button);



const GPAP_RunButton = withStyles((theme) => ({
    root: {
        color: "white",
        fontSize: "15px",
        backgroundColor: "#4575B4",
        '&:hover': {
            backgroundColor: "#feb24c",
        },
        margin: "1%"
    },
}))(Button);

const GPAP_Link_Button  = withStyles((theme) => ({
    root: {
        color: "#4575B4",
        backgroundColor: "white",
        '&:hover': {
            backgroundColor: "#91BFDB",
        },
        fontSize: "18px",
        margin: "1%"
    },
}))(Button);



const big_rounded_button = withStyles((theme) => ({
    root: {
        marginLeft: "1%",
        borderRadius: "100px",
        fontSize: "20px",
        padding: "15px 40px 15px 40px",
        boxShadow: "1px 1px 5px black",
        '&:hover': {
            backgroundColor: "#91BFDB",
        },
        margin: "1%"
    },
}))(Button);


const GPAP_Button_light  = withStyles((theme) => ({
    root: {
        color: "#4575B4",
        backgroundColor: "#FAFAFA",
        '&:hover': {
            backgroundColor: "#f0f0f0",
        },
    },
}))(Button);

const GPAP_Button_Orange  = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "orange",
        '&:hover': {
            backgroundColor: "#e6550d",
            color: "black"
        },
    },
}))(Button);

const GPAP_Button_Gray = withStyles((theme) => ({
    root: {
        disableelevation: "true",
        color: "black",
        variant:"contained",
        backgroundColor: "#EAEAEA",
        '&:hover': {
            backgroundColor: "#D5D5D5",
            color: "black"
        },

    },
}))(Button);




const GPAP_Button_LightOrange  = withStyles((theme) => ({
    root: {
        margin: "2px",
        color: "black",
        backgroundColor: "#F3B82A",
        boxShadow:"none",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#F3B82A", 
            boxShadow: "none",         
        },
    },
}))(Button);


const OrangeTextButton = withStyles((theme) => ({
    root: {
        color: "orange"
    },
}))(Button);

export {
    OrangeTextButton,
    GPAP_RunButton,
    GPAP_Button,
    GPAP_Button_light,
    GPAP_Button_Orange,
    GPAP_Link_Button,
    OrangeButton,
    big_rounded_button,
    GPAP_Button_LightOrange,
    GPAP_Button_Gray,
};