


export const uniqueStatusDic = [
    { label: "To Do", color: "#BDBDBD", value: "to do" },
    { label: "In Progress", color: "#F3B82A", value: "in progress" },
    { label: "Done", color: "#4CAF50", value: "done" }
];

export const uniquePriorityDic = [
    { label: "Low", color: "#4CAF50", value: "low" },
    { label: "Medium", color: "#F3B82A", value: "medium" },
    { label: "High", color: "#C03B2E", value: "high" }
];


export const analysisTypes = [
    { value: "somatic_fusion_analysis", label: "Somatic fusion analysis" },
    { value: "somatic_tumor_only", label: "Somatic tumor only" },
    { value: "somatic_tumor_normal", label: "Somatic tumor normal" },
    { value: "germline", label: "Germline" }
];

