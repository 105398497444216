



import React, {Fragment, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FlagIcon from '@material-ui/icons/Flag';
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import Grid from "@material-ui/core/Grid";

import {
    CustomSelect, SelectStandard
} from "../../../../../../../../../gpap-lib/components/shared-components/renderer";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import "./tag-dialog.css";
import Box from "@material-ui/core/Box";
import {bindActionCreators} from "redux";
import * as StudyActions from "../../../../../../../reducers/study_actions";
import {connect} from "react-redux";
import {
    cnv_variant_type_opts,
    inheritance_opts,
    origin_opts,
    significance_opts,
    type_opts,
    variant_type_opts,
    zygosity_opts
} from "./tag_form_options";
import Typography from "@material-ui/core/Typography";
import {delete_variants, post_variant, update_variants} from "../../../../../../../../../services/api/variants_api";
import {delete_treatment, post_treatment, put_treatment } from '../../../../../../../../../services/api/treatments_api';
import {delete_pharmacogenomics, post_pharmacogenomics, put_pharmacogenomics } from '../../../../../../../../../services/api/pharmacogenomics_api';
import {experiments_view_new, getGenes} from "../../../../../../../../../services/api/get";
import {
    GPAP_Button, GPAP_Button_light,
    GPAP_Button_LightOrange
} from "../../../../../../../../../gpap-lib/components/Button_Collection";
import DeleteIcon from '@material-ui/icons/Delete';
import {variantFinder} from "../../variant-table/gpap-table/variant_finder";
import {Alert} from "@material-ui/lab";
import GPAP_Dialog_Title from "../../../../../../../../../gpap-lib/components/GPAP_Dialog_Title";
import {tag_dict} from "./tag_dict";
import {date_generator} from "../../../../../../../../func/helpers";
import {getChr} from "./mapChrom";

import WarningBox from "../../../../../../../../study-page/create-study/WarningBox";
import getTitle from "./getDialogTitle";
import GPAP_Dialog from "../../../../../../../../../gpap-lib/components/GPAP_Dialog";
import MappedText from '../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText';

import { useSelector } from 'react-redux';
import { setTable }  from '../../../../../../../../../reducers/slices/tables/table.slice';
import { useDispatch } from 'react-redux';
import { treatmentFinder } from '../../../../../../../../instand/treatments/utils';
import { pharmacogenomicsFinder } from '../../../../../../../../instand/pharmacogenomics_table/utils';
import PropTypes from "prop-types";
import {customOptionLabel} from "./customTranscriptLabel";

function TagDialog(props) {

    //TODO: list all props here.


    const [open, setOpen] = useState(false);
    const [state, setState] = useState({ causative: false });
    const [initialized, setInitialized] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [index_case, setIndexCaseID] = useState(null);
    const [variant_flagged, setVariantFlagged] = useState(false);
    const [geneId, setGeneIdentifier] = useState("");
    const [tagVisibility, setTagVisibility] = useState(false);
    const [projects, setProjects] = useState([]);
    const [openDialogError, setOpenDialogError]= useState(false);
    const [treatment_flagged, setTreatmentFlagged] = useState(false);
    const [pharmacogenomics_flagged, setPharmacogenomicsFlagged] = useState(false);

    const allDataApis = useSelector(state => state.generaltable.allDataApis);
    const dispatch = useDispatch();


    const [errorInfo, setErrorInfo] = useState({
        title: "",
        description: "",
        type: "warning"
    })

    // final data to submit when I click on SAVE
    const [data, setData] = useState({
        sample: "None",
        rs: "",
        gene: "None",
        chromosome: "None",
        transcript: "None",
        zygosity: zygosity_opts[0].label,
        inheritance: "",
        cDNA: "",
        protein: "",
        origin: origin_opts[0].label,
        significance: "",
        variant_source: "GPAP",
        comments: "",
        variant_type: type_opts[0].label,
        sv_type: (props.dataType === "CNV") ? cnv_variant_type_opts[0].label : variant_type_opts[0].label,
        project: ""
    });

    // initial formOptions
    const [formOptions, setFormOptions] = useState({
        participant_id: "xxxx",
        samples : [{value: "none", origin: "none"}],
        genes: [{value: "none", origin: "none"}],
        transcripts: [{value: "none", origin: "none"}],
        zygosity_opts:[{value: "none", origin: "none"}],
        inheritance_opts: [{value: "none", origin: "none"}],
        significance_opts: [{value: "none", origin: "none"}],
        origin : origin_opts,
      /*  nucleotide: "",*/
        variant_type: type_opts,
        sv_type: (props.dataType === "CNV") ? cnv_variant_type_opts: variant_type_opts,
        project: []
    });

    const setIndexCase = (index_case) => {

        if (index_case.length !== 0) {
            let INDEX = index_case[0];
            let id = (INDEX.hasOwnProperty("report_id")) ? INDEX.report_id : INDEX.id;
            setIndexCaseID(id);
        } else {
            if (props.patients.patients[0] !== undefined) {
                let INDEX = props.patients.patients[0];
                let id = (INDEX.hasOwnProperty("report_id")) ? INDEX.report_id : INDEX.id;
                setIndexCaseID(id);
            }

        }

    }

    // Function to create and initialize data based on variant type
// Parameters:
// - variant: object representing the variant information
// - samples: array of samples related to the variant
// - transcripts: array of transcripts related to the variant
// - genes: array of genes related to the variant
// - type: type of variant, either "SNV" or "CNV"

    const create_data_init = (variant, samples, transcripts, genes, type) => {
        // Create a shallow copy of the data object
        let data_copy = {...data};

        // Extract cDNA and protein information from the variant
        const cDNA = variant.codon_change;
        const protein = variant.amino_acid_change;

        // If the variant type is SNV
        if(type === "SNV_Germline" || type=== "SNV_Somatic"){
            // Set the relevant properties from the variant and related data
            data_copy["sample"] = (samples.length > 0) ? samples[0].value : "None";
            data_copy['chromosome'] = variant["chrom"];
            data_copy['gene'] = (genes.length > 0) ? genes[0].value : "None";
            data_copy['ref'] = variant["ref"];
            data_copy["rs"] = variant["rs"];
            data_copy['alt'] = variant["alt"];
            data_copy["cDNA"] = cDNA;
            data_copy["protein"] = protein;
            data_copy['start_pos'] = variant["pos"];
            data_copy['transcript'] = (transcripts.length > 0) ? transcripts[0].value : "None";
            data_copy['comments'] = (variant["comments"]) ? variant["comments"] : "";
        }
        // If the variant type is CNV
        else{
            // Set the relevant properties from the CNV variant and related data
            data_copy["sample"] = variant["Experiment_ID"];
            data_copy['chromosome'] = variant["SV_chrom"];
            data_copy['gene'] = variant["Gene_name"];
            data_copy['ref'] = variant["ref"];
            data_copy["rs"] = variant["rs"];
            data_copy['alt'] = variant["alt"];
            data_copy['start_pos'] = variant["SV_start"];
            data_copy['end_pos'] = variant["SV_end"];
            data_copy['transcript'] = (transcripts.length > 0) ? transcripts[0].value : "None";
            data_copy['comments'] = (variant["comments"]) ? variant["comments"] : "";

            //
            if(variant.SV_type === "DEL" || variant.SV_type === "DUP"){
                data_copy["sv_type"] = (variant.SV_type === "DEL")
                    ? "Deletion"
                    : "Duplication";
            }
        }

        // Return the initialized data copy
        return data_copy
    }


    // initialize data;
    /**
     * Initializes data based on the variant, samples, genes, transcripts, and type.
     * If the 'patients' property is defined and contains a 'patients' key, sets the index case based on the 'Yes' value.
     * Calls the 'create_data_init' function to create and initialize data based on the variant type.
     * Calls the 'getProjects' function with the 'sample' from the data copy.
     * @param {Object} variant - Object representing the variant information
     * @param {Array} samples - Array of samples related to the variant
     * @param {Array} genes - Array of genes related to the variant
     * @param {Array} transcripts - Array of transcripts related to the variant
     * @param {string} type - Type of variant, either "SNV" or "CNV"
     * @returns {Object} - Initialized data copy
     */
    const initData = (variant, samples, genes, transcripts, type) => {


        // extract data;
        if (props.patients !== undefined && "patients" in props.patients) {
            let index_case = props.patients.patients.filter(p => p.index === "Yes");

            // Set the index case based on the 'Yes' value
            setIndexCase(index_case);

            // Call the create_data_init function to create and initialize data based on the variant type
            let data_copy = create_data_init(variant, samples, transcripts, genes, type);

            // Call the getProjects function with the 'sample' from the data copy
            if (data_copy["sample"] !== undefined){
                getProjects(data_copy["sample"]);
            }

            // Return the initialized data copy
            return data_copy;
        }
    };


    useEffect(function(){

        if(data["sample"] !== "None" && data["sample"] !== undefined){
            getProjects(data["sample"]);
        }


    },[data["sample"]])




    //Check comments for treatments
    useEffect(function(){


        let dataType = getRowDataType();

        let item = props.selected_table_rows.find(s => s.data_type === dataType);

        if(item!== undefined){
            if(item.data_row.length > 0 && (dataType === "Treatment_Somatic" || dataType === "Pharmacogenomics_Germline")){
                // take the first item
                let current_data = {...item.data_row[0]};
                current_data["comments"] =  "";
                setData(current_data);
            }
        }



    },[props.selected_table_rows])


    /**
     * Prepares form data based on the tagged variant, samples, genes, and transcripts.
     * Sets the 'gene' property of the copy to either the existing 'gene' value or the 'gene_name' value.
     * Calls the 'setDataToPost' function to set data based on the copy and the current variant type.
     * Sets the state based on whether the copy's status is "Causative".
     * Searches for the 'TAGGED_VARIANT.sample' in the samples and sets the index case ID and tag visibility accordingly.
     * Sets form options using data options including samples, genes, transcripts, zygosity options, inheritance options, significance options, origin options, variant types, and structural variant types.
     * @param {Object} TAGGED_VARIANT - Object representing the tagged variant information
     * @param {Array} samples - Array of samples
     * @param {Array} genes - Array of genes
     * @param {Array} transcripts - Array of transcripts
     */
    const prepareFormFromTag = (TAGGED_VARIANT, samples, genes, transcripts) => {
        const copy = { ...TAGGED_VARIANT };
        copy.gene = copy.gene !== undefined ? copy.gene : copy.gene_name;
        setDataToPost(copy);
        setState({ causative: copy.status === "Causative" });

        const exp = props.samples.find((d) => d.sample_id === TAGGED_VARIANT.sample);

        const dataOptions = {
            samples,
            genes,
            transcripts,
            zygosity_opts,
            inheritance_opts,
            significance_opts,
            origin: origin_opts,
            variant_type: type_opts,
            sv_type:(props.type === "CNV") ? cnv_variant_type_opts: variant_type_opts,
        };
        setFormOptions(dataOptions);

        if (exp !== undefined && exp.hasOwnProperty("pheno_id")) {
            setIndexCaseID(exp.pheno_id);
            setTagVisibility("sample_tag");
        } else {
            setTagVisibility("extra_tag");
        }
    };



    const findTaggedVariant = (sampleIds, USER_TAGS) => {
        let taggedVariant = USER_TAGS.find((tag) => sampleIds.includes(tag.sample));

        if (taggedVariant === undefined) {
            taggedVariant = USER_TAGS.find((tag) => tag.user === props.user);
        }

        return taggedVariant;
    };

    const processVariant = (variant, props) => {
        let genes = [{ label: variant.gene_name, value: variant.gene_name }];

        // does it work for somatic variants?? check
        let transcripts = variant.transcripts_all !== undefined ? variant.transcripts_all.map((x) => ({ value: x.transcript_id, label: x.transcript_id, canonical:  (x["mane_select"] !== undefined || x["mane_plus_clinical"] !== undefined) })) : []
        let geneID = getGeneIdentifier(variant.gene_name);
        setGeneIdentifier(geneID);

        let found = variantFinder(variant, props.annotations, props.tagged_variants, "SNV_Germline");

        if (variant.isTagged !== undefined) {
            setVariantFlagged(variant.isTagged);
        }

        return {
            genes,
            transcripts,
            geneID,
            found,
        };
    };

    const getSelectedTableRow = () => {

        let dataType = getRowDataType();

        let item = props.selected_table_rows.find(s => s.data_type === dataType);

        if(item!== undefined && item.data_row.length>0){
            return item.data_row[0];
        }
        else{
            return false;
        }
    }


    const getRowDataType = () => {

        return props.dataType;

    }



    useEffect(function(){

            // initialize

        let selectedDataRow = getSelectedTableRow();

        if( selectedDataRow ){
            // get info we need;
            setTagVisibility("no_tag");
            setSubmitted(false);
            let samples = props.samples.map(s => {return {"value": s.sample_id, "label": s.sample_id}});
            //let variantItem = props.selected_variant[0];


            let dataType = getRowDataType();

            if((dataType === "SNV_Germline") || (dataType === "SNV_Somatic")){

                const processedData = processVariant(selectedDataRow, props);
                const { genes, transcripts, geneID, found } = processedData;

                // if tagged .... get data from API;

                if(found !== undefined){

                    // gene key is here gene_name... rename
                    // is variant visible to this user?
                    let VISIBLE = false;
                    let USER_TAGS = found.filter(s => s.user === props.user);
                    if( found.length>0 ){
                        if(USER_TAGS.length>0){
                            VISIBLE= true;
                        }
                    }
                    else{
                        setTagVisibility(false);
                    }

                    if (VISIBLE) {
                        const sampleIds = props.samples.map((s) => s.sample_id);
                        const taggedVariant = findTaggedVariant(sampleIds, USER_TAGS);

                        if (taggedVariant && (taggedVariant.gene || taggedVariant.gene_name)) {
                            prepareFormFromTag(taggedVariant, samples, genes, transcripts);
                        }
                    } else {
                        setTagVisibility(USER_TAGS.length === 0 ? "extra_tag" : "no_tag");

                        let init_data = initData(selectedDataRow, samples, genes, transcripts, dataType);

                        if (Array.isArray(found) && found.length === 0) {
                            init_data = {
                                ...init_data,
                                inheritance: "",
                                significance: "",
                                sv_type: (props.type === "CNV") ? cnv_variant_type_opts[0].label : variant_type_opts[0].label,
                                zygosity: zygosity_opts[0].label,
                                variant_type: type_opts[0].label,
                                origin: dataType === "SNV_Somatic" ? origin_opts.find(opt => opt.value === "somatic").value : origin_opts.find(opt => opt.value === "germline").value
                            };
                        }

                        setDataToPost(init_data);

                        let dataOptions = {
                            samples,
                            genes,
                            transcripts,
                            zygosity_opts,
                            inheritance_opts,
                            significance_opts,
                            origin: origin_opts,
                            variant_type: type_opts,
                            sv_type: (props.type === "CNV") ? cnv_variant_type_opts: variant_type_opts,
                        };

                        setFormOptions(dataOptions);
                        setInitialized(true);
                    }


                    // parse variant and display it.

                 
               }
               
               else {
                    // else... read the variant and set the form.
                    // read variant to tag
                    readExistingVariant(selectedDataRow,samples, genes, transcripts, getSelectedTableRow());
                }
            }
            //Treatment CASE
            else if (dataType === "Treatment_Somatic") {
                try {

                    if (selectedDataRow) {
                        let treatment_tagged = treatmentFinder(selectedDataRow, allDataApis.participants[0]);

                        setTreatmentFlagged(false);

                        if (treatment_tagged.found) {
                            let current_data = { ...data };
                            current_data["comments"] = treatment_tagged.interpretation;
                            setData(current_data);
                            setTreatmentFlagged(true);
                        }
                    }
                } catch (error) {
                    console.error("Error occurred while processing treatment data:", error);
                    // Handle the error here
                    // You might want to set some state or trigger an error dialog
                }
            }

            //Pharmacogenomics CASE
            else if(dataType === "Pharmacogenomics_Germline"){
                //let treatment_tagged = treatmentFinder(variantItem.variant, props.patients.patients[0])
                let pharmacogenomics_tagged = pharmacogenomicsFinder(selectedDataRow, allDataApis.participants[0])

                setPharmacogenomicsFlagged(false);

                if (pharmacogenomics_tagged.found){
                    let current_data = {...data};
                    current_data["comments"] =  pharmacogenomics_tagged.interpretation;
                    setData(current_data);
                    setPharmacogenomicsFlagged(true);
                }
            }


            else{
                // CNV CASE
                let cnv_variant = getSelectedTableRow();
                // no tag by default;


                let cnv_init_data = initData(cnv_variant, samples, cnv_variant.Gene_name, [], getRowDataType());
                setDataToPost(cnv_init_data);

                let transcripts = cnv_variant["Tx"] ? [cnv_variant["Tx"]].map((x) => ({ value: x, label: x })) : [];

                let cnv_dataOptions = {
                    samples: samples,
                    genes: [cnv_init_data["gene"].toString()].map(g => {return [{ label: g, value: g }];}),
                    transcripts: transcripts,
                    zygosity_opts: zygosity_opts,
                    inheritance_opts: inheritance_opts,
                    significance_opts: significance_opts,
                    origin: origin_opts,
                    variant_type: type_opts,
                    sv_type: (props.dataType === "CNV") ? cnv_variant_type_opts: variant_type_opts,
                };

                setFormOptions(cnv_dataOptions);
                setInitialized(true);

                let found = variantFinder(cnv_variant, [], props.tagged_variants, "CNV");
                if(Array.isArray(found) && found.length > 0){
                    // we do not differentiate yet between own tags and external tags;
                    setTagVisibility("extra_tag");
                    setIndexCaseID(found[0].participant_id);

                }
                else{
                    setTagVisibility("no_tag");
                    // update the "index case id" tagged with the one that appears in the view.
                    // Ideally, we would like to list where all tags appear (list of Participant IDs)
                    //setIndexCaseID(found[0].participant_id);
                }
            }


        }

    },[open, initialized]);


    const readExistingVariant = (variant,samples, genes, transcripts, type) => {

        let init_data = initData(variant, samples, genes, transcripts, type);
        
        setDataToPost(init_data);
        // data to visualize the form;
        let dataOptions = {
            samples: samples,
            genes: genes,
            transcripts: transcripts,
            zygosity_opts: zygosity_opts,
            inheritance_opts: inheritance_opts,
            significance_opts: significance_opts,
            origin: origin_opts,
            variant_type: type_opts,
            sv_type: (type === "CNV") ? cnv_variant_type_opts : variant_type_opts
        };


        setFormOptions(dataOptions);
        setInitialized(true);

    }


    const setDataToPost = (data_copy) => {

        const type = getRowDataType();
        if(type === "SNV_Germline" || type=== "SNV_Somatic"){

            let selectedDataRow = getSelectedTableRow()
            if(selectedDataRow){
                let transcript = selectedDataRow["transcripts_all"].find(function (s) {
                    return s.transcript_id === data_copy['transcript'];
                });
                let cDNA = "";
                let protein = "";
                // find cDNA and Protein
                if(transcript!== undefined){
                    cDNA = transcript.codon_change;
                    protein = transcript.amino_acid_change;

                }

                let origin = (getRowDataType() === "SNV_Somatic") ? "somatic" : data_copy["origin"];


                // data to prepare the POST
                let snv_data_tag = {
                    sample: data_copy["sample"],
                    gene: data_copy["gene"],
                    rs: data_copy["rs"],
                    chromosome: data_copy["chromosome"],
                    transcript: data_copy['transcript'],
                    ref: data_copy["ref"],
                    alt: data_copy["alt"],
                    start_pos: data_copy['start_pos'],
                    zygosity: data_copy['zygosity'],
                    cDNA: cDNA,
                    protein: protein,
                    inheritance: data_copy["inheritance"],
                    origin: origin,
                    significance: data_copy["significance"],
                    variant_source: "GPAP",
                    comments: data_copy["comments"],
                    project: data_copy["project"],
                    variant_type: (data_copy.hasOwnProperty("variant_type")) ? data_copy["variant_type"] : "",
                    sv_type: (data_copy.hasOwnProperty("sv_type")) ? data_copy["sv_type"] : "SNV-InDel"
                }


                setData(snv_data_tag);
            }

        }
        else if(type === "CNV"){
            let cnv_data_tag = {
                sample: data_copy["sample"],
                gene: data_copy["gene"],
                rs: data_copy["rs"],
                chromosome: data_copy["chromosome"],
                transcript: data_copy['transcript'],
                ref: data_copy["ref"],
                alt: data_copy["alt"],
                start_pos: data_copy['start_pos'],
                end_pos: data_copy["end_pos"],
                zygosity: data_copy['zygosity'],
                /*cDNA: cDNA,
                protein: protein,*/
                inheritance: data_copy["inheritance"],
                origin: data_copy["origin"],
                significance: data_copy["significance"],
                variant_source: "GPAP",
                comments: data_copy["comments"],
                project: data_copy["project"],
                variant_type: (data_copy.hasOwnProperty("variant_type")) ? data_copy["variant_type"] : "",
                sv_type: (data_copy.hasOwnProperty("sv_type")) ? data_copy["sv_type"] : ""
            }
            setData(cnv_data_tag);

        }

    };


    const handleCausativeChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });

    };


    const handleClickOpen = () => {

        let selected_table_rows =  getSelectedTableRow();

        // currently, multiple select is not allowed.

        if(selected_table_rows){
            setOpen(true);
        }
        else{
            alert("Please, select at least 1 variant to tag")
        }

    };

    
    const prepareGeneObject = (gene_identifier,data_tag) => {
        let date = date_generator();
        // make sure chrom is string.
        //chromosome
        let chromosome = (typeof data_tag["chromosome"] === "string" ) ? data_tag["chromosome"] : data_tag["chromosome"].toString();
        let nucleotide = "g." + data_tag["start_pos"] + ":" + data_tag["ref"] + ">" + data_tag["alt"]


        let gene_obj = {
            "gene": {"id": gene_identifier, "name": data["gene"]},
            "variants": [
                {
                    "genomic_build": "GRCh37 (hg19)",
                    "chromosome": getChr(chromosome),
                    "dbSNP": data_tag["rs"],
                    "start_pos": data_tag["start_pos"],
                    "ref": data_tag["ref"],
                    "alt": data_tag["alt"],
                    "cDNA": data_tag["cDNA"],
                    "protein": data_tag["protein"],
                    "transcript": data_tag["transcript"],
                    "zygosity": data_tag["zygosity"],
                    "inheritance": data_tag["inheritance"],
                    "significance": data_tag["significance"],
                    "variant_source": "GPAP",
                    "comments": data_tag["comments"],
                    "origin": data_tag["origin"],
                    "sample": data_tag["sample"],
                    "timestamp": date,
                    "nucleotide": nucleotide,
                    "sv_type": data_tag["sv_type"],
                    "variant_type": data_tag["variant_type"],
                    "project": data_tag["project"]
                }]
        };

        if (state.causative) {
            gene_obj["status"] = "Causative";
        }

        return gene_obj;

    };

    const prepareRegionObject = (data_tag) =>{

        let date = date_generator();
        let chromosome = (typeof data_tag["chromosome"] === "string" ) ? data_tag["chromosome"] : data_tag["chromosome"].toString();

        // region:

        let regionField = getChr(chromosome) + ":" + data_tag["start_pos"] + ":" + data_tag["end_pos"];

        let region_obj = {
            "region": regionField ,
            "field": "regions",
            "variants": [
                {
                    "genomic_build": "GRCh37 (hg19)",
                    "chromosome": getChr(chromosome),
                    "dbSNP": data_tag["rs"],
                    "start_pos": data_tag["start_pos"],
                    "end_pos": data_tag["end_pos"],
                    "ref": data_tag["ref"],
                    "alt": data_tag["alt"],
                    "cDNA": data_tag["cDNA"],
                    "protein": data_tag["protein"],
                    "transcript": data_tag["transcript"],
                    "zygosity": data_tag["zygosity"],
                    "inheritance": data_tag["inheritance"],
                    "significance": data_tag["significance"],
                    "variant_source": "GPAP",
                    "comments": data_tag["comments"],
                    "origin": data_tag["origin"],
                    "sample": data_tag["sample"],
                    "timestamp": date,
                    //"nucleotide": nucleotide,
                    "sv_type": data_tag["sv_type"],
                    "variant_type": data_tag["variant_type"],
                    "project": data_tag["project"],
                    //"associated_genes": data_tag["gene"]
                    "associated_genes": Array.isArray(data_tag["gene"]) ? data_tag["gene"].toString() : data_tag["gene"]
                }]
        };

        if (state.causative) {
            region_obj["status"] = "Causative";
        }

        return region_obj

    }

    const prepareTreatmentObject = (treatment, data) => {
        const mapping = {
          "gene_name": "gene",
          "hgvs_p": "variant",
          "match_type": "match_type",
          "Cancer_Type": "cancer_type",
          "Therapies": "therapy",
          "Therapy_status": "therapy_status",
          "Evidence_level": "evidence_level"
        };
      
        // Create the output object
        const output = {};
      
        // Map properties based on the provided mapping
        Object.entries(mapping).forEach(([treatmentKey, outputKey]) => {
          if (treatment[treatmentKey] && treatment[treatmentKey].length > 0) {
            output[outputKey] = treatment[treatmentKey];
          }
          else {
            output[outputKey] = "NA";
          }
        });
      
        // Set the interpretation property from data.comments
        output.interpretation = ""
        if (data && data.comments) {
          output.interpretation = data.comments;
        }
      
        return output;
      }
    

    const preparePharmacogenomicsObject = (pharmacogenomics, data) => {
        const mapping = {
          "Gene": "gene",
          "Genotype": "genotype",
          "Haplotype1": "haplotype1",
          "Haplotype2": "haplotype2",
          "Phenotype": "phenotype",
          "VariantData": "variant_data"
        };
      
        // Create the output object
        const output = {};
      
        // Map properties based on the provided mapping
        Object.entries(mapping).forEach(([pharmacogenomicsKey, outputKey]) => {
          if (pharmacogenomics[pharmacogenomicsKey] && pharmacogenomics[pharmacogenomicsKey].length > 0) {
            output[outputKey] = pharmacogenomics[pharmacogenomicsKey];
          }
          else {
            output[outputKey] = "NA";
          }
        });
      
        // Set the interpretation property from data.comments
        output.interpretation = ""
        if (data && data.comments) {
          output.interpretation = data.comments;
        }
      
        return output;
      }



    const getGeneIdentifier = async (gene_name) => {

        let gene_id = await getGenes(gene_name).then(r => r.json());

        // look for id;
        let id = undefined;
        if (gene_id !== undefined) {
            if (gene_id.length !== 0) {
                id = gene_id[3].map(function (val) {
                    if (val.length !== 0) {
                        return {id: val[0], label: val[1]}
                    }
                })
            }
        }

        let gene_identifier = (id !== undefined && Array.isArray(id) && id.length > 0) ? id[0].id : "";

        setGeneIdentifier(gene_identifier);

        return gene_identifier;

    };


    const handleSubmit = async () => {

        let phenostore_api = config.apis_configuration.api_phenostore;

        // get gene code if exists;


        let selectedDataRow = getSelectedTableRow();
        let dataType = getRowDataType();

        if(selectedDataRow){
            if( dataType === "SNV_Germline" || dataType === "SNV_Somatic"){
                let geneObj = prepareGeneObject(geneId, data);


                let variant_data = {
                    participant_id: index_case,
                    genes: [geneObj],
                    field: "genes"
                };



                // get the data in the right format;

                //let variant_data = prepareData();

                if(data["inheritance"]!== "" && data["significance"]!== ""){
                    if(variant_flagged && tagVisibility!== "extra_tag"){ // update only if the variant belongs to the user
                        // update
                        delete variant_data.participant_id;
                        update_variants(phenostore_api, props.token, variant_data,  index_case)
                            .then(r =>
                            {
                                return  r.json()

                            })
                            .then(json => {
                                if(json["message"]!== "Error" && json["message"] !== "Variant(s) to update not found or nothing to update" ){


                                    setUpdated(true);
                                    setInitialized(false);
                                    props.annotate_variants("TAGGED", []);
                                    props.getAllTaggedVariants();
                                    //reset()
                                }
                                else{
                                    setOpenDialogError(true);
                                    setErrorInfo({title: "Server Error", description: "Error in updating the variant," + json["message"] , type: "alert"})
                                }
                            })
                    }
                    else{
                        post_variant(phenostore_api, props.token, variant_data)
                            .then(r =>
                            {
                                return  r.json();

                            })
                            .then(json => {
                                if (json["message"] && json["message"].includes("Added")){
                                    props.annotate_variants("TAGGED", [], "add");
                                    setSubmitted(true);
                                    setVariantFlagged(true);
                                    props.getAllTaggedVariants();
                                }
                                else{
                                    setOpenDialogError(true);
                                    setErrorInfo({title: "Server Error", description: "Error in tagging the variant, " + json["message"] , type: "alert"})
                                }
                            })
                    }
                }
                else{
                    setOpenDialogError(true);
                    setErrorInfo({title: "Required Fields", description: "Please, fill in all required fields", type: "warning"})
                }
            }

            //Tag Treatment Case
            else if (dataType === "Treatment_Somatic"){

                let treatment_obj = prepareTreatmentObject(selectedDataRow, data)

                //If it is already flagged update
                if (treatment_flagged){
                    updateTreatmentData()
                }

                //Otherwise, post
                else{
                    tagTreatmentData(treatment_obj)
                }
            }


            //Tag Pharmacogenomics Case
            else if (dataType === "Pharmacogenomics_Germline"){

                let pharmacogenomics_obj = preparePharmacogenomicsObject(selectedDataRow, data)

                //If it is already flagged update
                if (pharmacogenomics_flagged){
                    updatePharmacogenomicsData()
                }

                //Otherwise, post
                else{
                    tagPharmacogenomicsData(pharmacogenomics_obj)
                }
            }

            else{
                // variant type CNV;

                let regionObj = prepareRegionObject(data);


                let cnv_variant_data = {
                    participant_id: index_case,
                    regions: [regionObj],
                    field: "regions"
                };


                if(data["inheritance"]!== "" && data["significance"]!== ""){

                    post_variant(phenostore_api, props.token, cnv_variant_data)
                        .then(r => {
                            if (r.status === 200) {
                                return r.json();
                            } else {
                                throw new Error("Server Error");
                            }
                        })
                        .then(json => {
                            // The API call was successful
                            // Do something with the 'json' response
                            // props.annotate_variants("TAGGED", [], "add");


                            setSubmitted(true);

                            setVariantFlagged(true);
                            props.getAllTaggedVariants();
                            // reset()
                        })
                        .catch(error => {
                            // An error occurred during the API call

                            setOpenDialogError(true);
                            setErrorInfo({title: "Error", description: "Error in posting variants", type: "alert"})

                        });

                }

                else{
                    setOpenDialogError(true);
                    setErrorInfo({title: "Required Fields", description: "Please, fill in all required fields", type: "warning"})
                }
            }
        }


    }



    const deleteVariantData = () => {

        // delete variant;

        let phenostore_api = config.apis_configuration.api_phenostore;

        // get the data in the right format;

        let geneObj = prepareGeneObject(geneId, data);

        let variant_data = {
            participant_id: index_case,
            genes: [geneObj]
        };

        if(variant_flagged){
            // update
            delete variant_data.participant_id;
            delete_variants(phenostore_api, props.token, variant_data,  index_case)
                .then(r =>
                {

                    return  r.json()


                })
                .then(() => {

                    if("message"!== "Error"){
                        setDeleted(true);
                        setVariantFlagged(false);
                        setTagVisibility("no_tag"); 
                        // delete variant from our tags
                        // load again Tagged Variants..
                        props.annotate_variants("TAGGED", [], "delete");
                        props.getAllTaggedVariants();
                    }
                    else{
                        setOpenDialogError(true);
                        setErrorInfo({title: "Variant can't be deleted", description: "Error in deleting the variant", type: "alert"})
                    }
                })
        }


    };


    const tagTreatmentData = (treatment_obj) => {

        try {
            let report_id = props.patients.patients[0]?.report_id;
            if (!report_id) {
                throw new Error("report_id is missing");
            }

            let phenostore_api = config.apis_configuration.api_phenostore;

            post_treatment(phenostore_api, props.token, treatment_obj, report_id)
                .then(r => {
                    if (r.status === 200) {
                        return r.json();
                    } else {
                        throw new Error("Server Error");
                    }
                })
                .then(json => {
                    setSubmitted(true);
                    let updated_participants = { ...allDataApis.participants[0]};
                    if (!updated_participants.tagged_treatments) {
                        updated_participants = { ...updated_participants, tagged_treatments: [treatment_obj] };
                    } else {
                        updated_participants = {
                            ...updated_participants,
                            tagged_treatments: [...updated_participants.tagged_treatments, treatment_obj],
                        };
                    }
                    let newDataObject = { ...allDataApis };
                    newDataObject.participants = [updated_participants];
                    dispatch(setTable({ option: "allDataApis", value: newDataObject }))
                })
                .catch(error => {
                    // An error occurred during the API call
                    console.error("Error occurred during tagging treatment:", error);
                    setOpenDialogError(true);
                    setErrorInfo({title: "Error", description: "Error in tagging treatment", type: "alert"})
                });
        } catch (error) {
            console.error("Error occurred:", error);
            setOpenDialogError(true);
            setErrorInfo({title: "Error", description: error.message, type: "alert"});
        }
    }


    const updateTreatmentData = () => {

        let phenostore_api = config.apis_configuration.api_phenostore;

        // Set the interpretation property from data.comments
        let interpretation = ""
        if (data && data.comments) {
            interpretation = data.comments;
        }

        let selectedRowData = getSelectedTableRow();
        let rowDataType = getRowDataType();

        let treatment_data = {
            gene: selectedRowData.gene_name,
            variant:selectedRowData.hgvs_p,
            interpretation: interpretation
        };

        if(treatment_flagged && rowDataType === "Treatment_Somatic"){
            put_treatment(phenostore_api, props.token, treatment_data, props.patients.patients[0].report_id)
                .then(r =>r.json())
                .then((data) => {

                    const message = data.message;

                    if(message && message.includes("Updated")){
                        setDeleted(false);
                        setUpdated(true);

                        // Create a new copy of json for the participants property
                        let updated_participants = { ...allDataApis.participants[0]};

                        // Find the matching object in tagged_treatments and update its "interpretation" property
                        const matchingTreatmentIndex = updated_participants.tagged_treatments.findIndex(treatment => {
                        return treatment.gene === treatment_data.gene && treatment.variant === treatment_data.variant;
                        });


                        if (matchingTreatmentIndex !== -1) {
                            // Matching object found, create a new array with the updated object
                            const updatedTreatments = [...updated_participants.tagged_treatments];
                            updatedTreatments[matchingTreatmentIndex] = {
                                ...updatedTreatments[matchingTreatmentIndex],
                                interpretation: interpretation
                            };

                            updated_participants = {
                                ...updated_participants,
                                tagged_treatments: updatedTreatments,
                            };
                        }


                        // Create a new copy of allDataApis
                        let newDataObject = { ...allDataApis };
                        
                        // Update the participants property in the new object
                        newDataObject.participants = [updated_participants];
                        
                        // Dispatch the action to update the state
                        dispatch(setTable({ option: "allDataApis", value: newDataObject }))
                    }
                    else{
                        setOpenDialogError(true);
                        setErrorInfo({title: "Treatment can't be updated", description: "Error in updating the treatment", type: "alert"})
                    }
                })
        }
    };


    const tagPharmacogenomicsData = (pharmacogenomics_obj) => {

        let phenostore_api = config.apis_configuration.api_phenostore;

        post_pharmacogenomics(phenostore_api, props.token, pharmacogenomics_obj, props.patients.patients[0].report_id)
        .then(r => {
            if (r.status === 200) {
                return r.json();
            } else {
                throw new Error("Server Error");
            }
        })
        .then(json => {

            setSubmitted(true);

            // Create a new copy of json for the participants property
            let updated_participants = { ...allDataApis.participants[0]};

            // Check if the tagged_pharmacogenomics property exists in the first participant
            if (!updated_participants.tagged_pharmacogenomics) {
                // If it doesn't exist, create the property with an array containing pharmacogenomics_obj
                updated_participants = { ...updated_participants, tagged_pharmacogenomics: [pharmacogenomics_obj] };
            } else {
                // If it exists, create a new object with the added pharmacogenomics_obj
                updated_participants = {
                    ...updated_participants,
                    tagged_pharmacogenomics: [...updated_participants.tagged_pharmacogenomics, pharmacogenomics_obj],
                };
            }

            // Create a new copy of allDataApis
            let newDataObject = { ...allDataApis };

            // Update the participants property in the new object
            newDataObject.participants = [updated_participants];

            // Dispatch the action to update the state
            dispatch(setTable({ option: "allDataApis", value: newDataObject }))

        })
        .catch(error => {
            // An error occurred during the API call
            setOpenDialogError(true);
            setErrorInfo({title: "Error", description: "Error in tagging pharmacogenomics", type: "alert"})

        });
     }



    /**
     * Deletes tagged data of a specific type associated with a patient's report.
     * @param {function} dataToDelete - The function responsible for deleting the data.
     * @param {string} dataType - The type of data to delete (e.g., "Treatment_Somatic", "Pharmacogenomics_Germline").
     * @throws {Error} Throws an error if report_id is missing or if an error occurs during the deletion process.
     * @returns {void}
     *
     * // Example usage:
     * // deleteData(delete_treatment, "Treatment_Somatic");
     * // deleteData(delete_pharmacogenomics, "Pharmacogenomics_Germline");
     */ const deleteData = (dataToDelete, dataType) => {
        let phenostore_api = config.apis_configuration.api_phenostore;
        let selectedRowData = getSelectedTableRow();
        let rowDataType = getRowDataType();

        if (dataToDelete && dataType && rowDataType === dataType) {
            try {
                const report_id = props.patients.patients[0]?.report_id;
                if (!report_id) {
                    throw new Error("report_id is missing");
                }

                let data_to_delete;
                if(dataType === "Pharmacogenomics_Germline"){
                    data_to_delete = {
                        gene: selectedRowData["Gene"],
                        genotype: selectedRowData["Genotype"]
                    }
                }
                else{
                    data_to_delete = {
                            gene: selectedRowData.gene_name,
                            variant: selectedRowData.hgvs_p
                    }
                }

                let tagged_name = (dataType === "Pharmacogenomics_Germline") ? "pharmacogenomics" : "treatments"

                dataToDelete(phenostore_api, props.token, data_to_delete,report_id)
                    .then(r => r.json())
                    .then((data) => {
                        const message = data.message;
                        if (message && message.includes("Deleted")) {
                            setDeleted(true);
                            let updated_participants = { ...allDataApis.participants[0] };
                            if (updated_participants[`tagged_${tagged_name.toLowerCase()}`] && Array.isArray(updated_participants[`tagged_${tagged_name.toLowerCase()}`])) {
                                updated_participants[`tagged_${tagged_name.toLowerCase()}`] = updated_participants[`tagged_${tagged_name.toLowerCase()}`].filter(item => {
                                    return item.gene !== data_to_delete.gene || item.variant !== data_to_delete.variant;
                                });

                                /*if((dataType === "Pharmacogenomics_Germline")){
                                    updated_participants.tagged_pharmacogenomics = updated_participants.tagged_pharmacogenomics.filter(pharmacogenomics => {
                                        return pharmacogenomics.gene !== data_to_delete.gene || pharmacogenomics.variant !== data_to_delete.variant;
                                    });
                                }
                                else{
                                    updated_participants[`tagged_${tagged_name.toLowerCase()}`] = updated_participants[`tagged_${tagged_name.toLowerCase()}`].filter(item => {
                                        return item.gene !== data_to_delete.gene || item.variant !== data_to_delete.variant;
                                    });
                                }*/


                            }
                            let newDataObject = { ...allDataApis };
                            newDataObject.participants = [updated_participants];
                            dispatch(setTable({ option: "allDataApis", value: newDataObject }))
                        } else {
                            setOpenDialogError(true);
                            setErrorInfo({ title: `${dataType} can't be deleted`, description: `Error in deleting the ${dataType.toLowerCase()}`, type: "alert" })
                        }
                    })
                    .catch(error => {
                        console.error(`Error occurred during ${dataType.toLowerCase()} deletion:`, error);
                        setOpenDialogError(true);
                        setErrorInfo({ title: `${dataType} deletion failed`, description: `An error occurred while attempting to delete ${dataType.toLowerCase()}`, type: "alert" });
                    });
            } catch (error) {
                console.error("Error occurred:", error);
                setOpenDialogError(true);
                setErrorInfo({ title: "Error", description: error.message, type: "alert" });
            }
        }
    };




    const updatePharmacogenomicsData = () => {

        let phenostore_api = config.apis_configuration.api_phenostore;


        let selectedRowData = getSelectedTableRow();
        let rowDataType = getRowDataType();

        // Set the interpretation property from data.comments
        let interpretation = ""
        if (data && data.comments) {
            interpretation = data.comments;
        }

        let pharmacogenomics_data = {
            gene: selectedRowData["Gene"],
            genotype: selectedRowData["Genotype"],
            interpretation: interpretation
        };

        if(pharmacogenomics_flagged && rowDataType === "Pharmacogenomics_Germline"){
            put_pharmacogenomics(phenostore_api, props.token, pharmacogenomics_data, props.patients.patients[0].report_id)
                .then(r =>r.json())
                .then((data) => {

                    const message = data.message;

                    if(message && message.includes("Updated")){
                        setDeleted(false);
                        setUpdated(true);

                        // Create a new copy of json for the participants property
                        let updated_participants = { ...allDataApis.participants[0]};

                        // Find the matching object in tagged_pharmacogenomics and update its "interpretation" property
                        const matchingPharmacogenomicsIndex = updated_participants.tagged_pharmacogenomics.findIndex(pharmacogenomics => {
                        return pharmacogenomics.gene === pharmacogenomics_data.gene && pharmacogenomics.variant === pharmacogenomics_data.variant;
                        });


                        if (matchingPharmacogenomicsIndex !== -1) {
                            // Matching object found, create a new array with the updated object
                            const updatedPharmacogenomics = [...updated_participants.tagged_pharmacogenomics];
                            updatedPharmacogenomics[matchingPharmacogenomicsIndex] = {
                                ...updatedPharmacogenomics[matchingPharmacogenomicsIndex],
                                interpretation: interpretation
                            };

                            updated_participants = {
                                ...updated_participants,
                                tagged_pharmacogenomics: updatedPharmacogenomics,
                            };
                        }


                        // Create a new copy of allDataApis
                        let newDataObject = { ...allDataApis };

                        // Update the participants property in the new object
                        newDataObject.participants = [updated_participants];

                        // Dispatch the action to update the state
                        dispatch(setTable({ option: "allDataApis", value: newDataObject }))
                    }
                    else{
                        setOpenDialogError(true);
                        setErrorInfo({title: "Pharmacogenomics can't be updated", description: "Error in updating the pharmacogenomics", type: "alert"})
                    }
                })
        }
    };

    const reset = () => {
        setOpen(false);
        setUpdated(false);
        setDeleted(false);
        setSubmitted(false);
        setInitialized(false);
        setState({causative:false})
    };


    const handleClose = () => {
        reset()
    };

    const onSelectedOption = (name, key) => {

        //works

        let current_data = {...data};

        if(key=== "project" && name.value === "No Project"){
            current_data[key] = "";
        }
        else if(key === "sample"){

            let sample_to_tag = props.samples.find(s => s.sample_id === name.value);

            let participant_to_tag = sample_to_tag?.pheno_id !== undefined
                ? sample_to_tag.pheno_id
                : index_case;

            // change the "index case" to the one that is being tagged
            setIndexCaseID(participant_to_tag);
            current_data[key] = name.value;
            setData(current_data);
        }
        else{
            current_data[key] = name.value;
            setData(current_data);
            if(key === "sample"){
                getProjects(current_data["sample"])
            }
        }

        setDataToPost(current_data);

    };

    // add comments;
    const handleTextChange = (e) => {

        let current_data = {...data};
        current_data["comments"] =  e.target.value;
        setData(current_data);

        setDataToPost(current_data);
    };


    const updatedForm = (status, variant_type) => {

        return <div style={{width: "300px"}}>
            {completeDiagnosis(status, variant_type)}
        </div>

    };

    const editForm = (status, variant_type) => {

        let {url_phenostore } = config.apis_configuration;

        if(variant_type === "SNV_Germline" ||variant_type === "SNV_Somatic"){
            if(status === "Deleted"){
                return <GPAP_Button href={url_phenostore + "/#/summary/" + index_case} target={"_blank"}> Go to Patient
                    Summary </GPAP_Button>
            }
            else {
                return <Fragment>
                    <GPAP_Button
                        onClick={() => { setUpdated(false); setSubmitted(false);}}> Edit Again </GPAP_Button>
                    <GPAP_Button href={url_phenostore + "/#/summary/" + index_case} target={"_blank"}> Go to Patient
                        Summary </GPAP_Button>
                </Fragment>
            }

        }

        else if (variant_type === "Treatment_Somatic"){
            return <Fragment>
            <Box p={1}>
                <Typography variant={"subtitle1"}>
                    Clinical evidence {status} correctly
            </Typography>
            </Box>
            {/*<Box p={1}>
                <GPAP_Tooltip title={"Click here if you want to see a summary of the data stored in PhenoStore for the tagged participant"}>
                    <GPAP_Button href={url_phenostore + "/#/summary/" + allDataApis.participants[0].report_id} target={"_blank"}> Go to Patient Summary </GPAP_Button>
                </GPAP_Tooltip>
            </Box> */}
        </Fragment>
        }


        else if (variant_type === "Pharmacogenomics_Germline"){
            return <Fragment>
            <Box p={1}>
                <Typography variant={"subtitle1"}>
                    Haplotype tag {status} correctly
            </Typography>
            </Box>
       
        </Fragment>
        }


        else{
            return <Fragment>
                <Box p={1}>
                    <WarningBox
                        text={"If you want to edit this tag or if you tagged it by mistake, please go to the PhenoStore record."}/>
                </Box>
                <Box p={1}>
                    <GPAP_Tooltip title={"Click here if you want to edit/delete your CNV tag"}>
                        <GPAP_Button href={url_phenostore + "/#/patient/" + index_case} target={"_blank"}>
                            <MappedText text={"See tag in the "}/> {index_case} PhenoStore record
                        </GPAP_Button>
                    </GPAP_Tooltip>
                    <GPAP_Tooltip title={<MappedText text={"Click here if you want to see a summary of the data stored in PhenoStore for the tagged participant"} />}>
                        <GPAP_Button href={url_phenostore + "/#/summary/" + index_case} target={"_blank"}>
                            <MappedText text={"Go to Participant"}/> {index_case} <MappedText text={"summary"}/>
                        </GPAP_Button>
                    </GPAP_Tooltip>
                </Box>
            </Fragment>
        }



    };


    const completeDiagnosis = (status, variant_type) => {

        let { url_phenostore } = config.apis_configuration;

        return <Fragment>

            <Box p={1}>
                <Typography variant={"subtitle1"}>
                    {variant_type } variant {status} correctly
            </Typography>
            </Box>
            {
                (state.causative && status !== "deleted" && index_case!== null)
                    ? <React.Fragment>
                        <Typography variant={"subtitle1"}>
                            Please complete the participant diagnosis in GPAP PhenoStore
                        </Typography>
                        <GPAP_Button href={url_phenostore  + "/#/complete_diagnosis/" + index_case}
                                     target={"_blank"}> Go to complete case </GPAP_Button>
                    </React.Fragment>
                    : editForm(status, variant_type)
            }
            { (status === "deleted")
                ? <React.Fragment>
                    <Typography variant={"subtitle1"}><MappedText text={" You also deleted the variant from PhenoStore. If you did it by mistake, please tag again."}/>
                    </Typography>
                 </React.Fragment>
                : null
            }
        </Fragment>

    };


    const CNV_found_panel = () => {
        return editForm();
    }

    const submissionOutput = () => {

        let selectedDataRow = getSelectedTableRow();
        let dataType =  getRowDataType();

        if(selectedDataRow){
            let status = "tagged";

            if(updated){
                status = "updated";
            }
            else if(deleted){
                status = "deleted";
            }


            if(dataType === "SNV_Germline"){
                return  <Grid container
                              direction={"column"}>
                    <Grid item lg={12}>
                        {(updated)
                            ? updatedForm(status, dataType)
                            :  <Fragment>
                                {completeDiagnosis(status, dataType)}
                            </Fragment>
                        }

                    </Grid>

                </Grid>
            }

            else if((dataType === "Treatment_Somatic") || (dataType === "Pharmacogenomics_Germline")){
                return <Fragment>{editForm(status, dataType)}</Fragment>
            }


            else{
                return <Fragment>{editForm()}</Fragment>
            }
        }
        else{
            return <Fragment>{editForm()}</Fragment>
        }



    };


    const getProjects = async (sample) => {

        // extract projects;
        // get sample project

        // now collect experiments
        const data_object = {
            "page": 1,
            "fields": ["Participant_ID", "ExperimentID", "LocalExperimentID", "Owner", "project", "erns"],
            "pageSize": 100,
            "sorted": [],
            "filtered": [{"id": "ExperimentID", "value": [sample]}],
            "aggregates": ["project", "tissue", "erns"]
        }

        let response = await experiments_view_new(window.config.apis_configuration.api_data_management, props.token, data_object)
        let json = await response.json()

        let project = (json["items"].length > 0) ? json["items"].map(s => {
            return {sample: s.ExperimentID, project: s.project}
        }) : [];


        let array = (project.length> 0 && project[0].hasOwnProperty("project"))
            ? project[0].project.split(" ").map(s => {

                let val = (s === "") ? "No Project" : s;

                return {label: val, value: val}})
            : []

        setProjects(array);




    }

    const getTagVisibility = () => {


        switch (tagVisibility){
            case "sample_tag":
                return <React.Fragment>
                        <Alert severity={"info"}><MappedText text={tag_dict["sample_tag"]}/></Alert>
                        <Box p={1}>
                            <GPAP_Tooltip title="Delete">
                                <GPAP_Button_light
                                    size={"small"}
                                    aria-label="delete"
                                    onClick={() => deleteVariantData()}
                                    startIcon={ <DeleteIcon />} >
                                    <MappedText text={"Delete your tag"} />
                                </GPAP_Button_light>
                            </GPAP_Tooltip>
                        </Box>
                </React.Fragment>
            case "extra_tag":
                return <Alert severity={"warning"}>{tag_dict["extra_tag"]}</Alert>
            default:
                return null;
        }

    }

    const getOriginOptions = (variantType) => {
        if (variantType === "SNV_Germline") {

            return origin_opts.filter(opt => opt.value !== "somatic");

        } else if (variantType === "SNV_Somatic") {

            return origin_opts.filter(opt => opt.value === "somatic");
        }
        return origin_opts;
    };


    const submissionFormRender = () => {

        let size = 12;


        // order transcripts;


        const index = formOptions["transcripts"].findIndex(el => el.canonical === true);
        if (index > -1) {
            const itemToMove = formOptions["transcripts"].splice(index, 1)[0]; // Remove item from its current position
            formOptions["transcripts"].unshift(itemToMove); // Add the item to the beginning of the array
        }


        return    <Grid container
                        direction={"column"}
                        >
                            <Grid item lg={size}>
                                <Typography variant={"subtitle1"}>
                                    <MappedText text={"When you TAG a variant, the tag will be visible to all users of the platform regardless of the embargo period"} />
                                </Typography>
                                <Typography>
                                    {(variant_flagged)
                                        ?  <React.Fragment>

                                            {getTagVisibility()}

                                        </React.Fragment>
                                        :  <Alert severity={"info"}><MappedText text={"Variant not tagged yet"} /></Alert>}
                                </Typography>
                            </Grid>
                            <Grid item lg={size} style={{marginTop: "2%"}}>
                                {sectionHeader(<MappedText text={"Sample"} />, false)}
                                <SelectStandard
                                    name={"sample"}
                                    defaultValue = {formOptions["samples"].find(opt => opt.value === data.sample)}
                                    handleChange = {onSelectedOption}
                                    style={{ fontSize: "14px"}}
                                    options={formOptions["samples"]}/>
                            </Grid>
                            <Grid item lg={size} style={{marginTop: "2%"}}>
                                {sectionHeader(<MappedText text={"Related to"} />, false)}
                                <SelectStandard
                                    name={"variant_type"}
                                    defaultValue = {formOptions["variant_type"].find(opt => opt.value === data.variant_type)}
                                    handleChange = {onSelectedOption}
                                    style={{ fontSize: "14px"}}
                                    options={formOptions["variant_type"]}/>
                            </Grid>
                            <Grid item lg={size} style={{marginTop: "2%"}}>
                                {sectionHeader(<MappedText text={"Project"} />, false)}
                                <SelectStandard
                                    name={"project"}
                                    defaultValue = { (Array.isArray(projects))
                                        ? projects.find(opt => opt.value === data.project)
                                        : ""
                                    }
                                    handleChange = {onSelectedOption}
                                    style={{ fontSize: "14px"}}
                                    options={projects}/>


                               {/* <SelectStandard
                                    name={"project"}
                                    defaultValue = {formOptions["project"].find(opt => opt.value === data.project)}
                                    handleChange = {onSelectedOption}
                                    style={{ fontSize: "14px"}}
                                    options={formOptions["project"]}/>*/}
                            </Grid>

                            <Grid item lg={size} style={{marginTop: "2%"}}>
                                {sectionHeader(<MappedText text={"Gene"} />, false)}
                                <SelectStandard
                                    name = "genes"
                                    defaultValue = {formOptions["genes"][0]}
                                    style={{ fontSize: "14px"}}
                                    options={formOptions["genes"]}
                                    handleChange = {onSelectedOption}

                                />
                            </Grid>

                            <Grid item lg={size} style={{marginTop: "2%"}}>
                                {sectionHeader(<MappedText text={"Type of variant - Please review this field"} />, false)}
                                    <SelectStandard
                                    name={"sv_type"}
                                    defaultValue = {formOptions["sv_type"].find(opt => opt.value === data.sv_type)}
                                    handleChange = {onSelectedOption}
                                    style={{ fontSize: "14px"}}
                                    options={formOptions["sv_type"]}/>
                            </Grid>
                            <Grid item lg={size} style={{marginTop: "2%"}}>
                                {sectionHeader(<MappedText text={"Transcript"} />, false)}
                                <CustomSelect
                                    name = "transcript"
                                    defaultValue = {formOptions["transcripts"].find(opt => opt.value === data.transcript)}
                                    handleChange = {onSelectedOption}
                                    style={{ fontSize: "14px"}}
                                    customOptionLabel={customOptionLabel}
                                    options={formOptions["transcripts"]}/>
                            </Grid>
                            <Grid item lg={size} style={{marginTop: "2%"}}>
                                {sectionHeader(<MappedText text={"Inheritance"} />, true)}
                                <SelectStandard
                                    name = "inheritance"
                                    defaultValue = {(variant_flagged) ? formOptions["inheritance_opts"].find(opt => opt.value === data.inheritance) : " " }
                                    handleChange = {onSelectedOption}
                                    style={{ fontSize: "14px"}} options={formOptions["inheritance_opts"]}/>
                            </Grid>
                            <Grid item lg={size} style={{marginTop: "2%"}}>
                                {sectionHeader(<MappedText text={"Origin"} />, false)}
                                <SelectStandard
                                    name = "origin"
                                    defaultValue = { getRowDataType() === "SNV_Somatic"
                                    ? origin_opts.find(opt => opt.value === "somatic") 
                                    : (formOptions["origin"].find(opt => opt.value === data.origin))
                                    }                                       
                                    handleChange={onSelectedOption}
                                    style={{ fontSize: "14px" }}
                                    options={getOriginOptions(getRowDataType())}/>
                            </Grid>
                            <Grid item lg={size} style={{marginTop: "2%"}}>
                                {sectionHeader(<MappedText text={"Zygosity"} />,false)}
                                <SelectStandard
                                    name = "zygosity"
                                    defaultValue = {(variant_flagged) ? formOptions["zygosity_opts"].find(opt => opt.value === data["zygosity"]) : " " }
                                    handleChange = {onSelectedOption}
                                    style={{ fontSize: "14px"}} options={formOptions["zygosity_opts"]}/>
                            </Grid>
                            <Grid item lg={size}  style={{marginTop: "2%"}}>
                                {sectionHeader(<MappedText text={"Clinical Significance"} />, true)}
                                <SelectStandard
                                    name = "significance"
                                    defaultValue = {(variant_flagged) ? formOptions["significance_opts"].find(opt => opt.value === data["significance"]) : " " }
                                    handleChange = {onSelectedOption}
                                    style={{ fontSize: "14px"}} options={formOptions["significance_opts"]}/>
                            </Grid>
                            <Grid item lg={size}>
                                <Box p={2}>
                                    <TextField
                                        style={{width: "100%"}}
                                        id="standard-multiline-static"
                                        label= <MappedText text={"Interpretation"} />
                                        multiline
                                        rows={4}
                                        onChange = {handleTextChange}
                                        defaultValue={data.comments}
                                    />
                                </Box>
                            </Grid>
                            <Grid item lg={size}>
                                {sectionHeader(<MappedText text={"Is this the Causative Variant?"} />, false)}
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={state.causative} onChange={handleCausativeChange} name="causative" />}
                                        label= <MappedText text={"Causative Variant (confirmed as the cause of the primary disorder)"}/>
                                    />
                                </FormGroup>
                            </Grid>
                    </Grid>
    };



    const treatmentFormRender = () => {

        let size = 12;
        return    <Grid container direction={"column"}>
                        <Grid item lg={size}>
                        {treatment_flagged ? (
                                <Grid item lg={size} style={{ backgroundColor: '#c6dbef', padding: '10px', borderRadius: '5px' }}>
                            <Typography variant={"subtitle1"}>
                            <MappedText text={"This clinical evidence has already been tagged and will be displayed in the "}/> <span style={{ fontWeight: 'bold' }}><MappedText text={"Report"}/></span> <MappedText text={"module. Editing this tag will modify the interpretation comment."}/>
                            </Typography>
                            </Grid>
                        ) : (
                            <Typography variant={"subtitle1"}>
                            <MappedText text={"Tagging a clinical evidence will make it available to add in a "}/> <span style={{ fontWeight: 'bold' }}><MappedText text={"Report"}/></span>.
                            </Typography>
                        )}
                        </Grid>

                        <Grid item lg={size}>
                            <Box p={2}>
                                <TextField
                                    style={{width: "100%"}}
                                    id="standard-multiline-static"
                                    label=<MappedText text={"Interpretation"}/>
                                    multiline
                                    rows={4}
                                    onChange = {handleTextChange}
                                    defaultValue={data.comments}
                                />
                            </Box>
                        </Grid>
                        
                        {treatment_flagged && (
                        <Grid item lg={size}>
                          <GPAP_Button_light
                                    size={"small"}
                                    aria-label="delete"
                                    onClick={() => deleteData(delete_treatment, "Treatment_Somatic")}
                                    startIcon={ <DeleteIcon />} >
                                    <MappedText text={"Delete your tag"} />
                          </GPAP_Button_light>
                        </Grid>
                        )}

                          
                    </Grid>
    };


    const pharmacogenomicsFormRender = () => {

        let size = 12;
        return    <Grid container direction={"column"}>
                        <Grid item lg={size}>
                        {pharmacogenomics_flagged ? (
                                <Grid item lg={size} style={{ backgroundColor: '#c6dbef', padding: '10px', borderRadius: '5px' }}>
                            <Typography variant={"subtitle1"}>
                            <MappedText text={"This haplotype has already been tagged and will be displayed in the "}/> <span style={{ fontWeight: 'bold' }}><MappedText text={"Report"}/></span><MappedText text={" module. Editing this tag will modify the interpretation comment."}/>
                            </Typography>
                            </Grid>
                        ) : (
                            <Typography variant={"subtitle1"}>
                            <MappedText text={"Tagging a haplotype will make it available to add in a "}/><span style={{ fontWeight: 'bold' }}><MappedText text={"Report"}/></span>.
                            </Typography>
                        )}
                        </Grid>

                        <Grid item lg={size}>
                            <Box p={2}>
                                <TextField
                                    style={{width: "100%"}}
                                    id="standard-multiline-static"
                                    label=<MappedText text={"Interpretation"}/>
                                    multiline
                                    rows={4}
                                    onChange = {handleTextChange}
                                    defaultValue={data.comments}
                                />
                            </Box>
                        </Grid>

                        {pharmacogenomics_flagged && (
                        <Grid item lg={size}>
                          <GPAP_Button_light
                                    size={"small"}
                                    aria-label="delete"
                                    onClick={() => deleteData(delete_pharmacogenomics, "Pharmacogenomics_Germline")}
                                    startIcon={ <DeleteIcon />} >
                                    <MappedText text={"Delete your tag"} />
                          </GPAP_Button_light>
                        </Grid>
                        )}


                    </Grid>
    };

    const showContent = () => {

        let variant = getSelectedTableRow();

        if(variant){
          
            let dataType = getRowDataType();

            if(dataType === "SNV_Germline"){
                return submissionFormRender();
            }
 
            //Treatments
            else if ("Therapies" in variant || "Evidence_Source_link" in variant){
               return treatmentFormRender();
            }
            
            //Pharmacogenomics
            else if ("Haplotype1" in variant || "pgx_uri" in variant){
                return pharmacogenomicsFormRender();
             }

            else{
                let CNV_FOUND = variantFinder(variant, [], props.tagged_variants, "CNV");

                if(CNV_FOUND !== undefined && CNV_FOUND.length !== 0 ){
                    return <Fragment>
                        {CNV_found_panel()}
                    </Fragment>
                }
                else{
                    return submissionFormRender();
                }
            }
        }
        else{
            return null;
        }

    }

    const handleCloseDialogError = () => {


        setOpenDialogError(false);
        setErrorInfo({
            title: "",
            description: "",
            type: "warning"
        })

    }

    const getButtons = () => {

        let dataRow = getSelectedTableRow();
        let dataType = getRowDataType();

        if(dataType === "SNV_Germline" || dataType === "SNV_Somatic" ){
            return <React.Fragment>
                <Button onClick={handleClose} color="primary">
                    <MappedText text={"Cancel"}/>
                </Button>
                <Button onClick={handleSubmit} color="primary" autoFocus>
                    <MappedText text={"Submit"}/>
                </Button>
            </React.Fragment>
        }
        else{
            if(dataRow){
                let found = variantFinder(dataRow, [], props.tagged_variants, dataType);
                // if the variant is found, there is no other action than closing the dialog
                if(found!== undefined && found.length !== 0 ){
                    return  <React.Fragment>
                        <Button onClick={handleClose} color="primary">
                            <MappedText text={"Close"}/>
                        </Button>
                    </React.Fragment>
                }
                else{
                    return <React.Fragment>
                        <Button onClick={handleClose} color="primary">
                            <MappedText text={"Cancel"}/>
                        </Button>
                        <Button onClick={handleSubmit} color="primary" autoFocus>
                            <MappedText text={"Submit"}/>
                        </Button>
                    </React.Fragment>
                }
            }
            else{
                return null;
            }

        }

    }


    const sectionHeader = (text, required) => {
        return <div className={"section_header"}>
            {text}
            {(required) ? <div className={"required"}><MappedText text={"Required"}/></div> : null }
            </div>
    };

    let dataRow = getSelectedTableRow();
    let dataType = getRowDataType();
    let found = false;
    if(dataRow){

        found = variantFinder(getSelectedTableRow(), [], props.tagged_variants, dataType);

    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <GPAP_Dialog_Title id="alert-dialog-title" onClose={handleClose}>
                    {
                        getTitle(tagVisibility, dataType, found )
                    }
                </GPAP_Dialog_Title>
                <DialogContent>
                    {
                        (!submitted && !updated && !deleted)
                            ? showContent()
                            : submissionOutput()
                    }
                </DialogContent>
                <DialogActions>
                    {
                        (!submitted && !updated && !deleted)
                            ? getButtons()
                            : <GPAP_Button onClick={handleClose}>
                                <MappedText text={"Close"}/>
                            </GPAP_Button>
                    }
                </DialogActions>
            </Dialog>
            <GPAP_Dialog
                open={openDialogError}
                handleClose={handleCloseDialogError}
                title={errorInfo.title}
                text={errorInfo.description}
                onConfirmText={"ok"}
                onExitText={false}
                onConfirmAction={handleCloseDialogError}
                type={errorInfo.type}
            />
            <GPAP_Tooltip title= {<MappedText text={"Tag variants in the GPAP database"}/>}>
                <GPAP_Button_LightOrange
                    style={{
                        height: '28px',
                        boxShadow: 'none', 
                        textTransform: 'none',
                        color: 'white',
                      }}
                    size={"small"}
                    onClick={handleClickOpen}
                    variant="contained" startIcon={<FlagIcon />}>
                    <MappedText text={"Tag"}/>
                </GPAP_Button_LightOrange>
            </GPAP_Tooltip>
        </Fragment>
    );
}


function mapStateToProps (state) {


    if(state.authorization!==undefined)
    {
        return { studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            //selected_variant: state.selected_variant,
            selected_table_rows: state.selected_table_rows,
            samples: state.sample_list.samples,
            token: state.authorization.token,
            user: state.authorization.preferredName,
            patients: state.patients
        }
    }

}

// to send data from MainPanel component to the Store;



// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    studyActions: bindActionCreators(StudyActions, dispatch)
});



TagDialog.propTypes = {
    tagged_variants: PropTypes.arrayOf(PropTypes.string).isRequired,
    annotations: PropTypes.object.isRequired,
    annotate_variants: PropTypes.func.isRequired,
    getAllTaggedVariants: PropTypes.func.isRequired,
    dataType: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TagDialog);
