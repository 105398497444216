

import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import React from "react";
import Variant_Info from "../../../../variant-tabs/components/variant-info/Variant_Info";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import useTableStyles from "./ParticipantTable/styles_table";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";


export const GPAP_Table_Toolbar = (props) => {
    const classes = useTableStyles();
    const { numSelected, selected, type } = props;


    const renderVariantInfo = () => {

        return  <React.Fragment>
            <div style={{width: "99%"}}>
                <Variant_Info
                    tagged_variants={props.tagged_variants}
                    selected_variant={selected}
                    all_tagged_variants = { props.all_tagged_variants }
                    annotate_variants = {props.annotate_variants}
                    getAllTaggedVariants = {props.getAllTaggedVariants }
                    passAnnotations={ props.passAnnotations }  // not really used
                    dataType={props.dataType}
                />
            </div>
            <Box p={2}>
                {(props.participants.length !== 0)
                    ? <Typography className={classes.title} color="inherit" variant="subtitle1"
                                  component="div">{props.participants.length} Participants Selected : {props.participants[0]}</Typography>
                    : null
                }
            </Box>
        </React.Fragment>

    }

    const renderSelected = () => {
         return (<React.Fragment>
                 <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                     {(type === "search_across") ? <MappedText text={"Variants associated to experiments"}/>  : <MappedText text={"Variants"}/>}
                 </Typography>
             </React.Fragment>)
    }

    const renderContent = () => {

        return renderVariantInfo();
      /*  if(numSelected>0){
            return renderVariantInfo();
        }
        else{
            //return renderSelected();
            return null
        }*/
    }


    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}>
            {renderContent()}
        </Toolbar>
    );
};

GPAP_Table_Toolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};