import React from "react";
import GenomeCoverage from '../components/GenomeCoverageTable.js';
import GenotypePhenotype from '../components/GenotypePhenotypeTable.js';
import VariantMetrics from '../components/VariantMetricsTable.js';
import Pharmacogenomics from '../components/Pharmacogenomics.js';
import CoverageGenes from '../components/CoverageGenes.js';
import { useSelector } from 'react-redux';
import MappedText from "../../../../shared-components/mapText.js";
import { Alert, Typography } from '@mui/material';
import AlertInfoViews from "../../../../shared-components/AlertInfo.js";

export function sectionConfigGermline(clinicalReportName, setClinicalReportName, additionalData, annexesFields, updateAnnexesFields ) {
    const { allDataApis } = useSelector((state) => state.generaltable);


    let coverageTitle = '';
    let isCoverageAvailable = true; 


    const emptyGenes = {label: 'No genes were targeted'};

    switch (allDataApis?.experiments?.[0]?.library_strategy) {
        case 'WGS':
            coverageTitle = 'Genome Coverage Metrics';
            break;
        case 'WES':
            coverageTitle = 'Exome Coverage Metrics';
            break;
        case 'Panels':
            coverageTitle = 'Panel Coverage Metrics';
            break;
        default:
            coverageTitle = 'Coverage Metrics';
            isCoverageAvailable = false; 
    }

    return [
        {
            columns: 1,
            render: () => (
                <Typography style={{ fontSize: '14px', marginBottom: '20px' }}>
                    <MappedText text="The following annexes complete the report, which should be reviewed as a whole." />
                </Typography>
            ),
        },
        {
            title: 'Annex 1: Clinical record',
            columns:1,
            fields: [
                { title: 'Cancer Type', value: allDataApis?.participants?.[0].cancer_type  },
                { title: 'Family History', component: 'TextField',
                    value: annexesFields["family_history"], setValue: (val) => updateAnnexesFields("family_history", val)  },
            ],
        },
        {
            title: 'Annex 2: Methodology',
            fields: [
                { 
                    title: 'Test method', 
                    value: `${allDataApis?.experiments?.[0].library_strategy} using the ${allDataApis?.experiments?.[0].kit }` 
                },
                {
                    columns:12,
                    title: 'Type of variants assessed',
                    component: 'MultiSelect',
                    options: [
                        'Single nucleotide variants (SNVs)',
                        'Small insertions and deletions (InDels)',
                        'Copy number variants (CNVs)',
                        'Structural variants (SVs)'
                    ],
                    value: annexesFields["type_of_variants_assessed"],
                    setValue: (val) => updateAnnexesFields("type_of_variants_assessed", val),
                    defaultValue: [
                        'Single nucleotide variants (SNVs)',
                        'Small insertions and deletions (InDels)',
                        'Copy number variants (CNVs)',
                        'Structural variants (SVs)'
                    ]
                },
                { title: 'Sample type', value: allDataApis?.samples?.sample_type  },
                { title: 'Sample received', component: 'TextField',
                    value: annexesFields["sample_received"], setValue: (val) => updateAnnexesFields("sample_received",val)  },
                { title: 'Date of sample collection', value: allDataApis?.samples?.date_of_sample_collection },
            ],
        },
        {
            subTitle: 'Sample processing',
            fields: [
                { title: 'Centre', value: allDataApis?.centre },
                { title: 'Sequencing platform', value: allDataApis?.sequencing_platform },
                { title: 'Fragment (size and type)', value: allDataApis?.fragment },
            ],
        },
        {
            subTitle: 'Bioinformatic analysis',
            fields: [
                { title: 'Human reference genome', value: allDataApis?.human_reference_genome },
                { title: 'Aligner', value: allDataApis?.aligner },
                { title: 'Mark duplicates', value: allDataApis?.mark_duplicates},
                { title: 'Base recalibration', value: allDataApis?.base_recalibration },
                { title: 'Smart variants caller', value: allDataApis?.smart_variants_caller },
                { title: 'Analysis pipeline', value: allDataApis?.analysis_pipeline},
            ],
        },
        {
            title: 'Annex 3 : Quality control metrics',
            render: () => (
                <AlertInfoViews text={"The fields in this section have to be filled in manually after downloading the document. Automatic synchronisation coming soon."}/>
            ),
        },
        {
            subTitle: coverageTitle,
            render: () => {
                switch (allDataApis?.experiments?.[0]?.library_strategy) {
                    case 'WGS':
                        return <GenomeCoverage data={additionalData.genomeCoverageData} />;
                    case 'WES':
                        return <GenomeCoverage data={additionalData.genomeCoverageData} />;
                    case 'Panels':
                        return <GenomeCoverage data={additionalData.genomeCoverageData} />;
                    default:
                        return (
                            <Typography style={{ marginBottom: '20px' }}>
                                Unavailable coverage data
                            </Typography>
                        );
                }
            }
        },

        isCoverageAvailable && {
            caption: 'C10/C15/C20: percentage of bases covered by at least 10, 15 and 20 reads, respectively.',
        },
        {
            fields: [
                {
                    title: 'List of diagnostically targeted genes',
                    component: 'TextField',
                    value: annexesFields["list_of_diag_genes"] || emptyGenes.label,
                    setValue: (val) => updateAnnexesFields("list_of_diag_genes", val)
                }
            ],
        },
        {
            subTitle: 'Coverage of the genes of interest (RefSeq coding sections)',
        },
        {
            body: 'All genes have a C10 of 90 or more, except for:'
        },
        {
            render: () => <CoverageGenes data={additionalData.coverageGenesData} />
        },
        {
            title: 'Annex 4 : Variant metrics and genotype-phenotype associations',
        },
        {
            subTitle: 'Variant metrics',
            render: () => <VariantMetrics data={additionalData.variantMetricsData} />
        },
        {
            caption: 'GT: genotype; GQ: genotype quality; DP: depth of coverage (total reads); REF count: reads supporting reference allele; ALT count: reads supporting alternative allele; AAF: alternative allele frequency',
        },
        {
            subTitle: 'Genotype-phenotype associations',
            render: () => <GenotypePhenotype data={additionalData.genotypePhenotypeData} />
        },
    ].filter(Boolean);
}
