import React from 'react';
import { Link } from 'react-router-dom';
import CustomTab from "../../../general-table/pages/CustomTab";
import Tabs from "@mui/material/Tabs";

const TabNavigation = ({ classes, isReportingPage, handleChange, value }) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      className={classes.tabs}
      sx={{
        '& .MuiTabs-indicator': {
          backgroundColor: '#3e699e',
        },
      }}
    >
      {!isReportingPage && (
        <CustomTab
          label="Interpretation Requests"
          component={Link}
          to="/dir_list"
          className={classes.tab}
          sx={{ fontSize: '16px', fontWeight: '400', width: '210px' }}
        />
      )}
      <CustomTab
        label="Docs"
        component={Link}
        to="/reporting_doc"
        target="_blank"
        className={classes.tab}
        sx={{ fontSize: '16px', fontWeight: '400', width: '100px' }}
      />
      <CustomTab
        label="Contact"
        component={Link}
        to="/contact"
        className={classes.tab}
        sx={{ fontSize: '16px', marginRight: '30px', fontWeight: '400', width: '100px' }}
      />
    </Tabs>
  );
};

export default TabNavigation;
