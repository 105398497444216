import React from "react";
import { TextField } from "@mui/material";
import OptionSelect from "../selects/OptionSelect";
import DateFilter from "../dates/DateFilter";


function formatOption(option) {
  if (!option) return option;
  let words = option.replace(/_/g, ' ').toLowerCase().split(' ');
  if (words.length > 0) {

    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  }
  return words.join(' ');
}

function DefaultColumnFilter({
    column: {
      filterValue,
      preFilteredRows,
      setFilter,
      id,
      filterType
    },
  }) {
    if (filterType && filterType.type === 'none') {
      return null;
    }
    if (filterType.type === "select") {

      const options = new Map();
      preFilteredRows.forEach(row => {
        const originalValue = row.values[id];
        const formattedValue = formatOption(originalValue);
        options.set(formattedValue, originalValue);
      });
  

      const optionsForAutoComplete = Array.from(options).map(([label, value]) => ({
        label,   
        value: value 
      }));
  
      return (
        <>
          <OptionSelect
            optionType={filterType.option}
            options={optionsForAutoComplete}
            setFilter={setFilter}
            filterValue={filterValue}
          />
        </>
      );
    }

    if (filterType.type === "date") {
      return <DateFilter setFilter={setFilter} />;
    }

    return (
      <TextField
        id="standard-basic"
        size={"small"}
        placeholder={`Search...`}
        variant="outlined"
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      />
    );
  }

export default DefaultColumnFilter;
