import { createSlice } from "@reduxjs/toolkit";

export const tableSlice = createSlice({
  name: "generaltable",
  initialState: {
    allState: {},
    rowIcons: [],
    allDataApis: {},
    isLoadingApis: false,
    isToggle: true,
    isOpenUpload: false,
    isUploadFileTable: false,
    refresh: false,
    reportType: null,
  },
  reducers: {
    setTable: (state, action) => {
      const { option, value } = action.payload;
      state[option] = value;
    },
    toggleRefresh: (state) => {
      state.refresh = !state.refresh;
    },

  },
});

export const { setTable, toggleRefresh } = tableSlice.actions; 
export default tableSlice.reducer;
