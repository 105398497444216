import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../../../../../study-page/create-study/analysis-page/reducers/sample_actions";
import {connect} from "react-redux";
import Select from "react-select";
import {GPAP_Button} from "../../../../../../../../../gpap-lib/components/Button_Collection";
import Box from "@material-ui/core/Box";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import {Alert} from "@material-ui/lab";
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import {deleteFilterSet, get_all_filter_sets} from "../../../../../../../../../services/api/manage_study";
import DeleteIcon from "@material-ui/icons/Delete";
import {TouchApp} from "@material-ui/icons";
import {LinearProgress} from "@material-ui/core";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText.js";

import * as FilterSetActions from "../../../../../../../../../actions/index.js"


const useMount = (callback) => {
    React.useEffect(callback, [])
}

function FilterSelector(props){

    const [selected, setSelected] = useState(false);
    const [open, setOpen] = useState(true);
    const [applied, setApplied] = useState(false);
    const [filter_set_list, setFilterSetList] = useState([]);
    const [deleted, setDeleted] = useState(false);

    useMount(() => {

        updateFilterList();

    })

    useEffect(() => {
        if (props.resetSignal) {
            handleReset();
        }
    }, [props.resetSignal])


    const updateFilterList = () => {

        get_all_filter_sets(window.config.apis_configuration.api_nextgpap_server, props.token)
            .then(r => r.json())
            .then(function(json){
                let list_names = json.data.filter(s => s.filter_set_name!=="").map(p => p.filter_set_name)

                // if filter sets in DB, avoid adding the default ones..

                //let defaults = JSON.parse(JSON.stringify(props.filters));

                let defaults = [];
                let to_add = defaults.filter(s => !list_names.includes(s.filter_set_name));

                let filtersList = (Array.isArray(to_add)) ? to_add: [];
                filtersList = filtersList.concat(json.data.filter(s => s.filter_set_name!==""));
                setFilterSetList(filtersList);
            })

    }


    useEffect(function(){

        if(props.message){

            // update filter set-list;
            updateFilterList();

        }

    }, [props.message])

    const handleChange = (evt) => {
        setSelected(evt);
        setApplied(false);
    };

    // filter set reset;
    const handleReset = () => {

        setSelected(false);
        setApplied(false);
        props.reset();
        props.filterSet_Actions.reset_filter_set();

    };

    const handleSelect = () => {

        let f_selected =  filter_set_list.find(f => f.id === selected.value);
        // pass filter set to load:
        let new_filter = {...f_selected};
        
        props.setFilterSet(new_filter);

        // setup new filter;
        props.filterSet_Actions.set_filter_set( new_filter );
        setApplied(true);

    };

    const handleDelete = () => {

        let f_selected =  filter_set_list.find(f => f.id === selected.value);

        let del_obj = {"filter_set_id": f_selected.id};

        deleteFilterSet(window.config.apis_configuration.api_nextgpap_server, props.token, del_obj)
            .then(r => r.json())
            .then(function(json){
                if(json["message"] === "Success"){
                    updateFilterList();
                    setDeleted(true);
                    setSelected(false);
                    setTimeout(() => { setDeleted(false)}, 2000);
                }
            })
    }

    // process filters -name

    const getFilterOptions = () => {
        return filter_set_list.map(s => {
            let label = (s.filter_set_name!== "") ? s.filter_set_name : "Filter - " + s.id;
            return {label:label , value: s.id}
        });
    }

    const getAlertText = () => {
        let filterName = selected.label;
        if(Object.keys(props.currentFilterSet).length ===0 && filter_set_list.length!==0){
            // reset;
            return <MappedText text={`The ${filterName} filter set has been applied`}/>
        }
        else{
            return <MappedText text={"Filters applied and added to the current selection. If you want to change the filters or only apply this filter set, click on \"RESET FILTERS\" first."}/>;
        }

    }

    const getText = () => {
        return (applied) ? "Applied" : <MappedText text={"Apply"}/>;
    }

    const showDeleteBtn = ()=> {

        return (!window.location.href.includes("new_study"))
            ? <GPAP_Tooltip title={<MappedText text={"Click here if you want to delete the Filter-Set from the database. This is an irreversible action. "}/>}>
                <GPAP_Button disabled = {!selected}
                             size={"small"}
                             onClick={() => handleDelete()} startIcon={<DeleteIcon/>}><MappedText text={"Delete"}/></GPAP_Button></GPAP_Tooltip>
            :  null

    }

    return <div style={{ float: "left", width: "100%" }}>
        <Box p={1} style={{borderBottom: "1px solid #bdbdbd"}}>
            <Box p={0}>
             <GPAP_Tooltip title= {<MappedText text={"GPAP Predefined Filters are curated by the GPAP team"}/>}>
                 <div className={"title_section"} onClick={() => setOpen(!open) }>
                    <div style={{float: "left"}}>
                        <IconButton
                            style={{padding: "0px"}}
                            onClick={() => setOpen(!open)  } >
                            {open ? <ExpandLess /> : <ExpandMore style={{order: -1,
                                transform: "rotate(-90deg)"}}/>}
                        </IconButton> </div>
                    <span > <MappedText text={"Select a predefined filter set"}/></span>
                </div>
             </GPAP_Tooltip>
            </Box>
            <Collapse in={open}>
                <div style={{width: "100%"}}>
                    <Box p={2}>
                        {(!deleted)
                            ?  <Select
                                id='selector_filters'
                                onChange={handleChange}
                                value={(selected) ? selected : null}
                                options={getFilterOptions()}
                                isSearchable = {true}
                                style={{ fontSize: "13px"}}/>
                            : <LinearProgress/> }
                        <GPAP_Button disabled = {!selected||applied}
                                     size={"small"}
                                     onClick={() => handleSelect()}
                                     startIcon={<TouchApp/>}>{getText()}</GPAP_Button>
                        {/* <GPAP_Tooltip title={<MappedText text={"Reset the applied filter-set"}/>}><GPAP_Button disabled = {!selected}
                                     size={"small"} onClick={() => handleReset()}
                                     startIcon={<ClearIcon/>}><MappedText text={"Reset"}/></GPAP_Button>
                        </GPAP_Tooltip> */}
                   {/*     {showDeleteBtn()}*/}
                        {(applied)
                            ? <Alert severity={"info"} >{getAlertText()}</Alert>
                            : null
                        }
                        {(deleted)
                            ? <Alert severity={"success"}><MappedText text={"Filter Set deleted"}/></Alert>
                            : null
                        }
                    </Box>
                </div>
            </Collapse>
        </Box>
    </div>


}

//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    return {
        token: state.authorization.token,
        currentFilterSet: state.currentFilterSet
    }
}


// to send data from MainPanel component to the Store;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    filterSet_Actions: bindActionCreators(FilterSetActions, dispatch)
});




export default connect(mapStateToProps,mapDispatchToProps)(FilterSelector);