import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Box, Grid, Typography } from "@mui/material";
import MappedText from "../../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";

export default function ComponentSwitch({ states }) {
  const changeState = (e) => {
    states.setIsCheck(!states.isCheck);
    states.setRowIdSelected(null)
  };

  return (
    <Box sx={{ marginTop: '-5px'}}>
    <FormGroup>
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={5} textAlign="right">
        <Box mr={2}>
          <Typography variant="body1">
            <MappedText text={"Complete view"} />
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2} textAlign="center">
        <FormControlLabel
          control={<Switch sx={{ mx: 1 }} onClick={changeState} />}
          label=""
        />
      </Grid>
      <Grid item xs={5} textAlign="left">
        <Box ml={1}>
          <Typography variant="body1">
          <MappedText text={"Split by genes"} />
          </Typography>
        </Box>
      </Grid>
    </Grid>
    </FormGroup>
  </Box>
  );
}