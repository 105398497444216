import React, {Component} from "react";

import {bindActionCreators} from "redux";
import "./genotype-form.css"
import * as Actions from "../../../clinical-info/patient_actions";
import * as Sample_Actions from "../../../../reducers/sample_actions";
import {connect} from "react-redux";
import GenotypeRow from "./GenotypeRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {Divider, FormControlLabel, FormGroup} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import {inheritances} from "../../../../../../../../config-files/config_inheritances";
import * as Study_Actions from "../../../../../../../dashboard/reducers/study_actions";
import * as Inheritance_Actions from "../../../../reducers/inheritance_actions";
import {calculateSettings} from "../../helpers/calculateSettings";
import {filterConditions} from "../../helpers/filterConditions";
import {
    getCurrentAnalysis
} from "../../../../../../../dashboard/components/genomics-dashboard/components/results/components/variant-table/getAnnotations";
import GenotypeToolsRow from "./GenotypeTools";
import MappedText from "../../../clinical-info/mapText";

export function createConfiguration(setting, inheritance_key, configuration){
    let gt_settings = [];
    Object.keys(setting.gt).forEach(d => gt_settings = gt_settings.concat(setting.gt[d]));
    return {
        inheritance_key: inheritance_key,
        setting: gt_settings,
        label: configuration.label
    }
}

function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}


export function mapSamples(patients, samples_all){

    let copiedArray = [...patients];

    copiedArray.forEach(function(item,i){
        if(item.index === "Yes"){
            copiedArray.splice(i, 1);
            copiedArray.unshift(item);
        }
    });


    // iterate through all samples

    return samples_all.map(sample => {
        // Find corresponding patient
        const patient = patients.find(pat => sample.pheno_id === pat.report_id);

        if (!patient) return; // Skip if no corresponding patient is found

        // Determine whether the patient is an index
        const indexBool = (patient.index === "Yes") && (sample.sample_type === undefined || sample.sample_type === "index");

        return {
            "case": patient.index === "Yes" ? "index" : sample.sample_type || "other",
            "id": sample.sample_id,
            "sex": patient.sex,
            "report_id": patient.report_id,
            "index": patient.index,
            "affected": patient.affectedStatus === "Yes" || patient.affectedStatus === "Affected",
        };
    });



}


// TODO: change this to material UI Grid;

class GenotypeForm extends Component {

    constructor(props){
        super(props);
        this.state = {
            render:true,
            open: false,
            c_het: false
        };

        this.renderCheckboxes = this.renderCheckboxes.bind(this);
        this.renderHeaders = this.renderHeaders.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.compoundHet= this.compoundHet.bind(this);
        this.handleCHet = this.handleCHet.bind(this);
        this.createChekBoxes = this.createChekBoxes.bind(this);
        this.refreshExperiments = this.refreshExperiments.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.inheritance_key !== this.props.inheritance_key){
            this.setState({render:true})

        }
        else{
            this.setState({render:false})
        }

    }

    setOpen(){
        this.setState({open: !this.state.open})
    }


    // TODO: find a better solution that this;
    shouldComponentUpdate(nextProps, nextState, nextContext) {

        return (
            nextProps.inheritance_key !== this.props.inheritance_key
            || nextState.open !== this.state.open
            || this.props.samples !== nextProps.samples
        );
    }

    handleCHet(){

        // set AR for C-het
        let key = "ar";
        let analysis_ids, selected;
        if(this.state.c_het){
            // remove c_het if applied...
            this.props.samples_actions.removeGenotypeFromSamples("ar", 'compound heterozygosity');
            key = "custom";
            selected =  inheritances.find(inheritance => inheritance.key === key);
            analysis_ids = selected.config.filter(s => s.label === 'Custom Inheritance').map(s => {
                return { id: s.id, label: s.label}
            });
        }
        else{
            // apply c-het
            this.props.samples_actions.removeGenotypeFromSamples("custom", 'Custom Inheritance');
            selected =  inheritances.find(inheritance => inheritance.key === key);
            analysis_ids = selected.config.filter(s => s.label === 'compound heterozygosity').map(s => {
                return { id: s.id, label: s.label}
            });
        }

        let settings = {
            analysis_ids: analysis_ids
        };


        this.props.inheritance_actions.setInheritance(key);
        this.props.study_actions.setStudySettings(settings);

        this.setState({c_het: !this.state.c_het})
    }

    getCheckboxes (samples, configuration, genotypes) {

        let props = this.props;
        let samples_actions = props.samples_actions;
        let inheritance_key =  (this.state.c_het) ? "ar" : props.inheritance_key;

        // assign the gt configuration to the samples..


        // setting configuration;

        const settings_id = Math.floor(Math.random() * 200);


        return samples.map(function (sample_case) {
            let family_case = genotypes.find(gt => gt.case === sample_case.case);
            let setting = [];
            let gt_sample_configuration;
            if (family_case.case === "index") {
                setting = family_case.setting[0];
                gt_sample_configuration = createConfiguration(setting, inheritance_key, configuration);
                // add parameters;
                let ad_low_high = calculateSettings(sample_case, gt_sample_configuration.setting)
                gt_sample_configuration["dp"] = 10;
                gt_sample_configuration["gq"] = 30;
                gt_sample_configuration["ad_low"] = ad_low_high[0];
                gt_sample_configuration["ad_high"] = ad_low_high[1];
                samples_actions.setGenotype(sample_case.id, gt_sample_configuration, sample_case.report_id, true, settings_id)
            } else {
                // set setting;
                setting = family_case.setting.find(member => member.affected === sample_case.affected);

                gt_sample_configuration = createConfiguration(setting, inheritance_key, configuration);

                let ad_low_high = calculateSettings(sample_case, gt_sample_configuration.setting)
                //console.log(ad_low_high);
                gt_sample_configuration["dp"] = 10;
                gt_sample_configuration["gq"] = 30;
                gt_sample_configuration["ad_low"] = ad_low_high[0];
                gt_sample_configuration["ad_high"] = ad_low_high[1];
                samples_actions.setGenotype(sample_case.id, gt_sample_configuration, sample_case.report_id, false, settings_id)

            }

            let gt = setting.gt[sample_case.sex];
            return {
                gt: gt,
                sample_case: sample_case,
                editable: props.editable,
                gt_parameters: gt_sample_configuration,
                configuration_label: configuration.label,
                settings_id: settings_id
            }
        });
    }


   /// create data to render the checkboxes;
    renderCheckboxes(genotypes, configuration){

        let props = this.props;
        let samples_all = props.samples;
        
        let patients = props.patients.length !== 0 ? props.patients : props.inst_patients;

        // map affected status from patients information...
        let samples = mapSamples(patients, samples_all);

        let my_samples = [];
        let WITH_CONDITIONS = Object.keys(configuration.condition).length>0;

        if(WITH_CONDITIONS){
            my_samples = filterConditions(samples,configuration.condition);
            if(my_samples.length>0){
                 // return checkbox
                return this.getCheckboxes(samples, configuration, genotypes)
            }
        }
        else{
            return this.getCheckboxes(samples, configuration, genotypes)
        }

    }

    refreshExperiments(){

        // restore experiment list
        const self = this;
        let currentAnalysis = getCurrentAnalysis(this.props.studySettings, this.props.studyBucket);

        let samples_analysis = currentAnalysis.analysis.samples;

        samples_analysis.forEach(function (sample) {
            let {sample_id, pheno_id, sample_type, affectedStatus, sample_from, gt_settings, settings_id} = sample;
            let gt = (gt_settings !== undefined) ? gt_settings : sample.gt;
            self.props.samples_actions.setSamples(sample_id, pheno_id, sample_type, affectedStatus, sample_from, gt);
            if(gt.length>0){
                self.props.samples_actions.setGenotype(sample_id, gt[0],pheno_id, false, settings_id);
            }

        })



    }

    renderCheckboxRow(row_data){

        // return all rows;

        const self = this;

        if (this.props.inheritance_key === "somatic"){
            
            //const control_sample = { ...row_data[0] }; // Creating a shallow copy of an object
            const control_sample = JSON.parse(JSON.stringify(row_data[0])); //Create a deep copy to modify nested objects

            control_sample.gt = ['0/0']
            control_sample.sample_case.case = "control"

            row_data[0].sample_case.case = "tumor"
            
            //Add control sample if the analysis is not tumor only
            if (this.props.selectedDir.analysis !== "somatic_tumor_only")
               row_data.push(control_sample)
        }
        
        return row_data.map(function(row){
            let {gt, sample_case, editable, gt_parameters, settings_id } = row;
            if(gt_parameters!== undefined){
                return <GenotypeRow gt={gt}
                                    sample_case = { sample_case }
                                    gt_parameters={ gt_parameters }
                                    editable = { editable }
                                    refreshExperiments={self.refreshExperiments}
                                    settings_id={settings_id}
                />
            }
            else{
                return null;
            }

        })
    }

    renderHeaders(){

        let conf_labels = [{key: "GPAP Experiment ID", size: 2},{key: "Case", size:1},{key: "Affected", size: 1},{key: "", size: 2},{key: "", size: 2},{key: "", size:2}, {key: "", size: 2}]
        
        let newConfLabels = this.props.inheritance_key === 'somatic'
           ? conf_labels.filter(obj => obj.key !== 'Affected').map(obj => (obj.key === 'Case' ? { ...obj, key: 'Sample' } : obj))
           : [...conf_labels];

        return newConfLabels.map(function (label) {
            return <Grid item lg={label.size}>
              <div style={{fontWeight: "500", textTransform: "uppercase"}}><MappedText text={label.key}/></div>
            </Grid>

        });
    }

    compoundHet(){

        if(this.props.inheritance_key === "custom" || (this.props.inheritance_key === "ar" && this.state.c_het)) {

            return <React.Fragment>
                <Grid item lg={12}>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={this.state.c_het}
                                                           onChange={this.handleCHet} />}
                                          label="Compound Heterozygosity" />
                    </FormGroup>
                </Grid>
            </React.Fragment>


        }


    }


    createChekBoxes(configuration){


        let genotypes =  configuration.genotypes;
        let cbx = [];
        if (genotypes.length !== 0) {
            cbx = this.renderCheckboxes(genotypes, configuration, this.props.editable);
        }

        return cbx;



    }


    render() {

        let self = this;
        let props = this.props;
        
        //let patients = self.getPatients(props)
        let patients = props.patients.length !== 0 ? props.patients : props.inst_patients;

        let config =  props.config;


        if(self.state.c_het){
            let c_het_config = inheritances
                .find(item => item.key === "ar").config
                .find(c => c.label=== "compound heterozygosity")

            config = [c_het_config];

        }


        if (patients.length !== 0) {
            let gt_select_box = config.map(function (configuration) {

               // any conditions about patients...

               let cbx = self.createChekBoxes(configuration);
               if(cbx!== undefined){  // can be undefined if a condition in the inheritance is not satisfied.

                   let text = (props.parent !== configuration.label ) ? <React.Fragment>
                       <Box p={1}>              
                           <Typography variant="h6" component={"h6"} color={"primary"}>
                               {capitalize(configuration.label)}
                           </Typography>
                       </Box>
                       <Divider/>
                   </React.Fragment> : null


                   return (
                       <div style={{marginTop: "10px"}}>

                           {self.props.instandGeneticFindingsTab === "somatic" && (
                            <>
                            <GenotypeToolsRow></GenotypeToolsRow>
                            <br></br>        
                            </>
                            )}
                            
                           {text}
                           <Box p={1}>
                               <Grid container
                                     direction="row"
                                     justifyContent="flex-start"
                                     alignItems="center"
                                     spacing={0}>
                                  {/* {self.compoundHet()}*/}
                                   {self.renderHeaders()}
                                   <Grid item lg={12}>
                                       <Divider light={true}/>
                                   </Grid>
                                   {self.renderCheckboxRow(cbx)}

                               </Grid>
                           </Box>
                       </div>
                   )
               }
            });

            if(gt_select_box.length>0){
                return <div id="genotype_builder">
                    {gt_select_box}
                </div>;
            }
            return null;
        } else {
            return null;
        }
    }
}





//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.patients.length!==0)
    {
        return {
            patients: state.patients.patients,
            samples: state.sample_list.samples,
            somatic_samples: state.sample_list.somatic_samples,
            token: state.authorization.token,
            studySettings: state.studySettings,
            studyBucket: state.studyBucket
        }
    }
    else{
        return {patients: [], samples:[], token: state.authorization.token}
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    samples_actions: bindActionCreators(Sample_Actions, dispatch),
    study_actions: bindActionCreators(Study_Actions, dispatch),
    inheritance_actions: bindActionCreators(Inheritance_Actions, dispatch),
});



export default connect(mapStateToProps,mapDispatchToProps)(GenotypeForm);