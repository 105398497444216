import {getChr} from "../../toolbar/tag_dialog/mapChrom";


export const prepareVariantsTable = (variants, type) => {

    let data = [];

    if(variants!== undefined){

        if(type){
            data= variants.map(function(d) {
                let obj = d.source;
                obj["transcripts"] = d.effects.map(e => e.transcript_id);
                obj["transcripts_all"] = d.effects;
                return obj
            } );}

    else{
        variants.forEach(d => {
            let variant = d;
            let new_data = d.effects.map(effect => {
                effect["gene_name"] = variant.source.gene_name;
                effect["samples_germline"] = variant.source.samples_germline;
                    effect["pos"] = variant.source.pos;
                effect["chrom"] =variant.source.chrom;
                return effect;
            });

            data = data.concat(new_data);
    })
    }}





    return data;
};




export const variantFinder = (row, annotations, tagged_variants, type) =>
{
    let found = undefined;
    if(tagged_variants!== undefined){


        if(type === "SNV_Germline" || type=== "SNV_Somatic"){
            //SNV_Somatic???
            // filter
            let chromosome = getChr(row["chrom"]);
            found = tagged_variants.filter(variant => {

                let chrom_Bool = variant["chromosome"] === chromosome || parseInt(variant["chromosome"]) === chromosome;

                return ( chrom_Bool && variant["start_pos"] === row["pos"]  && variant["gene"] === row["gene_name"])});

            if (found.length === 0){
                if(annotations!== undefined){
                    if("tagged" in annotations){
                        found = annotations.tagged.filter(variant => {return (variant["chrom"] === chromosome && variant["pos"] === row["pos"] && variant["gene"] === row["gene_name"]
                            //&& variant["gene_name"] === row["gene_name"]
                        )});
                        return found;
                    }
                    else{
                        return found;
                    }

                }
                else{
                    return found;
                }
            }
            else{
                return found;
            }
        }
        else{


            let row_i = row;

            let chromosome = getChr(row_i["SV_chrom"]);

            found = tagged_variants.filter(variant => {

                let chrom_Bool = variant["chromosome"] === chromosome || parseInt(variant["chromosome"]) === chromosome;

                return ( chrom_Bool
                    && variant["start_pos"] === row_i["SV_start"]
                    //&& variant["end_pos"] === row_i["SV_end"] // not returned currently by get_all_variants;
                )
            });

            return found;

        }
    }
    
    else{
        return found;
    }
};



