import React from "react";
import {DIR_DATA_HEADER} from "../../home/instand-home/config/dir_data_header.js";
import CustomTable from "../../../general-table/components/tables/CustomTable";
import { useSelector } from "react-redux";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText.js";

export default function DirList () {
    const token = useSelector(state => state.authorization.token);


    const urlBase = window.config.apis_configuration.api_nextgpap_server
    return (
       <Grid container direction={"row"}>       
           <Grid item lg={12}>
               <Box pb={2}>
                   <Typography variant={"h3"}><MappedText text="Interpretation Requests"/></Typography>
                   
               </Box>
           </Grid>
           <Grid item lg={12}>
                
               <CustomTable
                   tableName={"dirs"}
                   url={urlBase +  "/dirs"}
                   token={token}
                   locationData=""
                   dataHeader= {DIR_DATA_HEADER}
                   idRelation={"dir_id"}
                   urlBase = {urlBase}
                   dataApiExtended = {[{
                    url: window.config.apis_configuration.api_data_management,
                    endpoint: "experimentsview",
                    idRelation: "tumor_experiment_id",
                    method: "POST",
                    files: ["Participant_ID", "RD_Connect_ID_Experiment"],
                    token
                  }, {
                    url: window.config.apis_configuration.api_data_management,
                    endpoint: "experimentsview",
                    idRelation: "control_experiment_id",
                    method: "POST",
                    files: ["Participant_ID"],
                    token
                  }]}
                   customFeatures= {[
                       { toggle: false },
                       { expanding: false },
                       { showHeader: false },
                       { columnsButton: true },
                       {
                           actions : {
                               edit: true,
                               delete:true
                           },
                       },

                   ]}
                   chipSmall=   {[
                       {
                           referenceColumn: "Priority",
                           cells: [
                               {
                                   data: "medium",
                                   color: "#F3B82A",
                                   textColor: "black",
                               },
                               {
                                   data: "low",
                                   color: "#4CAF50",
                                   textColor: "black",
                               },
                               {
                                   data: "high",
                                   color: "#C03B2E",
                                   textColor: "black",
                               },
                           ],
                       },
                       {
                           referenceColumn: "Status",
                           cells: [
                               {
                                   data: "in progress",
                                   color: "#F3B82A",
                                   textColor: "black",
                               },
                               {
                                   data: "done",
                                   color: "#4CAF50",
                                   textColor: "black",
                               },
                               {
                                   data: "to do",
                                   color: "#BDBDBD",
                                   textColor: "black",
                               },
                           ],
                       }
                   ]}
                    buttons={[
                        {
                            nameButton: "Create",
                        },
                    ]}
               />
           </Grid>
       </Grid>
    );
};