


export const DATA_HEADER = [
  
    {
        header: "Treatments",
        columns: [

            { nameTable: "Gene name", dataSearch: "gene_name", tooltip: "" },
            { nameTable: "Variant", dataSearch: "hgvs_p", tooltip: "" },
            {  filterType: { type: "select", option: "default" },nameTable: "Level of evidence", dataSearch: "Evidence_level", tooltip: "" },
            {  filterType: { type: "select", option: "default" },nameTable: "Match", dataSearch: "match_type", tooltip: "" },
            {  nameTable: "Cancer type", dataSearch: "Cancer_Type", tooltip: "" },
            {  nameTable: "Therapy", dataSearch: "Therapies", tooltip: "" },
            {  filterType: { type: "select", option: "default" },nameTable: "Therapy status", dataSearch: "Therapy_status", tooltip: "" },
            // {  filterType: { type: "select", option: "default" },nameTable: "Drug status ", dataSearch: "Drug_status", tooltip: "" },
            // {   filterType: "text", nameTable: "Evidence", dataSearch: "links" },
            //    {  filterType: { type: "none" }, nameTable: "Evidence CGI", dataSearch: "cgi_evidence_source" },
             {  filterType: { type: "none" }, nameTable: "Evidence CIViC ", dataSearch: "Evidence_Source_link" },    
        ],
    },  
    
];
