

import React, {Fragment, useState} from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PublicIcon from '@material-ui/icons/Public';
import {connect} from "react-redux";
import Divider from "@material-ui/core/Divider";
import CaButton from '../../../../../../../../../../gpap-lib/components/shared-components/CaButton';



let pop_config = {
    "gp1_af": "1000GP AF",
    "gp1_afr_af": "1000GP AFRICAN AF",
    "gp1_asn_af": "1000GP ASIAN AF",
    "gp1_eur_af": "1000GP EUROPEAN AF",
    "gnomad_af": "gnomAD Allele Freq.",
    "gnomad_ac": "gnomAD Allele Count",
    "gnomad_an": "gnomAD Allele Number",
    "gnomad_af_popmax":"gnomAD AF POPMAX",
    "gnomad_an_popmax": "gnomadAD AN POPMAX",
    "gnomad_ac_popmax": "gnomadAD AC POPMAX",
    "freqIntGermline": "Intern. Freq Germline",
    "freqIntGermlineNum": "Intern. Freq Germline Numb.",
    "freqIntGermlineDem": "Intern. Freq. Germline Dem",
    "gnomad_filter": "gnomAD Filter Status",
    "gnomad_nhomalt": "gnomAD N Homozygotes",
    "gnomad_faf95_popmax": "gnomAD Filtered AF 95",
    "mm_poly_af": "gnomAD mtDNA",
    "mm_poly_ac": "gnomAD mtDNA AC"
};




function PopulationPopOver(props) {


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const renderData = () => {


        let variant_current = false;
        if(!isDisabled()){


            let selectedDataRow = props.selected_table_rows.find(s => s.data_type === props.dataType);

            if(selectedDataRow!== undefined){
                let variantObj = selectedDataRow.data_row;
                variant_current = variantObj[0];
            }


        }

        if(variant_current ){

            let grid = Object.keys(pop_config).map(function(field, index){
                let val = (variant_current[field]!== undefined && variant_current[field]!== "") ? variant_current[field] : "NA";

                function isInteger(num){
                    return Number.isInteger(   parseFloat(num) );
                }

                val = (field === "freqIntGermline" && val !== "NA")
                    ? parseFloat(val).toFixed(5)
                    : ((val!== "NA" && !isInteger(val)) ? parseFloat(val).toFixed(5) : val);

                let background = (val=== "NA" || val === 0 || val === "0") ? "#f0f0f0" : "white";
                return <Fragment key={index}>
                    <Grid item lg={6}><Typography variant={"subtitle1"} style={{fontWeight: "500", display: "inline-block"}}> {pop_config[field]}</Typography></Grid>
                    <Grid item lg={6}
                          style={{textAlign: "center", paddingLeft: "10px", background: background, borderRadius: "3px", border: "1px solid", borderColor: background}}>
                        {val}
                    </Grid>
                </Fragment>

            });

            return <Fragment>
                {grid}
            </Fragment>

        }
        else{
            return <div>Population info not available</div>
        }


    };

    const isDisabled = () => {

        let selectedDataRow = props.selected_table_rows.find(s => s.data_type === props.dataType);

        return  selectedDataRow.data_row.length === 0;

    };


    return (
        <div style={{ margin: ".5%", display:"inline-block"}}>
            <CaButton
                disabled={isDisabled()} 
                aria-describedby={id}
                variant="outlined" 
                onClick={handleClick}
                startIcon={<PublicIcon/>}
                text="Population"/>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2} style={{width: "450px" }}>
                    <Grid container direction={"row"}>
                        <Box p={1}><Typography variant={"body1"} style={{fontWeight: "500"}}>Allele population frequencies</Typography></Box>
                        <Grid item lg={12}><Divider/></Grid>
                        {renderData()}
                    </Grid>
                </Box>
            </Popover>
        </div>
    );
}


function mapStateToProps (state) {
    if(state.selected_table_rows!==undefined)
    {
        return {
            selected_table_rows: state.selected_table_rows
        }
    }
    else{
        return {selected_table_rows: []}
    }
}

export default connect(mapStateToProps)(PopulationPopOver);