import { AlignmentType, HeadingLevel, Paragraph, TextRun } from "docx";
import { drawBasicTable } from "../../write-sections/write_utils/drawTables_examples.js";
import { renderToString } from "../../../create-report/shared-components/renderToString.js";
const headers = [
    "Gene",
    "Gene_MIM",
    "Phenotype",
    "Phenotype_MIM",
    "Inheritance"
];

const dataKeys = [
    "gene",
    "gene_mim",
    "phenotype",
    "phenotype_mim",
    "inheritance"
];

function WriteAssociationsSection(sectionData) {
    let rows = sectionData && Array.isArray(sectionData) ? sectionData : [];
    
    return [
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("Genotype-phenotype associations"),
                    bold: true,
                    size: 22, 
                })
            ],
            
        }),
        new Paragraph({
            text: "",
        }),
        drawBasicTable(rows, headers, dataKeys, renderToString("No data available")),
      
        new Paragraph({
            text: "",
        }),
    ];
}

export default WriteAssociationsSection;