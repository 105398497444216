


export function getSamples(url_prefix, token){
    return fetch(url_prefix + "/samples",
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "X-TOKEN-AUTH": token,
                "ID": "test"
            },
            //body: JSON.stringify(data)
        }
    )
}

export function mme_query(urlprefix, token, data){
    return fetch(urlprefix + "/samples/mme",
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "X-TOKEN-AUTH": token,
                "ID": "test",
            }
           }
    )
}

export function exomiser_query(urlprefix, token, data){
    return fetch(urlprefix + "/exomiser",
        {
            method: 'POST',
            body: data,
            headers: {
                //"Content-Type": "text/plain",
                'Authorization': token
            }
           }
    )
}


export function get_exomiser_results(url_prefix, token, fileID){
    return fetch(url_prefix + "/exomiser_results/" + fileID,
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": token,
            },
        }
    )
}

export function mme_send_mail(urlprefix, token, data){
    return fetch(urlprefix + "/mme/mail",
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "charset=UTF-8",
                "X-TOKEN-AUTH": token,
                "ID": "test",
            }
           }
    )
}

export function contact_user(urlprefix, token, data){
    return fetch(urlprefix + "/user/mail",
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "charset=UTF-8",
                "X-TOKEN-AUTH": token,
                "ID": "test",
            }
           }
    )
}



export function experiments_view_new(urlprefix, token, data){

    return fetch(urlprefix + "/experimentsview/", {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ token},

    })
}


export function participant_post(urlprefix, token, data){
    return fetch(urlprefix + "ExportMultiple",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}
    )
}



export function getParticipants_Aggregations(urlprefix, token, data){
    return fetch(urlprefix + "/participants",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}
    )
}

export function getParticipantBySample(urlprefix, token, data){
    return fetch(urlprefix + "/participants_by_exp",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            },
            body: JSON.stringify(data)}
    )
}



export function getFamily(urlprefix, token, familyID){
    return fetch(urlprefix + "/family/" + familyID,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            }
        }

    )
}

export function getMatchableParticipants(urlprefix, token){
    return fetch(urlprefix + "/MME_cases_visible",
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            }
        }

    )
}

export function getParticipant(urlprefix, token, id){
    return fetch(urlprefix + "/participant/" + id ,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            }
        }
    )
}


export function getParticipantGroups(urlprefix, token, id){
    return fetch(urlprefix + "/share_patient/" + id ,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            }
        }
    )
}


export function getParticipantClinicalInfo(urlprefix, token, id){
    return fetch(urlprefix + "/ExportPatient/" + id ,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/json;",
                'Authorization': token
            }
        }
    )
}


export function getCohortsDB(urlprefix, token){
    return fetch(urlprefix + "/allCohorts",
        {
            method: "GET",
            headers:{
                'Accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Content-Type': "application/json; charset=utf8",
                'Authorization': token                }
        })
}


export function getSingleCohort(urlprefix, token, id){


    return fetch(urlprefix + "/cohort/" + id,
        {
            method: "GET",
            headers:{
                'Accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Content-Type': "application/json; charset=utf8",
                'Authorization': token}
        })

}


export function getUser_Cohorts(urlprefix, token){
    return fetch(urlprefix + "/lookupcohort",
        {
            method: "GET",
            headers:{
                'Accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Content-Type': "application/json; charset=utf8",
                'Authorization': token}
        })
}

export function getVariants(url_prefix, token, data){
    return fetch(url_prefix + "/variants",
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "X-TOKEN-AUTH": token,
                /*"X-TOKEN-AUTH": "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJVM0RIQy1Ma0hzRlY0TkFLYkhNOFN0MWFkbTc4bzQ0SnlKX2hXMmRRZmtZIn0.eyJqdGkiOiIwN2ZmMzVjNy1hNjgyLTQzYmEtODc2Ny1kNmFkNDEwM2U3ZDkiLCJleHAiOjE1Nzg1OTI3MTEsIm5iZiI6MCwiaWF0IjoxNTc4NTgxOTExLCJpc3MiOiJodHRwczovL3Nzby5jbmFnLmNyZy5kZXYvYXV0aC9yZWFsbXMvbWVkcGVyY2FuIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6ImQ5ODVjMzI0LTg5MDYtNGVmMi05M2MwLTM3MDRjMjY5OGI2MCIsInR5cCI6IkJlYXJlciIsImF6cCI6Im1hbmFnZW1lbnQtY2xpZW50Iiwibm9uY2UiOiJhYTU5Mzc1ZC0yNGQxLTQ5MzAtODFlMS1jZTA0OTEyMDIxYzEiLCJhdXRoX3RpbWUiOjE1Nzg1ODE0NTMsInNlc3Npb25fc3RhdGUiOiJmYzY2M2MwOS0yOGEwLTRiNzItODUwZS02NjE3NWVkMTliNjMiLCJhY3IiOiIwIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJhbGJlcnRvIGNvcnZvIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiYWNvcnZvIiwiZ2l2ZW5fbmFtZSI6ImFsYmVydG8iLCJmYW1pbHlfbmFtZSI6ImNvcnZvIiwiZW1haWwiOiJhbGJlcnRvLmNvcnZvQGNuYWcuY3JnLmV1IiwiZ3JvdXAiOlsiL2NuYWctYmVsdHJhbiJdfQ.JJvW5R7_yC5yvaNmSnzfV0ZBPQP54ZxINpj4r0Dmjob-DSI9MDRxnI1RqMZ7eE9iBPhnc5sbwuVGIawMXcCxcwgL-O8hGzuOTx-YhVqGnmA5ADUaSD55_Ph-t1TR3eeZOMB4MvL0Itln3qN2_HXm5_-mnlyNCbpJIfrb0RhuIxYQl8nfFssNvsJSjDfh9P1qrlQPWD9KorZivwBt9IWdJkUcyujiwdrOWdTZbwMccKoTUihYvDK7u4TAV5CuFBFVG5UhChKF7czKb_N6KmFIhcwk793BDQuvGzo28DN81CoKHdv0lPoyYTndqznvTMgV213_kI5VytkKmVjukq5kFg",*/
               /* "ID": "test"*/
            },
            body: JSON.stringify(data)
        }

    )
}



export function getSAVariants(url_prefix, token, data){
    return fetch(url_prefix + "/samples/all/variants",
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "X-TOKEN-AUTH": token,
                /*"X-TOKEN-AUTH": "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJVM0RIQy1Ma0hzRlY0TkFLYkhNOFN0MWFkbTc4bzQ0SnlKX2hXMmRRZmtZIn0.eyJqdGkiOiIwN2ZmMzVjNy1hNjgyLTQzYmEtODc2Ny1kNmFkNDEwM2U3ZDkiLCJleHAiOjE1Nzg1OTI3MTEsIm5iZiI6MCwiaWF0IjoxNTc4NTgxOTExLCJpc3MiOiJodHRwczovL3Nzby5jbmFnLmNyZy5kZXYvYXV0aC9yZWFsbXMvbWVkcGVyY2FuIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6ImQ5ODVjMzI0LTg5MDYtNGVmMi05M2MwLTM3MDRjMjY5OGI2MCIsInR5cCI6IkJlYXJlciIsImF6cCI6Im1hbmFnZW1lbnQtY2xpZW50Iiwibm9uY2UiOiJhYTU5Mzc1ZC0yNGQxLTQ5MzAtODFlMS1jZTA0OTEyMDIxYzEiLCJhdXRoX3RpbWUiOjE1Nzg1ODE0NTMsInNlc3Npb25fc3RhdGUiOiJmYzY2M2MwOS0yOGEwLTRiNzItODUwZS02NjE3NWVkMTliNjMiLCJhY3IiOiIwIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJhbGJlcnRvIGNvcnZvIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiYWNvcnZvIiwiZ2l2ZW5fbmFtZSI6ImFsYmVydG8iLCJmYW1pbHlfbmFtZSI6ImNvcnZvIiwiZW1haWwiOiJhbGJlcnRvLmNvcnZvQGNuYWcuY3JnLmV1IiwiZ3JvdXAiOlsiL2NuYWctYmVsdHJhbiJdfQ.JJvW5R7_yC5yvaNmSnzfV0ZBPQP54ZxINpj4r0Dmjob-DSI9MDRxnI1RqMZ7eE9iBPhnc5sbwuVGIawMXcCxcwgL-O8hGzuOTx-YhVqGnmA5ADUaSD55_Ph-t1TR3eeZOMB4MvL0Itln3qN2_HXm5_-mnlyNCbpJIfrb0RhuIxYQl8nfFssNvsJSjDfh9P1qrlQPWD9KorZivwBt9IWdJkUcyujiwdrOWdTZbwMccKoTUihYvDK7u4TAV5CuFBFVG5UhChKF7czKb_N6KmFIhcwk793BDQuvGzo28DN81CoKHdv0lPoyYTndqznvTMgV213_kI5VytkKmVjukq5kFg",*/
                /* "ID": "test"*/
            },
            body: JSON.stringify(data)
        }

    )
}

export function family_get(handle,token,urlprefix){
    return fetch((urlprefix + `/family/${handle}`),{
        method: 'GET',
        headers:{
            'Content-Type': 'application/json',
            'Authorization': token}
    })
}


export function getFlaggedVariants(url_prefix, token){

        return fetch(url_prefix + "/variants/flagged",
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "X-TOKEN-AUTH": token,
                    /*"X-TOKEN-AUTH": "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJVM0RIQy1Ma0hzRlY0TkFLYkhNOFN0MWFkbTc4bzQ0SnlKX2hXMmRRZmtZIn0.eyJqdGkiOiIwN2ZmMzVjNy1hNjgyLTQzYmEtODc2Ny1kNmFkNDEwM2U3ZDkiLCJleHAiOjE1Nzg1OTI3MTEsIm5iZiI6MCwiaWF0IjoxNTc4NTgxOTExLCJpc3MiOiJodHRwczovL3Nzby5jbmFnLmNyZy5kZXYvYXV0aC9yZWFsbXMvbWVkcGVyY2FuIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6ImQ5ODVjMzI0LTg5MDYtNGVmMi05M2MwLTM3MDRjMjY5OGI2MCIsInR5cCI6IkJlYXJlciIsImF6cCI6Im1hbmFnZW1lbnQtY2xpZW50Iiwibm9uY2UiOiJhYTU5Mzc1ZC0yNGQxLTQ5MzAtODFlMS1jZTA0OTEyMDIxYzEiLCJhdXRoX3RpbWUiOjE1Nzg1ODE0NTMsInNlc3Npb25fc3RhdGUiOiJmYzY2M2MwOS0yOGEwLTRiNzItODUwZS02NjE3NWVkMTliNjMiLCJhY3IiOiIwIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5hbWUiOiJhbGJlcnRvIGNvcnZvIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiYWNvcnZvIiwiZ2l2ZW5fbmFtZSI6ImFsYmVydG8iLCJmYW1pbHlfbmFtZSI6ImNvcnZvIiwiZW1haWwiOiJhbGJlcnRvLmNvcnZvQGNuYWcuY3JnLmV1IiwiZ3JvdXAiOlsiL2NuYWctYmVsdHJhbiJdfQ.JJvW5R7_yC5yvaNmSnzfV0ZBPQP54ZxINpj4r0Dmjob-DSI9MDRxnI1RqMZ7eE9iBPhnc5sbwuVGIawMXcCxcwgL-O8hGzuOTx-YhVqGnmA5ADUaSD55_Ph-t1TR3eeZOMB4MvL0Itln3qN2_HXm5_-mnlyNCbpJIfrb0RhuIxYQl8nfFssNvsJSjDfh9P1qrlQPWD9KorZivwBt9IWdJkUcyujiwdrOWdTZbwMccKoTUihYvDK7u4TAV5CuFBFVG5UhChKF7czKb_N6KmFIhcwk793BDQuvGzo28DN81CoKHdv0lPoyYTndqznvTMgV213_kI5VytkKmVjukq5kFg",*/
                    }
            }
            )
}


// Get list of GENES
export function getGenes(str){
    return fetch("https://clinicaltables.nlm.nih.gov/api/genes/v3/search?sf=hgnc_id,symbol&df=hgnc_id,symbol,name,refseq_accession&authenticity_token&terms="+ str,
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }

    )
}

// Get list of GENES
export function getGenesEnsembl(str){

    const ensembl_prefix = (window.config.genome_ref && window.config.genome_ref === "hg38") ? "/ensembl" : "/ensembl/feb2014"

    return fetch(
        window.location.origin + ensembl_prefix + "/autocomplete?q="+ str.toLowerCase(),
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        }

    )
}




// Predefined GENE LISTS;




// SEARCH OMIM;



// Search PANELAPP;


export function getPanelApp(url){
    return fetch(url,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/x-www-form-urlencoded; charset=UTF-8",
            }}
    )
}


// TODO: test;
export function getPanelAppGenes(panel){

        return fetch("https://panelapp.genomicsengland.co.uk/api/v1/panels/" + panel + "/genes/?page=1",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': "application/x-www-form-urlencoded; charset=UTF-8",
                }}
        )
}


export function getStandard(url){

    return fetch(url,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': "application/x-www-form-urlencoded; charset=UTF-8",
            }}
    )
}



// Search Pathways


export function getPathways(string){

        return fetch("https://reactome.org/ContentService/search/fireworks?species=Homo%20sapiens&types=Pathway&start=0&query=" + string,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': "application/x-www-form-urlencoded; charset=UTF-8",
                }}
        )
    }



export function getOMIM_API(string){

    //return fetch("https://hpo.jax.org/api/hpo/disease/OMIM:" + string,

    return fetch("https://ontology.jax.org/api/network/annotation/" + string,

        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
               /* 'Content-Type': "application/json; charset=UTF-8",*/
            }}
    )
}


export function getPathwaysGenes(url_prefix, string, token){
    return fetch(url_prefix + "/cors_ctd/" + string,

        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "X-TOKEN-AUTH": token,
                /* 'Content-Type': "application/json; charset=UTF-8",*/
            }}
    )
}



// Virtual Gene Panel based;





// Build Gene List;


export function standardHPOSearch(string){

    //return fetch("https://hpo.jax.org/api/hpo/term/" + string,

    return fetch("https://ontology.jax.org/api/hp/search?q=" + string,

        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                /* 'Content-Type': "application/json; charset=UTF-8",*/
            }}
        )
}

export function getHPO_terms(urlPrefix, token, string){

    return fetch(urlPrefix + "/autocomplete/hpo/" + string,
        {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization': token,
            }}
    )
}


    export function getGenes_fromHPO(string){

    //return fetch("https://hpo.jax.org/api/hpo/term/" + string + "/genes?offset=0&max=7000",

    return fetch("https://ontology.jax.org/api/network/annotation/" + string,

        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                /* 'Content-Type': "application/json; charset=UTF-8",*/
            }}
    )
}


    export function get_Diseases_from_HPO(string){
            return fetch("https://hpo.jax.org/api/hpo/term/" + string + "/diseases?offset=0&max=200",

                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        /* 'Content-Type': "application/json; charset=UTF-8",*/
                    }}
            )


    }


    export function getGenesfromOMIM(val){
        //let url = "https://hpo.jax.org/api/hpo/search?fetchAll=true&q=" + val;

        let url = "https://ontology.jax.org/api/network/search/disease?q=" + val;


        return fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    /* 'Content-Type': "application/json; charset=UTF-8",*/
                }}
        )


    }

export function getHPO_fromDisgeNet(urlprefix, string, token){

    return fetch( urlprefix + "/cors_disgenet/" + string,

        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "X-TOKEN-AUTH": token,
                /* 'Content-Type': "application/json; charset=UTF-8",*/
            }}
    )
}


export function getOMIMInfo(urlprefix, string, token){

    return fetch( urlprefix + "/cors_omim/" + string,

        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "X-TOKEN-AUTH": token,
                /* 'Content-Type': "application/json; charset=UTF-8",*/
            }}
    )
}


export function getOMIMInfoElastic(urlprefix, string, token){

    return fetch( urlprefix + "/api/omim/" + string,

        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "Authorization": token,
                /* 'Content-Type': "application/json; charset=UTF-8",*/
            }}
    )
}

export function getROHs(url_prefix, string, token) {
        return fetch(url_prefix + "/rohs/?experiment=" + string,

            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + token,
                }}
        )
}


// get analysis information

export function getAnalysisInformation(token, string, groups, projects ) {

    let project_info = (projects!== "") ? "&project=" + projects : projects;

    return fetch("/datamanagement/api/analysed/?experiment=" + string + "&group=" + groups + project_info,

        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': "Bearer " + token
            }}
    )
}



export function getInfoPermissions(url_prefix, token){
        return fetch(url_prefix + "/permissions",

            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    "X-TOKEN-AUTH": token,
                }}
        )

}



export function post_analysis_status(url_prefix, token, sample, data){
    return fetch(url_prefix + "/analysed/" + sample,
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                "X-TOKEN-AUTH": token,
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(data)
        },

    )
}

export function get_analysis_status(url_prefix, token, sample){
    return fetch(url_prefix + "/analysed/" + sample,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "X-TOKEN-AUTH": token,
            }}
    )
}




    export function get_versions_info(url_prefix, token){
        return fetch(url_prefix + "/versions",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    "X-TOKEN-AUTH": token,
                }}
        )
    }


export function getVarSomeAnnotations(token, data){
    return fetch("/varsome/batch/hg19?add-ACMG-annotation=1",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                "Authorization": "Token " + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }
        )
}