

import React, {Component} from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import HomeIcon from '@material-ui/icons/Home';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import "./SideNavigation.scss";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as SidebarActions from "./sidebar_actions";
import Settings from '@material-ui/icons/Settings';
import Divider from "@material-ui/core/Divider";
import {get_versions_info} from "../../services/api/get";


class SideNavigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: "home",
            versions: false,
            expanded:false
        };
        this.pages = ["#/genomics", "#/study_overview", "#/new_study"]

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setExpanded = this.setExpanded.bind(this);

    }



    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setExpanded(bool){
        this.setState({expanded:bool})
    }


    handleClickOutside(event) {
        if (this.wrapperRef && event.srcElement.className!== undefined && typeof event.srcElement.className === "string" && !event.srcElement.className.includes("sidenav")) {
            this.setState({expanded:false})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.sidebarSettings.page !== this.state.page)
        {
            this.setState({page: this.props.sidebarSettings.page })
        }

        if(this.props.token!== undefined){

            if(!this.state.versions){
                get_versions_info(window.config.api_endpoint, this.props.token)
                    .then(r => {

                        if(r.ok){
                            return r.json()
                        }
                        else{
                            return "NA";
                        }


                    })
                    .then(json => this.setState({versions: json}))
                    .catch(error => console.log("GPAP Server Unavailable"));
            }

        }

    }


    getInfoDataset(){

        if(this.state.versions){

            return (this.state.expanded &&
                <div style={{
                    padding: "8%",
                    color: "white"
                }}>
                    <div> <strong> Platform version:</strong>  {this.state.versions.RelCode}</div>
                    <div> <strong> Dataset : </strong> { this.state.versions.ETLVersion }</div>
                </div>)
        }
        else{
            return null;
        }



    }




    render(){

        return (<SideNav
                expanded ={this.state.expanded}
                ref={this.wrapperRef}
                onToggle={(expanded) => {
                    this.setState({ expanded });
                }}
                onSelect={(selected) => {
                    if(selected === "home"){
                        if(config.instand){
                            window.location.href = "#/reporting_home"
                        }
                        else{
                            window.location.href = "#/home"
                        }

                    }
                    else if(selected === "new_study"){
                        window.location.href =  "#/create_new_study"
                    }
                    else if(selected === "settings"){
                        if(config.instand)
                        {
                            window.location.href = "#/instand_settings"
                        }
                        else{
                            window.location.href = "#/gpap_settings"
                        }

                    }
                }}
            >
                <SideNav.Toggle/>
                {(this.state.expanded)
                    ? <div className="username">
                        <div className="sc-htoDjs dgwEff">{this.props.username}</div>

                    </div>
                    : null}
                {/**/}
                <SideNav.Nav
                    defaultSelected="home"
                    selected={this.state.page} >
                    <NavItem eventKey="home">
                        <NavIcon>
                            <HomeIcon/>
                        </NavIcon>
                        <NavText>
                            My GPAP
                        </NavText>
                    </NavItem>

                    {(config.instand) ? null :  <NavItem eventKey="new_study">
                        <NavIcon>
                            <LibraryAddIcon/>
                        </NavIcon>
                        <NavText>
                            New Study
                        </NavText>
                    </NavItem>}
                    {
                        (this.props.groups!== undefined
                            && Array.isArray(this.props.groups)
                            && this.props.groups.includes("admin"))
                            ? <NavItem eventKey="settings">
                        <NavIcon>
                        <Settings/>
                        </NavIcon>
                        <NavText>
                        GPAP Settings
                        </NavText>
                        </NavItem>
                        : null
                    }
                    <Divider/>
                    {this.getInfoDataset()}

                </SideNav.Nav>
            </SideNav>)
    }
}



function mapStateToProps (state) {
    if(state.authorization.token!==undefined)
    {
        return {
            token: state.authorization.token,
            groups: state.authorization.groups,
            studySettings: state.studySettings,
            sidebarSettings: state.sidebarSettings}
    }
    else{
        return {
            sidebarSettings: state.sidebarSettings
        }
    }
}
const mapDispatchToProps = (dispatch) => ({
    sidebarActions: bindActionCreators(SidebarActions, dispatch)
});



export default connect(mapStateToProps, mapDispatchToProps)(SideNavigation);

