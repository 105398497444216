




const tagged_treatments_sample = [{
    "gene": "KMT2C",
    "user": "test",
    "therapy": "None  Aromatase Inhibitor",
    "variant": "p.Lys2815fs",
    "timestamp": "12-02-2024 10:00:53",
    "match_type": "Gene",
    "cancer_type": "\"Skin Squamous Cell Carcinoma  Breast Cancer\"",
    "evidence_level": "B",
    "interpretation": "Treatment 1",
    "therapy_status": "sensitive",
    "comment": "Variant Scope, evidence blablabla"
}]

const clinical_evidence_columns = [
    {
        Header: 'Gene',
        accessor: "gene",
        filter: 'fuzzyText',
    },
    {
        Header: 'Variant',
        accessor: "variant",
        filter: 'fuzzyText',
    },
    {
        Header: 'Therapy',
        accessor: "therapy",
        filter: 'fuzzyText',

    },
    /*{
        Header: 'Interpretation',
        accessor: "interpretation",
        filter: 'fuzzyText'
    },*/
    {
        Header: 'Cancer Type',
        accessor: "cancer_type",
        filter: 'fuzzyText'
    },
   /* {
        Header: 'Comment',
        accessor: "comment",
        filter: 'fuzzyText'
    }*/
]


export {clinical_evidence_columns,tagged_treatments_sample }