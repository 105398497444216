import React from "react";
import {Alert} from "@mui/material";
import Box from "@mui/material/Box";
import MappedText from "../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";




function AlertInfoViews({text}){

    return <Box py={2}>
            <Alert severity="info"><MappedText text={text}/></Alert>
        </Box>

}

export default AlertInfoViews;