import {Paragraph, TextRun, HeadingLevel, AlignmentType} from 'docx';
import React from "react";
import { renderToString } from '../../../create-report/shared-components/renderToString';
import {useTranslation} from "react-i18next";
import returnDisclaimerText from "./disclaimer_configs";
import { fontDoc } from '../../config/template_config';


const WriteDisclaimer = ( title, disclaimer, analysisType, t ) => {


    const disclaimerTextArray = returnDisclaimerText(analysisType);

    // here we should translate the text...

    const translateTextArray = disclaimerTextArray.map(text => t(text));

    const disclaimerText = translateTextArray.join("");

    //const disclaimerText = convertReactElementToPlainText(disclaimer);

    const textRuns = createTextRuns(disclaimerText);

    return [
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("DISCLAIMER"),
                    size: 24,
                    color: '#000000',
                    bold: true,
                    heading: HeadingLevel.HEADING_2,
                    
                }),
            ],
        }),
        new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        children: textRuns,
    })
    ];
};


const createTextRuns = (text) => {
    const parts = text.split(/(\(https:\/\/[^\s]+\))/g); // Split by URLs in parentheses
    return parts.map(part => {
        if (part.startsWith("(") && part.endsWith(")")) {
            return new TextRun({
                text: renderToString(part),
                size: 20,
                font: fontDoc,
                color: "0000FF", // Blue color to indicate a link
                underline: true,
            });
        } else {
            return new TextRun({
                text: renderToString(part),
                size: 20,
                font: fontDoc,
            });
        }
    });
};

const convertReactElementToPlainText = (reactElement) => {
    if (!reactElement) {
        return "";
    }
    if (typeof reactElement === "string") {
        return reactElement;
    }
    if (Array.isArray(reactElement)) {
        return reactElement.map(element => convertReactElementToPlainText(element)).join("");
    }
    if (reactElement.props && reactElement.props.children) {
        // Handle custom components like MappedText
        if (reactElement.type === "MappedText") {
            return reactElement.props.text || ""; // Extract the text prop from MappedText component
        }
        // Special case for <a> tags to capture the link
        if (reactElement.type === 'a') {
            const href = reactElement.props.href;
            const linkText = convertReactElementToPlainText(reactElement.props.children);
            return `${linkText} (${href})`; // Format the link as "text (url)"
        }
        return convertReactElementToPlainText(reactElement.props.children);
    }
    return "";
};

export default WriteDisclaimer;