import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";

import {Grid, Box} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { GPAP_Button_Gray } from "../../../gpap-lib/components/Button_Collection";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";


export function UploadListFile ({ saveListArray }) {

    const [files, setFiles] = useState("");
    const [nLists, setNumberLists] = useState(0);
        
    const handleChange = e => {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            const text = (e.target.result)
            console.log(text);

            if ("lists" in JSON.parse(e.target.result)){
                let content = JSON.parse(e.target.result);
                let N_Lists = content["lists"].length
                setNumberLists(N_Lists)
                setFiles(content["lists"]);
            }
        };
    };

    const handleSave = () => {


        saveListArray(files)

    }


    return (
        <Grid container spacing={2}>
                <Grid item md={3}>
                    <Box pt={2}>
                        <Typography  variant={"body1"} style={{ marginBottom: '12px' }}><MappedText text="Upload a .list file"/></Typography>
                        <Typography   variant={"body2"} style={{ marginBottom: '12px' }}><MappedText text="The .list file is the legacy file format used to store predefined gene lists in the GPAP. This upload is meant for CNAG users. For more information, contact Steve Laurie."/> </Typography>
                        <Typography  variant={"body2"} style={{ marginBottom: '12px' }}> <MappedText text="The file must contain a JSON array of Predefined Gene List Objects."/></Typography>
                        <GPAP_Button_Gray >
                        <MappedText text="upload .list file"/>
                            <input hidden type="file" onChange={handleChange} />
                        </GPAP_Button_Gray>
                    </Box>
                </Grid>
                <Grid item lg={12}>
                    <Box pt={2}>
                        {(nLists!==0 && files !== "") ? <Box pt={2}>
                            <Alert severity="success" style={{transitionDuration:"2s"}}>{nLists} <MappedText text="lists parsed correctly and ready to be saved."/> </Alert>
                        </Box> : null}
                        <GPAP_Button_Gray 
                                disable={ (nLists === 0 && files === "") }
                                onClick={handleSave}><MappedText text="Save Lists"/></GPAP_Button_Gray>

                    </Box>
                </Grid>
            </Grid>
    );
    }