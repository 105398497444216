



import React, {Component} from 'react';

import auth from "../../services/session/Auth";
import "./css/navigation-bar.css"
import {NavLink} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import GPAP_Breadcrumb from "./GPAP_Breadcrumb";
import Avatar from "@material-ui/core/Avatar";
import {connect} from "react-redux";
import NavigationMenu from "./navigation-menu/NavigationMenu";


class NavigationBar extends Component{

    constructor(props) {
        super(props);
        this.state = {
            keycloak: auth.user.keycloak,
            authenticated: auth.user.authenticated
        };
    }


    logout(){
      auth.user.keycloak.logout()
        .then((apiResponse) => {console.log("logout")})
        .catch(e=>{console.log(e)})
     }



     renderLoginDetails(){

        let {authorization}= this.props;
         if (authorization!== undefined && authorization.authenticated === true)
         {
             let user = authorization.preferredName;
             let welcome = user;
             return <React.Fragment>

                 <Button> <Avatar
                     style={{
                     background: "rgb(69, 117, 180)",
                     color: "white",
                     width: "30px",
                     height: "30px",
                     margin: "5px"
                     }}>{user.charAt(0).toUpperCase()}</Avatar> {welcome} </Button>
                 <Button onClick={this.logout.bind(this)}> <NavLink  exact to={"/#"}> LOGOUT </NavLink></Button>
             </React.Fragment>

         }
         else{
             let welcome = "Welcome Guest";
             return <React.Fragment>

                 <Button>{welcome} </Button>
                 <Button> <NavLink exact to={"/home"}>
                                 {/*  to={{ pathname: "/login/", state: { from: window.location.href }}}*/}
                     LOGIN </NavLink></Button>
             </React.Fragment>
         }
     }

    render() {



        let origin = window.location.origin;

        return (
            <div className="pageHeader">
                <Grid container
                      spacing={2}>
                    <Grid item xs={5} >
                        <Grid item >
                            <NavigationMenu
                                title={"Data Submission"}
                                orange={false}
                                items={[ {label:"Phenotypic Data", to:"/phenostore/"},
                                    {label:"Experiments & Files", to:"/submission/"}]}
                            />
                            <NavigationMenu
                                title={"Data Analysis"}
                                orange={true}
                                items={[ {label:"Genomic Analysis", to:"/"},
                                    {label:"Cohort Creation", to:"/cohortapp/"}]}
                            />
                            <NavigationMenu
                                title={"Data Management"}
                                orange={false}
                                items={[ {label:"Browse Phenotypic Participants", to:"/phenobrowse/"},
                                    {label:"Browse Experiments & Files", to:"/management/"}]}
                            />
                        </Grid>
                        <Grid item >
                            <GPAP_Breadcrumb/>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} style={{textAlign: "center"}}>
                        <div>
                            <a href={origin} style={{color: "black"}}>
                                <img src={window.config.static_files + "/img/gpap_logo.svg"} alt="GPAP" style={{width: "250px"}}/>
                            </a>
                        </div>
                    </Grid>
                    <Grid item xs={5} style={{textAlign: "end"}}>
                            <Button href={origin + "/gpap_doc/" }>User Guide</Button>
                            <Button><NavLink exact to="/contactus">Contact</NavLink></Button>
                        {/*    <LanguageSelector/>*/}
                            {this.renderLoginDetails()}
                            <div className={"navbar_links"}>
                            </div>

                    </Grid>

                </Grid>
            </div>
        );
    }
}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {authorization: state.authorization}
    }
}



export default connect(mapStateToProps,[])(NavigationBar);