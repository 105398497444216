export const italiano = {
    "Get in touch": "Mettiti in contatto",
    "Thank you for using our product.": "Grazie per aver utilizzato il nostro prodotto.",
    "Feel free to reach out if you have any questions or comments.": "Sentiti libero di contattarci se hai domande o commenti.",
    "Data Platforms & Tools Development Team": "Team di sviluppo di piattaforme e strumenti dati",
    "Barcelona, Spain": "Barcellona, ​​Spagna",
    "Phenotypic Data": "Dati fenotipici",
    "Experiments & Files": "Esperimenti e file",
    "Genomic Analysis": "Analisi genomica",
    "Cohort Creation": "Creazione di coorti",
    "Browse Phenotypic Participants": "Sfoglia i partecipanti fenotipici",
    "Browse Experiments & Files": "Sfoglia esperimenti e file",
    "Settings": "Impostazioni",
    "Interpretation Requests": "Richieste di interpretazione",
    "Docs": "Documenti",
    "Contact": "Contatto",
    "Autosomal recessive (both homozygous and compound heterozygous)": "Autosomico recessivo (sia omozigote che eterozigote composto)",
    "homozygous": "omozigote",
    "compound heterozygosity": "eterozigosità composta",
    "Autosomal dominant": "Autosomico dominante",
    "Autosomal dominant - de novo (parents required)": "Autosomico dominante - de novo (sono richiesti i genitori)",
    "De novo": "Di nuovo",
    "AR Consanguineous": "AR Consanguinei",
    "X-Linked Recessive ": "Recessivo legato all'X",
    "X-Linked Recessive - Index Case Male": "Recessivo legato all'X - Caso indice Maschile",
    "X-Linked Dominant ": "Dominante legata all'X",
    "X-Linked Dominant - Index Case Male": "Dominante legata all'X - Caso indice Maschile",
    "X-Linked Dominant - Index Case Female": "Dominante legata all'X - Caso indice Femmina",
    "Custom Inheritance ": "Eredità personalizzata",
    "Custom Inheritance": "Eredità personalizzata",
    "Somatic": "Somatico",
    "Ensembl": "Insieme",
    "gnomAD": "gnomAD",
    "UCSC": "UCSC",
    "NCBI": "NCBI",
    "DGVa": "DGVa",
    "GWAS Central": "GWAS Centrale",
    "GA4GH": "GA4GH",
    "VarSome": "VarSome",
    "dbSNP": "dbSNP",
    "OMIM": "OMIM",
    "Genetics Home Reference": "Riferimento per la casa di genetica",
    "NCBI Gene Reviews": "Recensioni sui geni NCBI",
    "PubCaseFinder": "PubCaseFinder",
    "PubMed": "PubMed",
    "HGMD": "HGMD",
    "Entrez": "Entrez",
    "GeneCards": "GeneCard",
    "COSMIC": "COSMICO",
    "ClinVar": "ClinVar",
    "ExAC": "ExAC",
    "GTEx": "GTEx",
    "ATLAS": "ATLANTE",
    "WikiPathways": "WikiPathways",
    "Open PHACTS": "Apri FACTS",
    "DisGeNET": "DisGeNET",
    "Gene Name": "Nome del gene",
    "Transcript ID": "ID della trascrizione",
    "Effect Impact": "Effetto Impatto",
    "Consequence": "Conseguenza",
    "Feature Type": "Tipo di funzione",
    "HGVS coding": "Codifica HGVS",
    "Human Splicing Finder": "Cercatore di splicing umano",
    "Amino Acid change": "Cambiamento degli aminoacidi",
    "Exon Rank": "Grado dell'esone",
    "Transcript BioType": "Trascrizione Biotipo",
    "CADD Pred": "Predisposizione CADD",
    "Revel Pred": "Revel Pred",
    "SplicaAI Pred": "SplicaAI Pred",
    "SIFT PRED": "SETACCIARE PRED",
    "SIFT SCORE": "PUNTEGGIO SETACCIARE",
    "POLYPHEN2 Pred": "POLIFEN 2 Pronostico",
    "POLYPHEN Score": "Punteggio POLIFEN",
    "Mutation Taster": "Assaggiatore di mutazioni",
    "InterVar": "InterVar",
    "MT Score": "Punteggio MT",
    "phyloP": "filoP",
    "GERP++": "GERP++",
    "SiPhy": "SiPhy",
    "OCScore": "OCScore",
    "onco_classifier": "onco_classificatore",
    "Study Name": "Nome dello studio",
    "Description": "Descrizione",
    "Created on": "Creato il",
    "Created by": "Creato da",
    "Permission": "Autorizzazione",
    "Actions": "Azioni",
    "Gene": "Gene",
    "Chr": "Chr",
    "Start": "Inizio",
    "End": "FINE",
    "Length": "Lunghezza",
    "Type": "Tipo",
    "Reads Observed": "Lettura osservata",
    "Reads Expected": "Lettura prevista",
    "Reads Ratio": "Legge il rapporto",
    "Int. Freq": "interno Freq",
    "Tool": "Attrezzo",
    "Sample": "Campione",
    "OMIM PhenoType": "Fenotipo OMIM",
    "Bayer Factor": "Fattore Bayer",
    "DGV goldstd overlap": "Sovrapposizione oro DGV",
    "DGV goldstd coordinates": "Coordinate DGV goldstd",
    "Gene ID": "Identificazione del gene",
    "Gene coding": "Codificazione genetica",
    "Functional class": "Classe funzionale",
    "Aminoacid length": "Lunghezza degli aminoacidi",
    "Aminoacid": "Amminoacido",
    "Impact": "Impatto",
    "Change": "Modifica",
    "Nucleotide": "Nucleotide",
    "Transcript": "Trascrizione",
    "GT": "GT",
    "MANE": "CRINIERA",
    "Clin.Sign.": "Clin.Sign.",
    "Criteria": "Criteri",
    "ACMG evidences": "Evidenze ACMG",
    "ACMG classification": "Classificazione ACMG",
    "Internal": "Interno",
    "GnomAD": "GnomAD",
    "CADD": "CADD",
    "Revel": "Divertiti",
    "SpliceAI": "SpliceAI",
    "SIFT": "VAGLIARE",
    "Polyphen2.": "Polifene2.",
    "MT": "MT",
    "Intervar Evidence": "Evidenza dell'Intervar",
    "Intervar Autom.": "Intervallo Autom.",
    "# Exp.": "#Esp.",
    "Polyphen2": "Polifene2",
    "Onco evidences": "Evidenze oncologiche",
    "Classification": "Classificazione",
    "Source": "Fonte",
    "Evidence Level??": "Livello di prova??",
    "Columns": "Colonne",
    "Create request": "Crea richiesta",
    "Upload report": "Carica rapporto",
    "Create report": "Crea rapporto",
    "Column Hiding": "Nascondere colonne",
    "Toggle All": "Attiva/disattiva tutto",
    "Complete view": "Visualizzazione completa",
    "Split by genes": "Diviso dai geni",
    "Experiment ": "Sperimentare",
    " does not have any data available yet": "non ha ancora nessun dato disponibile",
    "Access Request": "Richiesta di accesso",
    "Download": "Scaricamento",
    "Upload": "Caricamento",
    "Link": "Collegamento",
    "Download Report": "Scarica Rapporto",
    "The initial count of SNVs identified during the first execution of this query.": "Il conteggio iniziale di SNV identificati durante la prima esecuzione di questa query.",
    "This query did not return any variants": "Questa query non ha restituito alcuna variante",
    "No Associations": "Nessuna associazione",
    "We are working to integrate more resources in this section": "Stiamo lavorando per integrare più risorse in questa sezione",
    "Pathways": "Percorsi",
    "HmtDB - Human Mitochondrial Database": "HmtDB - Database mitocondriale umano",
    "Human Pluripotent Stem Cell Registry": "Registro delle cellule staminali umane pluripotenti",
    "Pathways Reactome": "Percorsi Reactome",
    "Reactome is a free, open-source, curated and peer-reviewed pathway database": "Reactome è un database di percorsi gratuito, open source, curato e sottoposto a revisione paritaria",
    "URL": "URL",
    "Disease": "Malattia",
    "Cell Line Name": "Nome della linea cellulare",
    "Cell Line": "Linea cellulare",
    "Provider": "Fornitore",
    "# variants grouped by chromosome": "# varianti raggruppate per cromosoma",
    "External Databases": "Database esterni",
    "External Links": "Collegamenti esterni",
    "No variants selected. Please select a variant to display the links": "Nessuna variante selezionata. Seleziona una variante per visualizzare i link",
    "No variants selected": "Nessuna variante selezionata",
    "No variant selected": "Nessuna variante selezionata",
    "Select one to use the resources": "Selezionane uno per utilizzare le risorse",
    "New Analysis": "Nuova analisi",
    "Query": "Domanda",
    "Current work": "Lavoro attuale",
    "Participants": "Partecipanti",
    "Save": "Salva",
    "Not Analyzed": "Non analizzato",
    "Solved": "Risolto",
    "Unsolved": "Irrisolto",
    "Ongoing Analysis": "Analisi continua",
    "Under segregation/ functional studies": "In fase di segregazione/studi funzionali",
    "Heterozygous variant found in AR disorder": "Variante eterozigote trovata nel disturbo AR",
    "Unaffected Relative": "Parente non affetto",
    "Other": "Altro",
    "select": "selezionare",
    "Lists applied:": "Elenchi applicati:",
    "Gene Filtering": "Filtraggio genetico",
    "Positions": "Posizioni",
    "Variant Effect": "Effetto variante",
    "Genotype Features": "Caratteristiche del genotipo",
    "ClinVar Classes": "Classi ClinVar",
    "Mane": "Criniera",
    "Onco Filter": "Filtro Onco",
    "PolyPhen-2 Pred.": "Polifen-2 pred.",
    "GnomAD Filter": "Filtro GnomAD",
    "Programs Filter": "Filtro programmi",
    "Variant Consequences": "Conseguenze delle varianti",
    "Used filter-set": "Set di filtri usati",
    "Applied Filters": "Filtri applicati",
    "Tag variants": "Varianti dell'etichetta",
    "There is no query selected. Or applied filters are not visible.": "Non c'è nessuna query selezionata. Oppure i filtri applicati non sono visibili.",
    "Filters": "Filtri",
    "Variants distributions - (based on max 3000 entries)": "Distribuzioni delle varianti - (basata su un massimo di 3000 voci)",
    "Export Variants (max.1000)": "Esporta varianti (max.1000)",
    "Once selected, click again to unapply": "Una volta selezionato, fare nuovamente clic per annullare l'applicazione",
    "Label": "Etichetta",
    "Important": "Importante",
    "Follow-Up": "Seguito",
    "Discarded": "Scartato",
    "Candidate": "Candidato",
    "List of Integrated Plugins": "Elenco dei plugin integrati",
    "Please, give a name to your study to save it correctly. ": "Per favore, dai un nome al tuo studio per salvarlo correttamente.",
    "You have unsaved queries": "Hai query non salvate",
    "Save current Study with Analyses and queries": "Salva lo studio corrente con analisi e query",
    "You are not the creator of the study": "Non sei l'ideatore dello studio",
    "Study title. Max 50 chars": "Titolo di studio. Massimo 50 caratteri",
    "Analyses without queries will not be saved": "Le analisi senza query non verranno salvate",
    "Update Study Information": "Aggiorna le informazioni sullo studio",
    "Save Study": "Salva studio",
    "Fill in some information to save your work.": "Inserisci alcune informazioni per salvare il tuo lavoro.",
    "Study description. Max 50 chars": "Descrizione dello studio. Massimo 50 caratteri",
    "Select": "Selezionare",
    "Please select study visibility": "Seleziona la visibilità dello studio",
    "Private": "Privato",
    "Share with your groups": "Condividi con i tuoi gruppi",
    "Public": "Pubblico",
    "Cancel": "Cancellare",
    "Save changes": "Salva modifiche",
    "Share the study with collaborators": "Condividi lo studio con i collaboratori",
    "CNV variant already Tagged": "Variante CNV già contrassegnata",
    "Tag CNV Variant": "Etichetta variante CNV",
    "See tag in the ": "Vedi l'etichetta nel",
    "Click here if you want to see a summary of the data stored in PhenoStore for the tagged participant": "Fare clic qui se si desidera visualizzare un riepilogo dei dati archiviati in PhenoStore per il partecipante taggato",
    "Go to Participant": "Vai a Partecipante",
    "summary": "riepilogo",
    " You also deleted the variant from PhenoStore. If you did it by mistake, please tag again.": "Hai anche eliminato la variante da PhenoStore. Se lo hai fatto per errore, tagga di nuovo.",
    "Delete": "Eliminare",
    "Delete your tag": "Elimina il tuo tag",
    "When you TAG a variant, the tag will be visible to all users of the platform regardless of the embargo period": "Quando TAGGI una variante, il tag sarà visibile a tutti gli utenti della piattaforma indipendentemente dal periodo di embargo",
    "Variant not tagged yet": "Variante non ancora taggata",
    "Related to": "Relativo a",
    "Project": "Progetto",
    "Type of variant - Please review this field": "Tipo di variante: controlla questo campo",
    "Inheritance": "Eredità",
    "Origin": "Origine",
    "Zygosity": "Zigosità",
    "Clinical Significance": "Significato clinico",
    "Interpretation": "Interpretazione",
    "Is this the Causative Variant?": "È questa la variante causativa?",
    "Causative Variant (confirmed as the cause of the primary disorder)": "Variante causativa (confermata come causa del disturbo primario)",
    "This clinical evidence has already been tagged and will be displayed in the ": "Questa evidenza clinica è già stata contrassegnata e verrà visualizzata nel",
    "Report": "Rapporto",
    "module. Editing this tag will modify the interpretation comment.": "modulo. La modifica di questo tag modificherà il commento di interpretazione.",
    "Tagging a clinical evidence will make it available to add in a ": "Contrassegnare un'evidenza clinica la renderà disponibile per aggiungere a",
    "This haplotype has already been tagged and will be displayed in the ": "Questo aplotipo è già stato taggato e verrà visualizzato nel file",
    " module. Editing this tag will modify the interpretation comment.": "modulo. La modifica di questo tag modificherà il commento di interpretazione.",
    "Tagging a haplotype will make it available to add in a ": "Contrassegnare un aplotipo lo renderà disponibile per aggiungere a",
    "Submit": "Invia",
    "Close": "Vicino",
    "Required": "Necessario",
    "Tag variants in the GPAP database": "Varianti dei tag nel database GPAP",
    "Tag": "Etichetta",
    "Pathogenic": "Patogeno",
    "Likely pathogenic": "Probabilmente patogeno",
    "Uncertain significance": "Significato incerto",
    "Likely benign": "Probabilmente benigno",
    "Benign": "Benigno",
    "Drug response": "Risposta ai farmaci",
    "Association": "Associazione",
    "Risk factor": "Fattore di rischio",
    "Protective": "Protettivo",
    "Autosomal recessive": "Autosomico recessivo",
    "X-linked": "Legato all'X",
    "X-linked dominant": "Dominante legata all'X",
    "X-linked recessive": "Recessivo legato all'X",
    "Y-linked inheritance": "Eredità legata all'Y",
    "Mitochondrial inheritance": "Eredità mitocondriale",
    "Oligogenic inheritance": "Eredità oligogenica",
    "Digenic inheritance": "Eredità digenica",
    "Autosomal dominant with paternal imprinting": "Autosomica dominante con imprinting paterno",
    "Autosomal dominant with maternal imprinting": "Autosomica dominante con imprinting materno",
    "Unknown": "Sconosciuto",
    "Heterozygous": "Eterozigote",
    "Homozygous": "Omozigote",
    "Hemizygous": "Emizigote",
    "Primary disorder": "Disturbo primario",
    "Secondary findings": "Risultati secondari",
    "Pharmacogenomics": "Farmacogenomica",
    "Polygenic risk score": "Punteggio di rischio poligenico",
    "SNV-InDel": "SNV-InDel",
    "Duplication": "Duplicazione",
    "Deletion": "Cancellazione",
    "Inversion": "Inversione",
    "Short tandem repeat": "Breve ripetizione in tandem",
    "Translocation": "Traslocazione",
    "germline": "linea germinale",
    "somatic": "somatico",
    "de novo": "de novo",
    "maternal": "materno",
    "paternal": "paterno",
    "inherited": "ereditato",
    "unknown": "sconosciuto",
    "The filters selected for SNV-InDels do not automatically apply to CNVs and SVs except gene lists. You can further filter the CNVs directly in the table": "I filtri selezionati per SNV-InDels non si applicano automaticamente a CNV e SV ad eccezione degli elenchi di geni. Puoi filtrare ulteriormente le CNV direttamente nella tabella",
    "Load variants by clicking on a gene or experiment on the left, or a participant in the table above.": "Carica le varianti facendo clic su un gene o un esperimento a sinistra o su un partecipante nella tabella sopra.",
    "No variants have passed the selected filters.": "Nessuna variante ha superato i filtri selezionati.",
    "To display variants, try using less restrictive filters or expanding the gene list": "Per visualizzare le varianti, prova a utilizzare filtri meno restrittivi o ad espandere l'elenco dei geni",
    "Variants associated to experiments": "Varianti associate agli esperimenti",
    "Variants": "Varianti",
    "The displayed number is the total sum of events in the FULL and SPLIT results.": "Il numero visualizzato è la somma totale degli eventi nei risultati COMPLETO e SPLIT.",
    "SNV and InDels": "SNV e InDels",
    "Note that the number of rows shown in the variants table can be higher than the number of genomic variants shown in the counter since one genomic variant can affect more than one gene.": "Tieni presente che il numero di righe mostrate nella tabella delle varianti può essere superiore al numero di varianti genomiche mostrate nel contatore poiché una variante genomica può influenzare più di un gene.",
    "Gene Info": "Informazioni sui geni",
    "Variant Information": "Informazioni sulla variante",
    "Chrom": "Cromato",
    "SV Type": "Tipo SV",
    "Copy Number": "Numero di copia",
    "CytoBand": "CytoBand",
    "Translocation_ID     ": "Traslocazione_ID",
    "Quality": "Qualità",
    "Clinical Association": "Associazione Clinica",
    "ACMG Classific.": "Classico ACMG.",
    "AnnotSV Reported pathogenic": "AnnotSV Riportato patogeno",
    "P_gain_phen": "P_guadagno_fen",
    "P_loss_phen": "P_loss_phen",
    "P_ins_phen": "P_ins_phen",
    "Population Frequency": "Frequenza della popolazione",
    "B_gain_AFmax": "Guadagno_B_AFmax",
    "B_loss_AFmax": "B_perdita_AFmax",
    "B_ins_AFmax": "B_ins_AFmax",
    "Albert AnnotSV populaton AFMax ": "Albert AnnotSV popolazione AFMax",
    "Internal Freq.": "Freq. interna",
    "Exact match": "Corrispondenza esatta",
    "Additional Information": "Informazioni aggiuntive",
    "Experiment ID": "ID dell'esperimento",
    "Translocation_ID": "Traslocazione_ID",
    "Internal Freq. Count": "Freq. interna Contare",
    "Location": "Posizione",
    "Clinical Association ": "Associazione Clinica",
    "AnnotSV population AFMax": "Popolazione AnnotSV AFMax",
    "Internal Freq": "Freq. interna",
    "Additional information": "Ulteriori informazioni",
    "Previous Tags": "Tag precedenti",
    "Status": "Stato",
    "Significance": "Significato",
    "Date": "Data",
    "Comments": "Commenti",
    "Your Tag": "La tua etichetta",
    "Samples Information not available": "Campioni Informazioni non disponibili",
    "IGV Browser": "Browser IGV",
    "Predictors": "Predittori",
    "Genotypes": "Genotipi",
    "Fam.ID": "ID fam",
    "Local Exp. ID": "Esp. Locale ID",
    "Affected Status": "Stato interessato",
    "Consanguinity": "Consanguineità",
    "Sex": "Sesso",
    "Solved Status": "Stato risolto",
    "Obs. HPOs": "Oss. HPO",
    "Diagnosis": "Diagnosi",
    "Contact Owner": "Contatta il proprietario",
    "Show the variants of the selected participant": "Mostra le varianti del partecipante selezionato",
    "Participant ID": "ID partecipante",
    "Owner": "Proprietario",
    "ERNs": "ERN",
    "Life Status": "Stato di vita",
    "# HPOs": "# HPO",
    "Date of Birth": "Data di nascita",
    "Date of Death": "Data di morte",
    "indel": "indel",
    "ref": "rif",
    "alt": "alt",
    "pos": "pos",
    "Population": "Popolazione",
    "freqIntGermline": "freqIntGermline",
    "gnomad_an_popmax": "gnomad_an_popmax",
    "gnomad_an": "gnomade_an",
    "freqIntGermlineNum": "freqIntGermlineNum",
    "freqIntGermlineDem": "freqIntGermlineDem",
    "gnomad_ac": "gnomad_ac",
    "gnomad_af": "gnomad_af",
    "gnomad_nhomalt": "gnomad_nhomalt",
    "gnomad_ac_popmax": "gnomad_ac_popmax",
    "gnomad_af_popmax": "gnomad_af_popmax",
    "spliceai_dp_al": "spliceai_dp_al",
    "sift_score": "setacciare_punteggio",
    "mm_poly_ac": "mm_poli_ac",
    "gnomad_faf95_popmax": "gnomad_faf95_popmax",
    "spliceai_ds_ag": "spliceai_ds_ag",
    "spliceai_dp_dg": "spliceai_dp_dg",
    "spliceai_max_ds": "spliceai_max_ds",
    "spliceai_ds_al": "spliceai_ds_al",
    "spliceai_ds_dl": "spliceai_ds_dl",
    "spliceai_dp_ag": "spliceai_dp_ag",
    "spliceai_dp_dl": "spliceai_dp_dl",
    "spliceai_ds_dg": "spliceai_ds_dg",
    "revel_score": "revel_score",
    "vep_proc_id.block_idx": "vep_proc_id.block_idx",
    "mt": "mt",
    "mm_dis_disease": "mm_dis_malattia",
    "intervar_automated": "intervar_automatizzato",
    "mm_dis_disease_status": "mm_dis_disease_status",
    "vep_proc_id.part_idx": "vep_proc_id.part_idx",
    "intervar_evidence": "intervar_evidence",
    "mm_poly_af": "mm_poly_af",
    "View Applied Filters": "Visualizza i filtri applicati",
    "Clearing filters": "Cancellazione dei filtri",
    "Use a predefined filter set": "Utilizza un set di filtri predefinito",
    "Variant Type": "Tipo di variante",
    "SNV Effect Predictions": "Previsioni sugli effetti SNV",
    "Position Specific and Runs Of Homozygosity": "Posizione specifica e serie di omozigosità",
    "Create/Adjust Filters": "Crea/Regola filtri",
    "Filter by variants": "Filtra per varianti",
    "Gene Filters": "Filtri genetici",
    "Filter by genes": "Filtra per geni",
    "Predefined lists": "Elenchi predefiniti",
    "Select a Predefined Gene List prepared from ERNs or the GPAP team": "Seleziona un elenco di geni predefiniti preparato dalle ERN o dal team GPAP",
    "Gene search and upload": "Ricerca e caricamento dei geni",
    "Enter multiple gene symbols using HGNC nomenclature or Upload your own": "Inserisci più simboli di geni utilizzando la nomenclatura HGNC o carica il tuo",
    "WARNING: This platform uses annotations from Ensembl ": "ATTENZIONE: questa piattaforma utilizza annotazioni di Ensembl",
    ". Any Gene Symbol used for filtering which does not match the symbol used in Ensembl v109 may be missed or wrongly assigned. This also applies to external web-services. We are currently working to mitigate this issue.": ". Qualsiasi simbolo genetico utilizzato per il filtraggio che non corrisponde al simbolo utilizzato in Ensembl v109 potrebbe mancare o essere assegnato erroneamente. Ciò vale anche per i servizi web esterni. Stiamo attualmente lavorando per mitigare questo problema.",
    "Diseases": "Malattie",
    "Select one or more OMIM terms or a PanelApp to query the associated genes": "Seleziona uno o più termini OMIM o una PanelApp per interrogare i geni associati",
    "Symptoms": "Sintomi",
    "Collect the HPOs from the index case or search more HPOs to query the associated genes": "Raccogliere gli HPO dal caso indice o cercare più HPO per interrogare i geni associati",
    "Pathways are retrieved from REACTOME and associations with genes from The Comparative Toxicogenomics database": "I percorsi vengono recuperati da REACTOME e le associazioni con i geni dal database Comparative Toxicogenomics",
    "Clear gene list": "Cancella elenco dei geni",
    "Operator": "Operatore",
    "Union": "Unione",
    "Intersection": "Intersezione",
    "Upload comma separated list of HGNC identifiers": "Carica un elenco separato da virgole di identificatori HGNC",
    "(The file is not stored in GPAP)": "(Il file non è archiviato in GPAP)",
    "Genes not validated": "Geni non convalidati",
    "Current selected genes by applying ": "Geni selezionati attuali mediante l'applicazione",
    " of gene lists": "delle liste di geni",
    "No genes selected": "Nessun gene selezionato",
    "All gene symbols were validated using Ensembl": "Tutti i simboli genetici sono stati convalidati utilizzando Ensembl",
    " gene symbols could not be validated using ": "Non è stato possibile convalidare i simboli genetici utilizzando",
    "Validating gene symbols..": "Convalida dei simboli genetici..",
    "Gene Validation": "Validazione genetica",
    "In the next step, you can extract genes from HPOs and add them to your queries": "Nel passaggio successivo, puoi estrarre i geni dagli HPO e aggiungerli alle tue query",
    "Next": "Prossimo",
    "Back": "Indietro",
    "Intersect the genes extracted from the HPOs ": "Intersecare i geni estratti dagli HPO",
    "Apply Intersection of Genes": "Applicare l'intersezione dei geni",
    "Make union of extracted genes (default)": "Effettua l'unione dei geni estratti (impostazione predefinita)",
    "Apply Union of Genes": "Applicare l'Unione dei geni",
    "You included the genes in your query. You made an ": "Hai incluso i geni nella tua query. Hai fatto un",
    " between the HPO genes": "tra i geni HPO",
    "Reset HPO gene list": "Reimposta l'elenco dei geni HPO",
    "Add Genes to Query": "Aggiungi geni alla query",
    "No genes shared across all HPOs": "Nessun gene condiviso tra tutti gli HPO",
    "No Genes": "Nessun gene",
    "Clear current HPO selection": "Cancella la selezione HPO corrente",
    "HPOs collected from analysed index case": "HPO raccolti dal caso indice analizzato",
    "Get HPOs from Participants": "Ottieni HPO dai partecipanti",
    "search for HPO terms": "cercare termini HPO",
    "You have now": "Lo hai adesso",
    "HPO terms selected": "Termini HPO selezionati",
    "Select Resource:": "Seleziona risorsa:",
    "HPO Ontology": "Ontologia HPO",
    "Select Method:": "Seleziona metodo:",
    "of genes in this section is applied ": "dei geni in questa sezione viene applicata",
    "Select a predefined gene list": "Seleziona un elenco di geni predefinito",
    "search for OMIM terms": "cercare termini OMIM",
    "Delete OMIM associated genes": "Elimina i geni associati a OMIM",
    "Enter multiple OMIM ids or names of disorders": "Inserisci più ID OMIM o nomi di disturbi",
    "Search for OMIM terms": "Cerca termini OMIM",
    "Request Error": "Richiedi errore",
    "Error": "Errore",
    "Loading genes...": "Caricamento dei geni...",
    "Panel ": "Pannello",
    "includes a total of ": "comprende un totale di",
    " entities. There are ": "entità. Ci sono",
    " distinct genes in the ": "geni distinti nel",
    " green list": "lista verde",
    "Read more of this panel in PanelApp": "Maggiori informazioni su questo pannello in PanelApp",
    "Delete PanelApp genes from query": "Elimina i geni PanelApp dalla query",
    "Add Panel App Genes": "Aggiungi i geni dell'app Panel",
    "Type of Panel": "Tipo di pannello",
    "Genes associated to": "Geni associati a",
    "No genes": "Nessun gene",
    "Search Pathways": "Percorsi di ricerca",
    "Delete Pathway associated genes": "Elimina i geni associati al percorso",
    "Search for Pathways": "Cerca percorsi",
    "Gene Search": "Ricerca genetica",
    "search for a gene name": "cercare il nome di un gene",
    "A number between 0-1": "Un numero compreso tra 0 e 1",
    "INTERNAL FREQUENCY": "FREQUENZA INTERNA",
    "Clear Filters": "Cancella filtri",
    "PASS + unannotated (NA)": "PASS + senza annotazioni (NA)",
    "non-PASS": "non PASS",
    "Selected Positions": "Posizioni selezionate",
    "X": "X",
    "The X chromosome has been automatically selected to match the selected inheritance": "Il cromosoma X è stato selezionato automaticamente per corrispondere all'eredità selezionata",
    "Info": "Informazioni",
    "Select a chromosome. Positions are considered ONLY if a chromosome is selected": "Seleziona un cromosoma. Le posizioni vengono considerate SOLO se viene selezionato un cromosoma",
    "Chromosome": "Cromosoma",
    "Start Position": "Posizione iniziale",
    "Chromosome required": "Cromosoma richiesto",
    "End Position": "Posizione finale",
    "Upload BED file": "Carica il file BED",
    "Upload Coordinate file": "Carica il file delle coordinate",
    "When applied, will return only variants that fall within a run of homozygosity of at least the length selected in the first affected sample": "Una volta applicato, restituirà solo le varianti che rientrano in una serie di omozigosità pari almeno alla lunghezza selezionata nel primo campione interessato",
    "Minimum run of homozygosity length": "Corsa minima della lunghezza di omozigosità",
    "Not Specified": "Non specificato",
    "Annotated as disease causing": "Annotato come causa di malattia",
    "Disease causing": "Causa di malattie",
    "Polymorphism": "Polimorfismo",
    "Neutral": "Neutro",
    "Damaging": "Dannoso",
    "Tolerated": "Tollerato",
    "Probably damaging": "Probabilmente dannoso",
    "Possibly damaging": "Forse dannoso",
    "Oncogenic": "Oncogenico",
    " Likely Oncogenic": "Probabilmente oncogeno",
    " VUS": "VUS",
    " Benign": "Benigno",
    " Likely Benign": "Probabilmente benigno",
    "Likely Benign": "Probabilmente benigno",
    " Pathogenic": "Patogeno",
    " Likely Pathogenic": "Probabile patogeno",
    "0": "0",
    "20": "20",
    "40": "40",
    "60": "60",
    "80": "80",
    "100": "100",
    "Phred-scaled CADD score, indicating level of deleteriousness e.g. 20 (predicted top 1% most deleterious)": "Punteggio CADD su scala Phred, che indica il livello di deleterietà, ad es. 20 (1% più deleterio previsto)",
    "CADD Prediction": "Previsione CADD",
    "High": "Alto",
    "Moderate": "Moderare",
    "Low": "Basso",
    "Modifier": "Modificatore",
    "transcript_ablation": "trascrizione_ablazione",
    "splice_acceptor_variant": "splice_acceptor_variant",
    "splice_donor_variant": "splice_donor_variant",
    "stop_gained": "stop_guadagnato",
    "frameshift_variant": "frameshift_variante",
    "stop_lost": "stop_lost",
    "start_lost": "inizio_perso",
    "transcript_amplification": "trascrizione_amplificazione",
    "feature_elongation": "feature_allungamento",
    "feature_truncation": "feature_troncamento",
    "inframe_insertion": "inframe_inserimento",
    "inframe_deletion": "inframe_deletion",
    "missense_variant": "missense_variant",
    "protein_altering_variant": "proteina_alterazione_variante",
    "splice_donor_5th_base_variant": "splice_donor_5th_base_variant",
    "splice_region_variant": "splice_region_variant",
    "splice_donor_region_variant": "splice_donor_region_variant",
    "splice_polypyrimidine_tract_variant": "splice_polypyrimidine_tract_variant",
    "incomplete_terminal_codon_variant": "incomplete_terminal_codon_variant",
    "start_retained_variant": "start_retention_variant",
    "stop_retained_variant": "stop_retention_variant",
    "synonymous_variant": "sinonimo_variante",
    "coding_sequence_variant": "codifica_sequenza_variante",
    "mature_miRNA_variant": "mature_miRNA_variant",
    "5_prime_UTR_variant": "5_prime_UTR_variante",
    "3_prime_UTR_variant": "3_prime_UTR_variante",
    "non_coding_transcript_exon_variant": "non_coding_transcript_exon_variant",
    "intron_variant": "intron_variante",
    "NMD_transcript_variant": "NMD_trascrizione_variante",
    "non_coding_transcript_variant": "non_coding_transcript_variant",
    "coding_transcript_variant": "codifica_trascrizione_variante",
    "upstream_gene_variant": "upstream_gene_variant",
    "downstream_gene_variant": "downstream_gene_variant",
    "TFBS_ablation": "TFBS_ablazione",
    "TFBS_amplification": "TFBS_amplificazione",
    "TF_binding_site_variant": "TF_binding_site_variant",
    "regulatory_region_ablation": "regolamentazione_regione_ablazione",
    "regulatory_region_amplification": "regolamentazione_region_amplification",
    "regulatory_region_variant": "regolatore_region_variante",
    "intergenic_variant": "variante_intergenica",
    "sequence_variant": "sequenza_variante",
    "SNV": "SNV",
    "INDEL": "INDEL",
    "Mane Select": "Criniera Seleziona",
    "Selected samples": "Campioni selezionati",
    "Any Sample": "Qualsiasi campione",
    "Variant of uncertain significance": "Variante di significato incerto",
    "Conflicting interpretations": "Interpretazioni contrastanti",
    "Any": "Qualunque",
    "Coding": "Codifica",
    "RNA": "RNA",
    "Known": "Conosciuto",
    "Predicted driver: Tier 1": "Driver previsto: Livello 1",
    "Predicted driver: Tier 2": "Driver previsto: Livello 2",
    "Predicted passenger": "Passeggero previsto",
    "Apply": "Fare domanda a",
    "Click here if you want to delete the Filter-Set from the database. This is an irreversible action. ": "Fare clic qui se si desidera eliminare il set di filtri dal database. Questa è un'azione irreversibile.",
    "GPAP Predefined Filters are curated by the GPAP team": "I filtri predefiniti GPAP sono curati dal team GPAP",
    "Select a predefined filter set": "Seleziona un set di filtri predefinito",
    "Reset the applied filter-set": "Reimposta il set di filtri applicato",
    "Reset": "Reset",
    "Filter Set deleted": "Set di filtri eliminato",
    "No leading and trailing spaces are allowed": "Non sono ammessi spazi iniziali e finali",
    "Name already exists.": "Il nome esiste già.",
    "Filter Panel": "Pannello filtro",
    "Run Query": "Esegui interrogazione",
    "Reset Filters": "Reimposta filtri",
    "Save Filter-Set": "Salva set di filtri",
    "Filter Name": "Nome del filtro",
    "Filter Description": "Descrizione del filtro",
    "filter_description": "descrizione_filtro",
    "Save Filter": "Salva filtro",
    "Filters saved in the database. You can find the filter-set in the section below.": "Filtri salvati nel database. Puoi trovare il set di filtri nella sezione seguente.",
    "Filter Set Saved Correctly": "Set di filtri salvato correttamente",
    "Create New Analysis": "Crea nuova analisi",
    "Genotype Settings": "Impostazioni del genotipo",
    "You have created ": "Hai creato",
    " analyses.": "analisi.",
    "You can now create a query that will be applied to the genotype(s). Select your filters and run the new query.": "Ora puoi creare una query che verrà applicata ai genotipi. Seleziona i filtri ed esegui la nuova query.",
    "Apply to current Samples": "Applica ai campioni attuali",
    "or start from new Samples:": "o iniziare da nuovi campioni:",
    "custom": "costume",
    "Autosomal Dominant": "Autosomico dominante",
    "Autosomal Recessive Homozygous": "Omozigote autosomico recessivo",
    "Autosomal Recessive Compound Heteroz.": "Composto autosomico recessivo Heteroz.",
    "De Novo": "De Novo",
    "Deleterious": "Deleterio",
    "tolerated": "tollerato",
    "Disease_causing_automatic": "Disease_causing_automatic",
    "Disease_causing": "Causare malattie",
    "Probably harmless": "Probabilmente innocuo",
    "Known to be harmless": "Noto per essere innocuo",
    "Oncegenic": "Oncegenico",
    "Likely Oncogenic": "Probabilmente oncogeno",
    "VUS": "VUS",
    "Likely Pathogenic": "Probabile patogeno",
    "Variants distributions": "Distribuzioni delle varianti",
    "GnomAD Population": "Popolazione GnomAD",
    "Filter Sets": "Set di filtri",
    "Predefined Gene Lists": "Elenchi di geni predefiniti",
    "Analysis Settings": "Impostazioni di analisi",
    "Analysis Parameters": "Parametri di analisi",
    "Standard Pipelines": "Condutture standard",
    "Preview External Resources": "Anteprima delle risorse esterne",
    "Filter Set Manager": "Gestore set di filtri",
    "Create and Save a new Filter Set": "Crea e salva un nuovo set di filtri",
    "Available Filter Sets": "Set di filtri disponibili",
    "GPAP default": "Impostazione predefinita GPAP",
    "User created": "Utente creato",
    "No description": "Nessuna descrizione",
    "View filters": "Visualizza filtri",
    "Applied filters:": "Filtri applicati:",
    "No filters found": "Nessun filtro trovato",
    "List of genes": "Elenco dei geni",
    "Search:": "Ricerca:",
    "No results": "Nessun risultato",
    "This list contains": "Questo elenco contiene",
    "genes": "geni",
    "Export in .txt": "Esporta in formato .txt",
    "Save List": "Salva elenco",
    "Create a new List": "Crea un nuovo elenco",
    "List Information": "Elenco informazioni",
    "List Title": "Titolo dell'elenco",
    "List Source": "Elenco fonti",
    "Notes": "Note",
    "Gene List": "Elenco dei geni",
    "Variant Positions": "Posizioni delle varianti",
    "Visibility": "Visibilità",
    "List Visibility": "Visibilità dell'elenco",
    "When active, the list will be usable for analyses. If this list has the same name as an existing one, the previous one will become inactive to users.": "Quando attivo, l'elenco sarà utilizzabile per le analisi. Se questo elenco ha lo stesso nome di uno esistente, quello precedente diventerà inattivo per gli utenti.",
    "Active": "Attivo",
    "Upload a List": "Carica un elenco",
    "View Lists": "Visualizza elenchi",
    "File type": "Tipo di file",
    "Please select file type": "Seleziona il tipo di file",
    "GPAP Gene File": "File del gene GPAP",
    ".list File": "File .list",
    "Comma Separated List": "Elenco separato da virgole",
    "ACTIVE": "ATTIVO",
    "Not Active": "Non attivo",
    "Predefined Gene List Info": "Informazioni sull'elenco dei geni predefiniti",
    "Predefined Lists in GPAP": "Elenchi predefiniti in GPAP",
    "Visible List": "Elenco visibile",
    "Are you sure to delete the Predefined Gene List?": "Sei sicuro di eliminare l'elenco dei geni predefiniti?",
    "Yes, delete it": "Sì, cancellalo",
    "Gene list was validated": "L'elenco dei geni è stato convalidato",
    "Upload Gene list": "Carica l'elenco dei geni",
    "Some genes could not be validated. Please check them and re-upload the file:": "Alcuni geni non possono essere validati. Controllali e ricarica il file:",
    "Please check the text": "Si prega di controllare il testo",
    "Genes are not in the correct format, please check the text": "I geni non sono nel formato corretto, controlla il testo",
    "Fields should not be empty, please check the text": "I campi non devono essere vuoti, controlla il testo",
    "Gene list is empty. Please check the file.": "L'elenco dei geni è vuoto. Si prega di controllare il file.",
    "Cancel Gene List Upload": "Annulla caricamento elenco geni",
    "Upload a GPAP gene file in txt format": "Carica un file del gene GPAP in formato txt",
    "Be sure that the file is in the correct format and all required fields are present": "Assicurati che il file sia nel formato corretto e che tutti i campi obbligatori siano presenti",
    "Select file": "Seleziona file",
    "Download our template file and fill it in with your data": "Scarica il nostro file modello e compilalo con i tuoi dati",
    "Download sample file": "Scarica il file di esempio",
    "Validating genes..": "Convalidare i geni..",
    "Upload a .list file": "Carica un file .list",
    "The .list file is the legacy file format used to store predefined gene lists in the GPAP. This upload is meant for CNAG users. For more information, contact Steve Laurie.": "Il file .list è il formato file legacy utilizzato per memorizzare elenchi di geni predefiniti nel GPAP. Questo caricamento è destinato agli utenti CNAG. Per ulteriori informazioni, contattare Steve Laurie.",
    "The file must contain a JSON array of Predefined Gene List Objects.": "Il file deve contenere un array JSON di oggetti dell'elenco di geni predefiniti.",
    "upload .list file": "caricare il file .list",
    "lists parsed correctly and ready to be saved.": "elenchi analizzati correttamente e pronti per essere salvati.",
    "Save Lists": "Salva elenchi",
    "Preview of External resources accessible in this instance:": "Anteprima delle risorse esterne accessibili in questa istanza:",
    "SNV external resources": "Risorse esterne SNV",
    "CNV external resources": "Risorse esterne del CNV",
    "Edit": "Modificare",
    "DIR LIST": "ELENCO DIR",
    "Case ID": "Identificativo del caso",
    "Patient ID": "ID paziente",
    "Analysis Type": "Tipo di analisi",
    "Priority": "Priorità",
    "Deadline": "Scadenza",
    "Assignee(s)": "Assegnatario/i",
    "Creation Date": "Data di creazione",
    "Tumor Experiment": "Esperimento sul tumore",
    "Control Experiment": "Esperimento di controllo",
    "Clinical referrer": "Referente clinico",
    "Clinical contact": "Contatto clinico",
    "Hospital Name": "Nome dell'ospedale",
    "Tumor characteristics": "Caratteristiche del tumore",
    "Tumor purity": "Purezza del tumore",
    "Average ploidy": "Ploidia media",
    "Microsatellite status": "Stato del microsatellite",
    "Tumor mutational burden": "Carico mutazionale del tumore",
    "Homologous recombination deficiency (HRD) status": "Stato di deficit di ricombinazione omologa (HRD).",
    "Bin-level log2 coverages and segmentation calls": "Coperture log2 a livello di bin e chiamate di segmentazione",
    "Source: CNVkit": "Fonte: CNVkit",
    "Copy number per chromosome": "Numero di copie per cromosoma",
    "Tumor Sample ID:": "ID campione di tumore:",
    "Mutational signatures": "Firme mutazionali",
    "Source: SigProfilerAssignment": "Fonte: SigProfilerAssignment",
    "Somatic Tumor Only": "Solo tumore somatico",
    "Somatic Tumor Normal": "Tumore somatico normale",
    "Somatic Fusion Analysis": "Analisi della fusione somatica",
    "Germline": "Linea germinale",
    "New Instand DIR Study": "Nuovo studio Instand DIR",
    "Request Overview": "Richiedi panoramica",
    "Reports": "Rapporti",
    "Tumor Genetic Overview": "Panoramica genetica del tumore",
    "Clinical Evidence": "Evidenza clinica",
    "Gene Fusions": "Fusioni geniche",
    "Request": "Richiesta",
    "To Do": "Fare",
    "In Progress": "In corso",
    "Done": "Fatto",
    "Medium": "Medio",
    "General Info": "Informazioni generali",
    "Request ID": "Richiedi un documento d'identità",
    "Administrative Info": "Informazioni amministrative",
    "Creator": "Creatore",
    "Creation date": "Data di creazione",
    "Hospital name": "Nome dell'ospedale",
    "Clinical referrer contact info": "Informazioni di contatto del referente clinico",
    "Filter Set": "Insieme di filtri",
    "Update Filters": "Aggiorna filtri",
    "Patient Info": "Informazioni sul paziente",
    "Clinical Info": "Informazioni cliniche",
    "Clinical referral": "Rinvio clinico",
    "Observed Symptoms": "Sintomi osservati",
    "Diagnostic date": "Data diagnostica",
    "Clinical diagnosis": "Diagnosi clinica",
    "Case number pathology": "Patologia del numero di casi",
    "Treatment history and response": "Storia del trattamento e risposta",
    "Cancer type": "Tipo di cancro",
    "Tumor Sample and Experiment Info": "Campione di tumore e informazioni sull'esperimento",
    "Sample Info": "Informazioni sul campione",
    "Sample ID": "ID campione",
    "Sample site of origin": "Sito di origine del campione",
    "Sample site of origin status": "Stato del sito di origine campione",
    "Sample fixation": "Fissazione del campione",
    "Purity": "Purezza",
    "Experiment Info": "Informazioni sull'esperimento",
    "Control Experiment ID": "ID esperimento di controllo",
    "Library strategy": "Strategia della biblioteca",
    "Kit": "Kit",
    "Control Sample and Experiment Info": "Controlla le informazioni sul campione e sull'esperimento",
    "Relevant Observations": "Osservazioni rilevanti",
    "Sample quality observations": "Osservazioni sulla qualità del campione",
    "Sequencing quality observations": "Osservazioni sulla qualità del sequenziamento",
    "Fusion": "Fusione",
    "Fusioned genes": "Geni fusi",
    "Junction read count": "Conteggio letture giunzioni",
    "Left Gene": "Gene sinistro",
    "Left gene": "Gene sinistro",
    "Left breakpoint": "Punto di interruzione sinistro",
    "Right Gene": "Giusto Gene",
    "Right gene": "Il gene giusto",
    "Right breakpoint": "Punto di interruzione giusto",
    "SNVs and InDels - Somatic": "SNV e InDels - Somatico",
    "SNVs and InDels - Germline": "SNV e InDels - Germline",
    "CNVs and SVs": "CNV e SV",
    "Treatments": "Trattamenti",
    "Gene Evidence": "Prove genetiche",
    "Genotype": "Genotipo",
    "Phenotype": "Fenotipo",
    "Haplotype1": "Aplotipo1",
    "Haplotype1 Evidence": "Prova dell'aplotipo1",
    "Haplotype2": "Aplotipo2",
    "Haplotype2 Evidence": "Prova dell'aplotipo2",
    "Variant Data": "Dati sulle varianti",
    "Clinical Reports": "Rapporti clinici",
    "Patient Reports": "Rapporti dei pazienti",
    "Clinical reports": "Rapporti clinici",
    "Report ID": "Identificativo del rapporto",
    "Report Name": "Nome del rapporto",
    "Genomic breakpoints detected": "Rilevati breakpoint genomici",
    "Clinically relevant gene": "Gene clinicamente rilevante",
    "Clinical significance**": "Significato clinico**",
    "cDNA": "cDNA",
    "Protein": "Proteina",
    "Associated Clinical Report ID": "ID del referto clinico associato",
    "Genetic Findings": "Risultati genetici",
    "Clinical Evidence and Patient Management": "Evidenza clinica e gestione del paziente",
    "Disclaimer": "Disclaimer",
    "Technical Limitations": "Limitazioni tecniche",
    "New Clinical Report": "Nuovo Rapporto Clinico",
    "Preview": "Anteprima",
    "Create": "Creare",
    "Annex 5: Pharmacogenomics": "Allegato 5: Farmacogenomica",
    "These are the haplotypes that were tagged in the Pharmacogenomics tab of this Interpretation Request.Check all the haplotypes that you want to display in the final report.": "Questi sono gli aplotipi contrassegnati nella scheda Farmacogenomica di questa richiesta di interpretazione. Seleziona tutti gli aplotipi che desideri visualizzare nel rapporto finale.",
    "Haplotype Interpretation": "Interpretazione dell'aplotipo",
    "No genes were targeted": "Nessun gene è stato preso di mira",
    "The following annexes complete the report, which should be reviewed as a whole.": "I seguenti allegati completano il rapporto, che è opportuno rivedere nel suo complesso.",
    "Gene Symbol": "Simbolo del gene",
    "Mean Coverage": "Copertura media",
    "Median Coverage": "Copertura mediana",
    "C10": "C10",
    "C15": "C15",
    "C20": "C20",
    "Gene MIM Number": "Numero del gene MIM",
    "Phenotype MIM Number": "Numero MIM del fenotipo",
    "Transcript cDNA": "Trascrizione del cDNA",
    "GQ": "GQ",
    "DP": "DP",
    "REF count": "Conteggio RIF",
    "ALT count": "Conteggio ALT",
    "AAF": "AAF",
    "Duplication Rate ": "Tasso di duplicazione",
    "Uniformity": "Uniformità",
    "Gene symbol": "Simbolo del gene",
    "Mean coverage": "Copertura media",
    "C1": "C1",
    "These are the evidences that were tagged in the": "Queste sono le prove che sono state taggate nel",
    " tab of this Interpretation Request.": "scheda di questa richiesta di interpretazione.",
    "Check all the evidences that you want to display in the final report.": "Controlla tutte le prove che desideri visualizzare nel rapporto finale.",
    "The": "IL",
    " Clinical Significance": "Significato clinico",
    "field shows the comments that were added when tagging an evidence.": "Il campo mostra i commenti che sono stati aggiunti quando si tagga una prova.",
    "The original tag comment ": "Il commento del tag originale",
    "will not": "non lo farà",
    " be modified here. Your modifications will only be visible in the exported report.": "essere modificato qui. Le tue modifiche saranno visibili solo nel report esportato.",
    "Conclusion": "Conclusione",
    "These are the interpretation comments that were written in each evidence tag.": "Questi sono i commenti interpretativi scritti in ciascuna etichetta di prova.",
    "variant": "variante",
    "Therapy": "Terapia",
    "Cancer Type": "Tipo di cancro",
    "Comment": "Commento",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to the American College of Medical Genetics (ACMG) recommendations (Richards et al. 2015, Riggs et al. 2020), ClinGen recommendations (Welcome to ClinGen ) and the Human Variation Variant Society (HGVS)": "Il significato clinico delle varianti genetiche viene interpretato utilizzando le informazioni attualmente disponibili ed è soggetto a cambiamenti con l'espansione delle conoscenze scientifiche. Le varianti genomiche sono classificate e riportate secondo le raccomandazioni dell'American College of Medical Genetics (ACMG) (Richards et al. 2015, Riggs et al. 2020), le raccomandazioni ClinGen (Welcome to ClinGen) e la Human Variation Variant Society (HGVS)",
    "Genetic counselling and recommendations are based on GeneReviews guidelines (Margaret et al 1993-2020).": "La consulenza genetica e le raccomandazioni si basano sulle linee guida GeneReviews (Margaret et al 1993-2020).",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to ClinGen, CGC and VICC recommendations (Horak et al 2022, Gene Fusion Specification —": "Il significato clinico delle varianti genetiche viene interpretato utilizzando le informazioni attualmente disponibili ed è soggetto a cambiamenti con l'espansione delle conoscenze scientifiche. Le varianti genomiche sono classificate e riportate secondo le raccomandazioni ClinGen, CGC e VICC (Horak et al 2022, Gene Fusion Specifica —",
    "Only FDA or EMA approved treatments are reported. The conclusion of this report is based solely on the results of the RNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician.": "Vengono riportati solo i trattamenti approvati dalla FDA o dall'EMA. La conclusione di questo rapporto si basa esclusivamente sui risultati del sequenziamento dell'RNA del tumore e sul tipo di tumore ricevuto. L'interpretazione finale delle conseguenze cliniche di questo rapporto dovrebbe pertanto essere sempre effettuata dal medico curante.",
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to ClinGen, CGC and VICC recommendations (Horak et al 2022) and the Human Variation Variant Society (HGVS)": "Il significato clinico delle varianti genetiche viene interpretato utilizzando le informazioni attualmente disponibili ed è soggetto a cambiamenti con l'espansione delle conoscenze scientifiche. Le varianti genomiche sono classificate e riportate secondo le raccomandazioni ClinGen, CGC e VICC (Horak et al 2022) e la Human Variation Variant Society (HGVS)",
    "Only FDA or EMA approved treatments are reported. The conclusion of this report is based solely on the results of the DNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician.": "Vengono riportati solo i trattamenti approvati dalla FDA o dall'EMA. La conclusione di questo rapporto si basa esclusivamente sui risultati del sequenziamento del DNA del tumore e sul tipo di tumore ricevuto. L'interpretazione finale delle conseguenze cliniche di questo rapporto dovrebbe pertanto essere sempre effettuata dal medico curante.",
    "Issuing centre": "Centro di emissione",
    "Requesting centre": "Centro richiedente",
    "Referrer": "Referente",
    "Reporting date": "Data del rapporto",
    "Name": "Nome",
    "Surname": "Cognome",
    "Sample Type": "Tipo di campione",
    "Biopsy Location": "Posizione della biopsia",
    "Reason for referral": "Motivo del rinvio",
    "Performed Study": "Studio eseguito",
    "Test performed (Library strategy)": "Test eseguito (Strategia della Biblioteca)",
    "*Based upon reference genome version GRCh38": "*Basato sulla versione del genoma di riferimento GRCh38",
    "**Based upon ClinGen, CGC and VICC recommendations (Horak et al. 2022)": "**Sulla base delle raccomandazioni ClinGen, CGC e VICC (Horak et al. 2022)",
    "**Based upon ACMG and ClinGen recommendations (Richards et al. 2015 and  ": "**Sulla base delle raccomandazioni ACMG e ClinGen (Richards et al. 2015 e",
    "These are the variants that were tagged in the": "Queste sono le varianti taggate nel file",
    " tab of this Interpretation Request. Check all the variants that you want to display in the final report.": "scheda di questa richiesta di interpretazione. Controlla tutte le varianti che desideri visualizzare nel rapporto finale.",
    "Genomic breakpoints detected*": "Rilevati breakpoint genomici*",
    "Included gene(s)": "Gene(i) incluso(i)",
    "Genomic position*": "Posizione genomica*",
    "Recommendations": "Raccomandazioni",
    "No information available about the Experiment Type": "Nessuna informazione disponibile sul tipo di esperimento",
    "Genomic Position:": "Posizione genomica:",
    "No ": "NO",
    " calculated": "calcolato",
    "Please fill these in manually after downloading the report": "Compilarli manualmente dopo aver scaricato il rapporto",
    "Report name": "Nome del rapporto",
    "This field is required.": "Questo campo è obbligatorio.",
    "Associated Clinical Report": "Rapporto clinico associato",
    "Select File": "Seleziona File",
    "Supported files: .doc, .docx, .pdf, .txt": "File supportati: .doc, .docx, .pdf, .txt",
    "Maximum file size: 10MB": "Dimensione massima del file: 10 MB",
    "File uploaded successfully": "File caricato con successo",
    "OK": "OK",
    "Gene name": "Nome del gene",
    "Variant": "Variante",
    "Level of evidence": "Livello di prova",
    "Match": "Incontro",
    "Therapy status": "Stato della terapia",
    "Drug status ": "Stato di droga",
    "Evidence": "Prova",
    "Evidence CGI": "Prove CGI",
    "Evidence CIViC ": "Prova CIViC",
    "Participants included in the study": "Partecipanti inclusi nello studio",
    "Minimum read depth at variant position": "Profondità di lettura minima nella posizione della variante",
    "Min-Max Alternate Allele Freq": "Freq. allelica alternativa min-max",
    "Minimum genotype quality (Phred-scaled) for variant position": "Qualità minima del genotipo (scala Phred) per la posizione della variante",
    "Type in a name": "Digita un nome",
    "Configuration details": "Dettagli di configurazione",
    "Affected": "Ricercato",
    "set Genotype Settings": "impostare le impostazioni del genotipo",
    "Quality Settings": "Impostazioni di qualità",
    "No genotype configured. The sample will not be included in the analysis": "Nessun genotipo configurato. Il campione non verrà incluso nell'analisi",
    "Minimum Depth": "Profondità minima",
    "Minimum Genotype Quality": "Qualità minima del genotipo",
    "Alternate Allele Frequency": "Frequenza allelica alternativa",
    "Please, bear in mind that the predefined inheritances take into account the reported affected status of the family members which in some cases may force unrealistic configurations. In such cases, please use the custom configuration to formulate your hypothesis\\n": "Si prega di tenere presente che le eredità predefinite tengono conto dello stato di affetto segnalato dei familiari che in alcuni casi può forzare configurazioni non realistiche. In questi casi, utilizza la configurazione personalizzata per formulare la tua ipotesi\\n",
    "Select a simulated mode of inheritance configuration:": "Selezionare una modalità simulata di configurazione dell'ereditarietà:",
    "or configure a custom mode of inheritance": "o configurare una modalità di ereditarietà personalizzata",
    "10": "10",
    "30": "30",
    "50": "50",
    "70": "70",
    "90": "90",
    "0.1": "0,1",
    "0.2": "0,2",
    "0.3": "0,3",
    "0.4": "0.4",
    "0.5": "0,5",
    "0.6": "0,6",
    "0.7": "0,7",
    "0.8": "0,8",
    "0.9": "0.9",
    "1": "1",
    "Only Visible to you": "Visibile solo a te",
    "Shared with your groups": "Condiviso con i tuoi gruppi",
    "Visible to All": "Visibile a tutti",
    "Amino Acid length": "Lunghezza degli aminoacidi",
    "CDS Position": "Posizione del CDS",
    "Unaffective Relative": "Parente non affettivo",
    "Gene Coding": "Codificazione genetica",
    "Functional Class": "Classe Funzionale",
    "Aminoacid Length": "Lunghezza degli aminoacidi",
    "Aminoacid Change": "Cambiamento degli aminoacidi",
    "Codon Change": "Cambiamento del codone",
    "Transcript Biotype": "Biotipo della trascrizione",
    "GnomAD AF": "GnomADAF",
    "GnomAD AF PopMAx": "GnomAD AF PopMAx",
    "CADD Pred.": "Predisposizione CADD",
    "SIFT Pred.": "SIFTPred.",
    "Polyphen2 Hvar Pred.": "Polifen2 Hvar Pred.",
    "Mutation Taster Pred.": "Assaggiatore di mutazione Pred.",
    "Indel": "Indel",
    "Reported HPOs": "HPO segnalati",
    "HPOs": "HPO",
    "Reported Diagnosis": "Diagnosi riportata",
    "Obs. HPSs": "Oss. HPS",
    "Position - Start": "Posizione - Inizio",
    "Positions - End": "Posizioni - Fine",
    "Polyphen2 hvar": "Polifene2 hvar",
    "Tagged Variants": "Varianti contrassegnate",
    "Variant Class": "Classe variante",
    "ClinVar Classification": "Classificazione ClinVar",
    " + labelFromStack + ": "+ etichettaDalloStack +",
    "docs": "documenti",
    "Clear": "Chiaro",
    "Loading...": "Caricamento...",
    "breadcrumb": "pangrattato",
    "Toggle navigation": "Attiva/disattiva la navigazione",
    "label": "etichetta",
    "dummyInput": "dummyInput",
    "menu": "menu",
    "container": "contenitore",
    "indicatorContainer": "indicatoreContenitore",
    "indicatorSeparator": "indicatoreSeparatore",
    "loadingIndicator": "caricamentoIndicatore",
    "control": "controllare",
    "group": "gruppo",
    "input": "ingresso",
    "multiValue": "multivalore",
    "option": "opzione",
    "placeholder": "segnaposto",
    "singleValue": "valore singolo"
}