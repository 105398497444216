import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import MappedText from "./analysis-page/components/clinical-info/mapText";

const useStyles = makeStyles((theme) => ({
    alert: {
        margin: theme.spacing(2),
    },
}));

const WarningBox = ({ text }) => {
    const classes = useStyles();

    return (
        <Alert severity="warning" className={classes.alert}>
           <MappedText text={text}/>
        </Alert>
    );
};

export default WarningBox;