import axios from "axios";
import { getValueByPath, setValueByPath } from "./utilsValues";




export const axiosApi = (options) => {
  const {
    url,
    method = "GET",
    dataSend,
    token,
    isCheck,
    split,
    dataHeader,
    nestedInfo,
    nestedInfoB,
    setMainData,
    locationData,
    setData,
    setResApi,
  } = options;

  let headers = {};
  if (token) {
    headers.Authorization = token;
  }

  const handleResponse = (res) => {

    setResApi(res.data);

    let data = ("items" in res.data) ? res.data.items : res.data;
    const dataToMap = locationData ? res.data[locationData] : data;

    setMainData(dataToMap);

    const respuesta = dataToMap.map((item) => {
      const data = {};

      const mapInfo = isCheck ? split : dataHeader;

      mapInfo.forEach(({ columns }) => {
        columns.forEach((col) => {
          let propertyValue = getValueByPath(item, col.dataSearch);
          if (propertyValue !== undefined) {
            setValueByPath(data, col.dataSearch, propertyValue);
          }
        });
      });

      const fullRespApi = dataToMap;

      const nested = isCheck
        ? nestedInfoB.map(({ dataSearch }) => {
            if (typeof item[dataSearch] === "object") {
              return item[dataSearch];
            }
            return {
              [dataSearch]: Array.isArray(item[dataSearch])
                ? item[dataSearch]
                : [item[dataSearch]],
            };
          })
        : nestedInfo.map(({ dataSearch }) => {
            return {
              [dataSearch]: Array.isArray(item[dataSearch])
                ? item[dataSearch]
                : [item[dataSearch]],
            };
          });

      return { ...data, fullRespApi, nested };
    });

    setData(respuesta);
  };

  const handleError = (error) => {

    console.error("Error", error);
    setData([]);
    setMainData([]);
  };

  if (method.toLowerCase() === "get") {
    axios.get(url, { headers }).then(handleResponse).catch(handleError);
  } else if (method.toLowerCase() === "post") {
    axios.post(url, dataSend, { headers }).then(handleResponse).catch(handleError);
  }
};

export const axiosInstance = (urlBase) => {
  const instance = axios.create({
    baseURL: urlBase,
  });
  return instance;
};
