import React, { useState, useEffect } from "react";
import SelectedRowInfoBox from "../../../../shared-components/SelectedRowInfoBox.js";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { renderTextField } from "../../../../../create-report/sections/genetic_findings/utils.js";
import MappedText from "../../../../shared-components/mapText.js";
import ReportTypography from "../../../../shared-components/ReportTypography.js";

export default function PharmacogenomicsInterpretation({ selectedRows, handleSectionUpdate }) {
    const [haplotypeData, setHaplotypeData] = useState([]);

    
    useEffect(() => {
        if (selectedRows && selectedRows.length > 0) {
            setHaplotypeData(selectedRows);
        } else {
            setHaplotypeData([]);  
        }
    }, [selectedRows]);

    const handleTextChange = (index, value) => {
        setHaplotypeData(prevState => {
            const updatedData = [...prevState];
            updatedData[index].interpretation = value || "";
            handleSectionUpdate({ rows: updatedData}, "Pharmacogenomics_Interpretation");
            return updatedData;
        });
    };

    const renderInfoBoxes = () => {
        return haplotypeData.map((s, i) => {
            const selected = selectedRows;
            const fields = {
                "field1":`${s.gene}:`,
                "field2": s.genotype,
            };

            return (
                <SelectedRowInfoBox
                    key={s.gene + "_" + i}
                    fields={fields}
                    comments={s.interpretation}
                    selected={selected}
                    dataType={"haplotype"}
                    handleOnTextChange={(event) => handleTextChange(i, event.target.value)}
                />
            );
        });
    };

    return (
        <>
             <Typography style={{ fontSize: '16px', fontWeight: '700', marginBottom: '10px' }}><MappedText text={"Haplotype Interpretation"} /></Typography>
            <ReportTypography text={"These are the interpretation comments for the selected haplotypes."} />
            <ReportTypography text={"The original tag comment will not be modified here. Your modifications will be visible only in the exported report."}/>
            <Box p={1}>
                {renderInfoBoxes()}
            </Box>
        </>
    );
}