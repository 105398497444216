

export const VARIANT_METRICS = [
    {
        Header: 'Gene',
        accessor: 'gene',
    },
    {
        Header: 'Transcript cDNA',
        accessor: 'transcript_cdna',
    },
    {
        Header: 'GT',
        accessor: 'gt',
    },
    {
        Header: 'GQ',
        accessor: 'gq',
    },
    {
        Header: 'DP',
        accessor: 'dp',
    },
    {
        Header: 'REF count',
        accessor: 'ref_count',
    },
    {
        Header: 'ALT count',
        accessor: 'alt_count',
    },
    {
        Header: 'AAF',
        accessor: 'aaf',
    },
];

export default VARIANT_METRICS;