import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import { Link } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Grid, Box, IconButton, Menu, MenuItem, Divider, ListItemIcon, } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Avatar from '@mui/material/Avatar';
import SettingsIcon from '@mui/icons-material/Settings';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import MappedText from "../../../scenes/study-page/create-study/analysis-page/components/clinical-info/mapText";
import LanguageSelector from "../../header/LanguageSelector";
import GPAP_Logo from '../../../../resources/GPAP-Cancer.png'
import CustomTab from "../../../general-table/pages/CustomTab";
import {connect} from "react-redux";
import auth from '../../../services/session/Auth'
import { useLocation, useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import TabNavigation from "./TabNavigation";


const useStyles = makeStyles((theme) => ({
  tabs: {
    '& .MuiTab-textColorPrimary': {
       //color: '#3e699e', 
    },
    '& .MuiTabs-indicator': {
      height: 6, 
      //backgroundColor: '#3e699e',
      color: '#3e699e'
      },
    },
}));


let configOptions = [
    //{hash: "#/instand_dashboard", value: 0},
    {hash: "#/dir_list", value: 0},
    {hash: "#/dir_overview/", value: 0},
    {hash: "#/reporting_doc/", value: 1},
    {hash: "#/contact", value: 2},


]

function getInitVal(){

    let hash = configOptions.find(s => window.location.hash.includes(s.hash));
    if(hash){
        return hash.value;
    }
    else{ return -1}

}

function InstandHeader({authorization}){

    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState(getInitVal());
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const history = useHistory(); 
    const classes = useStyles();
    const location = useLocation();
    const isReportingPage = location.pathname.endsWith('/reporting');

    const handleChange = (newValue) => {
      setValue(newValue);
    };

    const open = Boolean(anchorEl);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
      if (location.pathname === '/dir_list') {
          setIsLoggedIn(true);
      } else if (location.pathname === '/reporting') {
          setIsLoggedIn(false);
      }
  }, [location.pathname]);

    const handleAuthAction = () => {
      if (isLoggedIn) {
       
        setIsLoggedIn(false);
        
        history.push('/');
        auth.user.keycloak.logout().then(() => {
          console.log("Logout successful");
        })
        .catch((e) => {
          console.error("Logout failed", e);
        });
      } else {
        
        setIsLoggedIn(true);
        history.push('/dir_list');
      } 
    };

    const handleLogout = () => {
      if(authorization){
      auth.user.keycloak.logout()
        .then(() => {
          console.log("Logout successful");
        })
        .catch((e) => {
          console.error("Logout failed", e);
        });
      }
    };


    
    return <AppBar position="static" color="white" >
    <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
            <Link to="/#">
                <img src={GPAP_Logo} alt="GPAP" style={{ width: "250px" }} />
            </Link>
        </Typography> 

        <Box>
            <TabNavigation
                classes={classes}
                isReportingPage={isReportingPage}
                handleChange={handleChange}
                value={value}
            />
        </Box>

        <LanguageSelector/>

        {/* <IconButton color="#3e699e" marginRight= '3px' disableRipple 
        sx={{ textDecoration: 'none', color:"#3e699e", margin:'0 20px', backgroundColor: 'rgba(62, 105, 158, 0.1)', width: '32px', height: '32px', borderRadius:'4px'}}>
          <NotificationsIcon color="#3e699e"/>
        </IconButton> */}
        <IconButton  disableRipple 
        sx={{marginLeft: '10px', backgroundColor: 'rgba(62, 105, 158, 0.1)', color:"#3e699e", width: '78px', height: '39px', borderRadius:'50px' }}
        onClick={handleClick}>

          <Avatar 
          sx={{ width: '30px', height: '30px', fontSize:'16px', marginRight: '10px',bgcolor:"#3e699e" }}>  
            {authorization?.preferredName ? authorization.preferredName.charAt(0).toUpperCase() : ''}

            </Avatar>
          <SettingsIcon sx={{ width: '20px', height: '20px'}}/>
        </IconButton>
        
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        >
          <MenuItem onClick={handleClose}>{authorization?.preferredName}</MenuItem>
          {!isReportingPage && (
          <MenuItem onClick={handleClose} component={Link} to="/instand_settings">
            <ListItemIcon><Settings fontSize="small" /></ListItemIcon>
            <MappedText text={"Settings"}/>
          </MenuItem>
          )}
          <MenuItem onClick={handleAuthAction}>
            <ListItemIcon><Logout fontSize="small" /></ListItemIcon>
            <MappedText text={isLoggedIn ? "Logout" : "Login"}/>
          </MenuItem>
      </Menu>
    </Toolbar>
  </AppBar>
}

function mapStateToProps (state) {
  if(state.authorization!==undefined)
  {
      return {authorization: state.authorization}
  }
}



export default connect(mapStateToProps,[])(InstandHeader);