

export const GENOTYPE_PHENOTYPE= [
    {
        Header: 'Gene',
        accessor: "gene",
    },
    {
        Header: 'Gene MIM Number',
        accessor: "gene_mim_number",
    },
    {
        Header: 'Phenotype',
        accessor: "phenotype",
    },
    {
        Header: 'Phenotype MIM Number',
        accessor: "phenotype_mim_number",
    },
    {
        Header: 'Inheritance',
        accessor: "inheritance",
    },
    
];