

import React from "react";
import IconLeftExpansionPanelSummary from "../gpap-table/ParticipantTable/LeftIconExpansion";
import {ExpandMoreOutlined} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import GPAP_InfoIcon from "../../../../../../../../../gpap-lib/components/GPAP_InfoIcon";
import MappedText from "../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import GPAP_Tooltip from "../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import RightAlignedAccordionSummary from "../gpap-table/ParticipantTable/IconRightExpansionSummary";
import RightAlignedSummary from "./IconRightSummary";
import IconLeftSummary from "./IconLeftSummary";

export default function AccordionInfo({title, total, appendixTitle, tooltipTitle, customStyle}){

    let tot = (total === undefined) ? 0 : total;

    let app = (appendixTitle!== undefined) ? appendixTitle : "";
    let displayText = `${title} (${tot}) ${app}`;

    if(title === "CNVs and SVs" && total === undefined){
        displayText = "CNVs and SVs (error)"
    }

    if(title === "CNVs and SVs"){
        displayText = "CNVs and SVs (" + 
        (tot === -1 ? "genomic variants not available" : 
        (tot === 1 ? "1 genomic variant" : (tot || 0) + " genomic variants")) + ")";
    }

    let infoIconElement = (title=== "CNVs and SVs")
        ? <GPAP_InfoIcon style={{float:"left"}}
                         title={<MappedText text={"The filters selected for SNV-InDels do not automatically apply to CNVs and SVs except gene lists. You can further filter the CNVs directly in the table"}/>}/>
        : ""

    let tooltipContent = (
        <GPAP_Tooltip title={tooltipTitle} placement="top">
            <span>{displayText}</span>
        </GPAP_Tooltip>
    );


    // TODO: Simplify this;
    return customStyle ? (
        <RightAlignedSummary
            tooltipContent={tooltipContent}
            infoIconElement={infoIconElement}
        />
    ) : (
        <IconLeftSummary
            tooltipContent={tooltipContent}
            infoIconElement={infoIconElement}
        />
    );




}