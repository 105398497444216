



// table_config for  query section-variant



export const mutation_taster = [
    {'value': "A", "label": "Annotated as disease causing"},
    {'value': "D", "label": "Disease causing"},
    {'value': "P", "label": "Polymorphism"},
    {'value': "N", "label": "Neutral"},
];

export const Mane = [
    {'value': "True", "label": "Mane"},

];

export const siftpred = [
    {'value': "D", "label": "Damaging"},
    {'value': "T", "label": "Tolerated"},

];

export const polyphen2hvarpred = [
    {'value': "D", "label": "Probably damaging"},
    {'value': "P", "label": "Possibly damaging"},
    {'value': "B", "label": "Benign"},
];

export const onco_classifier = [
    {'value': "O", "label": "Oncogenic"},
    {'value': "LO", "label": " Likely Oncogenic"},
    {'value': "VUS", "label": " VUS"},
    {'value': "B", "label": " Benign"},
    {'value': "LB", "label": " Likely Benign"}
];
export const intervarclasses = [
    {'value': "B", "label": "Benign"},
    {'value': "LB", "label": "Likely Benign"},
    {'value': "P", "label": " Pathogenic"},
    {'value': "LP", "label": " Likely Pathogenic"},
    {'value': "VUS", "label": " VUS"}
];




export const cadd_pred = {
    defaultValue: null,
    marks:[
            {"value": 0, "label": "0"},
            {"value": 10, "label": ""},
            {"value": 20, "label": "20"},
            {"value": 30, "label": ""},
            {"value": 40, "label": "40"},
            {"value": 50, "label": ""},
            {"value": 60, "label": "60"},
            {"value": 70, "label": ""},
            {"value": 80, "label": "80"},
            {"value": 90, "label": ""},
            {"value": 100, "label": "100"},
    ],
    pushable:true,
    allowCross:false,
    min:0,
    max:100,
    step:10,
};
export const revel = {
    defaultValue: null,
    marks:[
    ],
    pushable:true,
    allowCross:false,
    min:0.0,
    max:1,
    step:0.1,
};
export const spliceai_max_ds = {
    defaultValue: null,
    marks:[
    ],
    pushable:true,
    allowCross:false,
    min:0.0,
    max:1,
    step:0.1,
};


export const dict_class_snv = {
    "mutationtaster": mutation_taster,
    "siftpred": siftpred,
    "polyphen2hvarpred": polyphen2hvarpred,
    "cadd_phred": null,
    "OCScore" : null,
    "revel": null,
    "spliceai_max_ds": null,
    "intervarclasses":intervarclasses,
    "onco_classifier":onco_classifier
};