

function data_converter(dataArray){
    let data= [];

    dataArray.forEach(function(el){
        return Object.keys(el).forEach(s => {
            data.push({ key: s, value: el[s] })
        });
    })

    return data;

}

const convertDate = (dateString) => {

    // Parse the date string into a Date object
    const date = new Date(dateString);

// Extract day, month, and year
    const day = date.getUTCDate(); // getUTCDate() returns the day of the month (1-31)
    const month = date.getUTCMonth() + 1; // getUTCMonth() returns the month (0-11), so we add 1
    const year = date.getUTCFullYear(); // getUTCFullYear() returns the 4-digit year

// Format day and month to ensure two digits
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

// Combine into the desired format
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

    return formattedDate;

}


// true is selected, false not selected
const checkIfSelected = (data, selectedRowIndexes) => {

    return data.filter((row, index) => selectedRowIndexes.includes(index));
    //return (selectedItems.find(rowItem => rowItem.gene === row.gene) !== undefined);

}

export {data_converter, checkIfSelected, convertDate}
