

import React, { useState } from "react";
import { filters } from "../../utils/filters";
import DefaultColumnFilter from "../Filters/DefaultColumnFilter";
import {
  useBlockLayout,
  useColumnOrder,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import useOptionFilter from "../../hooks/useOptionFilter";
import {
  IconButton,
  Pagination,
  Stack,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Chip,
} from "@mui/material";
import MaUTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { addTooltipRow } from "../../utils/addTooltipRow";
import NestedTable from "./NestedTable";
import styled from "@emotion/styled";
import ButtonGroupCustom from "../buttons/ButtonGroupCustom";
import { ArrowDownward, ArrowUpward, Info, Circle } from "@mui/icons-material";
import BootstrapTooltip from "../tooltips/BootstrapTooltip";
import { Link } from "@material-ui/icons";
import TagDialog from "../../../scenes/dashboard/components/genomics-dashboard/components/results/components/toolbar/tag_dialog/TagDialog";
import './TableComponent.css';
import { getTreatmentAnnotationIcon } from "../../../scenes/instand/treatments/utils";
import { getPharmacogenomicsAnnotationIcon } from "../../../scenes/instand/pharmacogenomics_table/utils";
import { isUrl } from "../../utils/isUrl";

function TableComponent({
  columns,
  data,
  type,
  nestedColumns,
  buttons,
  states,
  tooltipCompact,
  renderFeatures,
  chipFull,
  chipSmall,
  dataType,
  pagination=true,
}) {

  const { customFeatures } = states;
  const { showHeader } = customFeatures.find((obj) => "showHeader" in obj);

  const [expandedRows, setExpandedRows] = React.useState({});

  const filterTypes = React.useMemo(filters, []);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const sortNum = React.useCallback((rowA, rowB, columnId) => {
    const valA = parseFloat(rowA.values[columnId]) || 0;
    const valB = parseFloat(rowB.values[columnId]) || 0;
    if (valA > valB) {
      return 1;
    } else if (valA < valB) {
      return -1;
    } else {
      return 0;
    }
  }, []);

  const numColumns = React.useMemo(() => {
    return columns.map((group) => {
      const newColumns = group.columns.map((column) => {
        if (column.type === "number") {
          return {
            ...column,
            sortType: sortNum,
          };
        }
        return column;
      });

      return {
        ...group,
        columns: newColumns,
      };
    });
  }, [columns, sortNum]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize },
    resetResizing,
  } = useTable(
    {
      columns: numColumns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useBlockLayout,
    useResizeColumns,
    useOptionFilter(
      columns,
      type,
      states,
      setExpandedRows,
      data)
    );

  const firstPageRows = page;
  const hasData = data.length > 0;
  const hasPagination = pagination && hasData;


  const [selectedRow, setSelectedRow] = useState(null);

  const renderSortIcon = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return (
            <IconButton>
              <ArrowUpward />
            </IconButton>
        );
      } else {
        return (
            <IconButton>
              <ArrowDownward />
            </IconButton>
        );
      }
    }
    return null; // Return nothing if the column is not sorted
  };


  return (
    <>
    <Stack direction="row" spacing={2} style={{paddingBottom: "8px"}}>
    {states.classRow && (states.classRow === "Treatments" || states.classRow === "Pharmacogenomics")  &&
    <TagDialog
          annotate_variants = {(label, array, type) => console.log(type)}  // edit. See how to add tagged variants to the store
          all_tagged_variants={ states.all_tagged_variants}
          getAllTaggedVariants = {states.getAllTaggedVariants}
          dataType={dataType}
      />
    }

      <ButtonGroupCustom
        allColumns={allColumns}
        getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
        resetResizing={resetResizing}
        buttons={buttons}
        states={states}
        renderFeatures={renderFeatures}
      />
        </Stack>

      <TableContainer>
      <MaUTable {...getTableProps()}
      sx={{ width: "auto", tableLayout: "fixed" }} >
     
     <TableHead>
  {headerGroups.map((headerGroup, headerGroupIndex) => (
    <React.Fragment key={headerGroup.id}>
      <TableRow 
        sx={{
          backgroundColor: headerGroupIndex === 0 ? '#3E69A9' : (showHeader ? '#EAEAEA' : '#3E69A9'),
          color: headerGroupIndex === 0 ? 'white' : (showHeader ? 'black' : 'white'), 
          display: headerGroupIndex === 0 ? (showHeader ? 'table-row' : 'none !important') : 'table-row',
          '& th, & td': { 
            padding: '10px',
            lineHeight: 'normal', 
            alignItems: 'center', 
            borderRight: '1px solid white', 
            position: 'sticky',
            top: 0,
            zIndex: 1,
            '&:last-child': {
              borderRight: 0
            },
            '&:first-child': {
              width: customFeatures.find(feature => feature.toggle || feature.expanding) ? '60px!important' : 'auto', 
              minWidth: customFeatures.find(feature => feature.toggle || feature.expanding) ? '60px!important' : 'auto',
              maxWidth: customFeatures.find(feature => feature.toggle || feature.expanding) ? '60px!important' : 'auto',
            }
          }
        }} 
        {...headerGroup.getHeaderGroupProps()}
      >
        {headerGroup.headers.map((column, columnIndex) => (
          <TableCell 
            {...column.getHeaderProps(column.getSortByToggleProps())}
            sx={{
              width: columnIndex === 0 && customFeatures.find(feature => feature.toggle || feature.expanding) ? '20px' : 'auto'  
            }}
          >
            <div style={{ color: headerGroupIndex === 0 ? 'white' : (showHeader ? 'inherit' : 'white') }}> 
              {column.tooltip ? (
                <BootstrapTooltip title={column.tooltip} forceUnderline={true}>
                  {column.render("Header")}
                </BootstrapTooltip>
              ) : (
                column.render("Header")
              )}
              <span>
               {renderSortIcon(column)}
              </span>
            </div>
          </TableCell>
        ))}
      </TableRow>

      {headerGroup.headers.some(column => column.canFilter) && (
 <TableRow  {...headerGroup.getHeaderGroupProps()} >
 {headerGroup.headers.map((column) => (
   <TableCell {...column.getHeaderProps()} 
   sx={{ 
     verticalAlign: 'middle',  
    
     '&:first-child': {
       borderRight: '0',
       ...(states.customFeatures.find(feature => feature.toggle || feature.expanding) && {
         width: '60px!important',
       }),
     },
     position: 'sticky', top: 0, zIndex: 1,
    }}    >
      {column.canFilter && column.filterType && column.filterType.type !== 'none' ? column.render('Filter') : null}
   </TableCell>
 ))}
</TableRow>
)}

    </React.Fragment>
  ))}
</TableHead>

          <TableBodyCustom
            rowId={states.idExpanded.id}
            rowName={states.classRow}
            {...getTableBodyProps()}
          >
            {firstPageRows.map((row, i) => {
             
              prepareRow(row);
              return (
                <>
                  <TableRowCustom
                    //className={`${states.classRow}${row.id}`}
                    className={`${states.classRow}${row.id} ${selectedRow === row.id ? 'selected' : ''}`}
                    key={row.id}
                    {...row.getRowProps()}
                    rowId={states.idExpanded.id}
                    states={states}
                    expanded={states}
                    
                    onClick={() => {

                      setSelectedRow(row.id);
                       
                      if (states.setSelected !== undefined)
                        states.setSelected(row.original);

                      /*document.querySelector(
                        `.${states.classRow}${row.id}`
                      ).style.backgroundColor = "#c6dbef";*/

                    }}
                    onMouseEnter={() => {
                     /*document.querySelector(
                        `.${states.classRow}${row.id}`
                      ).style.backgroundColor = "#EAEAEA";*/

                      if (row.id !== selectedRow) {
                        document.querySelector(`.${states.classRow}${row.id}`).classList.add('hovered');
                      }

                      document.querySelector(
                        `.${states.classRow}${row.id}`
                      ).style.cursor = "pointer";
                    }}
                    onMouseLeave={() => {
                      if (row.id !== selectedRow) {
                        document.querySelector(`.${states.classRow}${row.id}`).classList.remove('hovered');
                      }
                      /*document.querySelector(
                        `.${states.classRow}${row.id}`
                      ).style.backgroundColor = "#fff";
                      }*/
                    }}
                  
                    >

                  {/* Add column for the flag of treatments*/}
                  {states.classRow && states.classRow === "Treatments" && (
                    <TableCell padding="checkbox" key={"cell_cb" + row.uniqueName}>
                      {getTreatmentAnnotationIcon(row, states.participant)}
                    </TableCell>
                  )}

                  {/* Add column for the flag of pharmacogenomics*/}
                  {states.classRow && states.classRow === "Pharmacogenomics" && (
                    <TableCell padding="checkbox" key={"cell_cb" + row.uniqueName}>
                      {getPharmacogenomicsAnnotationIcon(row, states.participant)}
                    </TableCell>
                  )}
    

                {row.cells.map((cell) => {
              if (cell.column.id === 'analysis' || cell.column.id === "origin") {
                let modifiedValue = cell.value;
                if (cell.value && typeof cell.value === 'string') {
                  let words = cell.value.replace(/_/g, ' ').split(' ');
                  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase(); 
                  modifiedValue = words.join(' ');
                }
                return (
                  <TableCell {...cell.getCellProps()} style={{ width: '150px' }}>
                    {modifiedValue}
                  </TableCell>
                );
              }
               // Remove when column is added in backend
                //  if (cell.column.id === 'gene_evidence' || cell.column.id === 'haplotype_evidence') {
                //   return (
                //     <TableCell {...cell.getCellProps()} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                //       <Link /> 
                //     </TableCell>
                //   );
                // }
               // Remove when column is added in backend
                      const { truncated, content } = addTooltipRow(
                        cell,
                        tooltipCompact,
                        data
                      );

                      
                      return (
                        <TableCell {...cell.getCellProps()}>
                          <BootstrapTooltip 
                            title={truncated ? `${cell.value}` : ""} 
                            isTruncated={truncated}
                          >
                          <div style={truncated ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' } : {}}>
                          {(() => {
                             const isValueArray = Array.isArray(cell?.value);
                            const matchedDataIcons = chipSmall
                              ?.flatMap((item) => item.cells)
                              .find((data) => isValueArray ? cell?.value.includes(data.data) : data.data === cell?.value);
                            const matchedDataCells = chipFull
                              ?.flatMap((item) => item.cells)
                              .find((data) => isValueArray ? cell?.value.includes(data.data) : data.data === cell?.value);
                         

                            const formattedValue = typeof cell.value === 'string'
                              ? cell.value.charAt(0).toUpperCase() + cell.value.slice(1).toLowerCase()
                              : cell.value;


                            if (matchedDataIcons) {
                              return (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Circle style={{ color: matchedDataIcons.color, fontSize: "12px" }} />
                                  <span style={{ marginLeft: '5px', color: matchedDataIcons.textColor }}>
                                    {formattedValue}
                                  </span>
                                </div>
                              );
                            } else if (matchedDataCells) {
                              return (
                                <Chip
                                  label={formattedValue}
                                  sx={{
                                    height: "20px",
                                    pl: "0px",
                                    pr: "0px",
                                    backgroundColor: matchedDataCells.color,
                                    color: matchedDataCells.textColor,
                                  }}
                                />
                              );
                            }
                        

                         
                                if (typeof cell.column.Header === "function") {
                                  return content;
                                }


                             

                                if (cell.column.id !== "VariantData") {
                                  let value;
                                  if (typeof cell.value === 'string') {
                                   
                                    let valuesArray = cell.value.split(' ').filter(val => val.trim().toLowerCase() !== "none");
                                    value = valuesArray.length > 0 ? valuesArray[0] : "None";
                                  } else {
                                    value = cell.value;
                                  }
                                

                                  if (isUrl(value) && value.trim().toLowerCase() !== "none") {
                                    return (
                                      <a
                                        style={{ textDecoration: "none" }}
                                        href={value}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {cell.column.icon ? (
                                          <img src={cell.column.icon} alt="Link Icon" style={{ width: '20px', height: '20px' }} />
                                        ) : (
                                          <Link />
                                        )}
                                      </a>
                                    );
                                  }
                                }
                                

                                if (cell.column.id === 'links') {
                                 
                                  if (Array.isArray(cell.value) && cell.value.length > 0) {
                                    if (cell.value[0].link === "No Data") {
                                   
                                      return (
                                        <TableCell {...cell.getCellProps()}>
                                          No data
                                        </TableCell>
                                      );
                                    } else {
                                 
                                      return (
                                        <TableCell {...cell.getCellProps()}>
                                        {cell.value.map((linkObj, index) => (
                                        <Tooltip key={index} title={linkObj.link}>
                                          <a href={linkObj.link} target="_blank" rel="noopener noreferrer">
                                            <img src={linkObj.icon} alt="Link Icon" style={{ width: '20px', height: '20px' }} />
                                          </a>
                                        </Tooltip>
                                      ))}

                                      </TableCell>
                                      );
                                    }
                                  } else {
                               
                                    return (
                                      <TableCell {...cell.getCellProps()}>N/A</TableCell>
                                    );
                                  }
                                }
  
                                return (
                                  <div>
                                    {cell.value ? (
                                      content
                                    ) : (
                                      <p className="score_NA">N/A</p>
                                    )}
                                  </div>
                                );
                             })()}
                            </div>
                          </BootstrapTooltip>
                        </TableCell>
                      );
                    })}
                  </TableRowCustom>

                  {row.isExpanded && expandedRows[row.id]?.expanded ? (
                    <TableRow>
                      <TableCell sx={{ backgroundColor: "#F9F9F9" }} colSpan={visibleColumns.length}>
                        {expandedRows[row.id]?.data ? ( 
                          <NestedTable
                            columns={nestedColumns}
                            data={expandedRows[row.id].data} 
                            states={states}
                          />
                        ) : (

                          <div>Loading...</div>
                        )}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              );
            })}
          </TableBodyCustom>
        </MaUTable>
      </TableContainer>

      <br />
    {hasPagination && (
      <div style={{ display: "flex", alignItems: "center", height: "50px", justifyContent: "center"}}>

      <TablePagination
      component="div"
      count={data.length}
      rowsPerPage={pageSize}
      page={pageIndex}
      onPageChange={(event, newPage) => gotoPage(newPage)}
      onRowsPerPageChange={(event) => setPageSize(Number(event.target.value))}
      style={{ '& .MuiTablePagination-actions': { display: 'none' } }}
      />

      <Pagination
      style={{ marginLeft: '40px' }}
      count={pageCount}
      page={pageIndex + 1}
      onChange={(event, value) => gotoPage(value - 1)}
      showFirstButton
      showLastButton
      />


</div>
)}
</>
);
}


export default TableComponent;

const TableRowCustom = styled(TableRow)`
height: 40px!important;
& > td:first-child {
  ${(props) => props.states.customFeatures.find(feature => feature.toggle || feature.expanding) ? 'width: 60px!important;' : ''}
}

  & > td {
    display: flex!important;
    align-items: center;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;


const TableBodyCustom = styled(TableBody)`
  ${(props) => {
    return (
      props.rowId &&
      `.${props.rowName}${props.rowId} > td {
    background-color: #C6DBEF !important;
  }`
    );
  }}
`;