


const disclaimerTextArray_somatic = [
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to ClinGen, CGC and VICC recommendations (Horak et al 2022) and the Human Variation Variant Society (HGVS) ",
    "(https://varnomen.hgvs.org/). ",
    "Only FDA or EMA approved treatments are reported. The conclusion of this report is based solely on the results of the DNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician."
];


const disclaimerTextArray_germline = [
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to the American College of Medical Genetics (ACMG) recommendations (Richards et al. 2015, Riggs et al. 2020), ClinGen recommendations (Welcome to ClinGen ) and the Human Variation Variant Society (HGVS) ",
    "(https://varnomen.hgvs.org/). ",
    "Genetic counselling and recommendations are based on GeneReviews guidelines (Margaret et al 1993-2020)."
];

const disclaimerTextArray_fusion = [
    "The clinical significance of genetic variants is interpreted using the information currently available and is subjected to changes as scientific knowledge expands. Genomic variants are classified and reported according to ClinGen, CGC and VICC recommendations (Horak et al 2022, Gene Fusion Specification —",
    "(https://fusions.cancervariants.org/en/latest/).",
    "Only FDA or EMA approved treatments are reported. The conclusion of this report is based solely on the results of the RNA sequencing of the tumour and the received tumour type. Final interpretation of the clinical consequence of this report should therefore always be performed by the treating physician."
];


export default function returnDisclaimerText(analysisType){


    if(analysisType.includes("somatic")){return disclaimerTextArray_somatic}
    else if (analysisType === "germline") {return disclaimerTextArray_germline}
    else if (analysisType === "somatic_fusion_analysis"){return disclaimerTextArray_fusion }


}
