


export const DIR_DATA_HEADER = [
    {
        header: "DIR LIST",
        columns: [
            { nameTable: "Case ID", dataSearch: "dir_id", tooltip: "" },
            { nameTable: "Patient ID", dataSearch: "Participant_ID", tooltip: "" },
            { filterType: { type: "select", option: "default" },nameTable: "Analysis Type", dataSearch: "analysis", tooltip: "" },
            { filterType: { type: "select", option: "default" },nameTable: "Priority", dataSearch: "priority", tooltip: "" },
            { filterType: { type: "select", option: "default" },nameTable: "Status", dataSearch: "status", tooltip: "" },
            { filterType: { type: "date" }, filter: "dateFilter", nameTable: "Deadline", dataSearch: "deadline", tooltip: "" },
            { nameTable: "Assignee(s)", dataSearch: "assignee", tooltip: "" },
            { nameTable: "Creation Date", dataSearch: "creation_date", tooltip: "" },
            { nameTable: "Tumor Experiment", dataSearch: "tumor_experiment_id", tooltip: "" },
            { nameTable: "Control Experiment", dataSearch: "control_experiment_id", tooltip: "" },
            { nameTable: "Clinical referrer", dataSearch: "clinical_referrer", tooltip: "" },
            { nameTable: "Clinical contact", dataSearch: "clinical_referrer_contact", tooltip: "" },
            { nameTable: "Hospital Name", dataSearch: "hospital_name", tooltip: "" },
        ],
    },
];

