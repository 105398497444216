
import React from "react";
import { useSelector } from "react-redux";
import {Grid, Typography} from "@mui/material";
import DataField from "../dir_overview/Datafield";
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import PDFViewer from "../../../components/instand-components/pdf";

import NoResultPNG from "./empty_fig/Result_Not_Available.png";


const ImageWithFallback = ({ base64String, altText }) => (
    <img
        src={base64String ? `data:image/png;base64,${base64String}` : NoResultPNG}
        alt={altText}
        style={{ width: "40%" }}
    />
);

const PDFViewerWithFallback = ({ base64String }) => (
    <Grid item xs={12}>
        {base64String ? <PDFViewer base64String={base64String} /> : <ImageWithFallback altText="Placeholder" />}
    </Grid>
);

function Biomarkers(props) {
   
    const {allDataApis} = useSelector((state) => state.generaltable);
    const binLevel = allDataApis?.tumor?.bin_level_log2_coverages_and_segmentation_calls;
    const copyNumber = allDataApis?.tumor?.copy_number_per_chromosome;
    
    const mutationalSignatures = allDataApis?.tumor?.mutational_signatures;

    let library_strategy = "";

    if (allDataApis.experiments && allDataApis.experiments.length > 0) {
        library_strategy = allDataApis.experiments[0].library_strategy || "";
    }

    let hideOpts = ['Whole exome sequencing', "Panel", "WES"];
    let hide = hideOpts.includes(library_strategy);


    
    return <Grid container direction={"row"}>
        <Grid item xs={12}>

            <Typography variant={"h5"}  mb={2} style={{  fontWeight: "700" }}><MappedText text={"Tumor characteristics"}/></Typography>
            
            <Grid container spacing={1}  mb={4}>
                <Grid item lg={3} md={4} xs={6} >
             
                    <DataField mb={2}
                               title="Tumor purity"
                               value={allDataApis?.samples?.percentage_of_tumoral_cells }
                               NA_Text={"Tumor purity cannot be determined for this sample"}
                    />
                    
                </Grid>
                
                <Grid  item lg={3} md={4} xs={6}>
                    <DataField title="Average ploidy"
                               value={allDataApis?.tumor?.average_ploidy}
                               NA_Text={"Average ploidy cannot be determined for this sample"}
                    />
                </Grid>
               
            </Grid>

            <Typography variant={"h5"}  mb={2} style={{  fontWeight: "700" }}><MappedText text={"Biomarkers of genetic instability"}/></Typography>

            {!(hide) ?  <Grid item sx={12} mb={4}>
                <DataField title="Microsatellite status"
                           value={allDataApis?.tumor?.microsatellite_status || "NA"}
                           helperText={allDataApis?.tumor?.microsatellite_status ? "Predicted by MSIsensor" : ""}
                           NA_Text={"Microsatellite status cannot be determined for this sample"}
                />
            </Grid>
            : null}


            <Grid container spacing={2} mb={4}>
                <Grid  item lg={3} md={4} xs={6} >
                    <DataField title="Tumor mutational burden" value={allDataApis?.tumor?.tumor_mutational_burden || "NA"}
                               helperText={allDataApis?.tumor?.tumor_mutational_burden ? "Computed by pyTMB" : ""}
                               NA_Text={"TMB cannot be determined for this sample"}
                    />
                </Grid>
            </Grid>
            {!(hide)
                ? <Grid item xs={12} mb={4}><DataField title="Homologous recombination deficiency (HRD) status"
                                                       value={allDataApis?.tumor?.homologous_recombination_deficiency_status || "NA"}
                                                       helperText={allDataApis?.tumor?.homologous_recombination_deficiency_status
                                                           ? "Computed by Classifier of Homologous Recombination Deficiency (CHORD)"
                                                           : ""}
                                                       NA_Text={"HRD cannot be determined for this sample"}
                /></Grid>
                : null
            }


            <Grid item xs={12}>
            <Typography variant={"h5"}  mt={2} style={{  fontWeight: "700" }}><MappedText text={"Bin-level log2 coverages and segmentation calls"}/></Typography>
            </Grid>

            {binLevel
                ? <Grid item xs={12}><Typography variant={"caption"}><MappedText text={"Source: CNVkit"}/></Typography></Grid>
                : null
            }

            <Grid item xs={12}>
                <ImageWithFallback base64String={binLevel} altText="Bin-level Log2 Coverages" />
            </Grid>

            <Grid item xs={12}>
            <Typography variant={"h5"}  mt={3} style={{  fontWeight: "700" }}><MappedText text={"Copy number per chromosome"}/></Typography>
            </Grid>

            {copyNumber
                ? <Grid item xs={12}><Typography variant={"caption"}  mb={2}><MappedText text={"Source: CNVkit"}/></Typography></Grid>
                : null
            }
           

            {/*<Grid item xs={12}>
            <Typography variant={"body2"} mt={3}><span style={{ fontWeight: "bold" }}><MappedText text={"Tumor Sample ID:"}/></span> S134576</Typography>
            </Grid>*/}
           
                <Grid item xs={12}>

                    <PDFViewerWithFallback base64String={copyNumber} />

                </Grid>

            <Grid item xs={12}>
            <Typography variant={"h5"}  mt={3} mb={2} style={{  fontWeight: "700" }}><MappedText text={"Mutational signatures"}/></Typography>
            </Grid>

            {mutationalSignatures
                ? <Grid item xs={12}><Typography variant={"caption"}><MappedText text={"Source: SigProfilerAssignment"}/></Typography></Grid>
                : null
            }

            <Grid item xs={12}>


                <PDFViewerWithFallback base64String={mutationalSignatures} />

            </Grid>
        </Grid> 
    </Grid>
}

export default Biomarkers;