


export const CLINICAL_DATA_HEADER = [
    {
        header: "Clinical reports",
        columns: [
            { nameTable: "Report ID", dataSearch: "clinical_report_id", tooltip: "" },
            { nameTable: "Report Name" , dataSearch: "clinical_report_name", tooltip: "" },
            { filterType: { type: "date" }, filter: "dateFilter", nameTable: "Creation Date" , dataSearch: "creation_date", tooltip: "" },
            { filterType: { type: "select", option: "default" },nameTable: "Origin" , dataSearch: "origin", tooltip: "" },
            { filterType: { type: "select", option: "default" },nameTable: "Creator" , dataSearch: "creator", tooltip: "" },
        ],
    },
];
