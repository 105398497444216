


export const mapping = [
    { key: "variantclasses" , label:"Variant Effect"},
    { key: "genotypefeatures" , label:"Genotype Features"},
    { key: "mutationtaster" , label:"Mutation Taster"},
    { key: "clinvarclasses" , label:"ClinVar Classes"},
    { key: "Mane" , label:"Mane"},
    { key: "onco_classifier" , label:"Onco Filter"},
    { key: "polyphen2hvarpred" , label:"PolyPhen-2 Pred."},
    { key: "gnomad_filter" , label:"GnomAD Filter"},
    { key: "programs_filter" , label:"Programs Filter"},
    { key: "variantconsequences" , label:"Variant Consequences"},

];


// maps values;


