

export const COVERAGE_GENES = [
    {
        Header: 'Gene Symbol',
        accessor: "gene_symbol", 
    },
    {
        Header: 'Transcript',
        accessor: "transcript",
    },
    {
        Header: 'Mean Coverage',
        accessor: "mean_coverage", 
    },
    {
        Header: 'Median Coverage',
        accessor: "median_coverage", 
    },
    {
        Header: 'C10',
        accessor: "c10", 
    },
    {
        Header: 'C15',
        accessor: "c15", 
    }, 
    {
        Header: 'C20',
        accessor: "c20", 
    },
];

export default COVERAGE_GENES;
