import {withStyles} from "@mui/styles";
import {Accordion} from "@mui/material";


const CustomAccordion = withStyles({
    root: {
        boxShadow: 'none', // Remove shadow
        '&.MuiPaper-root': {
            boxShadow: 'none', // Specifically remove shadow from Paper
        },
        '&.MuiPaper-elevation1': {
            boxShadow: 'none' // Target the elevation class directly
        },
        '&:before': {
            display: 'none' // Remove the default divider line
        },
        '&.Mui-expanded': {
            margin: '0px' // Optionally, remove extra margin when expanded
        }
    }
})(Accordion);

export default CustomAccordion;