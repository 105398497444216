


const table_function_config = [

    {mainHeading: "",subheadings: [
            { id: 'gene_name', numeric: false, disablePadding: true, label: 'Gene' },
            { id: 'gene_id', numeric: false, disablePadding: true, label: 'Gene ID' },
            { id: 'gene_coding', numeric: false, disablePadding: true, label: 'Gene coding' },
            { id: 'exon_rank', numeric: true, disablePadding: true, label: 'Exon Rank' },
            ]},
    {
        mainHeading: "Aminoacid Info",
        subheadings: [
            { id: 'functional_class', numeric: false, disablePadding: true, label: 'Functional class' },
            { id: 'amino_acid_length', numeric: true, disablePadding: true, label: 'Aminoacid length' },
            { id: 'amino_acid_change', numeric: true, disablePadding: true, label: 'Aminoacid' }
        ]
    },
    {
        mainHeading: "Effect",
        subheadings: [
            
            { id: 'effect_impact', numeric: false, disablePadding: true, label: 'Impact' },
            { id: 'effect', numeric: false, disablePadding: true, label: 'Change' },
            { id: 'codon_change', numeric: false, disablePadding: true, label: 'Nucleotide' },

        ]
    },
    {
        mainHeading: "Transcript",
        subheadings: [
            { id: 'transcript_id', numeric: false, disablePadding: true, label: 'Transcript ID' },
            { id: 'transcript_biotype', numeric: false, disablePadding: true, label: 'Transcript' },
        ]
    }

];


// with all fields that came for the release of 38;
const table_config_compact_38 =  [
    {mainHeading: "", subheadings: [
            { id: 'gene_name', numeric: false, disablePadding: true, label: 'Gene' }
           
        ]},
    {mainHeading: "Variant information", subheadings: [
            { id: 'gt', numeric: false, disablePadding: true, label: 'GT' },
            { id: 'transcript_biotype', numeric: false, disablePadding: true, label: 'Transcript' },
            { id: 'mane_annotation', numeric: false, disablePadding: true, label: 'MANE' },
            { id: 'effect_impact', numeric: false, disablePadding: true, label: 'Impact' },
            { id: 'codon_change', numeric: false, disablePadding: true, label: 'Nucleotide' },
            { id: 'amino_acid_change', numeric: false, disablePadding: true, label: 'Aminoacid' },
            { id: 'consequence', numeric: false, disablePadding: true, label: 'Consequence' }
            
        ]},

    {mainHeading:  "Clinical association", subheadings: [
            { id: 'acmg_verdict', numeric: false, disablePadding: true, label: 'Clin.Sign.' },
            { id: 'acmg_classifications', numeric: false, disablePadding: true, label: 'Criteria' },
            { id: 'omim', numeric: false, disablePadding: true, label: 'OMIM' },
            { id: 'intervar_evidence', numeric: false, disablePadding: true, label: 'ACMG evidences' },
            { id: 'intervar_automated', numeric: false, disablePadding: true, label: 'ACMG classification' },
            { id: 'clinvar_clnsig', numeric: false, disablePadding: true, label: 'ClinVar'},  ]},


    {mainHeading:  "Population frequency", subheadings: [
            { id: 'freqIntGermline', numeric: true, disablePadding: true, label: 'Internal' },
            { id: 'gnomad_af', numeric: true, disablePadding: true, label: 'GnomAD' }
            ]
    },
    {mainHeading:  "Predictors", subheadings : [
            { id: 'cadd_phred', numeric: true, disablePadding: true, label: 'CADD' },
            { id: 'revel_score', numeric: true, disablePadding: true, label: 'Revel' },
            { id: 'spliceai_max_ds', numeric: true, disablePadding: true, label: 'SpliceAI' },
            { id: 'sift_pred', numeric: false, disablePadding: true, label: 'SIFT' },
            { id: 'polyphen2_hvar_pred', numeric: false, disablePadding: true, label: 'Polyphen2.' },
            { id: 'mutationtaster_pred', numeric: false, disablePadding: true, label: 'MT', tooltip: "Mutation Taster" }
        ]}
];

const table_config_compact =  [
    {mainHeading: "", subheadings: [
            { id: 'gene_name', numeric: false, disablePadding: true, label: 'Gene' }

        ]},
    {mainHeading:  "Variant information", subheadings: [
            { id: 'gt', numeric: false, disablePadding: true, label: 'GT' },
            { id: 'transcript_biotype', numeric: false, disablePadding: true, label: 'Transcript' },
            { id: 'effect_impact', numeric: false, disablePadding: true, label: 'Impact' },
            { id: 'codon_change', numeric: false, disablePadding: true, label: 'Nucleotide' },
            { id: 'amino_acid_change', numeric: false, disablePadding: true, label: 'Aminoacid' },
            { id: 'consequence', numeric: false, disablePadding: true, label: 'Consequence' }

        ]},

    {mainHeading:  "Clinical association", subheadings: [
            { id: 'acmg_verdict', numeric: false, disablePadding: true, label: 'Clin.Sign.' },
            { id: 'acmg_classifications', numeric: false, disablePadding: true, label: 'Criteria' },
            { id: 'omim', numeric: false, disablePadding: true, label: 'OMIM' },
          /*  { id: 'intervar_evidence', numeric: false, disablePadding: true, label: 'Intervar Evidence' },
            { id: 'intervar_automated', numeric: false, disablePadding: true, label: 'Intervar Autom.' },*/
            { id: 'clinvar_clnsig', numeric: false, disablePadding: true, label: 'ClinVar'},  ]},


    {mainHeading: "Population frequency", subheadings: [
            { id: 'freqIntGermline', numeric: true, disablePadding: true, label: 'Internal' },
            { id: 'gnomad_af', numeric: true, disablePadding: true, label: 'GnomAD' }
        ]
    },
    {mainHeading:  "Predictors", subheadings : [
            { id: 'cadd_phred', numeric: true, disablePadding: true, label: 'CADD' },
          /*  { id: 'revel_score', numeric: true, disablePadding: true, label: 'Revel' },*/
            { id: 'sift_pred', numeric: false, disablePadding: true, label: 'SIFT' },
            { id: 'polyphen2_hvar_pred', numeric: false, disablePadding: true, label: 'Polyphen2.' },
            { id: 'mutationtaster_pred', numeric: false, disablePadding: true, label: 'MT', tooltip: "Mutation Taster" },
        ]}
];

const table_config_search_across = [
    {mainHeading: "", subheadings: [
            { id: 'gene_name', numeric: false, disablePadding: true, label: 'Gene' },
        ]},
    {mainHeading: "Variant information", subheadings: [
        { id: 'gt', numeric: false, disablePadding: true, label: 'GT' },
            { id: 'transcript_biotype', numeric: false, disablePadding: true, label: 'Transcript' },
            { id: 'effect_impact', numeric: false, disablePadding: true, label: 'Impact' },
            { id: 'codon_change', numeric: false, disablePadding: true, label: 'Nucleotide' },
            { id: 'amino_acid_change', numeric: false, disablePadding: true, label: 'Aminoacid' },
            { id: 'consequence', numeric: false, disablePadding: true, label: 'Consequence' }
            ]},

    {mainHeading: "Clinical association", subheadings: [
            { id: 'omim', numeric: false, disablePadding: true, label: 'OMIM' },
            { id: 'intervar_evidence', numeric: false, disablePadding: true, label: 'ACMG evidences' },
            { id: 'intervar_automated', numeric: false, disablePadding: true, label: 'ACMG classification' },
            { id: 'clinvar_clnsig', numeric: false, disablePadding: true, label: 'ClinVar' },  ]},

    {mainHeading: "Population", subheadings: [
            { id: 'n_exp', numeric: true, disablePadding: true, label: '# Exp.' },
            { id: 'freqIntGermline', numeric: true, disablePadding: true, label: 'Internal' },
            { id: 'gnomad_af', numeric: true, disablePadding: true, label: 'GnomAD' },
            ]},
    {mainHeading: "Predictors", subheadings : [
            { id: 'cadd_phred', numeric: true, disablePadding: true, label: 'CADD' },
            { id: 'revel_score', numeric: true, disablePadding: true, label: 'Revel' },
            { id: 'sift_pred', numeric: false, disablePadding: true, label: 'SIFT' },
            { id: 'polyphen2_hvar_pred', numeric: false, disablePadding: true, label: 'Polyphen2' },
            { id: 'mutationtaster_pred', numeric: false, disablePadding: true, label: 'MT' },
        ]}
];


// FOR SOMATIC
const table_config_compact_somatic =  [
    {mainHeading: "", subheadings: [
            { id: 'gene_name', numeric: false, disablePadding: true, label: 'Gene' }
           
        ]},
    {mainHeading: "Variant information", subheadings: [
            /*{ id: 'gt', numeric: false, disablePadding: true, label: 'GT' },*/
            { id: 'transcript_biotype', numeric: false, disablePadding: true, label: 'Transcript' },
            { id: 'effect_impact', numeric: false, disablePadding: true, label: 'Impact' },
            { id: 'codon_change', numeric: false, disablePadding: true, label: 'Nucleotide' },
            { id: 'amino_acid_change', numeric: false, disablePadding: true, label: 'Aminoacid' },
            { id: 'consequence', numeric: false, disablePadding: true, label: 'Consequence' }
            
        ]},
    {mainHeading: "Clinical association", subheadings: [
            //{ id: 'acmg_verdict', numeric: false, disablePadding: true, label: 'Clin.Sign.' },
            //{ id: 'acmg_classifications', numeric: false, disablePadding: true, label: 'Criteria' },
            { id: 'omim', numeric: false, disablePadding: true, label: 'OMIM' },
            { id: 'clinvar_clnsig', numeric: false, disablePadding: true, label: 'ClinVar'},
            { id: 'OCScore', numeric: true, disablePadding: true, label: 'OCScore' },
            { id: 'Evidence', numeric: false, disablePadding: true, label: 'Onco evidences' },
            { id: 'Classification', numeric: false, disablePadding: true, label: 'Classification' },               
            //{ id: 'Source', numeric: false, disablePadding: true, label: 'Source' },
            //{ id: 'Evidence Level??', numeric: false, disablePadding: true, label: 'Evidence Level??' }
        ]},
    {mainHeading: "Population frequency", subheadings: [
            { id: 'freqIntGermline', numeric: true, disablePadding: true, label: 'Internal' },
            { id: 'gnomad_af', numeric: true, disablePadding: true, label: 'GnomAD' }
        ]},
    {mainHeading: "Predictors", subheadings : [
            { id: 'cadd_phred', numeric: true, disablePadding: true, label: 'CADD' },
            { id: 'revel_score', numeric: true, disablePadding: true, label: 'Revel' },
            { id: 'spliceai_max_ds', numeric: true, disablePadding: true, label: 'SpliceAI' },
            { id: 'sift_pred', numeric: false, disablePadding: true, label: 'SIFT' },
            { id: 'polyphen2_hvar_pred', numeric: false, disablePadding: true, label: 'Polyphen2.' },
            { id: 'mutationtaster_pred', numeric: false, disablePadding: true, label: 'MT', tooltip: "Mutation Taster" }
        ]},

];

export {table_config_compact, table_function_config, table_config_search_across, table_config_compact_38, table_config_compact_somatic}