import React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Language } from "@mui/icons-material";
import {Box} from "@mui/material";

function renderData(data) {
  if (Array.isArray(data)) {
    return (
      <ul>
        {data.map((item, index) => (
          <li key={index}>{renderData(item)}</li>
        ))}
      </ul>
    );
  } else if (typeof data === "object" && data !== null) {
    return (
      <ul>
        {Object.entries(data).map(([key, value], index) => (
          <li key={index}>
            {key}: {renderData(value)}
          </li>
        ))}
      </ul>
    );
  } else {
    return <>{typeof data === "string" ? data : JSON.stringify(data)}</>;
  }
}

export default function BasicPopover({ states, text, render, data, buttonProps }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [renderView, setRenderView] = React.useState(render);
  const viewData = data || [];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const fullResApi =states?.data[0]?.fullRespApi

    React.useEffect(() => {
      if(!states?.idExpanded.id){
        return 
      }
    
      const indexOrginal = states?.dataFilter[states?.idExpanded.id]?.originalIndex
      let apiObject = (fullResApi) ?  fullResApi[indexOrginal] : false;

      if (apiObject) {
        let renderElements = [];

        const exists = data.some(key => apiObject.hasOwnProperty(key));

        if(exists){
          viewData.forEach((item) => {
            if (apiObject.hasOwnProperty(item)) {
              const value = apiObject[item];
              const render = (
                  <div key={item}>
                    <strong>{item} : </strong>
                    {renderData(value)}
                  </div>
              );

              renderElements.push(render);
            }
          });
        }
        else{

          const render = (
              <Box p={1}>
                No information available
              </Box>
          );

          renderElements.push(render);
        }


        setRenderView(renderElements);
      }
    }, [fullResApi, states]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button 
        variant="outlined" 
        onClick={handleClick} 
        startIcon={<Language />} 
        {...buttonProps} 
      >
        {text}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{ style: { maxWidth: "400px", maxHeight: "400px" } }}
      >
        <Typography sx={{ p: 2 }}>{renderView}</Typography>
      </Popover>
    </div>
  );
}
