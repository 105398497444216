import {Field} from "redux-form";

import React from "react";


import Select from 'react-select';
import "../../../scenes/dashboard/components/genomics-dashboard/components/side-panels/filter-panel/components/filter-sections/variant-section/renderer.css"
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import GPAP_Tooltip from "../GPAP_Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import MappedText from "../../../scenes/instand/reports/create-report/shared-components/mapText";



export const MultiSelect = props => {

    const { input, options } = props;


    return (<Select
        {...props}
        value={props.input.value}
        onChange={ (value, option) => {
            if (option.removedValue && option.removedValue.isFixed) {
                return;
            }
            else{
                return input.onChange(!value ? [] : value);
        }
        }
        }
        onBlur={() => input.onBlur(input.value)}
        isClearable={!options.some(opt => opt.isFixed)}
        options={options}
        isMulti
        closeMenuOnSelect = {false}
        style={{ fontSize: "14px"}}
    />)
};

export const SelectSingle = props => {

    const { input, options } = props;

    return (<Select
        {...props}
        value={props.input.value}
        onChange={value => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        isSearchable = {true}
        style={{ fontSize: "14px"}}
    />)

};

// a filter function that runs against each 'option' in `options`
const filterPanels = (candidate, input) => {
    if (input) {

        let input_Cap = input.toString().charAt(0).toUpperCase() + input.slice(1);

        let cond_1 = !!(candidate.data.disease_sub_group.includes(input) || candidate.data.disease_group.includes(input)) || candidate.data.label.includes(input);
        let cond_2 = !!(candidate.data.disease_sub_group.includes(input_Cap) || candidate.data.disease_group.includes(input_Cap)) || candidate.data.label.includes(input_Cap);

        return cond_1 || cond_2;

    }
    return true; // if not search, then all match
};

export const SelectSingleCustom = props => {

    const { input, options } = props;

    return (<Select
        {...props}
        filterOption={filterPanels}
        value={props.input.value}
        onChange={value => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        isSearchable = {true}
        style={{ fontSize: "14px"}}
    />)

};



export const SelectStandard = props => {
    const { options, defaultValue } = props;

    const handleChange = (value) =>
    {
        props.handleChange(value, props.name)
    };

    //let def_val = (Array.isArray(options) ? options[0] : "No Options" );

    return <Select
        defaultValue={defaultValue}
        options={options}
        onChange={handleChange}
        //formatGroupLabel={formatGroupLabel}
    />
};

export const CustomSelect = props => {
    const { options, defaultValue, customOptionLabel } = props;

    const handleChange = (value) =>
    {
        props.handleChange(value, props.name)
    };



    return <Select
        defaultValue={defaultValue}
        options={options}
        onChange={handleChange}
        formatOptionLabel={customOptionLabel}
    />
};










export const TextFieldForm = props => {
    const { input, defaultValue, label, helperText} = props;

    return (  <TextField
        id="standard-size-small"
        defaultValue={defaultValue}
        size="small"
        label={props.label}
        helperText= {helperText}
        {...props}
        value={isNaN(props.input.value) ? "" : props.input.value}
        onChange={value => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        style={{ fontSize: "14px"}}
    />)

};


export const renderRadioGroup = ({ input, ...rest }) => (
    <RadioGroup
        {...input}
        {...rest}
        valueSelected={input.value}
        onChange={(event, value) => input.onChange(value)}
    />
);

export const SelectArea = props => {

    let header = props.header;
    let collection_name = props.collection_name;
    let fields = props.fields;

    return (<div className={"field_container"}>
        <Grid container
              direction={"column"}
              justify={"center"}>
            <Grid item>
                <div className={"section_field_title"}>
                        {
                            (props.tooltip)
                                ?  <React.Fragment>
                                    <div style={{width: "95%", float: "left"}}><MappedText text={header}/></div>
                                    <div style={{width: "5%", float: "left"}}><GPAP_Tooltip
                                        placement="right"
                                        interactive
                                        title={(props.tooltip) ? props.tooltip : null}>
                                        <InfoIcon style={{fontSize: "1.3em", marginTop: "3px", color: "#3182bd"}} size={"small"} />
                                    </GPAP_Tooltip></div>

                                </React.Fragment>
                                :      <div style={{float: "left"}}><MappedText text={header}/></div>

                        }
                </div>

            </Grid>
            <Grid item>
                <Field name={collection_name}
                       component={MultiSelect}
                       style={{ fontSize: "14px"}}
                       options={fields}/>
            </Grid>
        </Grid>
        </div>)

};

export const SelectSingleArea = props => {

    let header = props.header;
    let collection_name = props.collection_name;
    let fields = (props.fields.length!==0) ? props.fields : [];

    return (<div className={"field_container"}>
        <Grid container
              direction={"column"}
              justifyContent={"center"}>
            <Grid item>
                <div className={"gpap-subtitle"}>{header}</div>
            </Grid>
            <Grid item>
                <Field name={collection_name} component={SelectSingle} options={fields}/>
            </Grid>
        </Grid>
    </div>)

};


export const SelectSingleAreaCustom = props => {

    let header = props.header;
    let collection_name = props.collection_name;
    let fields = (props.fields.length!==0) ? props.fields : [];

    return (<div className={"field_container"}>
        <Grid container
              direction={"column"}
              justifyContent={"center"}>
            <Grid item>
                <div className={"gpap-subtitle"}>{header}</div>
            </Grid>
            <Grid item>
                <Field name={collection_name} component={SelectSingleCustom} options={fields}/>
            </Grid>
        </Grid>
    </div>)

};




export const TextArea = props => {

    let header = props.header;
    let subheader = props.subheader;
    let collection_name = props.collection_name;
    let size = props.size !== undefined ? props.size : "100%";

    return (<div className={"field_container"} style={{width: size}}>
        <Grid container
              direction={"column"}
              justifyContent={"center"}>
            <Grid item>
                <div className={"gpap-subtitle"}>
                    {header}
                </div>
                {(subheader!== undefined)
                    ? <div className={"gpap-subsubtitle"}>{subheader}</div>
                    : null
                }
            </Grid>
            <Grid item>
                <Field name={collection_name} component={TextFieldForm} defaultValue={props.defaultValue}/>
            </Grid>
        </Grid>
    </div>)

};



