import React from "react";



export const GENETIC_FINDINGS_CNV_GERMLINE = [
    {
        Header: "Genomic breakpoints detected*",
        accessor: "region",
        filter: "fuzzyText",
    },
    {
        Header: 'Included gene(s)',
        accessor: "associated_genes",
        filter: "fuzzyText",
        Cell: ({ value }) => <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", width: "300px",  verticalAlign: "top", maxHeight: "100px", overflowY: "auto"}}>{value}</div>,
    },
    {
        Header: "Type",
        accessor: "sv_type",
        filter: "fuzzyText",
    },
    {
        Header: 'Clinical significance**',
        accessor: "significance",
        filter: 'fuzzyText',
    }, 
    {
        Header: 'Zygosity',
        accessor: "zygosity",
        filter: 'fuzzyText',
    },
    
];
export const GENETIC_FINDINGS_CNV_SOMATIC = [
    {
        Header: "Genomic breakpoints detected*",
        accessor: "region",
        filter: "fuzzyText",
    },
    {
        Header: 'Included gene(s)',
        accessor: "associated_genes",
        filter: "fuzzyText",
        Cell: ({ value }) => <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", width: "300px",  verticalAlign: "top", maxHeight: "100px", overflowY: "auto"}}>{value}</div>,
    },
    {
        Header: "Origin",
        accessor: "origin",
        filter: "fuzzyText",
    },
    {
        Header: "Type",
        accessor: "sv_type",
        filter: "fuzzyText",
    },
    {
        Header: 'Clinical significance**',
        accessor: "significance",
        filter: 'fuzzyText',
    }, 
    {
        Header: 'Zygosity',
        accessor: "zygosity",
        filter: 'fuzzyText',
    },
    //variant allele frequency (aaf)
    
];
