import React from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const NewBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#238b45',
        color: 'white',
        boxShadow: `0 0 0 2px`,
        fontSize: '12px',
        padding: '4px 8px',
        borderRadius: '12px',
    },
}));

export default NewBadge;