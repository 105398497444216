import React from "react";
import { Grid, Typography } from "@mui/material";
import Pharmacogenomics_Table from "./Pharmacogenomics_config";
import { useSelector } from 'react-redux';
import MappedText from "../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import AlertInfoViews from "../reports/create-report/shared-components/AlertInfo";

function Pharmacogenomics() {
  const token = useSelector(state => state.authorization.token);
  const baseUrl = window.config.apis_configuration.api_nextgpap_server_elastic;
  const url = `${baseUrl}/api/pgx`;
  const participant = useSelector(state => state.generaltable.allDataApis.participants[0]);
 
  return (
    <Grid container direction={"row"}>
      <Grid item lg={12}>
        <Typography variant={"h5"} style={{  fontWeight: "700" }}><MappedText text="Pharmacogenomics"/></Typography>
      </Grid>
        <Grid item lg={12}>
            <AlertInfoViews text={"These are all the haplotypes our system calculated. They’re not affected by the request’s filters or gene list."}/>
        </Grid>
      <Grid item lg={12}>
        <Pharmacogenomics_Table
          url = {url}
          token={token}
          participant={participant}
        />
      </Grid>
    </Grid>
  );
}

export default Pharmacogenomics;
