import React, { useState } from "react";
import "./toolbar_results.css";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import Collapse from "@material-ui/core/Collapse";
import VariantSummary from "../../../variant-tabs/VariantSummary";
import GPAP_Tooltip from "../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import { connect } from "react-redux";
import * as SidebarActions from "../../../../../../../../components/SideNavigation/sidebar_actions";
import PluginMenu from "./Plugins/PluginMenu";
import ViewAppliedFilters from "../view-applied-filters/ViewAppliedFilters";
import ExportVariants from "./export/ExportVariants";
import { GPAP_Button } from "../../../../../../../../gpap-lib/components/Button_Collection";
import { BarChartOutlined } from "@material-ui/icons";
import {bindActionCreators} from "redux";
import EditIcon from "@material-ui/icons/Edit";
import MappedText from "../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";
import {Addchart} from "@mui/icons-material";
import CaButton from "../../../../../../../../gpap-lib/components/shared-components/CaButton";

function ToolBar_New(props) {
    const [checked, setChecked] = useState(false);
    const [mode, setMode] = useState(true); // true is variants, false is functional

    const handleChange = () => {
        setChecked(!checked);
    };




    const getFilterButton = () => {

        let titleBtn = "New Query"
        let tooltip= "Filters";

        if(props.studySettings.analysisType === "instand"){
                titleBtn = "Create Query";
                tooltip= "Apply your own filters";
        }

        return  <GPAP_Tooltip title={tooltip}>
            <CaButton
                text= {titleBtn}
                variant="contained"
                id={"new_query"}
                aria-label="filters"
                onClick={() => props.sidebarActions.setView({ page: "Query" })}
                startIcon={<Addchart />}/>
        </GPAP_Tooltip>

    }

    return (
        <div className={"toolbar_results"}>
            {getFilterButton()}
            <GPAP_Tooltip title={<MappedText text={"Variants distributions - (based on max 3000 entries)"} />}>
                <IconButton
                    id={"charts_variants"}
                    color={checked ? "primary" : "default"}
                    aria-label="Details"
                    onClick={handleChange}
                >
                    <BarChartOutlined />
                </IconButton>
            </GPAP_Tooltip>
            <PluginMenu data={props.data} passAnnotations={props.passAnnotations} />
            {props.studySettings.analysisType !== "instand" && <ExportVariants />}
            <ViewAppliedFilters whichQuery="current" />
            {/*  My Switcher*/}
            {/*  {(tableType)
        ?   <FormControlLabel
                control={
                    <Switch
                        checked={!mode}
                        onChange={handleChangeMode}
                        name="functional_mode"
                        color="primary"
                    />
                }
                style={{float:"right"}}
                label="Transcripts Table"
            />
        : null
        }*/}
            <div>
                <Collapse in={checked}>
                    <VariantSummary data={props.data} total={[]} />
                </Collapse>
            </div>
        </div>
    );
}

function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.queryContainer !== undefined)
        {
            return {studySettings: state.studySettings}
        }

    }
}

const mapDispatchToProps = (dispatch) => ({
    sidebarActions: bindActionCreators(SidebarActions, dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(ToolBar_New);