import React from 'react';
import { Button } from '@mui/material';
import MappedText from "../../../scenes/instand/reports/create-report/shared-components/mapText";


const CaButton = ({
    onClick,
    text,
    startIcon,
    variant = 'outlined',
    size = 'small',
    customStyles = {},
    onChange
}) => {
    const defaultStyles = {
        outlined: {
            backgroundColor: "#ffffff",
            color: "#3E69A9",
            borderColor: "#4768a4",
        },
        contained: {
            backgroundColor: "#3E69A9",
            color: "#ffffff",
        }
    };
    const appliedStyles = {
        ...defaultStyles[variant],  
        ...customStyles             
    };
    return (
        <Button
            style={{ textTransform: 'none', boxShadow:'none',  height:"30px",width: 'auto',  ...appliedStyles }}
            variant={variant} 
            size={size}
            onClick={onClick}
            startIcon={startIcon}
            onChange={onChange}
        >
            <MappedText text={text} />
        </Button>
    );
};

export default CaButton;