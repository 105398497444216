


import React, {useState} from "react";

import "./variant-summary.css";
import { connect } from "react-redux";
import Variant_Info from "./components/variant-info/Variant_Info";
import TableChartIcon from '@material-ui/icons/TableChart';
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TabPanel from "../../../../../../gpap-lib/components/GPAP_TabPanel";
import {makeStyles} from "@material-ui/core";
import GeneAggTable from "./GeneAggTable";
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import Switch from "@material-ui/core/Switch/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import SampleTreeView from "./SampleTreeView";
import Divider from "@material-ui/core/Divider";
import DatabasesAccordion from "../results/components/database-accordion/DatabaseAccordion";
import ExternalLinks from "../results/components/external-sources/ExternalLinks";


const useStyles = makeStyles({
    tab: {
        minWidth: 40, // a number of your choice
    }
});


function ExternalResources(props) {

    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [bySample, setBySample] = useState(false);

    let gene_rows = props.summary;
    let sample_agg = props.sample_agg;


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeMode = () => {
        setBySample(!bySample)

    };

    const passSamples = (experiments, genes) => {
        props.passExperiments(experiments, genes)
    };


    const renderTabs = () => {


        let tabObject_1  = <Tab key="tab_1" className={classes.tab} icon={<TableChartIcon/>} aria-label={"Results Summary"}/>;
        let tabObject_2 =  <Tab key="tab_2" className={classes.tab} icon={<ExitToAppIcon/>} aria-label="Resources"/>;

        //Do not display External Databases
        let tabObject_3 =  <Tab  key="tab_3" className={classes.tab} icon={<PlaylistAddCheckIcon/>} aria-label={"External Databases"}/>;

        if(props.summary){
            return [tabObject_1 , tabObject_2,] //tabObject_3]
        }
        else{
            return [tabObject_2,] //tabObject_3]
        }

    };

    const renderTabPanels = () => {

        let config = (props.summary) ? ["summary", "resources", "external_db"] : ["resources", "external_db"];

        let n_experiments  = (props.participants!== undefined && props.participants.experiments !== undefined) ? props.participants.experiments.length : 0;
        let n_par  = (props.participants!== undefined && props.participants.participants !== undefined) ? props.participants.participants.length : 0;

        return config.map(function(item, index){

            switch (item) {
                case "summary":
                    return <TabPanel value={value} index={index} key={index}>
                        <Box p={1} style={{
                            minHeight: "100%",
                            float: "left",
                            width: "100%"
                        }}>
                            <Box p={1} style={{float: "left"}}>
                                <Badge badgeContent={n_experiments} color="primary">Experiments</Badge>
                            </Box>
                            <Box p={1} style={{float: "left"}}>
                                <Badge badgeContent={n_par} color="primary">Participants</Badge>
                            </Box>
                            <div style={{
                                float: "left",
                                paddingLeft: "40px",
                                paddingTop: "8px"
                            }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            checked={bySample}
                                            onChange={handleChangeMode}
                                            name="agg_type"
                                            color="primary"
                                        />
                                    }
                                    style={{float:"right"}}
                                    label="by Experiment"
                                />
                            </div>
                            <div style={{float: "left",
                                                    width: "100%",
                                                    margin: "10px"}}>

                            <Divider/></div>
                            {(bySample)
                                ? <SampleTreeView
                                    data={sample_agg}
                                    runSearchAcrossQuery={props.runSearchAcrossQuery}
                                    passSamples={passSamples}
                                    passGenes={props.passGenes}
                                />
                                : <GeneAggTable
                                    data={props.participants}
                                    runSearchAcrossQuery={props.runSearchAcrossQuery}
                                    rows={(Array.isArray(gene_rows)) ? gene_rows : []}
                                    totalVariants={props.totalVariants}
                                />
                            }
                        </Box>
                    </TabPanel>;
                case "resources":
                    return   <TabPanel value={value} index={index} key={index}>
                        <ExternalLinks/>
                    </TabPanel>;
                case "info":
                    return <TabPanel value={value} index={index} key={index}>
                        <Variant_Info/>
                    </TabPanel>;
                case "external_db":
                    return <TabPanel value={value} index={index} key={index}>
                        <DatabasesAccordion/>
                    </TabPanel>;
            }


        });

    };




    return (
        <div className={props.classStyle} id={props.classStyle}>
            <Paper square>
            <Tabs
                indicatorColor="primary"
                value={value}
                textColor="primary"
                onChange={handleChange}>
                {renderTabs()}
            </Tabs>
                <React.Fragment>
                    {renderTabPanels()}
                </React.Fragment>
            </Paper>


        </div>
    )

}


//state here is store;
// map the store to the props of MainPanel component;
function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        if(state.queryContainer !== undefined)
        {
            return {queryList: state.queryContainer.queryList}
        }

    }
}


// to send data from MainPanel component to the Store;
/*const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});*/



export default connect(mapStateToProps)(ExternalResources);