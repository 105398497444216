import React, {Component} from "react";
import {getPanelApp, getPanelAppGenes, getStandard} from "../../../../../../../../../../../../services/api/get";
import {SelectSingleAreaCustom} from "../../../../../../../../../../../../gpap-lib/components/shared-components/renderer";
import {bindActionCreators} from "redux";
import * as Actions from "../../../actions";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import {GPAP_Button} from "../../../../../../../../../../../../gpap-lib/components/Button_Collection";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import GPAP_Tooltip from "../../../../../../../../../../../../gpap-lib/components/GPAP_Tooltip";
import AddIcon from '@material-ui/icons/Add';
import {AlertInfo} from "../../../../../../../../../../../study-page/create-study/analysis-page/components/samples-section/SelectionInfo";
import IconButton from "@material-ui/core/IconButton";
import DeleteSweepOutlinedIcon from "@material-ui/icons/DeleteSweepOutlined";
import {tooltips} from "../../../variant-section/variantConfig";
import InfoIcon from "@material-ui/icons/Info";
import {LinearProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import MappedText from "../../../../../../../../../../../study-page/create-study/analysis-page/components/clinical-info/mapText";



// TODO: this component became a bit chaotic after many different changes and requirements. Better to refractor sooner or later



class PanelApp_Selection extends Component{

    constructor(props){
        super(props);
        this.state = {
            panelApp: [],
            genes:[],
            disabled: false,
            panel: undefined,
            panelApp_data: [],
            panelText: "",
            value: "whole_panel",
            error:false,
            panelAppItem: false,
            added_genes: false,
            list_loaded: false,
            sending_requests: false,
            loading: false
        };

        this.LIST_TYPE= "PanelApp";
        this.fetchData = this.fetchData.bind(this);
        this.addGenes = this.addGenes.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.fetchPanelAppData = this.fetchPanelAppData.bind(this);
        this.getInfo = this.getInfo.bind(this);
        this.getAddOrDeleteButton = this.getAddOrDeleteButton.bind(this);
        this.collectPanelAppGenes = this.collectPanelAppGenes.bind(this);
        this.load_applied_lists_if_any = this.load_applied_lists_if_any.bind(this);
        this.removeSelectedPanelType = this.removeSelectedPanelType.bind(this);
        this.panelAPP_URL = "https://panelapp.genomicsengland.co.uk/panels/";
    }

    componentDidMount() {

        // TODO: move to some config file...
        let url = "https://panelapp.genomicsengland.co.uk/api/v1/panels/";


        getPanelApp(url)
            .then((response) => response.json())
            .then((json) =>
            {

                this.setState(state => {
                    const panelApp = state.panelApp.concat(json.results);

                    return {
                        panelApp: panelApp,
                     }
                });
                this.fetchData(json.next);
            })
            .catch(function(error) {
                console.log(error);
            });



    }

    handleChange = (event) => {
        this.removeSelectedPanelType()
        this.setState({
            value: event.target.value,
            panelText:"The type of panel has changed. Click + Add Panel App Genes button",
        });
    };


    fetchPanelAppData(url, panel_id){

        //let panel = this.props.panel;
        const self = this;
        getStandard(url)
            .then(function(response){
                if(response.ok){
                    return response.json()
                }
                else{
                    self.setState(
                        {
                            error: true
                        }
                    );
                }
            } )
            .then(function(json){
                if(json!== undefined){
                    if(json.next!== null){
                        self.fetchPanelAppData(json.next, panel_id);
                        self.setState(state => {
                            const genes = self.state.genes.concat(json.results);
                            return { genes }
                        });
                    }
                    else{
                        let new_genes = self.state.genes.concat(json.results);
                        let currentItem = self.state.panelApp.find(s => s.id === panel_id);
                        // LOAD DATA OF PANEL
                        self.setState({
                            loading:false,
                            sending_requests: false,
                            list_loaded: true,
                            genes: new_genes,
                            panel:panel_id,
                            panelAppItem: currentItem });
                    }
                }
            })
    }


    async getGenes(formatted_string, panel_id) {


        this.fetchPanelAppData(formatted_string, panel_id);

    }

    collectPanelAppGenes(panel_id){

        const self = this;
        getPanelAppGenes(panel_id)
            .then(r => r.json())
            .then(function(json){

                if(json.next!== null ){
                    let new_genes = self.state.genes.concat(json.results);
                    self.setState({
                        sending_requests: true,
                        loading:true,
                        genes: new_genes,
                        panel: panel_id
                    });

                    self.getGenes(json.next, panel_id);
                }
                else{
                    let new_genes = self.state.genes.concat(json.results);
                    let currentItem = self.state.panelApp.find(s => s.id === panel_id);

                    // LOAD DATA OF PANEL

                    self.setState({
                        sending_requests: false,
                        loading:false,  // this one doesn't not seem to work correctly;
                        list_loaded: true,
                        genes: new_genes,
                        panel:panel_id,
                        panelAppItem: currentItem });

                }

            })


    }

    load_applied_lists_if_any(){

        let {applied_lists} = this.props;
        if(this.state.loading || this.state.clearing_form){
            return;
        }
        else{
            if(applied_lists.length!== 0 && !this.state.list_loaded ){
                // update panelapp if in REDUX STORE;
                if(applied_lists.length!== 0 ){
                    let panelAppID = applied_lists.find(s => s.type === this.LIST_TYPE);
                    if(panelAppID!== undefined){
                        this.collectPanelAppGenes(panelAppID.id);

                    }
                }
            }
            else if(applied_lists.length!== 0 && this.state.list_loaded && this.state.added_genes === false){
                this.addGenes();
            }
        }



    }


    async componentDidUpdate(prevProps, prevState, snapshot) {


        const self = this;

        // load list genes if not loaded yet or not different from previous
       let different = true;

       if(this.props.applied_lists.length === 0 && prevProps.applied_lists.length!==0){


           // re-initialize what necessary
           this.setState({
               panelAppItem: false,
               genes: [],
               loading: false,
               list_loaded: false,
               added_genes: false,
               panel: undefined}
           )
       }

       let panelApp_lists = this.props.applied_lists.filter(s => s.type === this.LIST_TYPE);

       if(panelApp_lists.length >0 && !this.state.loading && !this.state.clearing_form) {
           this.setState({loading:true});
           self.load_applied_lists_if_any();
       }


        let SELECT_VALUE= self.props.panel;
        let NEW_SELECTED_VALUE = SELECT_VALUE !== this.state.panel && SELECT_VALUE !== undefined && !this.state.loading;
        if ( NEW_SELECTED_VALUE ) {

            let panel_id = this.props.panel;
            self.collectPanelAppGenes(panel_id);

            self.setState({
                panelAppItem: false,
                genes: [],
                added_genes: false,
                panel: SELECT_VALUE,
                clearing_form: false,
                loading: true});
        }

        else if(this.state.genes.length > 0 && Array.isArray(this.props.panel) && this.props.panel.length === 0){
            // disable button
            this.setState({
                disabled: true
            })
        }
        else if(this.props.applied_lists.length){
           /* this.setState({
                panelItem: false
            })*/
        }

    }


    getInfo(){


        let { genes } = this.state;

        // 2 is amber, 3 is green panel;
        let green = genes.filter(s => s.evidence.includes("Expert Review Green")).map(s => s.entity_name);
        // see how many are uniques;
        let uniques = [...new Set(green)].length;



       if(this.state.sending_requests){
           return <React.Fragment>
               <Typography variant={"subtitle2"}><MappedText text={"Loading genes..."}/></Typography>
               <LinearProgress/>
           </React.Fragment>
       }
       else{
           if (this.state.panelAppItem)
           {
               return  <Box p={1}>
                   <div><MappedText text={"Panel "} />{this.state.panelAppItem.name} - (v.{this.state.panelAppItem.version}) <MappedText text={"includes a total of "} /> {this.state.panelAppItem.stats.number_of_genes} <MappedText text={" entities. There are "}/> {uniques} <MappedText text={" distinct genes in the "} /> <span style={{color: "green", fontWeight: "bold"}}><MappedText text={" green list"} /></span> </div>
                   <div> <a
                       href={ this.panelAPP_URL + this.state.panel + "/"}
                       target="_blank">
                        <MappedText text={"Read more of this panel in PanelApp"} />
                   </a>
                   </div>
               </Box>
           }
           else{
               return null;
           }
       }






}


    addGenes(){


        if(this.state.panelAppItem){
            let { actions, operator } = this.props;

            let panel_genes = [];

            let whole_panel = this.state.genes.map(gene => gene.entity_name)
            let green_panel = this.state.genes.filter(sample => sample.evidence.includes("Expert Review Green") === true).map(gene => gene.entity_name)
            let amber_panel = this.state.genes.filter(sample => sample.evidence.includes("Expert Review Amber") === true).map(gene => gene.entity_name)

            //Concat green & amber
            let green_amber_panel = green_panel.concat(amber_panel)

            if (this.state.value === "green_list")
            {
                panel_genes = green_panel
            }
            else if (this.state.value === "green_amber_lists")
            {
                panel_genes =  green_amber_panel
            }
            else{
                panel_genes = whole_panel
            }


            this.setState({
                added_genes:panel_genes,
                panelText: "",
                loading:false
            })

            if(operator=== "intersection"){
                actions.intersect_genelist(panel_genes, "disease");
            }
            else{
                actions.replace_genes_to_specific_genelist(panel_genes, "disease");
            }

            // add panel to the list;

            let { panelAppItem } = this.state;

            let panelList = {
                label: panelAppItem.name,
                version: panelAppItem.version,
                n_genes: panelAppItem.stats.number_of_genes,
                timestamp: panelAppItem.version_created,
                id: panelAppItem.id,
                type: this.LIST_TYPE
            }

            this.props.actions.add_applied_list(panelList);

        }

    }


    // iterate and fetch data;
    fetchData(nextURL)
    {
        if(nextURL!== null)
        {
            getPanelApp(nextURL)
                .then((response) => response.json())
                .then((json) => {
                    if(json.next === null){
                        this.setState(state => {
                            const panelApp = state.panelApp.concat(json.results);
                            return {
                                panelApp: panelApp
                            }
                        });
                    }else{
                        this.setState(state => {
                            const panelApp = state.panelApp.concat(json.results);
                            return {
                                panelApp: panelApp
                            }
                        });
                        this.fetchData(json.next)
                    }

                })
        }
    }

    removeSelectedPanelType(){
        this.props.actions.remove_genes_from_list(this.state.added_genes, "disease");
        //this.props.actions.remove_applied_list(this.state.panelAppItem.id, this.LIST_TYPE);
        this.setState({
            clearing_form: true,
            loading:false,
            added_genes: false,

        }
        )

    }

    clearList(){

        this.props.actions.remove_genes_from_list(this.state.added_genes, "disease");
        this.props.actions.remove_applied_list(this.state.panelAppItem.id, this.LIST_TYPE);
        this.setState({
            clearing_form: true,
            panelAppItem: false,
            genes: [],
            loading: false,
            list_loaded: false,
            added_genes: false,
            panel: undefined}
        )
    }



    getAddOrDeleteButton(){


        if(this.state.added_genes){

            return <Box m={0}>
               <div style={{float: "left"}}>
                   <GPAP_Tooltip title=<MappedText text={"Delete PanelApp genes from query"}/>>
                       <IconButton onClick={()=> this.clearList()}>
                           <DeleteSweepOutlinedIcon/>
                       </IconButton>
                   </GPAP_Tooltip>
               </div>
                <div style={{float: "left", margin: "2%", fontWeight: "bold"}}>
                    <MappedText text ={"PanelApp genes added to the query"}/>
                </div>
            </Box>

        }
        else{
            return  <GPAP_Button style={{ display: ""}}
                                 variant={"secondary"}
                                 disabled={this.state.disabled || this.state.genes.length === 0}
                                 onClick={this.addGenes}
                                 size={"sm"} startIcon={<AddIcon/>}><MappedText text={"Add Panel App Genes"}/> </GPAP_Button>
        }
    }


    render(){

        let panelApp = [];
            if(this.state.panelApp.length!== 0)
        {
            panelApp =  this.state.panelApp.map(function(pA){return {label: pA.name, value:pA.id, disease_group: pA.disease_group, disease_sub_group: pA.disease_sub_group}});
        }

        return   <Box m={2}>
                <div>
                    <div style={{width: "95%", float: "left"}}>  <MappedText text ={"Genomics England PanelApp"}/></div>
                    <div style={{width: "5%", float: "left"}}><GPAP_Tooltip
                        placement="right"
                        interactive
                        title ={tooltips["panelApp"]}>
                        <InfoIcon style={{fontSize: "1.3em", marginTop: "3px", color: "#3182bd"}} size={"small"} />
                    </GPAP_Tooltip></div>
                </div>

            <Box p={2}>
                <FormControl component="fieldset" style={{ marginTop: "12px"}}>
                    <FormLabel component="legend"><MappedText text={"Type of Panel"}/></FormLabel>
                    <RadioGroup aria-label="panel_type" name="panel_type" value={this.state.value} onChange={this.handleChange} row>
                        <FormControlLabel value="whole_panel" control={<Radio />} label=<MappedText text ={"Whole panel"}/> />
                        <FormControlLabel value="green_list" control={<Radio />} label=<MappedText text ={"Green List" }/>/>
                        <FormControlLabel value="green_amber_lists" control={<Radio />} label=<MappedText text ={"Green and Amber lists"}/> />
                    </RadioGroup>
                </FormControl>
            </Box>
            <div id="select-panelapp">
            <SelectSingleAreaCustom  header = {""} collection_name = {"panelapp"} fields = {panelApp}/>
            </div>
            {
                    this.getInfo()
            }
            {(this.state.error)
                ? <div style={{float: "left", width: "100%" }}>
                    <AlertInfo severity={"error"} title=<MappedText text ={"Error"}/> message=<MappedText text ={"Request Error"}/>/>
                    </div>
                : null
            }
            <Box p={0} >
            <span  style={{ display: "block", marginBottom: "12px", fontStyle: "italic" }}> <MappedText text={this.state.panelText}/> </span>

                {this.getAddOrDeleteButton()}


            </Box>
        </Box>
    }

}


function mapStateToProps (state) {
    if(state.form!==undefined)
    {
        let form = state.form;
        if(form.gene_section!== undefined && form.gene_section.values!== undefined){
            if(form.gene_section.values.panelapp!== undefined)
                return {
                panel:form.gene_section.values.panelapp.value, operator: state.geneList.operator, applied_lists: state.geneList.applied_lists };
            else{
                return {
                    token: state.authorization.token,
                    applied_lists: state.geneList.applied_lists
                }
            }
        }
        else{
            return {token: state.authorization.token, applied_lists: state.geneList.applied_lists}
        }
    }
    else{
        return {token: state.authorization.token, applied_lists: state.geneList.applied_lists}
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
});


export default connect(mapStateToProps,mapDispatchToProps)(PanelApp_Selection);