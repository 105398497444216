

import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {big_rounded_button, GPAP_Button} from "../../../gpap-lib/components/Button_Collection";
import * as Actions from "../create-study/analysis-page/reducers/sample_actions";
import * as General_Actions from "../../../actions/index.js";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import ClinicalInfo from "../create-study/analysis-page/components/clinical-info/ClinicalInfo";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import {Link, Redirect} from "react-router-dom";

import Analysis_List_Item
    from "../../dashboard/components/genomics-dashboard/components/side-panels/study-store/components/list-material-ui/Analysis_List_Item";

import {mapSamples} from "../create-study/analysis-page/components/GenotypeBuilder/components/GenotypeForm/GenotypeForm";
import inheritances_keys
    from "../../dashboard/components/genomics-dashboard/components/side-panels/study-store/components/list-material-ui/inheritance_list";
import {create_gt_config} from "../create-study/analysis-page/components/GenotypeBuilder/components/GenotypeForm/create_gt_configuration";
import * as StudyActions from "../../dashboard/reducers/study_actions";
import * as Sample_Actions from "../../../scenes/study-page/create-study/analysis-page/reducers/sample_actions";
import * as Inheritance_Actions
    from "../../../scenes/study-page/create-study/analysis-page/reducers/inheritance_actions";
import DeleteDialog from "./deleteDialog";
import Fade from "@material-ui/core/Fade/Fade";
import PermissionsBox from "../permission-box/PermissionsBox";
import {get_list_data} from "../../../services/api/lists_api";
import {parse_date} from "../helpers";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {getSingleCohort} from "../../../services/api/get";
import {LinearProgress} from "@material-ui/core";
import GPAP_Dialog from "../../../gpap-lib/components/GPAP_Dialog";
import {resetStudySettings} from "../../dashboard/reducers/study_actions";
import SampleSummary from "../create-study/analysis-page/analysis-summary/components/SampleSummary";
import {mergeGeneListWithFilters} from "./utils";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: "1%"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));



function StudyOverview(props){

    const classes = useStyles();

    const [load, setLoadStudy] = useState(false);
    const [initialized, initializeStudy] = useState(false);

    const [tagged_variants, setTaggedVariants] = useState([]);
    const [go_to_home, redirectHome] = useState(false);
    const [go_to_genomics, redirectGenomics] = useState(false);
    const [tmp_study, setTmpStudy] = useState(false);
    const [tmp_analyses, setTmpAnalyses] =  useState(false);
    const [tmp_lists, setTmpLists] =  useState([]);
    const [study_type, setStudyType] =  useState("family_trio");

    const [cohort, setCohort] = useState([]);
    const [cohortSamples, setCohortSamples] = useState([]);
    const [error, setError] = useState("");

    const [openDialog, setOpenDialog]= useState(false);
    
    const [gtIsNullMsg, setGtIsNullMsg] = useState(false);

    // TODO: shouldn't be here;
    let study = props.study;
    let analyses = props.analyses;
    let samples_array = props.samples_array;


    let {samplesLoaded, alreadyLoaded} = props;

    // get the data;

    useEffect(function(){

        if(!initialized){
            props.samples_actions.resetSamples();
            let study_local_id = prepare_study();
            create_analyses(study_local_id);
            initializeStudy(true);
            let studyActions = props.studyActions;
            studyActions.reset_bucket();
            studyActions.resetStudySettings();
        }

    });


    const prepare_study = () => {

        // create the study;
        let localID = Math.floor(Math.random()*200);

        let study = {...props.study};
        study.localId = localID;
        // update variants accordingly
        study.tagged_variants = (study.tagged_variants!== undefined && study.tagged_variants.length === tagged_variants) ? study.tagged_variants : tagged_variants ;
        study.analyses = [];

        setTmpStudy(study);

        return study.localId;

    };


    const assignStudyType = () => {

        let type = analyses.map(a => a.analysis_type);

        if (type.includes("search_across")) {
            setStudyType("search_across");
        } else if (type.includes("cohort")) {
            setStudyType("cohort");
        } else {
            setStudyType("family_trio");
        }

    }


    const saveLists = () => {

        //collect all possible gene lists;
        let query_objects = [];

        analyses.forEach(function (analysis) {
            let current_lists = [];
            analysis.queries.forEach(function (query) {
                // predefined gene lists
                // read applied filters...
                let extra_filters = query.extra;
                let id_array = extra_filters.map(g => g.value).filter(s => s !== undefined);

                if (id_array.length !== 0) {

                    query_objects.push(id_array);

                }
            })

        })

        // query the genelists;

        // Create an array of promises using the query_objects and get_list_data function
        let promises = query_objects.map(id_array => get_list_data(props.token, window.config.apis_configuration.api_nextgpap_server, {list_id: id_array})
            .then(y => y.json()));

        // Execute all promises and process the results
        Promise.all(promises).then(results => {
            // Initialize an empty array to store unique lists
            let lists = [];
            // Iterate through each result
            results.forEach(function(el) {
                // Check if the result has 'data'
                if ("data" in el) {
                    // Find if the current data already exists in the 'lists'
                    if (lists.findIndex(r => r.id === el["data"][0].id) === -1) {
                        // Concatenate the new data to the 'lists'
                        lists = lists.concat(el["data"]);
                        // Return the updated 'lists'
                        return lists;
                    }
                }
            });

            // Set the final list of unique data to the state variable
            setTmpLists(lists);
        });

    }

    useEffect(function(){

        // update lists if necessary;

        if(tmp_lists.length === 0 && tmp_analyses){
            saveLists();
        }

    },[tmp_analyses.length])


    const mapSampleSettings = (analysis) => {


        return analysis.samples.map(function (sample) {

            let gt_settings = (sample.gt_settings !== undefined)
                ? sample.gt_settings[0]  // case Family / Case
                : sample.gt[0] // case Search Across All

            if(gt_settings === null){

                if (props.analyses && props.analyses.length > 1)
                    setGtIsNullMsg(true)
                
                setOpenDialog(true);
            }
            else{
                return {
                    "sample_id": sample.sample_id,
                    "gt": gt_settings.setting,
                    // get index value instead
                    "index": (sample.index !== undefined) ? sample.index : false,
                    "dp": gt_settings.dp,
                    "gq": gt_settings.gq,
                    "ad_low": gt_settings.ad_low,
                    "ad_high": gt_settings.ad_high,
                }
            }


        })

    }

    const create_analyses = async (localID) => {

        // setup analysis
        if (analyses !== undefined) {
            // sort analyses by date of creation;
            analyses.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a["creationDate"]) - new Date(b["creationDate"])
            });


            analyses.forEach(function (analysis, analysisIndex) {
                const analysis_local_id = Math.floor(Math.random() * 200);
                analysis.studyLocalId = localID;
                analysis.localId = analysis_local_id;
                analysis.labelled = (analysis.labelled !== undefined) ? analysis.labelled : {};

                const query_of_analyses = analysis.queries.map(function (query) {
                    let queryC = {...query};
                    queryC.analysis_id = analysis_local_id;
                    // filter_set to filters;

                    // setup samples for each query.

                    // create samples for query

                    let SAMPLES = mapSampleSettings(analysis);

                    queryC.filter_set.filters["samples_germline"] = JSON.parse(JSON.stringify(SAMPLES));

                    queryC.filter_set.filters.samples_somatic = [];


                    queryC["localId"] = query.query_id;

                    return queryC
                });

                analysis.queries = JSON.parse(JSON.stringify(query_of_analyses));


            });

            //saveLists();
            assignStudyType()

            const my_analyses = JSON.parse(JSON.stringify(analyses));

            setTmpAnalyses(my_analyses);

            // load cohort;

            if (analyses[0].analysis_type === "cohort" && analyses[0].samples.length > 0) {
                await loadCohort(config.apis_configuration.api_cohortapp, props.token, analyses[0].samples[0].cohort_id);
            }
        }
    };

    const loadCohort = (apiUrl, token, cohortId) => {
        try {
            const response = getSingleCohort(apiUrl, token, cohortId)
                .then(resp => resp.json())
                .then(function(cohort){

                    // cohort = json;

                    if ("cohort" in cohort) {
                        setCohort(cohort.cohort);
                        const co_samples = cohort.cohort.data.map(co => {
                            const gt_settings = props.samples_array[0].gt;
                            return {
                                "sample_id": co,
                                "gt": gt_settings,
                            };
                        });

                        setCohortSamples(co_samples);
                    } else {
                        setError("Cohort Data Not Available");
                    }


                })

        } catch (error) {
            console.error("Error loading cohort:", error);
            setError("Error loading cohort");
        }
    };


    const setupSamples = async () => {

        let first_analysis = tmp_analyses[0];

        let analysis_type = first_analysis.analysis_type;


        // add samples to the store
        samples_array.forEach(function (sample) {
            let {sample_id, pheno_id, sample_type, affectedStatus, sample_from, gt_settings} = sample;

            let gt = (gt_settings !== undefined) ? gt_settings : sample.gt;

            // TODO: review these steps. Should we collect the participants ids?

            if (analysis_type === "cohort") {
                let affectedStatus = "yes";
                props.samples_actions.setSamples(sample_id, pheno_id, sample_type, affectedStatus, sample_from, gt);
                props.general_actions.set_selected_cohort(cohort.id);
            }
            else {
                props.samples_actions.setSamples(sample_id, pheno_id, sample_type, affectedStatus, sample_from, gt)
            }
        });

    };


    const setupInheritance = () => {


        // if cohort was collected
        if(cohortSamples.length>0){
            samples_array= [...cohortSamples];
        }

        let samples = mapSamples(props.patients, samples_array);
        let studyActions = props.studyActions;

        // select analysis to run.
        let first_analysis = tmp_analyses[0];


        let analysis_ids = {id: 0, label: ""};

        // set inheritance in redux
        if(first_analysis.analysis_type === "family_trio"){
            let inheritance = inheritances_keys.find( s=> s.key === first_analysis.inheritance_mode_id);
            props.inheritance_actions.setInheritance(inheritance.label);
            analysis_ids.label=inheritance.label;
            analysis_ids.id= first_analysis.inheritance_mode_id;
            let configuration_for_samples = create_gt_config(samples, inheritance.key);
            configuration_for_samples.forEach(function(conf_row){
                // TODO: this is generic...but this should be done for each analysis.

                if(inheritance.label === "custom"){
                    // load inheritance

                    let sample = first_analysis.samples.find(s => s.sample_id === conf_row.id );
                    //
                    let gt_settings = sample.gt_settings.find(s => s.inheritance_key === inheritance.label);
                    // do all the rest;

                    let copy = {...conf_row.configuration};
                    // take inheritance settings from saved samples;
                    copy.setting = gt_settings.setting;
                    //
                    props.samples_actions.setGenotype(conf_row.id, copy, conf_row.report_id, conf_row.bool)

                }
                else{
                    props.samples_actions.setGenotype(conf_row.id, conf_row.configuration, conf_row.report_id, conf_row.bool)
                }

            });
        }

        let obj = {
            analysis_ids: [analysis_ids],
            current_analysis: first_analysis.localId,
            current_query: (first_analysis.queries[0]!== undefined) ? first_analysis.queries[0].localId : null };

        studyActions.setStudySettings(obj);

    };


    const setupStudy = (allAnalyses) => {

        let studyActions = props.studyActions;
        studyActions.create_study(tmp_study);
        let settings = {
            id: tmp_study.localId,
            study_status: true,
            study_id_db: tmp_study.id
        };
        studyActions.setStudySettings(settings);
        // add analyses
        allAnalyses.forEach(function(analysis){
            studyActions.create_analysis(analysis);
        });

        studyActions.setStudySettings({analysisType:study_type});

    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const loadStudy = () => {


        if(!alreadyLoaded){
            if(Array.isArray(tmp_analyses)){
                let all_analyses = [...tmp_analyses];

                all_analyses.forEach(function(analysis) {
                    analysis.queries = analysis.queries.map(function(query) {
                        let gene_list = query.extra.find(s => s.type === "Predefined List");
                        let isOperatorApplied = query.extra.some(s => "gene_list_operator" in s);
                        let list_found = gene_list ? tmp_lists.find(list => list.id === gene_list.id) : false;
                        return mergeGeneListWithFilters({ ...query }, list_found, isOperatorApplied);
                    });
                });


                // setup study in redux store;
                setupStudy(all_analyses);
                // add samples to the store. This is necessary to setup the inheritance
                setupSamples();
                // setup inheritance in redux store;
                setupInheritance();
                // load it...
                setLoadStudy(true);
                //redirectGenomics(true);
            }
            else{
                setOpenDialog(true);
            }

        }
        else{
            setAlreadyLoaded(true);
        }

    };

    const go_to_study = () => {

        redirectHome(true)
    };

    const go_to_analysis = () => {

        redirectGenomics(true)

    };



    const analysesView = () => {

        return analyses.map(function(analysis, index){

            return <Analysis_List_Item
                key={index}
                readOnly = {true}
                queryDelete = { function(){
                    console.log("implement")
                } }
                analysis={analysis}
                queryViewer={ function(x){
                    console.log("implement")
                } }/>
        });
    };

    const getTaggedVariants = (variants) => {
        let current_variants = [...tagged_variants];
        let new_vars = current_variants.concat(variants);
        setTaggedVariants(new_vars);
    };

    const showParticipantsInfo = () => {
        if(samples_array.length!== 0 && samples_array.length<2 && samples_array.map(s => s.sample_id).includes("ALL_SAMPLES") ){
            return <Fade in = {true} timeout={1000}>
                <div className={"clinical_info"}>
                    <Grid container>
                        <Grid item>
                            <Box p={2}>
                                <Typography variant={"h6"} color={"secondaryText"}>Search Across All Study</Typography>
                                <PermissionsBox/>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        }
        else{
            return <React.Fragment>
                <ClinicalInfo samples={samples_array.filter(s => s.sample_id !== "ALL_SAMPLES")}
                              view_only={true}
                              passTaggedVariants = {getTaggedVariants} />
            </React.Fragment>
        }

    };

    const getPermission = () => {

       let permissions =  [{value: "user", label: "Only Visible to you"}, {value: "group", label: "Shared with your groups"}, {value: "public", label: "Visible to All"}];

       let el = permissions.find(s => s.value === study.permission);

        let d = study.creationDate;
        let data = new Date(d);
        let date_formatted = parse_date(data);

       return  <Box>
           <Typography variant={"body1"} color={"textSecondary"}>
               {(study!== undefined) ? el.label : "NA"}
           </Typography>
           {(study.owner)
               ?  <Typography variant={"subtitle1"} color={"textSecondary"}>
                   You created the study on {date_formatted}
               </Typography>
               : <Typography variant={"subtitle1"} color={"textSecondary"}>
                   The study was created on {date_formatted}
               </Typography>
           }
       </Box>


    }


    const LoadOrLoading = () => {

        if(study_type === "family_trio" && props.patients.length === 0 && props.sampleList.samples.length === 0){
            return <LinearProgress/>
        }
        else{
            return  <GPAP_Button onClick={loadStudy} > Load Study </GPAP_Button>;
        }


    }


    const renderNotLoaded = () => {

        return <div className={classes.root}>
            <GPAP_Dialog
                open={openDialog}
                handleClose={handleCloseDialog}
                title={gtIsNullMsg ? "One or more queries in your analysis is not working" : "Study can't be loaded"}
                text={gtIsNullMsg ? "You can still load the study and the rest of the queries" : "The study was not saved correctly and it can't be loaded."}
                onConfirmText={"ok"}
                onExitText={false}
                onConfirmAction={handleCloseDialog}
                type={gtIsNullMsg ? "warning" : "alert"}
            />
            <Grid container direction={"row"}
                  alignItems={"flex-start"}
                  justifyContent="center" spacing={2}>
                <Grid item lg={3}>
                    <GPAP_Button onClick={go_to_study} startIcon={<ArrowBackIcon/>}>
                        Back to MyGPAP
                    </GPAP_Button>
                </Grid>
                <Grid item lg={6}  >
                    <Paper className={classes.paper}>
                        <Typography variant={"h6"}>
                            Study Overview
                        </Typography>
                        <Typography variant={"h5"}>
                            {(study!== undefined) ? study.studyName : "NA"}
                        </Typography>
                        {getPermission()}
                        {
                            (alreadyLoaded)
                                ?  <React.Fragment>
                                    <Typography> Study Loaded, you will find it in your Study List </Typography>
                                    <GPAP_Button onClick={go_to_analysis}>
                                        Go to Analysis
                                    </GPAP_Button>
                                    <DeleteDialog
                                        onlyIcon={false}
                                        study_id = {study.id}
                                        token = {props.token}
                                        go_to_study = {go_to_study}
                                    />
                                </React.Fragment>
                                : <Box p={1}>
                                    {LoadOrLoading()}
                                    {(study.owner) ?  <DeleteDialog
                                        onlyIcon={false}
                                        study_id = {study.id}
                                        go_to_study = {go_to_study}
                                    /> : null
                                    }
                                </Box>

                        }

                    </Paper>
                </Grid>
                <Grid item lg={3}>
                </Grid>
                {  (study_type === "family_trio")
                    ? <Grid item lg={8} >
                    <Paper>
                        {
                            (samplesLoaded)
                                ?   showParticipantsInfo()
                                :   <div> Participants information not loaded.  </div>
                        }
                    </Paper>
                </Grid>
                    : null
                }
                {/*{ (study_type === "cohort")
                    ? <Grid item lg={8} >
                    <Paper>
                        <SampleSummary/>
                    </Paper>
                </Grid> : null}*/}
                <Grid item lg={4} >
                    <Paper>
                        {
                            (study!== undefined && analyses!== undefined)
                                ? <Box p={1}>
                                    <Typography variant={"h6"} color={"textSecondary"}>
                                        Analyses
                                    </Typography>
                                    <Box p={2}>
                                        <List component="div" disablePadding>
                                            {analysesView()}
                                        </List>
                                    </Box>
                                </Box>
                                : <Typography variant={"h6"}>
                                    No analyses in this study
                                </Typography>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </div>

    };

    const studyNotFound = () => {

        return <div className={classes.root}>
            <Grid container direction={"column"} alignItems={"center"} justifyContent="center" spacing={2}>
                <Grid item lg={5}>
                    <Box p={2}>
                        <Typography variant={"h4"}>The study was not found</Typography>
                    </Box>
                </Grid>
                <Grid item lg={5}>
                        <Link
                            to={"/home"}
                            component={big_rounded_button}>
                            Dashboard
                        </Link>
                </Grid>
            </Grid></div>

    };

    const renderView = () => {


        if(go_to_genomics){
            if(study_type === "cohort"){
                return <Redirect to={{
                    pathname: "/genomics",
                    state: {
                        loadStudy: true,
                        samples_cohort: cohortSamples
                    } }}

                />;
            }
            else{
                return <Redirect to={{
                    pathname: "/genomics",
                    state: { loadStudy: true } }}

                />;
            }

        }
        else if(go_to_home){
            return <Redirect to={{pathname: "/home" }}/>;
        }
        else if (!load && study!== undefined){
            return renderNotLoaded()
        }
        else if (study === undefined){
            return studyNotFound()
        }
        else{
            if(study_type === "cohort"){
                return <Redirect to={{
                    pathname: "/genomics",
                    state: {
                        loadStudy: true,
                        samples_cohort: cohortSamples
                    } }}

                />;
            }
            else{
                return <Redirect to={{pathname: "/genomics", state: { loadStudy: true } }}/>;
            }

        }

    };


    return  renderView()

}


function mapStateToProps (state) {
    if(state.authorization!==undefined)
    {
        return {
            token: state.authorization.token,
            studySettings: state.studySettings,
            studyBucket: state.studyBucket,
            sampleList: state.sample_list,
            patients: state.patients.patients
        }
    }
}
const mapDispatchToProps = (dispatch) => ({
    general_actions: bindActionCreators(General_Actions, dispatch),
    studyActions: bindActionCreators(StudyActions, dispatch),
    actions: bindActionCreators(Actions, dispatch),
    inheritance_actions: bindActionCreators(Inheritance_Actions, dispatch),
    samples_actions: bindActionCreators(Sample_Actions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(StudyOverview);