import MappedText from "../../../../../../../../../../instand/reports/create-report/shared-components/mapText";
import React from "react";

export const newColumns = (
  data,
  configFilter,
  isCheck,
  preFilterData,
  compactColumns = [],
  compactColumnsSplit = [],
  tooltipCompact = [],
  tooltipCompactSplit = []
) => {
  let newColumns = [];

  return data.map((item, index) => {

    if (compactColumns || compactColumnsSplit) {
      newColumns = [...item.columns];

      const data = isCheck ? compactColumnsSplit : compactColumns;

      data.forEach((compactElem) => {
        const referenceTable = compactElem.referenceTable;
        const nameColumn = compactElem.nameColumn;

        const newColumn = {
          dataSearch: nameColumn,
          nameTable: nameColumn,
          filterType: "text",
          tooltip: compactElem.tooltip
        };

        const refIndex = newColumns.findIndex(
          (col) => col.nameTable === referenceTable
        );

        if (refIndex !== -1) {
          if (compactElem.position === "left") {
            newColumns.splice(refIndex, 0, newColumn);
          } else {
            newColumns.splice(refIndex + 1, 0, newColumn);
          }
        }
      });


      newColumns = newColumns.filter((column) => {
        for (let compactElem of data) {
          for (let rowSelect of compactElem.rowSelect) {
            if (column.nameTable === rowSelect.replaceWith) {
              return false;
            }
          }
        }
        return true;
      });
    }

    if (tooltipCompact.length || tooltipCompactSplit.length) {

      const dataArray = isCheck ? tooltipCompactSplit : tooltipCompact;

      dataArray.forEach((data) => {


        newColumns = newColumns.reduce((columns, element) => {

          const isPresent = data.rowSelect.some(
            (rowSelect) => rowSelect.replaceWith === element.nameTable
          );

          if (!isPresent) columns.push(element);

          return columns;
        }, []);
      });
    }

    const dataRender =
      compactColumns || compactColumnsSplit ? newColumns : item.columns;

    return {
      id: index + 1,
      Header: item.header ? item.header : "",
      columns: dataRender.map((column) => {
        if (!column.nameTable) return null;
        return {
          filterType: column.filterType ? (typeof column.filterType === 'string' ? { type: column.filterType } : column.filterType) : { type: "text" },
          data: preFilterData,
          Header: <MappedText text={column.nameTable}/>,
          tooltip: column.tooltip ? column.tooltip : "",
          accessor: column.dataSearch,
          filter: column.filter ? column.filter : "text",
          type: column.type ? column.type : "text",
        };
      }),
    };
  });
};
