export const filters = () => ({
  text: (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      if (rowValue === "" || rowValue === undefined || rowValue === null || rowValue === "N/A") {
        return false;
      }
      return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase());
    });
  },

  multiSelect: (rows, id, filterValue) => {
    if (filterValue.length === 0) {
      return rows;
    }
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue !== "N/A" && rowValue !== "" && rowValue !== undefined && rowValue !== null && filterValue.includes(rowValue);
    });
  },

  dateFilter: (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      if (rowValue === 'N/A' || rowValue === '' || rowValue === undefined || rowValue === null) {
        return false;
      }
      const rowDate = new Date(rowValue);
      rowDate.setHours(0, 0, 0, 0);
      const filterDate = new Date(filterValue);
      filterDate.setHours(0, 0, 0, 0);
      return rowDate <= filterDate;
    });
  },
});