export const filters = () => {

  // Helper function to deal with population numeric filter logic
  const numericFilter = (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];

      if (rowValue === 'N/A' || rowValue === '' || rowValue === undefined || rowValue === null) {
        return false; 
      }

      const numericValue = parseFloat(rowValue);
      if (isNaN(numericValue)) {
        return false;
      }

      return numericValue <= parseFloat(filterValue);
    });
  };

  return {
    text: (rows, id, filterValue) => {

      const isNumericFilter = !isNaN(parseFloat(filterValue)) && isFinite(filterValue);

      if (isNumericFilter) {
        return numericFilter(rows, id, filterValue);
      }

      return rows.filter((row) => {
        const rowValue = row.values[id];

        if (id === "SV_chrom") {
          return String(rowValue) === String(filterValue);
        }

        if (rowValue === "" || rowValue === undefined || rowValue === null || rowValue === "N/A") {
          return false; 
        }

        return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase());
      });
    },

    multiSelect: (rows, id, filterValue) => {
      if (filterValue.length === 0) {
        return rows;
      }

      return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== "N/A" && rowValue !== "" && rowValue !== undefined && rowValue !== null && filterValue.includes(rowValue);
      });
    },

    numeric: numericFilter
  };
};
