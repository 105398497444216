

// table_config for  query section-variant

import React from "react";

import {mutation_taster, polyphen2hvarpred, siftpred} from "../snv-effect-prediction/SNV_Config";

export const tooltips = {
    variantclasses:  <div><span>SnpEff reports putative variant impact in order to make it easier quickly to categorize and prioritize variants. As defined by SnpEFF - see <a style={{color: "orange" }} href="https://pcingola.github.io/SnpEff/se_inputoutput/#impact-prediction" target="_blank">Effect prediction details"</a> section for a detailed explanation</span></div>
,
    v_tagged:"When applied,it will return variants that have been tagged only in the GPAP database",
    variantconsequences:<div><span>Ensembl Variation - Calculated variant consequences . see <a style={{color: "orange" }} href="https://www.ensembl.org/info/genome/variation/prediction/predicted_data.html" target="_blank">Effect prediction details</a></span></div>,
    mane:<div><span>MANE Select is the transcript agreed as universal standard for clinical reporting, NCBI - WWW Error Blocked Diagnostic <a style={{color: "orange" }} href="https://www.ncbi.nlm.nih.gov/refseq/MANE/" target="_blank">NCBI MANE</a></span></div>,
    clinvarclasses:"ClinVar aggregates information about genomic variation and its relationship to human health",
    panelApp: <div><span>Browse Genomics England virtual gene panels related to human disorders <a style={{color: "orange" }} href="https://panelapp.genomicsengland.co.uk/" target="_blank">Genomics England PanelApp</a></span></div>


}


export const variant_class = [
    {'value': "high", "label": "High"},
    {'value': "moderate", "label": "Moderate"},
    {'value': "low", "label": "Low",  },
    {'value': "modifier", "label": "Modifier" }
];

export const variant_consequences_class = [
    {'value': "transcript_ablation", "label": "transcript_ablation"},
    {'value': "splice_acceptor_variant", "label": "splice_acceptor_variant"},
    {'value': "splice_donor_variant", "label": "splice_donor_variant"},
    {'value': "stop_gained", "label": "stop_gained"},
    {'value': "frameshift_variant", "label": "frameshift_variant"},
    {'value': "stop_lost", "label": "stop_lost"},
    {'value': "start_lost", "label": "start_lost"},
    {'value': "transcript_amplification", "label": "transcript_amplification"},
    {'value': "feature_elongation", "label": "feature_elongation"},
    {'value': "feature_truncation", "label": "feature_truncation"},
    {'value': "inframe_insertion", "label": "inframe_insertion"},
    {'value': "inframe_deletion", "label": "inframe_deletion"},
    {'value': "missense_variant", "label": "missense_variant"},
    {'value': "protein_altering_variant", "label": "protein_altering_variant"},
    {'value': "splice_donor_5th_base_variant", "label": "splice_donor_5th_base_variant"},
    {'value': "splice_region_variant", "label": "splice_region_variant"},
    {'value': "splice_donor_region_variant", "label": "splice_donor_region_variant"},
    {'value': "splice_polypyrimidine_tract_variant", "label": "splice_polypyrimidine_tract_variant"},
    {'value': "incomplete_terminal_codon_variant", "label": "incomplete_terminal_codon_variant"},
    {'value': "start_retained_variant", "label": "start_retained_variant"},
    {'value': "stop_retained_variant", "label": "stop_retained_variant"},
    {'value': "synonymous_variant", "label": "synonymous_variant"},
    {'value': "coding_sequence_variant", "label": "coding_sequence_variant"},
    {'value': "mature_miRNA_variant", "label": "mature_miRNA_variant"},
    {'value': "prime_5_UTR_variant", "label": "5_prime_UTR_variant"},
    {'value': "prime_3_UTR_variant", "label": "3_prime_UTR_variant"},
    {'value': "non_coding_transcript_exon_variant", "label": "non_coding_transcript_exon_variant"},
    {'value': "intron_variant", "label": "intron_variant"},
    {'value': "NMD_transcript_variant", "label": "NMD_transcript_variant"},
    {'value': "non_coding_transcript_variant", "label": "non_coding_transcript_variant"},
    {'value': "coding_transcript_variant", "label": "coding_transcript_variant"},
    {'value': "upstream_gene_variant", "label": "upstream_gene_variant"},
    {'value': "downstream_gene_variant", "label": "downstream_gene_variant"},
    {'value': "TFBS_ablation", "label": "TFBS_ablation"},
    {'value': "TFBS_amplification", "label": "TFBS_amplification"},
    {'value': "TF_binding_site_variant", "label": "TF_binding_site_variant"},
    {'value': "regulatory_region_ablation", "label": "regulatory_region_ablation"},
    {'value': "regulatory_region_amplification", "label": "regulatory_region_amplification"},
    {'value': "regulatory_region_variant", "label": "regulatory_region_variant"},
    {'value': "intergenic_variant", "label": "intergenic_variant"},
    {'value': "sequence_variant", "label": "sequence_variant"}
];


export const variant_type = [
    {'value': "snv", "label": "SNV",  },
    {'value': "indel", "label": "INDEL", },
];

export const mane_select = [
    {'value': "Mane", "label": "Mane Select",  },
];
export const tagged_variants = [
    {'value': "selected", "label": "Selected samples" },
    {'value': "any", "label": "Any Sample"},
];

export const clinvar = [
    {'value': "P", "label": "Pathogenic", },
    {'value': "L", "label": "Likely pathogenic",  },
    {'value': "U", "label": "Variant of uncertain significance", },
    {'value': "C", "label": "Conflicting interpretations",},
    {'value': "D", "label": "Drug response", },
    {'value': "A", "label": "Any", },
    ];


export const transcript = [
    {'value': "other", "label": "Other",  },
    {'value': "coding", "label": "Coding",  },
    {'value': "RNA", "label": "RNA",  },
];

/*
export const cgi_oncogenic = [
    {'value': "K", "label": "Known"},
    {'value': "P1", "label": "Predicted driver: Tier 1"},
    {'value': "P2", "label": "Predicted driver: Tier 2"},
    {'value': "PP", "label": "Predicted passenger"}
];*/


export const dict_classes_variants = {
        "clinvarclasses": clinvar,
        "variantclasses": variant_class,
        "variantconsequences": variant_consequences_class,
        "genotypefeatures": transcript,
        "snv": variant_type,
        "indel": variant_type,
        "mutationtaster": mutation_taster,
        "siftpred": siftpred,
        "polyphen2hvarpred": polyphen2hvarpred

};