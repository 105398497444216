import {
  createItalicsParagraph,
  createTitle,
  createKeyValueParagraph,
  createParagraphWithSpacing,
  createBoldUnderlinedParagraph,
  createHeadTitle,
  createSubTitle,
  createSpacer
} from "../../write-sections/write_utils/documentHelpers";

function AnnexesSomatic(allDataApis, annexesFields) {

  
  return [
    createHeadTitle("ANNEXES"),
    createSpacer(50, 50),
    createParagraphWithSpacing("The following annexes complete the report, which should be reviewed as a whole."),
    createSpacer(50, 50),
    createTitle("ANNEX I: CLINICAL RECORD"),
    createTitle("ANNEX II: TUMOUR GENOMIC CHARACTERISTICS"),
    createTitle("ANNEX III: OTHER BIOMARKERS"),
    createTitle("ANNEX IV: METHODOLOGY"),
    createTitle("ANNEX V: QUALITY CONTROL METRICS"),
    createBoldUnderlinedParagraph("ANNEX I: CLINICAL RECORD"),
    createParagraphWithSpacing("Cancer type", true),
    createParagraphWithSpacing(allDataApis?.participants?.[0].cancer_type || "NA"),
    createParagraphWithSpacing("Family history", true),
    createParagraphWithSpacing(annexesFields.family_history || "NA"),
    createParagraphWithSpacing("Treatment", true),
    createParagraphWithSpacing(annexesFields?.treatment || "NA"),
    createBoldUnderlinedParagraph("ANNEX II: TUMOUR GENOMIC CHARACTERISTICS"),
    createKeyValueParagraph("Purity", allDataApis?.tumor?.tumor_purity || "Tumor purity cannot be determined for this sample."),
    createKeyValueParagraph("Average ploidy", allDataApis?.tumor?.average_ploidy || "Average ploidy cannot be determined for this sample."),
    createBoldUnderlinedParagraph("ANNEX III: OTHER BIOMARKERS"),
    createKeyValueParagraph("Microsatellite status", allDataApis?.tumor?.microsatellite_status || "Microsatellite status cannot be determined for this sample."),
    createKeyValueParagraph("Tumour mutation load", allDataApis?.tumor?.tumor_mutational_load || "Tumour mutation load cannot be determined for this sample."),
    createKeyValueParagraph("Tumour mutational burden", allDataApis?.tumor?.tumor_mutational_burden || "Tumour mutational burden cannot be determined for this sample."),
    createKeyValueParagraph("Homologous Recombination Deficiency", allDataApis?.tumor?.homologous_recombination_deficiency_status|| "Homologous Recombination Deficiency cannot be determined for this sample."),
    createKeyValueParagraph("Mutational profiles", allDataApis?.tumor?.mutational_profiles || "Mutational profiles cannot be determined for this sample."),
    createKeyValueParagraph("Molecular tissue of origin prediction", allDataApis?.tumor?.molecular_tissue || "Molecular tissue of origin prediction cannot be determined for this sample."),//Pending
    createKeyValueParagraph("Tumor specific viral insertions", allDataApis?.tumor?.tumor_specific || "Tumor specific viral insertions cannot be determined for this sample."),//Pending
    createBoldUnderlinedParagraph("ANNEX IV: METHODOLOGY"),
    createKeyValueParagraph("Test method", `${allDataApis?.experiments?.[0].library_strategy} using the ${allDataApis?.experiments?.[0].kit }` || "NA"),
    createKeyValueParagraph("Type of variants assessed", allDataApis?.type_of_variants_assessed || "NA"),//Pending
    createKeyValueParagraph("Sample received", annexesFields?.sample_received || "NA"),//Pending
    createKeyValueParagraph("Sample type", allDataApis?.samples.sample_type|| "NA"),
    createKeyValueParagraph("Sample condition", allDataApis?.samples.sample_status),
    createKeyValueParagraph("Site of tumour", allDataApis?.samples.sample_tissue_of_origin),
    createKeyValueParagraph("Treatment given before sample was taken", annexesFields?.treatment_given_before || "NA"),
    createKeyValueParagraph("Date of sample collection", allDataApis?.samples.date_of_sample_collection || "NA"),
    createSpacer(50, 50),
    createSubTitle("Sample information"),
    createSpacer(50, 50),
    createKeyValueParagraph("DNA purity", annexesFields?.dna_purity || "NA"),//Pending
    createKeyValueParagraph("DNA integrity", annexesFields?.dna_integrity || "NA") ,//Pending
    createSpacer(50, 50),
    createSubTitle("Sample processing"),
    createSpacer(50, 50),
    createKeyValueParagraph("Center", allDataApis?.centre  || "NA"),//Pending
    createKeyValueParagraph("Sequencing platform", allDataApis?.sequencing_platform || "NA"),//Pending
    createKeyValueParagraph("Fragment (size and type)", allDataApis?.fragment || "NA"),//Pending
    createSpacer(50, 50),
    createSubTitle("Bioinformatics analysis"),
    createSpacer(50, 50),
    createKeyValueParagraph("Human reference genome", allDataApis?.human_reference_genome || "NA"),//Pending
    createKeyValueParagraph("Aligner", allDataApis?.aligner || "NA"),//Pending
    createKeyValueParagraph("Mark duplicates", allDataApis?.mark_duplicates || "NA"),//Pending
    createKeyValueParagraph("Base recalibration", allDataApis?.base_recalibration || "NA"),//Pending
    createKeyValueParagraph("Smart variants caller", allDataApis?.smart_variants_caller || "NA"),//Pending
    createKeyValueParagraph("Analysis pipeline", allDataApis?.analysis_pipeline || "NA"),//Pending
    createSpacer(50, 50),
  ];  
}

export default AnnexesSomatic;