import axios from "axios";

export const apiData = async (data, config) => {

  const { url, method, endpoint, idRelation, files, token } = config[0];





  let response;

  const headers = {
    Authorization: `Bearer ${token}`,
  };


  // collect all ids first

  // Collect all RD-Connect Experiment IDs
  const idValues = data.map(function(item){

    //
    if(item.analysis !== "germline"){
      return item?.[idRelation];
    }
    else{
      return item?.[config[1].idRelation];
    }

  }).filter(id => id);

// Check if there are any IDs to query
  if (idValues.length === 0) {
    // Return data with "N/A" for each file if no IDs
    const naFilesData = files.reduce((acc, file) => {
      return {...acc, [file]: "N/A"};
    }, {});
    return data.map(item => ({...item, ...naFilesData}));
  }

// Make a single request with the array of IDs

  const DM_Field_Experiment = "RD_Connect_ID_Experiment"

  const body = {
    "page": 1,
    "pageSize": idValues.length,
    "filtered": [{
      "id": DM_Field_Experiment,
      "value": idValues
    }],
    "fields": files,
    "sorted": [],
  };

  if (method.toLowerCase() === "get") {
    response = await axios[method.toLowerCase()](
        `${url}/${endpoint}/`,
        { headers, params: { ids: idValues } }  // Assuming you can send IDs as URL params for GET
    );
  } else {
    response = await axios[method.toLowerCase()](`${url}/${endpoint}/`, body, { headers });
  }

// Process the response
  const responseData = response.data.items || [];
  const idToDataMap = {};

// Create a mapping from ID to file data
  responseData.forEach(item => {
    const id = item[DM_Field_Experiment];
    const filesData = {};

    files.forEach(file => {
      filesData[file] = item[file] || "N/A";
    });

    idToDataMap[id] = filesData;
  });

// Map the original data to include files data
  const dataPromise = data.map(item => {

    const idField = (item.analysis === "germline") ? config[1].idRelation : config[0].idRelation;

    const idValue = item?.[idField];
    let filesData = idToDataMap[idValue] || {};

    if (Object.keys(filesData).length === 0) {
      filesData = {};
      files.forEach(file => {
        filesData[file] = "N/A";
      });
    }

    return { ...item, ...filesData };
  });

  return dataPromise;





};
