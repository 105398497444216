export const instand_default_query = {
    "size":3000,
    "from":0,
    "fromCNV":0,
    "chrom":[],
    "indel":false,
    "freqInt": null,
    "svn":false,
    "genotypefeatures":{"other":false,"coding":false,"RNA":false},
    "variantclasses":{"high": true, "low": false, "moderate": false, "modifier": false},
    "mutationtaster":{"A":false,"D":false,"P":false},
    "clinvarclasses":{"P":false,"L":false,"A":false, "U":false,"C":false,"D":false},
    "onco_filter":{"K":false,"P1":false,"P2":false,"PP":false},
    "polyphen2hvarpred":{"D":false,"P":false,"B":false},
    "population":{"gnomad_af":null,"gp1_af":null,"exac":null},
    "siftpred":{"D":false,"T":false},
    "gnomad_filter":{"pass":false,"nonpass":false},
    "gene":[],
    "samples_germline":[],
    "samples_somatic":[],
    "compound_in":false,
    "cadd_phred":null,
    "cosmic":false,
    "qc_filter":{"dp_tumor":10,"dp_control":10,"dp_ref_tumor":10,"dp_alt_tumor":3,"vaf_tumor_low":0.05,"vaf_tumor_high":0.8},
    "nprograms":0,
    "programs_filter":{"mutect":false,"strelka":false,"caveman":false,"muse":false,"lancet":false},
    "variantconsequences": {
        "transcript_ablation": false,
        "splice_acceptor_variant": false,
        "splice_donor_variant": false,
        "stop_gained": false,
        "frameshift_variant": false,
        "stop_lost": false,
        "start_lost": false,
        "transcript_amplification": false,
        "feature_elongation": false,
        "feature_truncation": false,
        "inframe_insertion": false,
        "inframe_deletion": false,
        "missense_variant": false,
        "protein_altering_variant": false,
        "splice_donor_5th_base_variant": false,
        "splice_region_variant": false,
        "splice_donor_region_variant": false,
        "splice_polypyrimidine_tract_variant": false,
        "incomplete_terminal_codon_variant": false,
        "start_retained_variant": false,
        "stop_retained_variant": false,
        "synonymous_variant": false,
        "coding_sequence_variant": false,
        "mature_miRNA_variant": false,
        "prime_5_UTR_variant": false,
        "prime_3_UTR_variant": false,
        "non_coding_transcript_exon_variant": false,
        "intron_variant": false,
        "NMD_transcript_variant": false,
        "non_coding_transcript_variant": false,
        "coding_transcript_variant": false,
        "upstream_gene_variant": false,
        "downstream_gene_variant": false,
        "TFBS_ablation": false,
        "TFBS_amplification": false,
        "TF_binding_site_variant": false,
        "regulatory_region_ablation": false,
        "regulatory_region_amplification": false,
        "regulatory_region_variant": false,
        "intergenic_variant": false,
        "sequence_variant": false
    },        "cnv_germline":true,
    "cnv_somatic":false};

