import { AlignmentType, HeadingLevel, Paragraph, TextRun } from "docx";
import { drawBasicTable } from "../write_utils/drawTables_examples.js";
import { drawClinicalEvidenceTable } from "../write_utils/drawClinicalEvidenceTable.js";
import { bodyText, TEXT_FOR_NEGATIVE_CLINICAL_EVIDENCE,fontDoc } from "../../config/template_config.js";
import { renderToString } from "../../../create-report/shared-components/renderToString.js";
import { createSpacer } from "../write_utils/documentHelpers";
import { htmlToText } from 'html-to-text';

const headers = [
    "Gene",
    "Drug",
    "Level of evidence",
    "Clinical significance",
];

const dataKeys = [
    "gene",
    "therapy",
    "evidence_level",
    "interpretation",
];

function WriteClinicalEvidenceSection(sectionData) {
   
    let rows = [];
    let list = [];
    let conclusion = "";

    const renderRows = (rows) => {
        const sections = rows.map(row => {
            let gene = (row.gene !== undefined) ? row.gene : "NA";
            let therapy = (row.therapy !== undefined) ? row.therapy : "NA";

            const text = therapy + "(" + gene + ")";
            const titlePar = new Paragraph({
                children: [
                    new TextRun({
                        text: text,
                        bold: true,
                        font: fontDoc,
                    })
                ],
                spacing: { after: 40, before: 40 },
            });

            const CommentPar = new Paragraph({
                children: [
                    new TextRun({
                        text: row.comment || renderToString("No additional information provided."),
                        italics: !row.comment,
                        font: fontDoc,
                        color: !row.comment ? "888888" : "000000",
                    }),
                ],
            });

            const spacerPar = createSpacer(50, 50);

            return [titlePar, CommentPar, spacerPar];
        });
        return sections.flat();
    }

    if (sectionData !== null && sectionData.rows) {
        rows = sectionData.rows;
        conclusion = sectionData.conclusion;

        list = (rows.length !== 0)
            ? renderRows(rows)
            : [new Paragraph({
                children: [
                    new TextRun({
                        text: renderToString("No additional information provided"),
                        italics: true,
                        font: fontDoc,
                        color: "888888",
                    }),
                ]
            }),];
    }

    const clinicalEvidenceTitleHtml = renderToString("CLINICAL EVIDENCE AND PATIENT MANAGEMENT");
    const clinicalEvidenceTitleText = htmlToText(clinicalEvidenceTitleHtml, {
        wordwrap: false,
        uppercaseHeadings: false,
    });

    const infoHtml = renderToString("Only information relevant to the specific type of cancer under investigation is reported.");
    const infoText = htmlToText(infoHtml, {
        wordwrap: false,
        uppercaseHeadings: false,
    });

    const clinicalSignificanceHtml = renderToString("Clinical Significance");
    const clinicalSignificanceText = htmlToText(clinicalSignificanceHtml, {
        wordwrap: false,
        uppercaseHeadings: false,
    });

    const conclusionHtml = renderToString("Conclusion");
    const conclusionText = htmlToText(conclusionHtml, {
        wordwrap: false,
        uppercaseHeadings: false,
    });

    return [
        new Paragraph({
            children: [
                new TextRun({
                    text: clinicalEvidenceTitleText,
                    size: 24,
                    color: '#000000',
                    bold: true,
                    font: fontDoc,
                    heading: HeadingLevel.HEADING_2,
                    underline: true
                }),
            ]
        }),
        new Paragraph({
            text: "",
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: infoText,
                    size: 22,
                    color: '#000000',
                    font: fontDoc,
                    heading: HeadingLevel.HEADING_3,
                }),
            ]
        }),
        new Paragraph({
            text: "",
        }),
        drawClinicalEvidenceTable(rows, headers, dataKeys, TEXT_FOR_NEGATIVE_CLINICAL_EVIDENCE),
        new Paragraph({
            text: "",
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: clinicalSignificanceText,
                    size: 24,
                    color: '#000000',
                    bold: true,
                    font: fontDoc,
                    heading: HeadingLevel.HEADING_2,
                    underline: true
                }),
            ]
        }),
        ...list,
        new Paragraph({
            children: [
                new TextRun({
                    text: conclusionText,
                    size: 24,
                    color: '#000000',
                    bold: true,
                    font: fontDoc,
                    heading: HeadingLevel.HEADING_2,
                    underline: true
                }),
            ]
        }),
        new Paragraph({
            children: [
                new TextRun({
                text: conclusion !== "" ? conclusion : renderToString("No additional information provided."),
                italics:!conclusion,
                color: !conclusion? "888888" : "000000",
                alignment: AlignmentType.LEFT, 
                spacing: {after: 10, before: 10}
                })
            ],        
        }),

    ];
}

export default WriteClinicalEvidenceSection;