import { AlignmentType, Paragraph, Table, TableCell, TableRow, TextRun, WidthType, BorderStyle, VerticalAlign, ShadingType } from "docx";
import { bodyText } from "../../config/template_config";
import { renderToString } from "../../../create-report/shared-components/renderToString";



const table_margins = {
    top: 100,
    bottom: 100, 
    left: 100, 
    right: 100 
};


function writeHeaders(headerNames) {
    return [
        new TableRow({
            tableHeader: true,
            children: headerNames.map((text, index, array) => {
                const localizedText = renderToString(text)
                return new TableCell({
                    children: [new Paragraph({ children: [new TextRun({ text: localizedText, bold: true })], alignment: AlignmentType.LEFT  })],
                    width: {
                        size: 1505,
                        type: WidthType.DXA,
                    },
                    height: {
                        size: 1505,
                        type: WidthType.DXA,
                    },
                    margins: table_margins,
                    verticalAlign: VerticalAlign.CENTER,
                    shading: {
                        fill: "#fafafa", // Light Gray
                        type: ShadingType.CLEAR,
                    },
                    borders: {
                        top: { style: BorderStyle.SINGLE, size: 20, color: "#033C58" },
                        bottom: { style: BorderStyle.SINGLE, size: 20, color: "#033C58" },
                        left: index === 0 ? { style: BorderStyle.SINGLE, size: 20, color: "#033C58" } : { style: BorderStyle.NONE, color: "#FFFFFF"  },
                        right: index === array.length - 1 ? { style: BorderStyle.SINGLE, size: 20, color: "#033C58" } : { style: BorderStyle.NONE, color: "#FFFFFF"  },
                    },
                });
            })
        })
    ];
}

function writeRows(data, dataKeys) {
    return data.map(function (element, rowIndex, dataArray) {
        let cells = dataKeys.map((key, index, array) => {
            let value = (element[key] !== undefined) ? String(element[key]) : "NA";
            return new TableCell({
                children: [new Paragraph({
                    text: value,
                    alignment: AlignmentType.LEFT,
                })],
                width: {
                    size: 8505,
                    type: WidthType.DXA,
                },
                margins: table_margins,
                verticalAlign: VerticalAlign.CENTER,
                borders: {
                    top: { style: BorderStyle.SINGLE, size: 20, color: "#033C58" },
                    bottom: rowIndex === dataArray.length - 1 ? { style: BorderStyle.SINGLE, size: 20, color: "#033C58" } : { style: BorderStyle.NONE, color: "#FFFFFF"  },
                    left: index === 0 ? { style: BorderStyle.SINGLE, size: 20, color: "#033C58" } : { style: BorderStyle.NONE, color: "#FFFFFF"  },
                    right: index === array.length - 1 ? { style: BorderStyle.SINGLE, size: 20, color: "#033C58" } : { style: BorderStyle.NONE, color: "#FFFFFF"  },
                },                
            });
        });

        return new TableRow({
            children: cells,
            alignment: AlignmentType.LEFT,
            verticalAlign: VerticalAlign.LEFT,
        });
    });
}

function drawGeneticFindingsTable(data, headerNames, dataKeys, no_data_text) {

    if (data.length !== 0) {
        const headers = writeHeaders(headerNames);
        const dataRows = writeRows(data, dataKeys);
        const rows = headers.concat(dataRows);

        return new Table({
            rows: rows,
            alignment: AlignmentType.CENTER,
            spacing: { after: 100, before: 100 }
        });
    } else {
        return new Paragraph({
            children: [bodyText(no_data_text)],
            alignment: AlignmentType.LEFT,
            spacing: { after: 100, before: 100 }
        });
    }
}

export { drawGeneticFindingsTable }