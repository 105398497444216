import { Table, TableRow, TableCell, Paragraph, TextRun, WidthType, BorderStyle } from "docx";
import {capitalizeFirstLetter, createSpacer} from "../write_utils/documentHelpers";
import { renderToString } from "../../../create-report/shared-components/renderToString";
import { convertDate } from "../../../create-report/sections/utils";
export default function WriteGeneralInfo ( dirSelected, allDataApis, studyPerformed) {

    return [new Table({
        width: { size: 100, type: WidthType.PERCENTAGE },
        rows: [
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            children: [
                                new TextRun({
                                    text: renderToString("Issuing centre: "),
                                    bold: true
                                }),
                                new TextRun(dirSelected.issuingCentre|| 'NA')
                            ]
                        })],
                        borders: {
                            top: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            bottom: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            right: { style: BorderStyle.NONE, color: "#FFFFFF" },
                        },
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [
                                new TextRun({
                                    text: renderToString("Requesting centre: "),
                                    bold: true
                                }),
                                new TextRun(allDataApis?.dir?.[0].hospital_name|| 'NA')
                            ]
                        }), new Paragraph({ text: "" })],
                        borders: {
                            top: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            bottom: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            left: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            right: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                        },
                    }),
                ],

            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Reporting date: "),
                                        bold: true
                                    }),
                                    new TextRun(convertDate(allDataApis?.dir?.[0].creation_date) || 'NA')
                                ]
                            }), new Paragraph({ text: "" })
                        ],
                        borders: {
                            top: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            bottom: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            right: { style: BorderStyle.NONE, color: "#FFFFFF" }
                        },
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Referrer: "),
                                        bold: true
                                    }),
                                    new TextRun( allDataApis?.dir?.[0].clinical_referrer|| 'NA')
                                ]
                            })],
                        borders: {
                            top: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            bottom: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            left: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            right: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                        },
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Patient ID: "),
                                        bold: true
                                    }),
                                    new TextRun(allDataApis?.participants?.[0].report_id ||'NA')
                                ]
                            }),
                        ],
                        borders: {
                            top: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            bottom: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            right: { style: BorderStyle.NONE, color: "#FFFFFF" }
                        },
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                text: ""
                            })],
                        borders: {
                            top: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            bottom: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            left: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            right: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                        },
                    }),
                ],

            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Name: "),
                                        bold: true
                                    }),
                                    new TextRun(allDataApis?.participants?.[0].first_name || "NA")
                                ],
                            }),],
                        borders: {
                            top: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            bottom: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            right: { style: BorderStyle.NONE, color: "#FFFFFF" }
                        },
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Date of Birth: "),
                                        bold: true,
                                    }),
                                    new TextRun(convertDate(allDataApis?.participants?.[0].birth)||'NA')
                                ]
                            })
                        ],
                        borders: {
                            top: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            bottom: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            left: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            right: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                        },
                    })],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Surname: "),
                                        bold: true
                                    }),
                                    new TextRun(allDataApis?.participants?.[0].last_name || "NA")
                                ]
                            }), new Paragraph({ text: "" })],
                        borders: {
                            top: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            bottom: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            right: { style: BorderStyle.NONE, color: "#FFFFFF" }
                        },
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: renderToString("Sex: "),
                                        bold: true
                                    }),
                                    new TextRun(
                                        allDataApis?.participants?.[0].sex === 'M'
                                            ? 'Male'
                                            : allDataApis?.participants?.[0].sex === 'F'
                                                ? 'Female'
                                                : 'NA'
                                    )
                                ]
                            })
                        ],
                        borders: {
                            top: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            bottom: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            left: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            right: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                        },
                    })]
            }),
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            children: [
                                new TextRun({
                                    text: renderToString("Sample type: "),
                                    bold: true
                                }),
                                new TextRun(capitalizeFirstLetter(allDataApis?.samples?.sample_type) || "NA")
                            ]
                        })],
                        borders: {
                            top: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            bottom: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            left: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            right: { style: BorderStyle.NONE, color: "#FFFFFF" },
                        },
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [
                                new TextRun({
                                    text: renderToString("Biopsy location: "),
                                    bold: true
                                }),
                                new TextRun(capitalizeFirstLetter(allDataApis?.samples?.sample_tissue_of_origin) || "NA")
                            ]
                        })
                        ],
                        borders: {
                            top: { style: BorderStyle.NONE, color: "#FFFFFF"},
                            bottom: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                            left: { style: BorderStyle.NONE, color: "#FFFFFF" },
                            right: { style: BorderStyle.SINGLE, size: 3, color: "#033C58" },
                        },
                    })],
            }),
        ],
    }),
        createSpacer(50, 50),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("REASON FOR REFERRAL: "),
                    bold: true
                }),
                new TextRun(capitalizeFirstLetter(allDataApis?.participants?.[0]?.referral) || "NA")
            ]
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("PERFORMED STUDY: "),
                    bold: true
                }),
                new TextRun({
                    text: renderToString(studyPerformed) || renderToString("No additional information provided."), 
                    italics: !studyPerformed,
                    color: !studyPerformed ? "888888" : "000000",
                }),
            ]
        }),
        new Paragraph({
            children: [
                new TextRun({
                    text: renderToString("TEST PERFORMED (Library strategy): "),
                    bold: true
                }),
                new TextRun(capitalizeFirstLetter(allDataApis?.experiments?.[0].library_strategy)|| 'NA')
            ]
        })];
}