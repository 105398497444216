import { fetchApi } from "../../../general-table/utils/fetchApi";
import { setTable } from "./table.slice";
import {aggregationFields, ExperimentFields} from "./request_config";
import axios from "axios";
export const loadingAllInfo = (dir_id, method, token) => async (dispatch) => {
  let idRelation;

  const url = window.config.apis_configuration.api_nextgpap_server;

  const options = {
    urlBase: url,
    token: token,
    data: {},
    method,
    endpoint: "dir",
    identifier: dir_id,
  };  
  
  try {
    dispatch(setTable({ option: "isLoadingApis", value: true }));

    const apiDIRS = await fetchApi({
      urlBase: url,
      token: token,
      method: "GET",
      endpoint: "dirs",
    });


    const api1 = await fetchApi({
      ...options,
      method: "GET",
      token: token,
      data: {
        "page": 1,
        "pageSize": 1,
        "filtered": [{"id": "dir_id", "value": [dir_id]}],
        "fields": [],
        "sorted": [],
      },
    });

  
    let tumor_experiment_id = api1.item.tumor_experiment_id;
    let control_experiment_id = api1.item.control_experiment_id;
    const experiment_id = tumor_experiment_id || control_experiment_id; 

    const api2 = await fetchApi({
      ...options,
      token: "Bearer " + token,
      urlBase: window.config.apis_configuration.api_data_management,
      data: {
        "page": 1,
        "pageSize": 20,
        "filtered": [{"id": "ExperimentID", "value": [experiment_id]}],
        "fields": ExperimentFields,
        "sorted": [],
        "aggregates": aggregationFields
      },
      endpoint: "experimentsview/",
      identifier: ""
    });

    idRelation = api2.items[0].Participant_ID;

    const api3 = await fetchApi({
      ...options,
      urlBase: window.config.apis_configuration.api_phenostore,
      data: {
        "page": 1,
        "pageSize": 10,
        "sorted": [],
        "filtered": [{"id": "report_id", "value": idRelation}],
        "aggrKeys": ["sex", "affectedStatus", "solved", "index"]
      },
      endpoint: "participants", // phenostore endpoint with all fields
      identifier: "",
    });

    let sample = api2.items[0].sample;

    const api4 = await fetchApi({
      ...options,
      token: "Bearer " + token,
      method: "GET",
      urlBase: window.config.apis_configuration.api_data_management,
      data: {},
      endpoint: "sample",
      identifier: "?sample=" + sample,
    });

    let api5;
    try {
      api5 = await fetchApi({
        ...options,
        token: "Bearer " + token,
        method: "GET",
        urlBase: window.config.apis_configuration.api_data_management,
        endpoint: "tumor",
        identifier: "?experiment=" + experiment_id,
      });
    } catch (error) {
      console.error("Error fetching tumor data:", error);
      api5 = null; // or any default value you want to assign
    }

    const newDataObject = {
      dir: apiDIRS.items,
      dirs: api1.item,
      experiments: api2.items,
      participants: api3.rows,
      samples: api4,
      tumor: api5,
    };


    dispatch(setTable({ option: "isLoadingApis", value: false }));
    dispatch(setTable({ option: "allDataApis", value: newDataObject }));

  }
  catch (error) {
    console.log("Errors", error);
  }
};

export const createClinicalReport = (formData, token) => async (dispatch) => {
  const baseUrl = window.config.apis_configuration.api_nextgpap_server;
  const apiUrl = `${baseUrl}/clinical_report`;


  for (let [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
  }

  try {
      const response = await axios.post(apiUrl, formData, {
          headers: {
              'Authorization': `${token}` 
          }
      });

      console.log('Server:', response.data); 

      dispatch(setTable({ option: "createSuccess", value: true }));

      return response.data; 

  } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message); 

      dispatch(setTable({ option: "createSuccess", value: false }));
  }
};