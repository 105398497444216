import React from 'react';
import { Typography } from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';
import IconLeftExpansionPanelSummary from "../gpap-table/ParticipantTable/LeftIconExpansion";

const IconLeftSummary = ({ tooltipContent, infoIconElement }) => {
    return (
        <IconLeftExpansionPanelSummary
            style={{ backgroundColor: "#f0f0f0" }}
            expandIcon={<ExpandMoreOutlined />}
            IconButtonProps={{ edge: 'start' }}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography
                style={{
                    flex: '1 1 100%',
                    color: "rgb(69, 117, 180)"
                }}
                variant="h6"
                component="div"
            >
                {tooltipContent !== "" && (
                    <div>{tooltipContent} {infoIconElement}</div>
                )}
            </Typography>
        </IconLeftExpansionPanelSummary>
    );
};

export default IconLeftSummary;
