import  React, {useState,useEffect} from "react";
import { Grid, Box, } from "@mui/material";
import GeneralTable from "../../../../../create-report/shared-components/general-table/GeneralTable.js";
import { SOMATIC_GENOME_COVERAGE } from "../config/somatic_genome_coverage.js";


export default function SomaticGenomeCoverage({data}) {


    return (
        <Grid item xs={12}>
                <Box>
                <GeneralTable
                    dataType={"coverage of genes of interest"}
                    data={ data }
                    columns={ SOMATIC_GENOME_COVERAGE} />
                </Box>
        </Grid>
    );
}
